import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { NavigationEnd, Router, RouterStateSnapshot } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

import { Config } from 'config';
import { UnifiiLoginPath, UnifiiPasswordChangePath, UserAccessRootPath } from 'discover/discover-constants';
import { AppError } from 'shell/errors/errors';
import { SavedUsersService } from 'shell/services/saved-users.service';
import { UserAccessManager } from 'shell/services/user-access-manager';

/**
 * Wrapper for the authentication process,
 * contains a router-outlet for steps in the authentication process
 * Contains html boilerplate and has request handler for
 * displaying progress and errors in the UI (requestHandler)
 *
 * Benefits: Creates a better UI experience, minimizes reload flashes,
 * reduces code duplication
 */
@Component({
	selector: 'ud-user-access',
	templateUrl: './user-access.html',
	standalone: false,
})
export class UserAccessComponent implements OnInit, OnDestroy {

	error: AppError | null;
	message: string | null;
	backButtonRoute: string | null;

	private router = inject(Router);
	private accessManager = inject(UserAccessManager);
	private savedUserService = inject(SavedUsersService);
	private config = inject(Config);
	private subscription = new Subscription();

	ngOnInit() {
		this.subscription.add(this.router.events.pipe(filter((e) => e instanceof NavigationEnd)).subscribe(() => {
			this.enableBackButton(this.router.routerState.snapshot);
		}));
		this.subscription.add(this.accessManager.errorEvent.subscribe((error) => { this.error = error; }));
		this.subscription.add(this.accessManager.deniedEvent.subscribe(({ message }) => { this.message = message ?? null; }));
	}

	ngOnDestroy() {
		this.subscription.unsubscribe();
	}

	// Show back button on unifii login screen, other pages have routing actions handled by custom buttons
	private enableBackButton(route: RouterStateSnapshot) {

		const url = route.url.split(';')[0];

		if (url === `/${UserAccessRootPath}/${UnifiiLoginPath}` &&
            ((this.savedUserService.enabled && this.savedUserService.users.length) || this.config.unifii.tenantSettings?.authProviders?.length)) {
			this.backButtonRoute = `/${UserAccessRootPath}`;

			return;
		}

		if (url === `/${UserAccessRootPath}/${UnifiiPasswordChangePath}`) {
			this.backButtonRoute = `/${UserAccessRootPath}/${UnifiiLoginPath}`;

			return;
		}

		this.backButtonRoute = null;
	}

}
