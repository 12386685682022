<uf-panel class="content-pane content scrollable bg-gray">
	@if (!uploadResult && form) {
		<div class="uf-container-lg bg-solid">
			<div [formGroup]="form" class="uf-grid pad-top row-gap-lg">
				<div class="uf-box col-12">
					@if (uploadType === userCsvUploadType.BASIC) {
						<div class="uf-app-bar accent medium">
							<div class="title">{{ discoverTK.UsersBasicCSVTemplateTitle | translate }}</div>
						</div>
						<div class="pad-sides">
							<p class="small">{{ discoverTK.UsersBasicCSVTemplateMessage | translate }}</p>
							<p class="small">{{ discoverTK.UsersBasicCSVTemplateUsageMessage | translate }}</p>
						</div>
					}
					@if (uploadType === userCsvUploadType.ADVANCED) {
						<div class="uf-app-bar accent medium">
							<div class="title">{{ discoverTK.UsersAdvancedCSVTemplateTitle | translate }}</div>
						</div>
						<div class="pad-sides">
							<p class="small">{{ discoverTK.UsersAdvancedCSVTemplateMessage | translate }}</p>
							<p class="small">
								{{ discoverTK.UsersAddMultipleAdvancedCSVMessage | translate }}
								@if (companyControl?.enabled) {
									<span>
										{{ discoverTK.UsersAddMultipleAdvancedCSVMessageCompany | translate }}
									</span>
								}
							</p>
						</div>
					}
					<div class="pad-right pad-bottom">
						<a [href]="csvTemplatePath" download class="uf-button right small">
							{{ discoverTK.UsersDownloadCSVTemplate | translate }}
						</a>
					</div>
				</div>
				<div class="uf-box col-12">
					<div class="uf-app-bar accent medium">
						@if (uploadType === userCsvUploadType.BASIC) {
							<div class="title">
								{{ discoverTK.UsersAddMultipleBasicCSVTitle | translate }}
							</div>
						}
						@if (uploadType === userCsvUploadType.ADVANCED) {
							<div class="title">
								{{ discoverTK.UsersAddMultipleAdvancedCSVTitle | translate }}
							</div>
						}
					</div>
					@if (fileControl) {
						<div class="uf-grid condensed">
							@if (!fileControl.value.length) {
								<div class="col-12 pad-sides">
									<p class="small">{{ discoverTK.UsersAddMultipleBasicCSVMessage | translate }}</p>
								</div>
							}
							@if (fileControl.value.length) {
								<div class="col-12 pad-bottom">
									<ul class="uf-list">
										@for (file of fileControl.value; track file; let i = $index) {
											<li class="uf-list-item">
												<span class="grow">{{ file.name }}</span>
												<button [title]="sharedTermsTK.ActionRemove | translate" (click)="removeFile()" type="button" class="uf-action tertiary small">
													<uf-icon name="delete" />
												</button>
											</li>
										}
									</ul>
								</div>
							}
							<div class="col-12 pad-sides pad-bottom">
								<uf-file [formControlName]="userInputControlKey.File" type=".csv" />
								<uf-error [control]="fileControl" />
							</div>
						</div>
					}
				</div>
				@if (canChangeUsernameControl && canChangeUsernameControl.enabled) {
					<div class="col-12 uf-box">
						<div class="uf-app-bar accent medium">
							<div class="title">{{ commonTK.UsernameLabel | translate }}</div>
						</div>
						<div class="uf-grid pad">
							<uf-checkbox [control]="canChangeUsernameControl" [label]="labelDictionary[userInfoControlKeys.CanChangeUsername]" class="col-12" />
						</div>
					</div>
				}
				@if (changePasswordOnNextLoginControl && changePasswordOnNextLoginControl.enabled) {
					<div class="col-12 uf-box">
						<div class="uf-app-bar accent medium">
							<div class="title">{{ commonTK.PasswordLabel | translate }}</div>
						</div>
						<div class="uf-grid pad">
							<uf-checkbox [control]="changePasswordOnNextLoginControl" [label]="discoverTK.UserCreateNewPasswordLabel | translate" class="col-12" />
						</div>
					</div>
				}
				@if (companyControl && companyControl.enabled) {
					<div class="col-12 uf-box">
						<div class="uf-app-bar accent medium">
							<div class="title">{{ commonTK.CompanyLabel | translate }}</div>
						</div>
						<div class="pad">
							<uf-company-input [control]="companyControl" [editableCompanies]="allowedCompanies" class="col-12 col-md-6" />
						</div>
					</div>
				}
				@if (unitsControl && unitsControl.enabled) {
					<div class="uf-box col-12 gap-top-sm">
						<div class="uf-app-bar flat medium accent">
							<div class="title">{{ commonTK.HierarchyLabel | translate }}</div>
						</div>
						<div class="uf-grid pad-bottom">
							<uf-hierarchy-input [control]="unitsControl" class="col-12" />
						</div>
					</div>
				}
				@if (rolesControl && rolesControl.enabled) {
					<div class="col-12 uf-box">
						<div class="uf-app-bar medium accent">
							<div class="title">{{ commonTK.RolesLabel | translate }}</div>
						</div>
						<div class="pad">
							<uf-role-input [control]="rolesControl" [editableRoles]="allowedRoles" />
						</div>
					</div>
				}
				@if (claimsControl && claimsControl.enabled) {
					<div class="col-12 uf-box">
						<div class="uf-app-bar accent medium">
							<div class="title">{{ commonTK.ClaimsLabel | translate }}</div>
						</div>
						<uf-claim-editor [control]="claimsControl" [editableClaimsValues]="allowedClaimsValues" [width]="fieldWidth.Half" class="col-12 pad-sm-bottom" />
					</div>
				}

				@if (error?.message) {
					<uf-blockquote [content]="error?.message" icon="errorSolid" class="col-12 error" />
				}
				<div class="col-12 row">
					<button (click)="back()" type="button" class="uf-button tertiary left">
						{{ sharedTermsTK.ActionCancel | translate }}
					</button>
					<button [disabled]="uploading" (click)="upload()" type="button" class="uf-button primary">
						{{ saveButtonLabel }}
					</button>
				</div>
			</div>
		</div>
	}
	@if (uploadResult) {
		<ud-user-upload-result [csvUpload]="true" [uploadResult]="uploadResult" (resolved)="back(true)" />
	}
</uf-panel>
