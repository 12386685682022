import { TableInputManager, TableInputs } from '@unifii/components';
import { FilterEntry, FilterValue, TableDataSource, TableDataSourceQueryOptions } from '@unifii/library/common';
import { CompaniesClient, Company, Query, RequestAnalyticsOrigin } from '@unifii/sdk';

export class CompanyTableDataSource extends TableDataSource<Company> {

	constructor(
		private companiesClient: CompaniesClient,
		private tableIdentifier: string,
		private tableInputManager: TableInputManager<FilterValue, FilterEntry>,
		private tableInputs?: TableInputs<FilterValue>,
	) {
		super();
	}

	get filtered(): boolean {
		return !!this.tableInputs?.q?.trim();
	}

	get sorted(): boolean {
		return this.tableInputs?.sort != null;
	}

	load(options?: TableDataSourceQueryOptions) {
		const query = this.buildQuery(options);

		return this.companiesClient.query(query, {
			signal: this.loadController?.signal,
			analytics: { origin: RequestAnalyticsOrigin.Table, id: this.tableIdentifier },
		}).then(
			(data) => { this.stream.next({ data }); },
			(error) => { this.stream.next({ error }); },
		);
	}

	private buildQuery(options?: TableDataSourceQueryOptions): Query {
		return this.tableInputManager.createQuery(this.tableInputs).limit(options?.limit, options?.offset);
	}

}
