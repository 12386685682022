import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild, inject } from '@angular/core';
import { ChartConfiguration, ChartData } from 'chart.js';
import Chart from 'chart.js/auto'; // chartjs 3 is treeshakable by default, but since ours is driven by back end config, we import everything from 'auto' to be safe

import { DiscoverContext } from 'discover/discover-context';

@Component({
	selector: 'us-chart',
	templateUrl: 'chart.html',
	styleUrls: ['./chart.less'],
	standalone: false,
})
export class ChartComponent implements AfterViewInit, OnInit {

	@ViewChild('canvas', { static: true }) canvas: ElementRef;
	@ViewChild('image', { static: true }) image: ElementRef;

	@Input() colours: string[];

	private context = inject(DiscoverContext);
	private chart: Chart;
	private _config: ChartConfiguration;

	ngOnInit() {
		// grab some colours from the project theme if no colours provided
		if (!this.colours && this.context.project?.theme) {
			const theme = this.context.project.theme;

			this.colours = [
				'' + theme.brandAccent,
				'' + theme.brand,
				'' + theme.primaryAction,
			].filter((colour) => !!colour);
		}
	}

	@Input() set config(v: ChartConfiguration) {
		if (v.options) {
			v.options.animation = {
				onComplete: () => {
					this.updateSnapshot();
				},
			};
		}

		this._config = v;
		if (this.chart) {
			this.chart.update();
		} else {
			this.init();
		}
	}

	get config(): ChartConfiguration {
		return this._config;
	}

	ngAfterViewInit() {
		this.init();
	}

	addData(newData: ChartData) {
		for (const dataset of newData.datasets) {
			// assign a colour, fill, and add the dataset
			if (this.config.type === 'pie' || this.config.type === 'doughnut') {
				dataset.backgroundColor = dataset.backgroundColor ?? this.colours;
				dataset.borderColor = dataset.borderColor ?? this.colours;
			} else {
				const palletteColour = this.colours && this.colours.length ? this.colours[this.chart.data.datasets.length % this.colours.length] : ''; // using remaider to avoid going out of bounds of colours array

				dataset.backgroundColor = dataset.backgroundColor ?? palletteColour;
				dataset.borderColor = dataset.borderColor ?? palletteColour;
			}
			this.chart.data.datasets.push(dataset);
		}

		if (newData.labels) {
			for (const label of newData.labels) {
				this.chart.data.labels?.push(label);
			}
		}

		this.chart.update();
	}

	clearData() {
		this.chart.data = {
			labels: [],
			datasets: [],
		};

		this.chart.update();
	}

	changeRatio(ratio: number) {
		if (this.config?.options && this.config.options.aspectRatio !== ratio) {
			this.config.options.aspectRatio = ratio;
			const savedData = Object.assign({}, this.chart.data);

			this.clearData();
			this.init();
			this.addData(savedData);
		}
	}

	updateSnapshot() {
		this.image.nativeElement.setAttribute('src', this.getChartAsImage());
	}

	downloadChartAsImage(filename = 'chart.png') {
		const a = document.createElement('a');

		a.href = this.getChartAsImage();
		a.download = filename;
		a.click();
	}

	private init() {

		if (this.chart) {
			this.chart.destroy();
		}

		if (!this.config) {
			console.warn('Chart config must be supplied');

			return;
		}

		this.chart = new Chart(this.canvas.nativeElement, this.config);
	}

	private getChartAsImage(): string {
		return this.canvas.nativeElement.toDataURL('image/png', 0.8);
	}

}
