import { Component, EventEmitter, Input, OnInit, Output, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CommonTranslationKey, DataDisplayListItem, ModalService, SharedTermsTranslationKey, UfControlArray, UfControlGroup, UfFormBuilder } from '@unifii/library/common';
import { PermissionAction, UserCreate, UserCreateResult, UserInfo, UserInvite, UsersClient } from '@unifii/sdk';
import { UserFormContext, UserKeys, UsersAddEditFormControl } from '@unifii/user-provisioning';

import { DiscoverTranslationKey } from 'discover/discover.tk';

import { UserAddComponent, UserAddModalData } from './user-add.component';

@Component({
	selector: 'ud-user-upload-result',
	templateUrl: './user-upload-result.html',
	styleUrls: ['user-upload-result.less', 'user-management.less'],
	standalone: false,
})
export class UserUploadResultComponent implements OnInit {

	@Input({ required: true }) uploadResult: UserCreateResult;
	@Input() users?: UserCreate[] | UserInvite[];
	@Input() csvUpload?: boolean;

	@Output() resolved = new EventEmitter<boolean>();

	protected readonly commonTK = CommonTranslationKey;
	protected readonly discoverTK = DiscoverTranslationKey;
	protected readonly sharedTermsTK = SharedTermsTranslationKey;
	protected readonly userInfoKey = UserKeys;
	protected readonly actionAdd = PermissionAction.Add;
	protected form: UfControlArray;
	protected uploadStatus: DataDisplayListItem[];
	protected context = inject(UserFormContext);

	private readonly maxUsersToManuallyEdit = 50;
	private busy: boolean;
	private router = inject(Router);
	private route = inject(ActivatedRoute);
	private modalService = inject(ModalService);
	private usersClient = inject(UsersClient);
	private translate = inject(TranslateService);
	private ufb = inject(UfFormBuilder);
	private userController = inject(UsersAddEditFormControl);

	ngOnInit() {
		this.init();
	}

	protected async save() {
		if (this.busy) {
			return;
		}

		try {
			this.busy = true;

			const failedUsers = this.form.controls.map((control) => this.userController.toDataModel(control as UfControlGroup)) as UserCreate[] | UserInvite[];
			const responseData = this.context.action === PermissionAction.Add ?
				await this.usersClient.bulkAdd(failedUsers as UserInfo[]) :
				await this.usersClient.bulkInvite(failedUsers as UserInvite[]);

			if (!responseData.errors?.length) {
				this.resolved.emit(true);
			} else {
				this.users = failedUsers;
				this.uploadResult = responseData;
				this.csvUpload = false;
				this.init();
			}
		} catch (e) {
			console.error(e);
		} finally {
			this.busy = false;
		}
	}

	protected back() {
		void this.router.navigate(['../../'], { relativeTo: this.route });
	}

	protected async editUser(index: number) {
		const control = this.form.controls[index] as UfControlGroup;
		const savedData = this.userController.toDataModel(control);

		const addModalInfo: UserAddModalData = {
			control: this.userController.buildRoot(savedData),
		};

		const userControl = await this.modalService.openMedium(UserAddComponent, addModalInfo, { guard: true });

		if (userControl) {
			this.form.controls[index] = userControl;
			this.form.updateValueAndValidity();
		}
	}

	protected removeUser(i: number) {
		this.uploadResult.errors?.splice(i, 1);
		this.form.removeAt(i);
	}

	private init() {

		let failedUsers: UserInvite[] | UserCreate[] = [];

		if (this.uploadResult.errors && this.uploadResult.errors.length <= this.maxUsersToManuallyEdit) {
			if (this.users) {
				failedUsers = this.context.action === PermissionAction.Add ?
					(this.users as UserCreate[]).filter((_user, index) => this.uploadResult.errors.find((error) => error.index === index)) :
					(this.users as UserInvite[]).filter((_user, index) => this.uploadResult.errors.find((error) => error.index === index));
			} else {
				failedUsers = this.context.action === PermissionAction.Add ?
					this.uploadResult.errors.map((error) => error.user as UserCreate | undefined).filter((user): user is UserCreate => !!user) :
					this.uploadResult.errors.map((error) => error.invitation as UserInvite | undefined).filter((user): user is UserInvite => !!user);
			}
		}

		this.form = this.ufb.array(failedUsers.map((user) => this.userController.buildRoot(user)));

		this.uploadStatus = [{
			term: this.translate.instant(DiscoverTranslationKey.UsersUploadResultSuccessLabel),
			data: this.uploadResult.successCount,
		}, {
			term: this.translate.instant(DiscoverTranslationKey.UsersUploadResultFailLabel),
			data: this.uploadResult.errors.length,
		}];
	}

}
