<ud-user-access-box>
	<ng-container content>
		@if (linkError) {
			<uf-blockquote [content]="linkError.message" icon="errorSolid" class="error gap-top small" />
		}

		@if (form) {
			<p>{{ discoverTK.CompleteRegistrationDescription | translate }}</p>
			<div class="grid">
				<form [formGroup]="form" (ngSubmit)="submit()" class="col-1of1 grid">
					@if (emailControl) {
						<uf-text [label]="labels[controlKeys.Email]" [control]="emailControl" class="col-1of1" />
					}
					@if (usernameControl) {
						<uf-text [label]="labels[controlKeys.Username]" [control]="usernameControl" class="col-1of1">
							<span class="suffix">*</span>
						</uf-text>
					}
					@if (firstNameControl) {
						<uf-text [label]="labels[controlKeys.FirstName]" [control]="firstNameControl" class="col-1of1">
							<span class="suffix">*</span>
						</uf-text>
					}
					@if (lastNameControl) {
						<uf-text [label]="labels[controlKeys.LastName]" [control]="lastNameControl" class="col-1of1">
							<span class="suffix">*</span>
						</uf-text>
					}
					@if (phoneControl) {
						<uf-text [label]="labels[controlKeys.Phone]" [control]="phoneControl" class="col-1of1" />
					}
					@if (passwordControl) {
						<uf-password-advanced [label]="labels[controlKeys.Password]" [control]="passwordControl" class="col-1of1">
							<span class="suffix">*</span>
						</uf-password-advanced>
					}
					@if (error) {
						<div class="col-1of1">
							<uf-blockquote [content]="error.message || (discoverTK.CompleteRegistrationActionRegisterFail | translate)" icon="errorSolid" class="error" />
						</div>
					}
					@if (passwordControl) {
						<uf-password-indicator [control]="passwordControl" [style.visibility]="!form.controls[controlKeys.Password]?.value ? 'hidden' : 'visible'" class="col-1of1" />
					}
					<div class="col-1of1 col center-all">
						<button [disabled]="busy || linkError" type="submit" class="uf-button primary">
							{{ discoverTK.CompleteRegistrationActionRegister | translate }}
						</button>
					</div>
				</form>
			</div>
		}
	</ng-container>
</ud-user-access-box>
