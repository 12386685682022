<div class="uf-app-bar">
	<button (click)="close(false)" type="button" class="uf-action tertiary">
		<uf-icon [title]="sharedTermsTK.ActionClose | translate" name="close" />
	</button>
	<h3>{{ data.title }}</h3>
</div>

<div class="uf-grid pad grow">
	<div class="col-12">
		@if (data.content) {
			<div #content></div>
		}
		@if (data.message && !data.content) {
			<div [markdownRenderer]="data.message"></div>
		}
	</div>
</div>

<div class="uf-form-actions">
	<button (click)="close(false)" class="uf-button tertiary small" type="button">
		{{ data.cancelLabel }}
	</button>
	<button (click)="close(true)" class="uf-button primary small" type="button" ufAutofocus>
		{{ data.confirmLabel }}
	</button>
</div>
