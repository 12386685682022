import { InjectionToken } from '@angular/core';
import { DataPropertyDescriptor } from '@unifii/library/common';
import { AstNode, Dictionary, Option, Table, TableSourceType } from '@unifii/sdk';

export interface TableModuleConfig {
	addOptions?: Option[]; // options available when add action triggered
	filterLink?: { identifier: string; expression: string };
}

/**
 * @description
 * Configuration required by the TablePageComponent and associated classes
 */
export interface TablePageConfig {
	sourceType: TableSourceType;
	table: Table;
	isSearchable: boolean;
	propertyDescriptors: Map<string, DataPropertyDescriptor>;
	addOptions?: Option[]; // options available when add action triggered
	// bucket specific config
	bucket?: string;
	hasRollingVersion?: boolean;
	modules?: TableModuleConfig[];
}

export const TablePageConfig = new InjectionToken<TablePageConfig>('TablePageConfig');

export type ModuleInfo = {
	identifier: string;
	canAdd?: boolean;
	filter?: AstNode;
	filterLink?: Dictionary<any>;
} | null;

export const ModuleInfo = new InjectionToken<ModuleInfo>('ModuleInfo');
