<form (ngSubmit)="authorize()" class="uf-grid uf-container-sm">
	<div class="col-12 row center-all justify-center">
		<h5 class="no-margin padded--horizontal">{{ tenantSettings?.name }}</h5>

		@if (canChangeCompany) {
			<div>
				<button [title]="discoverTK.ActionChangeCompany | translate" [disabled]="inProgress" (click)="changeTenant()" type="button" class="uf-action small tertiary right">
					<uf-icon name="change" />
				</button>
			</div>
		}
	</div>

	@if (formGroup.controls.username) {
		<uf-text [label]="commonTK.UsernameLabel | translate" [control]="formGroup.controls.username | asUfControl" name="username" autocomplete="username" class="col-12" />
	}

	@if (formGroup.controls.password) {
		<uf-password [label]="commonTK.PasswordLabel | translate" [showReveal]="true" [control]="formGroup.controls.password | asUfControl" name="password" autocomplete="password" class="col-12" />
	}

	@if (rememberMeEnabled) {
		@if (formGroup.controls.rememberMe) {
			<uf-checkbox [label]="discoverTK.LoginRemeberMeInputLabel | translate" [control]="formGroup.controls.rememberMe | asUfControl" name="rememberMe" class="col-12" />
		}
	}

	<div class="col-12 col center-all space-children">
		<button [disabled]="inProgress" type="submit" class="uf-button primary">
			{{ sharedTermsTK.ActionLogin | translate }}
		</button>
		<a [routerLink]="['/login', 'request-password-change']" class="uf-button tertiary">
			{{ discoverTK.ActionRequestPasswordChange | translate }}
		</a>
	</div>
</form>
