import { Injectable, inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ModalService } from '@unifii/library/common';

import { ShellTranslationKey } from 'shell/shell.tk';

@Injectable({ providedIn: 'root' })
export class DialogsService {

	private modalService = inject(ModalService);
	private translateService = inject(TranslateService);

	/** Navigation request from meanwhile in a modified content => Leave Vs Stay */
	async confirmLeaveWithoutSaving() {
		return await this.modalService.openConfirm({
			title: this.translateService.instant(ShellTranslationKey.UnsavedChangesModalTitle),
			message: this.translateService.instant(ShellTranslationKey.UnsavedChangesModalMessage),
			confirmLabel: this.translateService.instant(ShellTranslationKey.LeaveLabel),
			cancelLabel: this.translateService.instant(ShellTranslationKey.DontLeaveLabel),
		}) ?? false;
	}

	/** Delete some content with non-reproducible information => Delete Vs Keep */
	async confirmDelete() {
		return await this.modalService.openConfirm({
			title: this.translateService.instant(ShellTranslationKey.DeleteDialogTitle),
			message: this.translateService.instant(ShellTranslationKey.DeleteDialogMessage),
			confirmLabel: this.translateService.instant(ShellTranslationKey.DeleteLabel),
			cancelLabel: this.translateService.instant(ShellTranslationKey.DoNotDeleteLabel),
		}) ?? false;
	}

}
