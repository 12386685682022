<div class="col-1of1">
	<div class="ud-card-actions">
		<button (click)="close()" type="button" class="uf-action tertiary small">
			<uf-icon name="close" />
		</button>
	</div>

	<h3>{{ discoverTK.ContactInfoTitle | translate }}</h3>
	@if (!contactDetails) {
		<p>{{ discoverTK.CompanyHelpMessage | translate }}</p>
	}

	@if (contactDetails) {
		<p>{{ discoverTK.ContactInfoMessage | translate }}</p>
		@if (contactDetails.length) {
			<uf-data-display-list [items]="contactDetails" class="small small-label" />
		}
	}

	@if (providers.length) {
		<h3>{{ discoverTK.SupportLoginLabel | translate }}</h3>
		@for (provider of providers; track provider) {
			<div class="col gap-bottom">
				@if (provider.providerLoginLabel) {
					<label>{{ provider.providerLoginLabel }}</label>
				}
				<button (click)="providerSignIn(provider)" type="button" class="authentication-provider grow">
					<img [src]="provider.loginIcon" />
					<p class="small">{{ provider.loginLabel }}</p>
				</button>
			</div>
		}
	}
</div>
