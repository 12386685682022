<uf-panel class="container" ufMasterDetail>
	<div class="grid--fixed">
		@if (label) {
			<div class="col-1of1">
				<h3 style="text-align: center">{{ label }}</h3>
			</div>
		}
		<div class="ud-card-list col-1of1">
			@for (card of cards; track card) {
				@if (card.accessible) {
					<a [routerLink]="card.id" class="ud-product-card--centered">
						@if (card.url) {
							<div class="ud-image--no-margin--fixed-ratio--16by9">
								<img [attr.src]="card.url" [attr.alt]="card.label" />
							</div>
						}
						@if (card.label) {
							<div class="ud-product-card-label">
								<h4>{{ card.label }}</h4>
							</div>
						}
					</a>
				}
				@if (!card.accessible) {
					<div class="ud-product-card--centered">
						@if (card.url) {
							<div class="ud-image--no-margin--fixed-ratio--16by9">
								<img [attr.src]="card.url" [attr.alt]="card.label" />
							</div>
						}
						@if (card.label) {
							<div class="ud-product-card-label">
								<h4>{{ card.label }}</h4>
							</div>
						}
					</div>
				}
			}
		</div>
	</div>
</uf-panel>
<router-outlet />
