<div #pdfContainer class="container">
	<div></div>
	@if (loading) {
		<us-loading-spinner />
	}
	@if (error) {
		<uf-icon class="pdf-error large" name="document-error" />
	}
</div>
<div class="uf-app-bar flat accent medium">
	<button [title]="shellTK.PreviousPageLabel | translate" (click)="previousPage()" type="button" class="uf-action">
		<uf-icon name="arrowUp" />
	</button>
	<button [title]="shellTK.NextPageLabel | translate" (click)="nextPage()" type="button" class="uf-action">
		<uf-icon name="arrowDown" />
	</button>
	<div class="title">
		@if (!error) {
			<span>{{ page }}/{{ pagesCount }}</span>
		}
	</div>
	<button [title]="shellTK.ZoomInLabel | translate" (click)="zoomIn()" type="button" class="uf-action">
		<uf-icon name="add" />
	</button>
	<button [title]="shellTK.ZoomOutLabel | translate" (click)="zoomOut()" type="button" class="uf-action">
		<uf-icon name="minus" />
	</button>
	<button [title]="shellTK.FullscreenLabel | translate" (click)="toggleExpandedMode()" type="button" class="uf-action expand-btn">
		<uf-icon [name]="isExpanded ? 'fullscreen-close' : 'fullscreen-open'" />
	</button>
</div>
