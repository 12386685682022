import { Injectable } from '@angular/core';
import { LengthAtLeast, hasLengthAtLeast, isStringNotEmpty, objectKeys } from '@unifii/sdk';

interface Subdirectory {
	path: string;
	params: Record<string, string>;
}

export const KEY_SIGN_IN = '~sign-in';
const KEY_NEW = '~new';
const KEY_DETAIL = '~detail';
const KEY_EDIT = '~edit';
const KEY_FORM = '~form';
const KEY_PAGE = '~page';
const KEY_NODE = 'n';

@Injectable({ providedIn: 'root' })
export class LegacyRouteAdapter {

	transform(url: string): { url: string; transformed?: boolean } {
		const subs = this.parse(url);

		if (!hasLengthAtLeast(subs, 1)) {
			return { url };
		}

		let result: string | undefined;

		switch (subs[0].path) {
			case KEY_SIGN_IN:
				result = this.transformSignInRoute(subs);
				break;
			case KEY_FORM:
				result = this.transformFormRoute(subs);
				break;
			case KEY_PAGE:
				result = this.transformPageRoute(subs);
				break;
			case KEY_NODE:
				result = this.transformNodeRoute(subs);
				break;
			default:
				result = this.transformIdentifierRoute(subs);
				break;
		}

		return { url: result ?? url, transformed: result != null };
	}

	private transformSignInRoute(subs: Subdirectory[] & LengthAtLeast<Subdirectory[], 1>): string | undefined {
		const sub = subs[0];
		const subNew = hasLengthAtLeast(subs, 2) && subs[1].path === 'new' ? subs[1] : undefined;
		const params = subNew ? subNew.params : sub.params;
		let result = `/${sub.path}`;

		if (subNew) {
			result = `${result}/${subNew.path}`;
		}

		for (const key of objectKeys(params)) {
			result +=`;${key}=${params[key]}`;
		}

		return result;
	}

	private transformFormRoute(subs: Subdirectory[] & LengthAtLeast<Subdirectory[], 1>): string | undefined {
		const sub = subs[0];
		const params = sub.params;

		if (!params.bucket) {
			return;
		}

		if (params.definitionIdentifier && !params.id) {
			// F2
			return `/${params.definitionIdentifier}`;
		}

		if (params.id && !params.definitionIdentifier) {
			// F3
			return `/form-data/${params.bucket}/${params.id}`;
		}

		// F1 and other non matching cases
		return undefined;
	}

	// TODO - remove legacy route
	private transformPageRoute(subs: Subdirectory[] & LengthAtLeast<Subdirectory[], 1>): string | undefined {
		const sub = subs[0];
		const params = sub.params;

		if (params.id) {
			// P1
			return `/${KEY_PAGE}/${params.id}`;
		}

		// P2
		return undefined;
	}

	private transformNodeRoute(subs: Subdirectory[]): string | undefined {

		if (!hasLengthAtLeast(subs, 3)) {
			return;
		}

		const nodeId = subs[1].path;

		const routePrefix = `/${KEY_NODE}/${nodeId}`;

		switch (subs[2].path) {
			case 'dashboard':
			case 'iframe':
			case 'custom':
			case 'pdf-viewer':
				// N1 | N2 | N3 | N4
				return;
		}

		if (!hasLengthAtLeast(subs, 4)) {
			// N5 | N6 | N7 | N8
			return;
		}

		if (![KEY_NEW, KEY_DETAIL].includes(subs[3].path)) {
			// N9
			return;
		}

		if (subs[3].path === KEY_DETAIL) {
			const formData = subs[3].params.id;
			const isEditKey = subs[4]?.path === KEY_EDIT;

			if (formData && isEditKey) {
				// N10
				return `${routePrefix}/${subs[2].path}/${formData}`;
			}

			return;
		}

		if (subs[3].path === KEY_NEW) {
			const definitionIdentifier = subs[3].params.definitionIdentifier;

			if (definitionIdentifier) {
				// N11
				return `${routePrefix}/${subs[2].path}/${definitionIdentifier}`;
			}

			// N12
			return;
		}

		return undefined;
	}

	private transformIdentifierRoute(subs: Subdirectory[]): string | undefined {

		if (!hasLengthAtLeast(subs, 2)) {
			// I1 | I2 | I3 | I4
			return;
		}

		const firstPath = subs[0].path;
		const secondPath = subs[1].path;

		if (![KEY_NEW, KEY_DETAIL].includes(secondPath)) {
			// I5 | I6
			return;
		}

		const params = subs[1].params;

		if (secondPath === KEY_DETAIL) {
			const id = params.id;

			if (!id) {
				return;
			}
			if (subs.length === 2) {
				// I7
				return `/${firstPath}/${id};mode=detail`;
			}
			if (subs[2]?.path === KEY_EDIT) {
				// I8
				return `/${firstPath}/${id}`;
			}

			return;
		}

		// secondPath === KEY_NEW
		const definitionIdentifier = params.definitionIdentifier;

		if (!definitionIdentifier) {
			// I9 & I10
			return `/${firstPath}/new-company`;
		}

		// I11
		return `/${firstPath}/${definitionIdentifier}`;
	}

	private parse(route: string): Subdirectory[] {
		return route.split('/').filter((v) => isStringNotEmpty(v)).map((subdirectory) => {

			if (!subdirectory || subdirectory.trim() === '') {
				throw new Error(`LegacyRouteAdapter.parse: "${route}" bad format`);
			}

			const parts = subdirectory.split(';');
			const path = parts.shift() ?? '';
			const params = parts.reduce<Record<string, string>>((result, param) => {
				const keyValue = param.split('=').filter((v) => v.trim().length > 0);

				if (keyValue.length === 2 && hasLengthAtLeast(keyValue, 2)) {
					result[keyValue[0].trim()] = keyValue[1].trim();
				}

				return result;
			}, {});

			return { path, params };
		});
	}

}
