import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';

import { Config } from 'config';
import { SavedUsersService } from 'shell/services/saved-users.service';

/**
 * Ensure tenant enables saved User (or in flags)
 */
export const savedUsersGuard: CanActivateFn = (): boolean => {

	const savedUsersService = inject(SavedUsersService);
	const router = inject(Router);
	const config = inject(Config);

	if ((savedUsersService.enabled && savedUsersService.users.length) || config.unifii.tenantSettings?.authProviders?.length) {
		return true;
	}

	void router.navigate(['/login/form']);

	return false;
};
