import { Component, HostBinding, inject } from '@angular/core';
import { Modal, ModalData, SharedTermsTranslationKey } from '@unifii/library/common';
import { AppInfo } from '@unifii/sdk';

import { DiscoverTranslationKey } from 'discover/discover.tk';

@Component({
	selector: 'ud-required-update-modal',
	templateUrl: './required-update-modal.html',
	standalone: false,
})
export class RequiredUpdateModalComponent implements Modal<AppInfo, null> {

	@HostBinding('class.uf-form-card') cardClass = true;

	data = inject<AppInfo>(ModalData);

	protected readonly sharedTermsTK = SharedTermsTranslationKey;
	protected readonly discoverTK = DiscoverTranslationKey;

}
