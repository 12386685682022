import { Component, OnInit, inject } from '@angular/core';
import { Breadcrumb, RuntimeDefinition } from '@unifii/library/common';
import { Compound, FieldType } from '@unifii/sdk';

import { CollectionComponent } from 'shell/content/collection.component';
import { CollectionItemContent } from 'shell/content/content-types';
import { BreadcrumbsService } from 'shell/services/breadcrumbs.service';

@Component({
	selector: 'us-collection-item',
	templateUrl: './collection-item.html',
	styleUrls: ['./collection-item.less'],
	providers: [BreadcrumbsService],
	standalone: false,
})
export class CollectionItemComponent implements OnInit, CollectionItemContent {

	definition: RuntimeDefinition;
	compound: Compound;
	title: string;
	breadcrumbs: Breadcrumb[] = [];

	private breadcrumbsService = inject(BreadcrumbsService);
	private collectionComponent = inject(CollectionComponent, { optional: true });

	ngOnInit() {
		this.breadcrumbsService.title = this.title;
		if (this.collectionComponent) {
			this.breadcrumbs = this.breadcrumbsService.getBreadcrumbs();
		}
	}

	get compoundLabel(): string | undefined {

		const field = this.definition.fields.find((f) => f.type === FieldType.Text);

		if (field) {
			return this.compound[field.identifier as string];
		}

		return this.compound.id;
	}

}
