import { Component, EventEmitter, Input, Output, inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CommonTranslationKey, SharedTermsTranslationKey } from '@unifii/library/common';

@Component({
	selector: 'us-icon-message',
	templateUrl: './icon-message.html',
	styleUrls: ['./icon-message.less'],
	standalone: false,
})
export class IconMessageComponent {

	@Input() image: string;
	@Input() mode: string;
	@Input() retry: string;

	@Output() onRetry = new EventEmitter<boolean>();

	protected readonly sharedTermsTK = SharedTermsTranslationKey;

	private translate = inject(TranslateService);
	private _heading: string;
	private _message: string;

	@Input() set heading(v: string) {
		this._heading = v;
	}

	get heading(): string {
		if (this._heading) {
			return this._heading;
		}

		switch (this.mode) {
			case 'empty': return this.translate.instant(CommonTranslationKey.TableFeedbackDataEmptyTitle) as string;
			case 'error': return this.translate.instant(CommonTranslationKey.TableFeedbackDataErrorTitle) as string;
			default: return '';
		}
	}

	@Input() set message(v: string) {
		this._message = v;
	}

	get message(): string {
		if (this._message) {
			return this._message;
		}

		switch (this.mode) {
			case 'error': return this.translate.instant(CommonTranslationKey.TableFeedbackDataErrorDescription) as string;
			default: return '';
		}
	}

	emitRetry() {
		this.onRetry.emit();
	}

}
