<div class="uf-container-lg bg-solid">
	<div class="uf-grid pad-top row-gap-lg">
		<div class="uf-box col-12">
			<div class="uf-app-bar accent medium">
				<div class="title">{{ discoverTK.UsersUploadResultStatusTitle | translate }}</div>
			</div>
			<div class="uf-grid pad">
				<div class="col-12">
					<uf-data-display-list [items]="uploadStatus" class="small-label" />
				</div>
			</div>
		</div>
		@if (form.controls.length || uploadResult.errors.length) {
			<div class="col-12 uf-box">
				<div class="uf-app-bar accent medium">
					<div class="title">{{ discoverTK.UsersUploadResultFailedEntriesTitle | translate }}</div>
				</div>
				<div class="uf-grid">
					<div class="col-12 pad-bottom">
						@if (form.controls.length) {
							<p class="small pad-sides">
								{{ discoverTK.UsersUploadResultFailedEntriesMessage | translate }}
							</p>
						}
						@if (uploadResult.errors.length && !form.controls.length) {
							<div class="pad-sides pad-top">
								<table class="uf-table break-word">
									<thead>
										<tr>
											@if (csvUpload) {
												<th>
													{{ discoverTK.UsersUploadResultLineNumberLabel | translate }}
												</th>
											}
											@if (!csvUpload) {
												<th>
													{{ discoverTK.UsersUploadResultUserNumberLabel | translate }}
												</th>
											}
											<th>{{ sharedTermsTK.Error | translate }}</th>
										</tr>
									</thead>
									<tbody>
										@for (error of uploadResult.errors; track error; let i = $index) {
											<tr>
												@if (csvUpload) {
													<td>{{ error.index + 2 }}</td>
												}
												@if (!csvUpload) {
													<td>{{ error.index + 1 }}</td>
												}
												<td class="error-message">{{ error.errorMessage }}</td>
											</tr>
										}
									</tbody>
								</table>
							</div>
						}
						@if (form.controls.length) {
							<div class="col space-children margin--top user-card-display">
								@for (control of form.controls; track control; let i = $index) {
									<div class="uf-card margin--horizontal--large">
										<div class="pad">
											<button [title]="sharedTermsTK.ActionRemove | translate" (click)="removeUser(i)" type="button" class="uf-action tertiary small float-right">
												<uf-icon name="delete" />
											</button>
											<button [title]="sharedTermsTK.ActionEdit | translate" (click)="editUser(i)" type="button" class="uf-action tertiary small float-right">
												<uf-icon name="edit" />
											</button>
											<uf-data-display-list class="small-label">
												@if (context.action === actionAdd && control.get(userInfoKey.Username)?.enabled) {
													<dt>{{ commonTK.UsernameLabel | translate }}</dt>
													<dd>{{ control.value?.username }}</dd>
												}
												@if (control.get(userInfoKey.FirstName)?.enabled) {
													<dt>{{ commonTK.FirstNameLabel | translate }}</dt>
													<dd>{{ control.value?.firstName }}</dd>
												}
												@if (control.get(userInfoKey.LastName)?.enabled) {
													<dt>{{ commonTK.LastNameLabel | translate }}</dt>
													<dd>{{ control.value?.lastName }}</dd>
												}
												@if (control.get(userInfoKey.Email)?.enabled) {
													<dt>{{ commonTK.EmailLabel | translate }}</dt>
													<dd>{{ control.value?.email }}</dd>
												}
												@if (control.get(userInfoKey.Phone)?.enabled) {
													<dt>{{ commonTK.PhoneLabel | translate }}</dt>
													<dd>{{ control.value?.phone }}</dd>
												}
											</uf-data-display-list>
										</div>
									</div>
								}
							</div>
							<div class="pad-sides user-table-display">
								<table class="uf-table">
									<thead>
										@if (form.controls[0]; as firstControl) {
											<tr>
												@if (firstControl.get(userInfoKey.Username)?.enabled) {
													<th>{{ commonTK.UsernameLabel | translate }}</th>
												}
												@if (firstControl.get(userInfoKey.FirstName)?.enabled) {
													<th>{{ commonTK.FirstNameLabel | translate }}</th>
												}
												@if (firstControl.get(userInfoKey.LastName)?.enabled) {
													<th>{{ commonTK.LastNameLabel | translate }}</th>
												}
												@if (firstControl.get(userInfoKey.Email)?.enabled) {
													<th>{{ commonTK.EmailLabel | translate }}</th>
												}
												@if (firstControl.get(userInfoKey.Phone)?.enabled) {
													<th>{{ commonTK.PhoneLabel | translate }}</th>
												}
												<th>{{ sharedTermsTK.Error | translate }}</th>
												<th></th>
											</tr>
										}
									</thead>
									<tbody>
										@for (control of form.controls; track control; let i = $index) {
											<tr>
												@if (control.get(userInfoKey.Username)?.enabled) {
													<td>{{ control.value?.username }}</td>
												}
												@if (control.get(userInfoKey.FirstName)?.enabled) {
													<td>{{ control.value?.firstName }}</td>
												}
												@if (control.get(userInfoKey.LastName)?.enabled) {
													<td>{{ control.value?.lastName }}</td>
												}
												@if (control.get(userInfoKey.Email)?.enabled) {
													<td>{{ control.value?.email }}</td>
												}
												@if (control.get(userInfoKey.Phone)?.enabled) {
													<td>{{ control.value?.phone }}</td>
												}
												<td class="error-message">{{ uploadResult.errors[i]?.errorMessage }}</td>
												<td class="pad-right">
													<div class="row space-children">
														<button [title]="sharedTermsTK.ActionEdit | translate" (click)="editUser(i)" type="button" class="uf-action tertiary small right">
															<uf-icon name="edit" />
														</button>
														<button [title]="sharedTermsTK.ActionRemove | translate" (click)="removeUser(i)" type="button" class="uf-action tertiary small">
															<uf-icon name="delete" />
														</button>
													</div>
												</td>
											</tr>
										}
									</tbody>
								</table>
							</div>
						}
					</div>
				</div>
			</div>
		}

		<div class="col-12 row">
			<button (click)="back()" type="button" class="uf-button tertiary left">
				{{ sharedTermsTK.ActionCancel | translate }}
			</button>
			@if (form.controls.length) {
				<button (click)="save()" type="button" class="uf-button primary">
					{{ sharedTermsTK.ActionCreate | translate }}
				</button>
			}
		</div>
	</div>
</div>
