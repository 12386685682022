import { animate, style, transition, trigger } from '@angular/animations';
import { Component, OnInit, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SharedTermsTranslationKey } from '@unifii/library/common';
import { FieldHelperFunctions } from '@unifii/library/smart-forms';
import { PublishedContent, StructureNode, TableDetailTemplate, TableSourceType, UfRequestError, ensureUfError } from '@unifii/sdk';

import { Config } from 'config';
import { AppContent } from 'shell/content/content-types';
import { AppError } from 'shell/errors/errors';
import { NavigationService } from 'shell/nav/navigation.service';
import { Authentication } from 'shell/services/authentication';
import { PermissionsFunctions } from 'shell/services/permissions-functions';
import { TableNodeInfo } from 'shell/shell-model';

const fade = trigger('fade', [
	transition(':leave', [
		animate('400ms ease', style({ opacity: 0 })),
	]),
]);

@Component({
	selector: 'us-dashboard-page',
	templateUrl: './dashboard-page.html',
	styleUrls: ['../content/content-node.less'],
	animations: [fade],
	standalone: false,
})
export class DashboardPageComponent implements OnInit, AppContent {

	title: string;

	protected readonly sharedTk = SharedTermsTranslationKey;
	protected isMenuView: boolean;
	protected error: AppError | undefined;
	protected tableNodeInfos: TableNodeInfo[];
	protected pageMode = TableDetailTemplate.PageView;
	protected emptyMessage: string | undefined;

	private config = inject(Config);
	private router = inject(Router);
	private route = inject(ActivatedRoute);
	private nav = inject(NavigationService);
	private content = inject(PublishedContent);
	private authentication = inject(Authentication);

	async ngOnInit() {
		const dashboardNode = this.nav.current;

		if (!dashboardNode) {
			return;
		}

		this.isMenuView = !!dashboardNode.tags?.includes(TableDetailTemplate.MenuView);
		this.pageMode = dashboardNode.tags?.includes(TableDetailTemplate.PageViewHideEmptyTables) ?
			TableDetailTemplate.PageViewHideEmptyTables :
			TableDetailTemplate.PageView;
		this.emptyMessage = dashboardNode.emptyMessage;

		try {
			this.tableNodeInfos = await this.getTablesNodeInfo(dashboardNode);
		} catch (error) {
			this.error = ensureUfError(error);

			return;
		}

		const firstTableInfo = this.tableNodeInfos[0];

		if (!firstTableInfo) {
			this.error = new UfRequestError('your data is empty');

			return;
		}

		const childIdentifier = this.route.children[0]?.snapshot.params.identifier;

		if (this.isMenuView && !childIdentifier) {
			void this.router.navigate([firstTableInfo.table.identifier], { relativeTo: this.route });
		}
	}

	private async getTablesNodeInfo(node: StructureNode): Promise<TableNodeInfo[]> {
		const items: TableNodeInfo[] = [];
		const userRoles = this.authentication.userInfo?.roles;

		for (const { identifier, pageSize, roles } of node.bucketOptions ?? []) {

			if (identifier) {
				if (!PermissionsFunctions.getTablePath(this.config.unifii.projectId, identifier).length) {
					continue;
				}

				const table = await this.content.getTable(identifier);

				if (table.sourceType !== TableSourceType.Bucket || !FieldHelperFunctions.areRolesMatching(userRoles, roles)) {
					continue;
				}

				items.push({ table, pageSize });
			}
		}

		return items;
	}

}
