import { Component, ElementRef, HostBinding, OnDestroy, OnInit, ViewChild, inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BarcodeScannerModalProvider, CommonTranslationKey, Modal, ModalRuntime, SharedTermsTranslationKey, ToastService } from '@unifii/library/common';
import { scanImageData } from 'zbar.wasm';

export const UsBarcodeScannerModalProvider: BarcodeScannerModalProvider = () => UsBarcodeScannerModalComponent;

@Component({
	selector: 'us-barcode-scanner-modal',
	templateUrl: './barcode-scanner-modal.html',
	styleUrls: ['./barcode-scanner-modal.less'],
	standalone: false,
})
export class UsBarcodeScannerModalComponent implements Modal<void, string>, OnInit, OnDestroy {

	@ViewChild('canvas', { static: true }) canvas: ElementRef<HTMLCanvasElement>;
	@ViewChild('video', { static: true }) video: ElementRef<HTMLVideoElement>;

	@HostBinding('class.uf-form-card') class = true;

	runtime = inject<ModalRuntime<void, string | undefined>>(ModalRuntime);

	protected readonly sharedTermsTK = SharedTermsTranslationKey;
	protected readonly commonTK = CommonTranslationKey;
	protected loading = false;

	private readonly scanProidMs = 800;
	private toast = inject(ToastService);
	private translate = inject(TranslateService);
	private mediaStream: MediaStream;

	async ngOnInit() {

		try {
			await this.init();
			while (this.mediaStream) {
				await this.scan();
				await this.sleep(this.scanProidMs);
			}
		} catch (err) {
			console.error(err);
		}
	}

	ngOnDestroy() {

		if (!this.mediaStream) {
			return;
		}

		this.mediaStream.getTracks().forEach((track) => { track.stop(); });
	}

	close() {
		this.runtime.close();
	}

	private async init() {

		this.loading = true;

		try {
			this.mediaStream = await navigator.mediaDevices.getUserMedia({
				audio: false,
				video: {
					facingMode: 'environment',
				},
			});
		} catch (e) {
			this.toast.error(this.translate.instant(this.commonTK.CameraCaptureError));
			this.runtime.close();

			return;
		}

		this.video.nativeElement.srcObject = this.mediaStream;
		this.video.nativeElement.setAttribute('playsinline', '');
		this.video.nativeElement.play();

		await new Promise((r) => {
			this.video.nativeElement.onloadedmetadata = r;
		});

		this.loading = false;
	}

	private async scan() {
		const width = this.video.nativeElement.videoWidth;
		const height = this.video.nativeElement.videoHeight;

		this.canvas.nativeElement.width = width;
		this.canvas.nativeElement.height = height;
		const ctx = this.canvas.nativeElement.getContext('2d');

		if (!ctx) {
			throw new Error('No Canvas');
		}

		if (!width || !height) {
			return;
		}

		ctx.drawImage(this.video.nativeElement, 0, 0, width, height);
		const imgData = ctx.getImageData(0, 0, width, height);
		const res = await scanImageData(imgData);

		if (!res.length) {
			return;
		}

		this.runtime.close(res[0]?.decode());
	}

	private sleep(ms: number): Promise<void> {
		return new Promise((r) => setTimeout(r, ms));
	}

}
