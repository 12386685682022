import { Directive, HostListener, Input, inject } from '@angular/core';
import { ModalService } from '@unifii/library/common';
import { AttachmentsClient } from '@unifii/sdk';

import { PdfViewerModalComponent } from 'shell/common/modals/pdf-viewer-modal.component';

@Directive({
	selector: '[pdfViewerLink]',
	standalone: false,
})
export class PdfViewerLinkDirective {

	/** PDF attachment id */
	@Input({ required: true }) pdfViewerLink: string;
	@Input({ required: true }) label: string | null;

	private modalService = inject(ModalService);
	private attachmentsClient = inject(AttachmentsClient, { optional: true });

	@HostListener('click', ['$event'])
	async onClick(e: PointerEvent) {
		e.preventDefault();
		e.stopPropagation();

		if (!this.attachmentsClient) {
			console.warn('AttachmentsClient not provided');

			return Promise.resolve(undefined);
		}

		const url = await this.attachmentsClient.getAttachmentUrl(this.pdfViewerLink);

		void this.modalService.openLarge(PdfViewerModalComponent, {
			url,
			title: this.label,
		});
	}

}
