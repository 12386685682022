import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DataDisplayListItem, ModalService, SharedTermsTranslationKey, WindowWrapper } from '@unifii/library/common';
import { PermissionAction, Progress } from '@unifii/sdk';
import { Observable, Subject, Subscription, merge, timer } from 'rxjs';
import { share, takeUntil } from 'rxjs/operators';

import { Config, Environment } from 'config';
import { DiscoverContext } from 'discover/discover-context';
import { DiscoverTranslationKey } from 'discover/discover.tk';
import { UpdateProgressComponent } from 'discover/offline/update-progress.component';
import { Mode, ShellService } from 'shell/core/shell.service';
import { OfflineQueue } from 'shell/offline/forms/offline-queue';
import { OfflineManager } from 'shell/offline/offline-manager';
import { ContentInfo } from 'shell/offline/offline-model';
import { Authentication } from 'shell/services/authentication';
import { EditedDataService } from 'shell/services/edited-data.service';
import { PermissionsFunctions } from 'shell/services/permissions-functions';
import { TranslationsService } from 'shell/services/translations.service';
import { UserProfilePath } from 'shell/shell-constants';
import { ShellTranslationKey } from 'shell/shell.tk';
import { ChangeLanguageComponent, ChangeLanguageModalData } from 'shell/translations/change-language.component';

@Component({
	selector: 'ud-settings',
	templateUrl: './settings.html',
	styleUrls: ['./settings.less'],
	standalone: false,
})
export class DiscoverSettingsComponent implements OnInit, OnDestroy {

	protected readonly sharedTermsTK = SharedTermsTranslationKey;
	protected readonly shellTK = ShellTranslationKey;
	protected readonly discoverTK = DiscoverTranslationKey;
	protected readonly userProfilePath = UserProfilePath;
	protected readonly translations = inject(TranslationsService);

	protected preview: boolean;
	protected contentInfo: ContentInfo;
	protected versionInfo: DataDisplayListItem[];
	protected updateAvailable: ContentInfo | null;
	protected previewAvailable: boolean;
	protected offlineSyncRequired: boolean;
	protected showMyProfileLink: boolean;
	protected showChangeProject: boolean;
	protected availableLanguages: string[];
	protected context = inject(DiscoverContext);
	protected config = inject(Config);
	protected env = inject(Environment);
	protected auth = inject(Authentication);
	protected editedDataService = inject(EditedDataService);

	private modalService = inject(ModalService);
	private shellService = inject(ShellService);
	private offlineManager = inject(OfflineManager);
	private offlineQ = inject(OfflineQueue);
	private translateService = inject(TranslateService);
	private window = inject<Window>(WindowWrapper);
	private destroyed = new Subject<void>();
	private subscriptions = new Subscription();

	async ngOnInit() {
		this.versionInfo = this.getVersionInfo();

		this.showMyProfileLink = !this.config.flags?.hideMyProfile &&
            this.auth.getGrantedInfoWithoutCondition(PermissionsFunctions.getMePath(), PermissionAction.Read).granted;

		this.showChangeProject = !this.config.flags?.hideChangeProject && this.auth.allowedProjects.length > 1;

		this.availableLanguages = this.context.project ? this.translations.availableProjectLanguages(this.context.project) : [];

		// Check content update immediately and every 90sec
		this.subscriptions.add(timer(0, 90000).subscribe(() => { this.checkForUpdate(); }));

		if (this.config.unifii.offline) {
			try {
				this.contentInfo = await this.offlineManager.getContentInfo();
			} catch (e) {
				console.warn(`We're online! No offline version`);
			}
		}

		// An hard-coded preview is provided by the environment.ts, override Context one
		if (this.env.unifii.preview != null) {
			this.previewAvailable = false;
			this.preview = this.env.unifii.preview;
			this.shellService.mode = this.env.unifii.preview ? Mode.Preview : Mode.Stable;
		} else {
			this.previewAvailable = this.auth.canAccessPreview;
			this.preview = this.context.preview;
			this.shellService.mode = this.preview ? Mode.Preview : Mode.Stable;

			if (!this.previewAvailable && this.preview) {
				this.context.preview = false;
				void this.reload();
			}
		}

		this.initOfflineSync();
		this.subscriptions.add(this.shellService.toggleMode.subscribe(() => void this.toggle()));
	}

	ngOnDestroy() {
		this.subscriptions.unsubscribe();
	}

	/**
     * toggleMode  => request triggered from preview/stable checkbox
     * !toggleMode => request to update to next version available
     */
	protected async toggle() {

		try {
			// Confirm for switch mode

			let message: string = this.context.preview ? this.translateService.instant(DiscoverTranslationKey.SettingsModalChangeModeProductionMessage) : this.translateService.instant(DiscoverTranslationKey.SettingsModalChangeModePreviewMessage);

			if (this.editedDataService.edited) {
				message = `${message}?<br>
                ${this.translateService.instant(ShellTranslationKey.UnsavedChangesModalMessage)}`;
			}

			const change = await this.modalService.openConfirm({
				title: this.translateService.instant(DiscoverTranslationKey.SettingsModalChangeModeTitle),
				message,
			});

			// User refused to switch
			if (!change) {
				return;
			}

			// Change mode
			this.context.preview = !this.context.preview;

			// clear edited state
			this.editedDataService.clearEdited();

			// For online application just restart
			if (!this.config.unifii.offline) {
				void this.reload();

				return;
			}

			// Switch mode
			const next = await this.offlineManager.updateAvailable();

			if (!next) {
				// No version available, reload needed to show content and data in sync with the mode
				void this.reload();

				return;
			}

			this.subscriptions.add(this.updateOfflineContent().subscribe({
				error: (err) => {
					console.warn('Error updating content', err);
					// Restore toggled mode
					this.context.preview = !this.context.preview;
					// Nothing else to do
				},
				complete: () => {
					// Update completed
					void this.reload();
				},
			}));

		} catch (e) {
			this.context.preview = !this.context.preview;
			await this.modalService.openAlert({
				title: this.translateService.instant(this.sharedTermsTK.ErrorUnknown),
				message: this.context.preview ?
					this.translateService.instant(DiscoverTranslationKey.SettingsModalChangeProductionFailMessage) :
					this.translateService.instant(DiscoverTranslationKey.SettingsModalChangePreviewFailMessage),
			});
		}
	}

	protected async update() {
		const next = await this.offlineManager.updateAvailable();

		if (!next) {
			console.warn('SettingsComponent: Update, no available version!');

			return;
		}

		const proceed = await this.modalService.openConfirm({
			title: this.translateService.instant(DiscoverTranslationKey.SettingsModalUpdateVersionTitle),
			message: this.translateService.instant(DiscoverTranslationKey.SettingsModalUpdateVersionMessage, { version: next.name }),
		});

		if (proceed) {
			this.subscriptions.add(this.updateOfflineContent().subscribe({
				error: (err) => {
					console.warn('Error updating content', err);
				},
				complete: () => {
					// Update completed
					void this.reload();
				},
			}));
		}
	}

	protected async reload() {

		if (this.editedDataService.edited && !await this.modalService.openConfirm({
			title: this.translateService.instant(ShellTranslationKey.UnsavedChangesModalTitle),
			message: this.translateService.instant(ShellTranslationKey.UnsavedChangesModalMessage),
			confirmLabel: this.translateService.instant(SharedTermsTranslationKey.ActionRefresh),
			cancelLabel: this.translateService.instant(ShellTranslationKey.DontLeaveLabel),
		})) {
			return;
		}

		this.editedDataService.clearEdited();
		this.window.location.reload();
	}

	protected close() {
		this.shellService.closeRightDrawer();
	}

	protected async showChangeLanguage() {

		const data: ChangeLanguageModalData = {
			languages: this.availableLanguages,
			current: this.translations.currentLanguage,
		};

		await this.modalService.openFit(ChangeLanguageComponent, data);
	}

	private checkForUpdate() {

		if (!this.config.unifii.offline) {
			return;
		}

		void this.offlineManager.updateAvailable().then((info) => {

			this.updateAvailable = info;

			if (info) {
				this.shellService.notify('OfflineContent');
			} else {
				this.shellService.done('OfflineContent');
			}
		});
	}

	/** Update offline content to the available version, show the progress dialog */
	private updateOfflineContent(): Observable<Progress> {
		// Update content
		const updateObs = this.offlineManager.updateContent().pipe(share());

		void this.modalService.openFit(UpdateProgressComponent, { progress: updateObs });

		return updateObs;
	}

	private initOfflineSync() {

		void this.offlineQ.count().then((count) => { this.offlineSyncRequired = count > 0; });

		const additions = this.offlineQ.additions.pipe(takeUntil(this.destroyed));
		const deletions = this.offlineQ.deletions.pipe(takeUntil(this.destroyed));

		this.subscriptions.add(merge(additions, deletions).subscribe(async() => {
			const count = await this.offlineQ.count();

			console.log('Update offline counts', count);
			this.offlineSyncRequired = count > 0;
		}));
	}

	private getVersionInfo(): DataDisplayListItem[] {

		const info = [{ term: this.translateService.instant(DiscoverTranslationKey.VersionLabel),
			data: this.config.version }];

		if (this.config.productVersion != null) {
			info.push({ term: this.translateService.instant(DiscoverTranslationKey.ProductVersionLabel), data: this.config.productVersion });
		}

		return info;
	}

}
