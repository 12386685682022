<div class="uf-app-bar">
	<a (click)="close()" class="uf-action tertiary">
		<uf-icon name="close" />
	</a>
	<h3>{{ (data.isEdit ? discoverTK.UserEditAction : discoverTK.UserAddAction) | translate }}</h3>
</div>
<form [formGroup]="form" (ngSubmit)="addUser()" class="col grow">
	<uf-panel class="grow">
		<div class="uf-grid pad">
			@if (firstNameControl && firstNameControl.enabled) {
				<uf-text [control]="firstNameControl" [label]="labelDictionary[userInfoControlKeys.FirstName]" class="col-12">
					@if (firstNameRequired) {
						<span class="suffix">*</span>
					}
				</uf-text>
			}

			@if (lastNameControl && lastNameControl.enabled) {
				<uf-text [control]="lastNameControl" [label]="labelDictionary[userInfoControlKeys.LastName]" class="col-12">
					@if (lastNameRequired) {
						<span class="suffix">*</span>
					}
				</uf-text>
			}

			@if (emailControl && emailControl.enabled) {
				<uf-text [control]="emailControl" [label]="labelDictionary[userInfoControlKeys.Email]" class="col-12">
					@if (emailRequired) {
						<span class="suffix">*</span>
					}
				</uf-text>
			}

			@if (phoneControl && phoneControl.enabled) {
				<uf-phone [control]="phoneControl" [label]="labelDictionary[userInfoControlKeys.Phone]" class="col-12">
					@if (phoneRequired) {
						<span class="suffix">*</span>
					}
				</uf-phone>
			}

			@if (usernameControl && usernameControl.enabled) {
				<uf-text [control]="usernameControl" [label]="labelDictionary[userInfoControlKeys.Username]" class="col-12">
					@if (usernameRequired) {
						<span>*</span>
					}
					@if (action == actionInvite) {
						<uf-help [content]="discoverTK.UserCanChangeUsernameHelp | translate" class="suffix" />
					}
				</uf-text>
			}

			@if (canChangeUsernameControl && canChangeUsernameControl.enabled) {
				<uf-checkbox [control]="canChangeUsernameControl" [label]="labelDictionary[userInfoControlKeys.CanChangeUsername]" class="col-12" />
			}

			@if (passwordControl && passwordControl.enabled) {
				<uf-password-advanced [control]="passwordControl" [label]="labelDictionary[userInfoControlKeys.Password]" class="col-12">
					@if (passwordRequired) {
						<span class="suffix">*</span>
					}
				</uf-password-advanced>
				<uf-password-indicator [control]="passwordControl" [style.visibility]="!passwordControl.value ? 'hidden' : 'visible'" class="col-12" />
			}

			@if (unitsControl && unitsControl.enabled) {
				<uf-hierarchy-input [control]="unitsControl" [label]="labelDictionary[userInfoControlKeys.Units]" class="col-12">
					@if (unitsRequired) {
						<span class="suffix">*</span>
					}
				</uf-hierarchy-input>
			}

			@if (rolesControl && rolesControl.enabled) {
				<uf-role-input [control]="rolesControl" [label]="labelDictionary[userInfoControlKeys.Roles]" [editableRoles]="editableRoles" class="col-12">
					@if (rolesRequired) {
						<span class="suffix">*</span>
					}
				</uf-role-input>
			}

			@if (companyControl && companyControl.enabled) {
				<uf-company-input [formControlName]="userInfoControlKeys.Company" [editableCompanies]="editableCompanies" [label]="labelDictionary[userInfoControlKeys.Company]" class="col-12" />
			}
		</div>

		<div class="uf-grid condensed">
			@if (claimsControl && claimsControl.enabled) {
				<uf-claim-editor [control]="claimsControl" [editableClaimsValues]="editableClaimsValues" [width]="fieldWidth.Half" class="col-12 pad-sm-bottom" />
			}
		</div>

		<!-- feedback-username-as-email to target hide it in custom apps -->
		@if (!usernameControl?.enabled) {
			<div class="uf-grid pad feedback-username-as-email">
				<uf-message [content]="discoverTK.UserCreateFeedbackUsernameAsEmail | translate" class="col-12" />
			</div>
		}
	</uf-panel>

	<div class="uf-form-actions">
		<button (click)="close()" type="button" class="uf-button tertiary left">
			{{ sharedTermsTK.ActionCancel | translate }}
		</button>
		<button [disabled]="form.status === 'PENDING'" ufAutofocus type="submit" class="uf-button primary">
			{{ (data.isEdit ? sharedTermsTK.ActionEdit : sharedTermsTK.ActionAdd) | translate }}
		</button>
	</div>
</form>
