import { Component } from '@angular/core';
import { ContentLinkComponent } from '@unifii/library/smart-forms/input';

@Component({
    selector: 'ud-content-link-input',
    templateUrl: './content-link.html',
    styleUrls: ['./content-link.less'],
})
export class DiscoverContentLinkComponent extends ContentLinkComponent {
}
