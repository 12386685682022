import { Injectable, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DownloadConfig, TableContainerManager, TableInputManager, TableInputs } from '@unifii/components';
import { AngularRouterLink, ColumnDisplayDescriptor, ContextProvider, DataPropertyDescriptor, FilterEntry, FilterValue, ModalService, SingleChoiceListModalComponent, SingleChoiceRadioModalComponent, TableConfig, getDefaultTableConfig, getTableCustomColumnsDisplayDescriptors } from '@unifii/library/common';
import { Client, FormData, Option, PermissionAction, Table, TableSourceType, UfRequestError, hasLengthAtLeast } from '@unifii/sdk';
import { Subject } from 'rxjs';

import { Config } from 'config';
import { TableDisplayMode } from 'shell/content/content-node.component';
import { UsFormService } from 'shell/form/form-service.service';
import { Authentication } from 'shell/services/authentication';
import { PermissionsFunctions } from 'shell/services/permissions-functions';
import { FormDataPath, NewItemPath, TableSearchMinLength } from 'shell/shell-constants';
import { ShellTranslationKey } from 'shell/shell.tk';
import { BucketTableDataSource } from 'shell/table/form-data/bucket-table-datasource';
import { TableColumnFactory } from 'shell/table/table-column-factory';
import { TableInputManagerFactory } from 'shell/table/table-input-manager-factory';
import { ModuleInfo, TablePageConfig } from 'shell/table/table-page-config';

import { checkExportAllowedForRoles, checkShowCount, getTableRQLIncludeIdentifiers } from '../table-functions';

@Injectable()
export class BucketTableContainerManager implements TableContainerManager<FormData, FilterValue, FilterEntry> {

	tableConfig: TableConfig<FormData>;
	tableIdentifier: string;
	showSearch: boolean;
	searchMinLength = TableSearchMinLength;
	addActionConfig = false;
	downloadConfig: DownloadConfig | undefined;
	customColumns: ColumnDisplayDescriptor[] = [];
	defaultSort: string | undefined;
	reload = new Subject<void>();
	update = new Subject<TableInputs<FilterValue>>();
	updateItem = new Subject<FormData>();
	inputManager: TableInputManager<FilterValue, FilterEntry>;
	formDefinitions: Option[] = []; // list of form definitions associated with the table
	help: string | undefined;

	private showCount = false;
	private downloadTableInputs: TableInputs<FilterValue> | undefined;
	private client: Client;
	private auth: Authentication;
	private contextProvider: ContextProvider;
	private formService: UsFormService;
	private config: Config;
	private columnFactory: TableColumnFactory;
	private router: Router;
	private route: ActivatedRoute;
	private modalService: ModalService;
	private translateService: TranslateService;
	private table: Table;
	private moduleInfo: ModuleInfo;
	private addOptions: Option[] | undefined;
	private propertyDescriptors: Map<string, DataPropertyDescriptor>;

	constructor() {
		this.client = inject(Client);
		this.auth = inject(Authentication);
		this.contextProvider = inject(ContextProvider);
		this.formService = inject(UsFormService);
		this.config = inject(Config);
		this.columnFactory = inject(TableColumnFactory);
		this.router = inject(Router);
		this.route = inject(ActivatedRoute);
		this.modalService = inject(ModalService);
		this.translateService = inject(TranslateService);
		this.moduleInfo = inject<ModuleInfo>(ModuleInfo);

		const { table, bucket, propertyDescriptors, addOptions, isSearchable } = inject(TablePageConfig);

		this.tableIdentifier = table.identifier;
		this.table = table;
		this.defaultSort = table.defaultSort;
		this.showSearch = isSearchable;
		this.help = table.help;

		this.inputManager = inject(TableInputManagerFactory).create(table, this.moduleInfo?.filter);

		if (table.hideExport !== true && checkExportAllowedForRoles(table, this.auth.userInfo?.roles ?? [])) {
			this.downloadConfig = {
				name: `${bucket}.csv`,
				getUrl: this.getDownloadUrl.bind(this),
			};
		}

		if (!bucket) {
			throw new UfRequestError('Set bucket first');
		}

		this.formService.init(bucket);
		this.addActionConfig = !!addOptions?.length && (this.moduleInfo ? !!this.moduleInfo.canAdd : true);
		this.addOptions = addOptions;
		this.propertyDescriptors = propertyDescriptors;
		this.showCount = checkShowCount(this.config, table);
		this.customColumns = getTableCustomColumnsDisplayDescriptors(table.columns);
		this.tableConfig = this.getTableConfig(table, propertyDescriptors);
	}

	createDataSource(inputs?: TableInputs<FilterValue>) {
		// Store the inputs locally for the purpose of build the DownloadUrl
		this.downloadTableInputs = Object.assign({}, inputs);
		delete this.downloadTableInputs.sort;

		// DataSource for feed the Table
		return this.getDataSource(inputs);
	}

	async addActionCallback() {
		// if child of a detail component
		if (this.moduleInfo) {

			if (!this.addOptions || !hasLengthAtLeast(this.addOptions, 1)) {
				console.warn('bucket-table-container: Module needs addOptions to perform addActionCallback');

				return;
			}

			let selected: Option | undefined;

			if (this.addOptions.length === 1) {
				selected = this.addOptions[0];
			} else {
				selected = await this.modalService.openMedium(SingleChoiceListModalComponent, {
					title: this.translateService.instant(ShellTranslationKey.FormBucketDialogAddFormTitle),
					options: this.addOptions,
				});
			}

			if (!selected) {
				return;
			}

			const params = this.moduleInfo.filterLink ?? {};

			params.$definition = selected.identifier;
			void this.router.navigate([FormDataPath, this.table.source, NewItemPath, params]);

			return;
		}

		void this.router.navigate([NewItemPath], { relativeTo: this.route });
	}

	private async getDownloadUrl(): Promise<string | null> {

		const downloadType = (await this.modalService.openFit(SingleChoiceRadioModalComponent, {
			title: this.translateService.instant(ShellTranslationKey.ExportModalTitle),
			options: [
				{ identifier: 'basic', name: this.translateService.instant(ShellTranslationKey.ExportBasicLabel) },
				{ identifier: 'full', name: this.translateService.instant(ShellTranslationKey.ExportFullLabel) },
			],
		}))?.identifier as 'basic' | 'full' | undefined;

		if (!downloadType) {
			return null;
		}

		const includeIdentifiers = downloadType === 'basic' ?
			getTableRQLIncludeIdentifiers(this.table.columns, this.tableConfig.columns, this.propertyDescriptors) :
			undefined;

		const dataSource = this.getDataSource(this.downloadTableInputs, includeIdentifiers);
		const url = dataSource.getDownloadUrl();

		if (!url) {
			throw new Error('Failed to get download url');
		}

		const { token } = await this.client.getDownloadToken(url);

		return `${url}&_dlt=${token}`;
	}

	private getDataSource(inputs?: TableInputs<FilterValue>, include?: string[] ): BucketTableDataSource {
		return new BucketTableDataSource({
			formService: this.formService,
			tableIdentifier: this.tableIdentifier,
			tableInputManager: this.inputManager,
			tableInputs: inputs,
			showCount: this.showCount,
			include,
		});
	}

	private getTableConfig(table: Table, propertyDescriptors: Map<string, DataPropertyDescriptor>): TableConfig<FormData> {
		const columns = this.columnFactory.create(TableSourceType.Bucket, table.columns ?? [], propertyDescriptors, true);
		const tableConfig = getDefaultTableConfig(columns, `table_${table.identifier}`);

		tableConfig.row = { link: (item) => table.source ? this.getRowLink(item, table.source, table) : [] };

		return tableConfig;
	}

	private getRowLink(formData: FormData, bucket: string, table: Table): AngularRouterLink {
		const isGranted = this.auth.getGrantedInfo(
			PermissionsFunctions.getBucketDocumentPath(this.config.unifii.projectId, bucket, `${formData.id}`),
			PermissionAction.Read,
			formData,
			this.contextProvider.get(),
		).granted;

		if (isGranted) {

			if (this.moduleInfo) {
				if (table.detail) {
					return ['/', this.moduleInfo.identifier, formData.id, { mode: TableDisplayMode.Detail }];
				}

				return ['/', FormDataPath, bucket, formData.id];
			}

			if (table.detail) {
				return [formData.id, { mode: TableDisplayMode.Detail }];
			}

			return [formData.id];
		}

		return [];
	}

}

