import { Injectable, inject } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Subject } from 'rxjs';

import { ShellComponent } from 'shell/core/shell.component';

import { TitleConfig } from './title-config';

export enum Mode {
	Stable, Preview,
}

@Injectable({ providedIn: 'root' })
export class ShellService {

	busyEvents = new Subject<boolean>();
	menuOpened = new Subject<boolean>();
	/**
     * used by header and setting component to change preview mode,
     * logic for switching is in the settings component
     */
	toggleMode = new Subject<void>();

	private shell: ShellComponent;
	private _mode: Mode = Mode.Stable;
	private notifications = new Map<string, number>();
	private _title: string;
	private titleService = inject(Title);
	private titleConfig = inject(TitleConfig);

	register(shell: ShellComponent) {
		this.shell = shell;
		this.shell.title = this._title;
	}

	setTitle(title?: string) {
		if (!title) {
			title = this.titleConfig.appTitle;
		}

		this._title = title;

		if (this.shell) {
			this.shell.title = title;
		}

		this.titleService.setTitle(this.titleConfig.prefix ? this.titleConfig.prefix + ': ' + title : title);
	}

	get title(): string {
		return this.shell?.title ?? this._title;
	}

	set busy(v: boolean) {
		this.busyEvents.next(v);
	}

	get mode(): Mode {
		return this._mode;
	}

	set mode(v: Mode) {
		this._mode = v;
	}

	notify(key: string, count = 1) {
		this.notifications.set(key, this.notifications.get(key) ?? 0 + count);
	}

	done(key: string) {

		if (!this.notifications.has(key)) {
			return;
		}

		const value = Math.max((this.notifications.get(key) as number) - 1, 0);

		this.notifications.set(key, value);
	}

	reset(key: string, count = 0) {
		this.notifications.set(key, count);
	}

	getNotificationCount(identifier?: string): number {
		if (identifier != null) {
			return this.notifications.get(identifier) ?? 0;
		}

		return Array.from(this.notifications.values()).reduce((acc, n) => acc + n, 0);
	}

	toggleMenuDrawer() {
		this.shell.menuDrawer.open = !this.shell.menuDrawer.open;

		if (this.shell.menuDrawer.open) {
			this.menuOpened.next(true);
		}
	}

	openLeftDrawer() {

		if (this.shell.leftDrawer != null) {
			this.shell.leftDrawer.open = true;
		}
	}

	openRightDrawer() {
		if (this.shell.rightDrawer != null) {
			this.shell.rightDrawer.open = true;
		}
	}

	closeRightDrawer() {
		if (this.shell.rightDrawer != null) {
			this.shell.rightDrawer.open = false;
		}
	}

	closeLeftDrawer() {
		if (this.shell.leftDrawer != null) {
			this.shell.leftDrawer.open = false;
		}
	}

	closeMenuDrawer() {
		if (this.shell.menuDrawer != null) {
			this.shell.menuDrawer.open = false;
		}
	}

}
