import { Injectable, inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Repository, WindowWrapper } from '@unifii/library/common';
import { ProjectInfo, hasLengthAtLeast } from '@unifii/sdk';

import { ShellFeatureFlagService } from './shell-feature-flag.service';

export const LanguageKey = 'UfLanguage';

@Injectable()
export class TranslationsService {

	private window = inject(WindowWrapper);
	private repo = inject(Repository);
	private translate = inject(TranslateService);
	private flagService = inject(ShellFeatureFlagService);

	get languages(): string[] {
		return this.translate.getLangs();
	}

	get currentLanguage(): string {
		return this.translate.currentLang;
	}

	availableProjectLanguages(project: ProjectInfo): string[] {
		return [project.primaryLanguage, ...(project.languages ?? [])]
			.filter((language) => this.languages.includes(language.code))
			.map((language) => language.code);
	}

	async register(languages: string[]) {

		if (!hasLengthAtLeast(languages, 1)) {
			console.warn('No languages provided, please defined at least one language for this app!');

			return;
		}

		const appDefaultLanguage = languages[0];

		this.translate.addLangs(languages);

		this.translate.setDefaultLang(appDefaultLanguage);

		const userLang = this.repo.loadString(LanguageKey);

		if (userLang && languages.includes(userLang)) {
			await this.use(userLang, false);

			return;
		}

		if (await this.flagService.isEnabled('translations')) {
			const language = this.findLanguage(languages);

			if (language) {
				await this.use(language, false);

				return;
			}
		}

		// language not found return default
		if (languages.includes(appDefaultLanguage)) {
			await this.use(appDefaultLanguage, false);

			return;
		}

		// fallback to any available
		if (languages.length > 0) {
			await this.use(languages[0], false);
		}
	}

	use(lang: string, userAction = true, reload = true) {

		// Language not available
		if (!this.languages.includes(lang)) {
			return;
		}

		// Language already in use
		if (this.currentLanguage === lang) {
			return;
		}

		if (!userAction) {
			return this.translate.use(lang).toPromise();
		}

		this.repo.storeString(LanguageKey, lang);

		if (reload) {
			// After reload the language will be loaded
			this.window.location.reload();
		}

		return undefined;
	}

	private findLanguage(languages: string[]): string | null {

		// browser returns full language 'en-au'
		const browserCultureLang = this.translate.getBrowserCultureLang();

		if (browserCultureLang) {
			// (en-au, [en, en-au, de, pt-BR, pt-PT]) -> en-au
			if (languages.includes(browserCultureLang)) {
				return browserCultureLang;
			}

			// return first language that includes
			// (pt, [en, en-au, de, pt-BR, pt-PT]) -> pt-BR
			const foundBrowserLanguage = languages.find((s) => s.includes(browserCultureLang));

			if (foundBrowserLanguage) {
				return foundBrowserLanguage;
			}
		}

		// Browser language removes region and returns 'en'
		const browserLang = this.translate.getBrowserLang();

		// find language if region doesn't exist (can be risky)
		// (en-au, [en, de, pt-BR, pt-PT]) -> en
		if (browserLang && languages.includes(browserLang)) {
			return browserLang;
		}

		// not found
		return null;

	}

}
