import { Permission, PermissionAction } from '@unifii/sdk';

import { PermissionFields, UserPermissionInfo } from './authentication';

// Permissions that include any key word
const ANY_PERMISSION_ACTIONS = [PermissionAction.Read, PermissionAction.Update, PermissionAction.Retrieve, PermissionAction.Delete];

const normalizePermissions = (permissions: Permission[]): Permission[] =>

	permissions.reduce<Permission[]>((normalized, permission) => {

		if (splitRequired(permission)) {

			const additional = createAdditionalPermission(permission);

			permission.actions = permission.actions.filter((action) => !additional.actions.find((a) => a.name === action.name));
			normalized.push(permission, additional);
		} else {
			normalized.push(permission);
		}

		return normalized;
	}, []);

const splitRequired = (permission: Permission): boolean => {

	// Requires additional permission
	const paths = ['users', 'projects'];
	const path = permission.path.join('/');

	// temporary name until i better understand purpose of white list
	const containsAnyPermissions = permission.actions.some((a) => ANY_PERMISSION_ACTIONS.includes(a.name));

	return paths.includes(path) && containsAnyPermissions;

};

const createAdditionalPermission = (permission: Permission): Permission => {

	const path = [...permission.path, '?'];
	const actions = permission.actions.filter((action) => ANY_PERMISSION_ACTIONS.includes(action.name));

	return { path, actions };
};

const mapToUserPermissionInfo = (permission: Permission): UserPermissionInfo => {
	let exp = '^' + permission.path.map((step) => {
		if (step === '?') {
			return '[^\\/]*';
		}
		if (step === '*') {
			return '[^$]+$';
		}

		return step;
	}).join(`\\/`);

	exp = exp.endsWith('$') ? exp : exp + '$';
	const pathRegEx = new RegExp(exp, 'g');

	// TODO Temporally ignore the blacklists
	return Object.assign({}, permission, {
		path: permission.path.join('/'),
		pathRegEx,
		fields: permission.fields?.length ? permission.fields : undefined,
		lockedFields: /* permission.lockedFields?.length ? permission.lockedFields : */ undefined,
		readFields: permission.readFields?.length ? permission.readFields : undefined,
		deniedFields: /* permission.deniedFields?.length ? permission.deniedFields : */ undefined,
	});
};

const mergePermissionsFields = (entries: PermissionFields[]): PermissionFields => {

	const acc: PermissionFields = { };

	for (const entry of entries) {
		acc.fields = arraysUnion(acc.fields, entry.fields);
		acc.readFields = arraysUnion(acc.readFields, entry.readFields);
	}

	return acc;

	// TODO Temporally ignore the blacklists
	/*
    const acc: PermissionFields = {
        fields: [],
        lockedFields: [],
        readFields: [],
        deniedFields: [],
    };

    for (const entry of entries) {
        if (acc.fields && acc.lockedFields) {
            // No entry with unlimited edit fields so far
            if (!entry.fields && !entry.lockedFields ) {
                acc.fields = undefined;
                acc.lockedFields = undefined;
            } else {
                // union the whitelists
                if (entry.fields) {
                    acc.fields.push(...entry.fields);
                }
                // intersect the blacklists
                if (entry.lockedFields) {
                    acc.lockedFields = arraysIntersection(acc.lockedFields, entry.lockedFields);
                }
            }
        }

        if (acc.readFields && acc.deniedFields) {
            // No entry with unlimited read fields so far
            if (!entry.readFields && !entry.deniedFields ) {
                acc.readFields = undefined;
                acc.deniedFields = undefined;
            } else {
                // union the whitelists
                if (entry.readFields) {
                    acc.readFields.push(...entry.readFields);
                }
                // intersect the blacklists
                if (entry.deniedFields) {
                    acc.deniedFields = arraysIntersection(acc.deniedFields, entry.deniedFields);
                }
            }
        }            
    }

    return acc;
    */
};

const isFieldGranted = (field: string, action: PermissionAction, permissionFields: PermissionFields): boolean => {
    
	// TODO Temporally ignore black list
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const canAccess = (whiteList: string[] | undefined, blackList: string[] | undefined): boolean => {
		/* if (blackList?.includes(field)) {
            return false;
        }*/
        
		if (!whiteList) {
			return true;
		}

		return whiteList.includes(field);
	};

	switch (action) {
		case PermissionAction.Add:
		case PermissionAction.Invite:
		case PermissionAction.Update:
			return canAccess(permissionFields.fields, permissionFields.lockedFields);
		case PermissionAction.List:
		case PermissionAction.Read:
			return canAccess(permissionFields.readFields, permissionFields.deniedFields);
		default:
			console.warn(`AuthService.checkPermissions at field '${field}' level not available on action ${action}`);
            
			return true;
	}
};

/** Return strict intersection between a & b */
const arraysIntersection = <T>(a: T[] | undefined, b: T[] | undefined): T[] => {

	if (!a || !b) {
		return [];
	}

	return a.filter((v) => b.includes(v));
};

/** Return loosely intersection between a & b */
const arraysLooselyIntersection = <T>(a: T[] | undefined, b: T[] | undefined): T[] | undefined => {
	if (!a || !b) {
		return a ?? b;
	}

	return arraysIntersection(a, b);
};

/** Return union of a & b, undefined when both are undefined */
const arraysUnion = <T>(a: T[] | undefined, b: T[] | undefined): T[] | undefined => {
	if (a) {
		a.push(...(b ?? []));

		return [...a];
	}
    
	if (b) {
		b.push(...(a ?? []));
        
		return [...b];
	}

	return undefined;
};

/** /me (current user) */
const getMePath = (): string[] =>
	['me'];

/** /roles */
const getRolesPath = (): string[] =>
	['roles'];

/** /default-claims (aka user-claims) */
const getDefaultClaimsPath = (): string[] =>
	['default-claims'];

/** /users */
const getUsersPath = (): string[] =>
	['users'];

/** /user/:userId */
const getUserPath = (userId?: number): string[] =>
	[...getUsersPath(), `${userId ? userId : '?'}`];

/** /companies */
const getCompaniesPath = (): string[] =>
	['companies'];

/** /company/:companyId */
const getCompanyPath = (companyId?: string): string[] =>
	[...getCompaniesPath(), `${companyId ? companyId : '?'}`];

/** /units */
const getHierarchyUnitsPath = (): string[] =>
	['units'];

/** /units/? */
const getHierarchyUnitPath = (): string[] =>
	[...getHierarchyUnitsPath(), '?'];

/** /projects  */
const getProjectsPath = (): string[] =>
	['projects'];

/** /projects/:projectId */
const getProjectPath = (projectId: string): string[] =>
	[...getProjectsPath(), `${projectId}`];

/** /projects/:projectId/structure */
const getStructurePath = (projectId: string): string[] =>
	[...getProjectPath(projectId), 'structure'];

/** /projects/:projectId/collections */
const getCollectionsPath = (projectId: string): string[] =>
	[...getProjectPath(projectId), 'collections'];

/** /projects/:projectId/collections/:collectionIdentifier */
const getCollectionPath = (projectId: string, collectionIdentifier: string): string[] =>
	[...getCollectionsPath(projectId), collectionIdentifier];

/** /projects/:projectId/collections/:collectionIdentifier/items */
const getCollectionItemsPath = (projectId: string, collectionIdentifier: string): string[] =>
	[...getCollectionPath(projectId, collectionIdentifier), 'items'];

/** /projects/:projectId/collections/:collectionIdentifier/items/:collectionItemId */
const getCollectionItemPath = (projectId: string, collectionIdentifier: string, collectionItemId: string): string[] =>
	[...getCollectionItemsPath(projectId, collectionIdentifier), collectionItemId];

/** /projects/:projectId/buckets */
const getBucketsPath = (projectId: string): string[] =>
	[...getProjectPath(projectId), 'buckets'];

/** /projects/:projectId/buckets/:bucketId */
const getBucketPath = (projectId: string, bucketId: string): string[] =>
	[...getBucketsPath(projectId), bucketId];

/** /projects/:projectId/buckets/:bucketId/revisions */
const getBucketRevisionsPath = (projectId: string, bucketId: string): string[] =>
	[...getBucketsPath(projectId), bucketId, 'revisions'];

/** /projects/:projectId/buckets/:bucketId/docs */
const getBucketDocumentsPath = (projectId: string, bucketId: string): string[] =>
	[...getBucketsPath(projectId), bucketId, 'docs'];

/** /projects/:projectId/buckets/:bucketId/docs/:documentId */
const getBucketDocumentPath = (projectId: string, bucketId: string, documentId: string): string[] =>
	[...getBucketDocumentsPath(projectId, bucketId), documentId];

/** /projects/:projectId/views */
const getViewsPath = (projectId: string): string[] =>
	[...getProjectPath(projectId), 'views'];

/** /projects/:projectId/views/:viewId */
const getViewPath = (projectId: string, viewId: string): string[] =>
	[...getViewsPath(projectId), viewId];

/** /projects/:projectId/pages */
const getPagesPath = (projectId: string): string[] =>
	[...getProjectPath(projectId), 'pages'];

/** /projects/:projectId/pages/:pageId */
const getPagePath = (projectId: string, pageId: string): string[] =>
	[...getPagesPath(projectId), `${pageId}`];

/** /projects/:projectId/forms */
const getFormsPath = (projectId: string): string[] =>
	[...getProjectPath(projectId), 'forms'];

/** /projects/:projectId/forms/:formId */
const getFormPath = (projectId: string, formId: string): string[] =>
	[...getFormsPath(projectId), formId];

/** /projects/:projectId/tables */
const getTablesPath = (projectId: string): string[] =>
	[...getProjectPath(projectId), 'tables'];

/** /projects/:projectId/tables/:tableIdentifier */
const getTablePath = (projectId: string, tableIdentifier: string): string[] =>
	[...getTablesPath(projectId), tableIdentifier];

export const PermissionsFunctions = {
	normalizePermissions, mapToUserPermissionInfo, mergePermissionsFields, isFieldGranted, arraysIntersection, arraysLooselyIntersection,
	getRolesPath, getDefaultClaimsPath, getUsersPath, getUserPath,
	getMePath, getCompaniesPath, getCompanyPath, getHierarchyUnitsPath, getHierarchyUnitPath,
	getProjectsPath, getProjectPath, getStructurePath, getCollectionsPath, getCollectionPath, getCollectionItemsPath, getCollectionItemPath,
	getBucketPath, getBucketRevisionsPath, getBucketDocumentsPath, getBucketDocumentPath,
	getViewsPath, getViewPath, getPagesPath, getPagePath, getFormsPath, getFormPath, getTablesPath, getTablePath,
};

// projects/:projectId/external-data-sources
// function getExternalDataSourcesPath = (projectId: number): string[] =>
// [...getProjectPath(projectId), 'external-data-sources'];

// projects/:projectId/external-data-sources/:externalDataSourceId
// function getExternalDataSourcePath = (projectId: number, externalDataSourceId: string): string[] =>
// [...getExternalDataSourcesPath(projectId), externalDataSourceId];
