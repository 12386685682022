import { Injectable, inject } from '@angular/core';
import { AssetProfile, FieldType, ImageProfile, Progress, StructureNode, StructureNodeType, TenantClient } from '@unifii/sdk';
import { Observable, lastValueFrom, merge, of } from 'rxjs';
import { concatMap, filter, flatMap, map, reduce, tap, toArray } from 'rxjs/operators';

import { Config } from 'config';
import { ErrorService } from 'shell/errors/error.service';
import { ContentLoader } from 'shell/offline/content-loader';
import { ContentUpdater } from 'shell/offline/content-updater.service';
import { ContentDb, IndexedDbWrapper, TenantDb } from 'shell/offline/indexeddb-wrapper';
import { ContentInfo, ContentPackage, ContentState, ContentStores, TenantStores, checkUpdateType, getProjectNames, getStepsDone } from 'shell/offline/offline-model';
import { OnlineContentLoader } from 'shell/offline/online-content-loader.service';

@Injectable({ providedIn: 'root' })
export class OfflineManager {

	private config = inject(Config);
	private tenantClient = inject(TenantClient);
	private tenantDb = inject(TenantDb);
	private contentDb = inject(ContentDb);
	private loader = inject(OnlineContentLoader);
	private updater = inject(ContentUpdater);
	private errorService = inject(ErrorService);

	/**
     * @param override Override the default ContentLoader for this execution
     * Progress milestones:
     * 5% - Start
     * 20% - Content loaded
     * 90% - Content updated
     * 99% - Assets pruned
     * 100% - Completed
     */
	updateContent(override?: ContentLoader): Observable<Progress> {

		return new Observable((observer) => {

			const loader = override ?? this.loader;

			(async() => {

				try {

					const existingVersion = await this.getContentInfo();
					const nextVersion = await this.updateAvailable(override);

					// Guard local version up to date
					if (!nextVersion) {
						console.log('OfflineContent: No available version to be installed, ABORTED');
						if (!existingVersion) {
							observer.error('No existing or installable content!');
						}
						observer.complete();

						return;
					}

					console.log(`OfflineContent: Installation of version ${nextVersion.name} STARTED`);

					let start: number;

					observer.next(getStepsDone(nextVersion.name, 0, 5));

					// Guarantee there is no projectDB for the update target version (blocking failure)
					await this.deleteDatabase(getProjectNames(nextVersion).db);

					// Load CMS Content
					start = performance.now();
					const content = await loader.load(nextVersion);

					content.assets = await this.extractAssets(content);
					console.log(`OfflineContent: Content load completed in ${(performance.now() - start).toFixed(0)}ms`);
					observer.next(getStepsDone(nextVersion.name, 5, 20));

					// Create DB for Project content
					const nextContentDB = new IndexedDbWrapper(this.errorService);

					nextContentDB.db = this.openProjectDB(
						content.info,
						content.collections.map((collection) => collection.definition.identifier),
					);

					// Register DB as Next in Versions (temporary)
					await this.tenantDb.put<ContentInfo>(
						TenantStores.Versions,
						Object.assign(content.info, { state: ContentState.Next }),
						getProjectNames(content.info).key,
					);
					console.log(`OfflineContent: Created new projectDb for ${content.info.name}`);

					// Update content, include assets
					start = performance.now();
					await this.updater.update(nextContentDB, content, loader).pipe(
						tap((progress) => { observer.next(getStepsDone(nextVersion.name, 20, 90, progress)); }))
						.toPromise();
					console.log(`OfflineContent: Content store completed in ${(performance.now() - start).toFixed(0)}ms`);

					// Prune assets, based on the tenant active projects DBs
					start = performance.now();

					// Mark to be removed
					if (existingVersion) {
						console.log(`OfflineContent: Marked previous content ${existingVersion.name} to be deleted`);
						await this.tenantDb.put<ContentInfo>(
							TenantStores.Versions,
							Object.assign(existingVersion, { state: ContentState.Delete }),
							getProjectNames(existingVersion).key,
						);
					}

					// Add/Replace existingContentInfo with nextContentInfo
					const activeProjects: Observable<{ key: string; value: ContentInfo }> = merge(this.tenantDb.getAll<string, ContentInfo>(TenantStores.Projects).pipe(
						filter((kvp) => kvp.key !== content.info.projectId)),
					merge(of({ key: content.info.projectId, value: content.info })));

					// Prune assets based on the fabricated list of active projects
					const deletedAssetsCount = await this.pruneAssets(activeProjects);

					console.log(`OfflineContent: Pruned ${deletedAssetsCount} assets in ${(performance.now() - start).toFixed(0)}ms`);
					observer.next(getStepsDone(nextVersion.name, 90, 99));

					// Complete
					observer.next(getStepsDone(nextVersion.name, 99, 100));

					// Register stored DB as official project content DB
					await this.tenantDb.put<ContentInfo>(
						TenantStores.Projects,
						Object.assign(content.info, { state: ContentState.Active }),
					);
					console.log(`OfflineContent: Version ${content.info.name} activated into TenantDb`);

					// Delete (temporary) entry as Next from Versions
					await this.tenantDb.delete(TenantStores.Versions, getProjectNames(content.info).key);

					// Official ContentDB point to new DB
					this.contentDb.db = nextContentDB.db;
					console.log(`OfflineContent: Switch ContentDb reference to the new installed DB`);

					console.log(`OfflineContent: Installation of version ${nextVersion.name} FINISHED`);
					observer.complete();

				} catch (e) {
					observer.error(e);
				}

			})();
		});
	}

	/**
     * @param override Override the default ContentLoader for this execution
     * Verify if there is a newer content valid to be installed
     */
	async updateAvailable(override?: ContentLoader): Promise<ContentInfo | null> {

		const loader = override ? override : this.loader;

		try {
			const from = await this.getContentInfo();

			const to = await loader.getLatestInfo();

			const updateType = checkUpdateType(from, to, this.config.unifii.preview ?? false);

			if (updateType != null) {
				return to;
			}
		} catch (e) { /* */ }

		return null;
	}

	/**
     * Open and store a connection to the current project DB
     * Project DB must be compatible with the App Config (preview content flag)
     * Project DB must be registered as Active under TenantDb.Projects
     * Project DB must be present in IndexedDb
     */
	async openContentDB(): Promise<IDBDatabase> {

		// Single promise reference guard
		if (this.contentDb.db) {
			return this.contentDb.db;
		}

		const info = await this.getContentInfo();

		if (!info) {
			throw new Error(`ContentDB is not registered within the tenant`);
		}

		// App configured for stable can not access preview content
		if (!this.config.unifii.preview && info.preview) {
			throw new Error(`ContentDB is not compatible with the app configuration, preview mismatch`);
		}

		this.contentDb.db = new Promise<IDBDatabase>((resolve, reject) => {

			const names = getProjectNames(info);
			const request = indexedDB.open(names.db, 3);

			request.onsuccess = (e: any) => {

				const db: IDBDatabase = e.currentTarget.result;

				db.onversionchange = (x: any) => {
					console.log('OfflineContent: ContentDB ' + names.db + ' versionchange ' + x.oldVersion + ' > ' + x.newVersion);
					db.close();
				};

				resolve(e.currentTarget.result);
			};

			request.onerror = () => {
				// Version point to a corrupted DB... remove the version reference
				this.tenantDb.delete(TenantStores.Projects, info.projectId).then(() => {
					reject('ContentDB error open DB');
				});
			};

			request.onupgradeneeded = async(e: any) => {

				const database: IDBDatabase = request.result;
				const transaction: IDBTransaction = e.target.transaction;

				if (e.oldVersion === 0) {
					// Version point to a non existing DB... remove the version reference
					await this.tenantDb.delete(TenantStores.Projects, info.projectId);
					reject(`ContentDB doesn't exists`);
				}

				if (e.oldVersion === 1) {
					database.createObjectStore(ContentStores.FormVersions);
				}

				// TODO remove index lookup after users have upgraded projects
				if (e.oldVersion === 2) {
					const store = transaction.objectStore(ContentStores.Pages);

					store.createIndex('identifier', 'identifier', { unique: true });
				}

			};

		});

		return this.contentDb.db;
	}

	/**
     * Delete pending/invalid project DBs and their reference inside the tenant
     */
	async cleanUp() {

		await this.openTenantDB();

		return this.tenantDb.getAll<string, ContentInfo>(TenantStores.Versions).pipe(
			filter((entry) => entry.value.state === ContentState.Delete),
			tap((entry) => { console.log(`OfflineManager: Clean up found ${entry.value.name} listed to be deleted`); }),
			flatMap((entry) => this.removeProjectContent(entry.value)),
		).toPromise();
	}

	/** Close the current ContentDb connection */
	projectChanged() {
		this.contentDb.db = null;
	}

	/** Get ContentInfo of the current project */
	async getContentInfo(): Promise<ContentInfo> {

		await this.openTenantDB();

		return this.tenantDb.get<ContentInfo>(TenantStores.Projects, this.config.unifii.projectId);
	}

	private async extractAssets(content: ContentPackage): Promise<(AssetProfile | ImageProfile)[]> {

		const assets: (AssetProfile | ImageProfile)[] = [];

		for (const p of content.pages) {
			const assetFields = p.fields.filter((i) => ([FieldType.ImageList, FieldType.FileList].includes(i.type)));

			for (const f of assetFields) {
				assets.push(...(f.value || []));
			}
		}

		for (const view of content.views) {
			const assetFields = (view.definition.fields || []).filter((i) => ([FieldType.ImageList, FieldType.FileList].includes(i.type)));

			for (const f of assetFields) {
				assets.push(...(view.compound[f.identifier as string] || []));
			}
		}

		for (const collection of content.collections) {
			const assetFields = (collection.definition.fields || []).filter((i) => ([FieldType.ImageList, FieldType.FileList].includes(i.type)));

			for (const compound of collection.compounds) {
				for (const f of assetFields) {
					assets.push(...(compound[f.identifier as string] || []));
				}
			}
		}

		if (content.structure?.children) {
			assets.push(...await this.extractPDFAssets(content.structure.children));
		}

		return assets;
	}

	private async extractPDFAssets(structureNodes: StructureNode[], assets: AssetProfile[] = []): Promise<AssetProfile[]> {

		for (const node of structureNodes) {

			if (node.children) {
				assets.push(...await this.extractPDFAssets(node.children, assets ?? []));
			}

			if (node.type === StructureNodeType.PdfViewer) {
				assets.push(await this.tenantClient.getAsset((node.id as number).toString()));
			}
		}

		return assets;
	}

	private async pruneAssets(activeProjects: Observable<{ key: string; value: ContentInfo }>): Promise<number> {

		// Get assets keys used by this tenant contents
		const used$ = activeProjects.pipe(

			// construct db for each active project
			map((kvp) => {
				const info = kvp.value;
				const projectDb = new IndexedDbWrapper(this.errorService);

				projectDb.db = this.openProjectDB(info);

				return { info, projectDb };
			}),

			// count assets in each project and log it
			flatMap((temp) => temp.projectDb.count(ContentStores.Assets), (temp, count) => {
				console.log(`OfflineContent: project ${temp.info.projectId}.${temp.info.name} has ${count} assets`);

				return temp.projectDb;
			}),

			// get used assets in each project
			concatMap((db) => db.getValues<string>(ContentStores.Assets)),

			// reduce to a single set
			reduce((usedSet, value) => usedSet.add(value), new Set<string>()),

			// count total assets and log the numbers
			flatMap(() => this.tenantDb.count(TenantStores.Assets), (usedSet, count) => {
				console.log(`OfflineContent: ${count} stored, ${count - usedSet.size} unused assets`);

				return usedSet;
			}),
		);

		const unused$ = used$.pipe(
			concatMap(() => this.tenantDb.getKeys<string>(TenantStores.Assets), (used, assetKey) => ({ used, assetKey })),
			filter((temp) => !temp.used.has(temp.assetKey)),
			map((temp) => temp.assetKey),
		);

		const unused = await lastValueFrom(unused$.pipe(toArray()));

		// Prune unused assets
		await this.tenantDb.delete(TenantStores.Assets, unused);

		return unused.length;
	}

	private async removeProjectContent(info: ContentInfo): Promise<void> {

		try {
			const names = getProjectNames(info);

			await this.tenantDb.put<ContentInfo>(
				TenantStores.Versions,
				Object.assign(info, { state: ContentState.Delete }),
				names.key,
			);
			console.log(`OfflineContent: ProjectDb ${names.db} marked for delete`);

			await this.deleteDatabase(names.db);
			console.log(`OfflineContent: Removed contentDb ${names.db}`);

			await this.tenantDb.put<ContentInfo>(
				TenantStores.Versions,
				Object.assign(info, { state: ContentState.Previous }),
				names.key,
			);
			console.log(`OfflineContent: Project ${names.db} info added to history`);

		} catch (e) {
			/* Delete failed, no big issue, the version has been unallocated from the tenantDB anyway and delete will be re-tried*/
			console.log('OfflineContent: Remove database failed', e);
		}
	}

	private openTenantDB(): Promise<IDBDatabase> {

		// Single promise reference
		if (this.tenantDb.db) {
			return this.tenantDb.db;
		}

		this.tenantDb.db = new Promise((resolve, reject) => {

			const dbName = `content-${this.config.unifii.tenant}`;
			const request = indexedDB.open(dbName, 1);

			request.onsuccess = (e) => {

				const db: IDBDatabase = (e.currentTarget as any).result;

				db.onversionchange = (x: any) => {
					console.log(`OfflineContent: TenantDb ${dbName} versionchange ${x.oldVersion} > ${x.newVersion}`);
					db.close();
				};

				resolve(db);
			};

			request.onupgradeneeded = (e) => {
				const database = (e.currentTarget as any).result;

				database.createObjectStore(TenantStores.Assets);
				database.createObjectStore(TenantStores.Projects, { keyPath: 'projectId' });
				database.createObjectStore(TenantStores.Versions);
			};

			request.onerror = reject;
		});

		return this.tenantDb.db;
	}

	private openProjectDB(info: ContentInfo, collections?: string[]): Promise<IDBDatabase> {

		return new Promise((resolve, reject) => {

			const names = getProjectNames(info);
			const request = indexedDB.open(names.db, 3);

			request.onsuccess = (e) => {

				const db: IDBDatabase = (e.currentTarget as any).result;

				db.onversionchange = (x: any) => {
					console.log(`OfflineContent: ProjectDb ${names.db} versionchange ${x.oldVersion} > ${x.newVersion}`);
					db.close();
				};

				resolve(db);
			};

			request.onupgradeneeded = () => {

				console.log('OfflineContent: Created NEW ContentDB!');
				const database: IDBDatabase = request.result;

				// Version 1
				database.createObjectStore(ContentStores.Collections, { keyPath: 'identifier' });
				database.createObjectStore(ContentStores.Forms, { keyPath: 'identifier' });
				database.createObjectStore(ContentStores.Views, { keyPath: 'id' });
				database.createObjectStore(ContentStores.ViewDefinitions, { keyPath: 'identifier' });
				database.createObjectStore(ContentStores.Pages, { keyPath: 'identifier' });
				database.createObjectStore(ContentStores.Buckets, { keyPath: 'bucket' });
				database.createObjectStore(ContentStores.Tables, { keyPath: 'identifier' });
				database.createObjectStore(ContentStores.Structure, { autoIncrement: true });
				database.createObjectStore(ContentStores.Assets, { autoIncrement: true });
				database.createObjectStore(ContentStores.Indexes);
				database.createObjectStore(ContentStores.Identifiers, { autoIncrement: true });

				for (const collection of (collections ?? [])) {
					database.createObjectStore(collection, { keyPath: 'id' });
				}

				// Version 2
				database.createObjectStore(ContentStores.FormVersions);

			};

			request.onerror = reject;
		});
	}

	private deleteDatabase(name: string): Promise<boolean> {

		return new Promise<boolean>((resolve, reject) => {
			const request = indexedDB.deleteDatabase(name);

			request.onsuccess = () => {
				resolve(true);
			};

			request.onerror = (error) => {
				console.log('OfflineContent: deleteDatabase error', error);
				reject(error);
			};

			request.onblocked = (error) => {
				console.log('OfflineContent: deleteDatabase blocked', error);
				reject(error);
			};
		});
	}

}
