import { Injectable, inject } from '@angular/core';
import { ContextProvider } from '@unifii/library/common';
import { PermissionAction, UserInfo, UserStatus, getUserStatus, mapUserToUserContext } from '@unifii/sdk';
import { ProvisioningFormProviderPermissionInfo, UserFormContext, UserFormContextActionType, UserFormProvider, UserFormResourceType, UserKeys } from '@unifii/user-provisioning';

import { Config } from 'config';
import { Authentication, PermissionGrantedResult } from 'shell/services/authentication';
import { PermissionsFunctions } from 'shell/services/permissions-functions';
import { ME_REQUIRED_FIELDS, USER_REQUIRED_FIELDS } from 'shell/table/users/users-constants';

@Injectable()
export class UserFormPermissionsProvider implements UserFormProvider {

	/** Discover hardcoded black list of editable/readable fields */
	private readonly notEditableFields = [UserKeys.SystemRoles, UserKeys.IsTester] as string[];
	private readonly notReadableFields = [UserKeys.IsTester] as string[];
	/** Tenant dependent required fields */
	private readonly tenantRequiredFields: string[] = inject(Config).unifii.tenantSettings?.isUserEmailRequired ? [UserKeys.Email] : [];

	private auth = inject(Authentication);
	private contextProvider = inject(ContextProvider);
	private context = inject(UserFormContext);

	getReadUserPermissionInfo(user: UserInfo): ProvisioningFormProviderPermissionInfo {
		const result = this.readUser(user);

		return {
			granted: result.granted,
			condition: result.condition,
			fields: result.fieldsPermissions.readFields,
		};
	}

	canReadUserField(field: string, user: UserInfo): boolean {
		if (this.notReadableFields.includes(field)) {
			return false;
		}

		return this.readUser(user, field).granted;
	}

	getEditUserPermissionInfo(user: UserInfo | undefined, action: UserFormContextActionType): ProvisioningFormProviderPermissionInfo {
		const result = this.editUser(user, action, undefined);

		return {
			granted: result.granted,
			condition: result.condition,
			fields: this.pruneNotEditableFields(result.fieldsPermissions.fields),
		};
	}

	canEditUserField(field: string, user: UserInfo | undefined, action: UserFormContextActionType): boolean {
		if (this.notEditableFields.includes(field)) {
			return false;
		}

		return this.editUser(user, action, field).granted;
	}

	isEditUserFieldRequired(field: string, action: UserFormContextActionType): boolean {
		return USER_REQUIRED_FIELDS[action].includes(field) || this.tenantRequiredFields.includes(field);
	}

	getReadMePermissionInfo(): ProvisioningFormProviderPermissionInfo {
		const result = this.readMe(undefined);

		return {
			granted: result.granted,
			condition: result.condition,
			fields: result.fieldsPermissions.readFields,
		};
	}

	canReadMeField(field: string): boolean {
		if (this.notReadableFields.includes(field)) {
			return false;
		}

		return this.readMe(field).granted;
	}

	getUpdateMePermissionInfo(): ProvisioningFormProviderPermissionInfo {
		const result = this.updateMe(undefined);

		return {
			granted: result.granted,
			condition: result.condition,
			fields: result.fieldsPermissions.fields,
		};
	}

	canUpdateMeField(field: string): boolean {
		return this.updateMe(field).granted;
	}

	isUpdateMeFieldRequired(field: string): boolean {
		return ME_REQUIRED_FIELDS.includes(field) || this.tenantRequiredFields.includes(field);
	}

	canListUsers(): boolean {
		return this.auth.getGrantedInfoWithoutCondition(PermissionsFunctions.getUsersPath(), PermissionAction.List).granted;
	}

	canListRoles(): boolean {
		return this.auth.getGrantedInfoWithoutCondition(PermissionsFunctions.getRolesPath(), PermissionAction.List).granted;
	}

	canListCompanies(): boolean {
		return this.auth.getGrantedInfoWithoutCondition(PermissionsFunctions.getCompaniesPath(), PermissionAction.List).granted;
	}

	canListClaims(): boolean {
		return this.auth.getGrantedInfoWithoutCondition(PermissionsFunctions.getDefaultClaimsPath(), PermissionAction.List).granted;
	}
	canListHierarchies(): boolean {
		return this.auth.getGrantedInfoWithoutCondition(PermissionsFunctions.getHierarchyUnitsPath(), PermissionAction.List).granted;
	}

	/* ***************************************************** DISCOVER ONLY METHODS ******************************************* */

	/** Permission to INVITE a user */
	canInvite(): boolean {
		switch (this.context.type) {
			case UserFormResourceType.User:
				return this.auth.getGrantedInfoWithoutCondition(PermissionsFunctions.getUsersPath(), PermissionAction.Invite).granted;
			case UserFormResourceType.Me:
				return false;
		}
	}

	/** Permission to UPDATE the user */
	canUpdate(user: UserInfo): boolean {
		switch (this.context.type) {
			case UserFormResourceType.User:
				return this.editUser(user, this.context.action, undefined).granted;
			case UserFormResourceType.Me:
				return user ? this.updateMe(undefined).granted : false;
		}
	}

	/** Permission to DELETE the user */
	canDelete(user: UserInfo): boolean {
		if (this.context.type === UserFormResourceType.Me || user?.id == null) {
			return false;
		}
		const status = getUserStatus(user);
		const hasDeletePermission = this.auth.getGrantedInfo(PermissionsFunctions.getUserPath(+user.id), PermissionAction.Delete, mapUserToUserContext(user), this.contextProvider.get()).granted;

		return status === UserStatus.Pending && hasDeletePermission;
	}

	private readUser(user: UserInfo, field?: string): PermissionGrantedResult {
		// No READ permissions for undefined user
		if (user.id == null) {
			return { granted: false, condition: undefined, fieldsPermissions: {} };
		}

		return this.auth.getGrantedInfo(PermissionsFunctions.getUserPath(+user.id), PermissionAction.Read, mapUserToUserContext(user), this.contextProvider.get(), field);
	}

	private editUser(user: UserInfo | undefined, action: UserFormContextActionType, field: string | undefined): PermissionGrantedResult {
		switch (action) {
			case PermissionAction.Add:
			case PermissionAction.Invite:
				return this.auth.getGrantedInfoWithoutCondition(PermissionsFunctions.getUsersPath(), action, field);
			case PermissionAction.Update:
				return user?.id != null ?
					this.auth.getGrantedInfo(PermissionsFunctions.getUserPath(+user.id), action, mapUserToUserContext(user), this.contextProvider.get(), field) :
					{ granted: false, condition: undefined, fieldsPermissions: {} };
		}
	}

	private readMe(field: string | undefined): PermissionGrantedResult {
		return this.auth.getGrantedInfoWithoutCondition(PermissionsFunctions.getMePath(), PermissionAction.Read, field);
	}

	private updateMe(field: string | undefined): PermissionGrantedResult {
		return this.auth.getGrantedInfoWithoutCondition(PermissionsFunctions.getMePath(), PermissionAction.Update, field);
	}

	private pruneNotEditableFields(fields?: string[]): string[] | undefined {
		return fields?.filter((field) => !this.notEditableFields.includes(field));
	}

}
