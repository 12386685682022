import { Injectable, inject } from '@angular/core';
import { Router } from '@angular/router';
import { SessionStorageWrapper } from '@unifii/library/common';
import { UfRequestError } from '@unifii/sdk';
import { BehaviorSubject } from 'rxjs';

import { Environment } from 'config';
import { CompleteRegistrationPath, IdentityPath, PasswordChangePath, UserAccessRootPath } from 'discover/discover-constants';
import { AppError } from 'shell/errors/errors';

export interface AccessDeniedInfo {
	error?: string;
	message?: string;
	deniedRedirectURL?: any[] | string; // Redirect URL when access denied
	grantedRedirectURL?: string; // Redirect URL after access granted
}

const GrantedRedirectURL = 'UFGrantedRedirectURL';

/**
 * Holds stateful information about the user access that wont survive a refresh
 * Allows
 *  - Message & Errors to be passed to access pages
 *  - Holds a startURL for the app to start on once the user is granted access
 */
@Injectable({ providedIn: 'root' })
export class UserAccessManager {

	deniedEvent = new BehaviorSubject<AccessDeniedInfo>({});
	errorEvent = new BehaviorSubject<AppError | null>(null);

	private rootURL = '/';
	private defaultRedirectCommands = inject(Environment).authProvider ? ['/', UserAccessRootPath, IdentityPath]: ['/' + UserAccessRootPath];
	private _grantedRedirectURL: string | null;

	private router = inject(Router);
	private sessionStorage = inject(SessionStorageWrapper);

	showError(error: AppError | null) {
		this.errorEvent.next(error);
	}

	async deny(info: AccessDeniedInfo = {}) {
		/**
         * When access denied store url in sessionStorage for external auth providers
         * this way we can guarantee users get redirected after access is granted
         */
		this.grantedRedirectURL = info.grantedRedirectURL ?? null;

		// notify subscribers
		await this.redirect(info.deniedRedirectURL ?? this.defaultRedirectCommands);

		if (info.error != null) {
			this.errorEvent.next(new UfRequestError(info.error));
		}
		this.deniedEvent.next(info);
	}

	grant() {
		// Reset denied events
		this.deniedEvent.next({});
		void this.redirect(this.grantedRedirectURL ?? this.rootURL);
		this.grantedRedirectURL = null; // Reset SessionStorage
	}

	private redirect(url: any[] | string): Promise<boolean> {
		if (Array.isArray(url)) {
			return this.router.navigate(url);
		} else {
			return this.router.navigateByUrl(url);
		}
	}

	private set grantedRedirectURL(v: string | null) {
		// Ignore url's that are outside is outside of the main component
		if (
			(v ?? '').includes(`/${UserAccessRootPath}`) ||
            (v ?? '').includes(`/${PasswordChangePath}`) ||
            (v ?? '').includes(`/${CompleteRegistrationPath}`) ||
            v === '/'
		) {
			return;
		}

		this._grantedRedirectURL = v;

		if (v) {
			this.sessionStorage.setItem(GrantedRedirectURL, v);
		} else {
			this.sessionStorage.removeItem(GrantedRedirectURL);
		}
	}

	private get grantedRedirectURL(): string | null {

		if (this._grantedRedirectURL) {
			return this._grantedRedirectURL;
		}

		return this.sessionStorage.getItem(GrantedRedirectURL) as string | null;
	}

}
