import { inject } from '@angular/core';
import { CanDeactivateFn } from '@angular/router';

import { ContentNodeComponent } from 'shell/content/content-node.component';

import { DialogsService } from './dialogs.service';
import { EditedDataService } from './edited-data.service';

/**
* Guard is called when trying to leave the page, if component has edited data then user must confirm before leaving.
* If component is content node, then its child components state is checked.
*/
export const editedDataGuard: CanDeactivateFn<object | ContentNodeComponent<object>> = (component: object | ContentNodeComponent<object>) => {

	const dialogs = inject(DialogsService);
	const editedDataService = inject(EditedDataService);

	if ((component instanceof ContentNodeComponent && component.edited) || editedDataService.getEditedStatus(component)) {
		return dialogs.confirmLeaveWithoutSaving();
	}

	return true;
};
