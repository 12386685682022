import { Component, HostBinding, OnDestroy, OnInit, inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Modal, ModalData, ModalRuntime, SharedTermsTranslationKey } from '@unifii/library/common';
import { Progress, UfRequestError } from '@unifii/sdk';
import { Observable, Subscription } from 'rxjs';

import { DiscoverTranslationKey } from 'discover/discover.tk';
import { ErrorService } from 'shell/errors/error.service';

export interface UpdateProgressData {
	progress: Observable<Progress>;
}

@Component({
	selector: 'ud-update-progress',
	templateUrl: './update-progress.html',
	styleUrls: ['./update-progress.less'],
	standalone: false,
})
export class UpdateProgressComponent implements Modal<UpdateProgressData, boolean>, OnInit, OnDestroy {

	@HostBinding('class.ud-form-card') get isError() {
		return !!this.error;
	}

	runtime = inject<ModalRuntime<UpdateProgressData, boolean>>(ModalRuntime);
	data = inject<UpdateProgressData>(ModalData);

	protected readonly sharedTermsTK = SharedTermsTranslationKey;
	protected readonly discoverTK = DiscoverTranslationKey;
	protected version: string | null;
	protected progress = 0;
	protected error: UfRequestError | undefined;

	private subscriptions = new Subscription();
	private errorService = inject(ErrorService);
	private translateService = inject(TranslateService);

	ngOnInit() {
		this.update();
	}

	ngOnDestroy() {
		this.subscriptions.unsubscribe();
	}

	update() {
		this.subscriptions.unsubscribe();
		this.subscriptions = new Subscription();

		this.subscriptions.add(this.data.progress.subscribe({
			next: (progress) => {
				this.progress = progress.done / progress.total;
				this.version = progress.id ?? null;
			},
			complete: () => {
				this.close();
			},
			error: (error) => {
				console.error(error);
				this.error = this.errorService.createError(
					this.translateService.instant(DiscoverTranslationKey.UpdateProgressErrorUpdateFail),
					error,
				);
			},
		}));
	}

	close() {
		this.runtime.close(this.error == null);
	}

}
