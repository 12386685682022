import { Component, OnInit, inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Breadcrumb, CommonTranslationKey, SharedTermsTranslationKey } from '@unifii/library/common';
import { PermissionAction } from '@unifii/sdk';
import { UserFormContext, UserFormResourceType } from '@unifii/user-provisioning';

import { DiscoverTranslationKey } from 'discover/discover.tk';
import { Authentication } from 'shell/services/authentication';
import { PermissionsFunctions } from 'shell/services/permissions-functions';
import { ShellTranslationKey } from 'shell/shell.tk';

@Component({
	selector: 'ud-user-create',
	templateUrl: './user-create.html',
	styleUrls: ['./user-create.less'],
	standalone: false,
})
export class UserCreateComponent implements OnInit {

	readonly sharedTermsTK = SharedTermsTranslationKey;
	readonly commonTK = CommonTranslationKey;
	readonly discoverTK = DiscoverTranslationKey;

	protected error: string;
	protected breadcrumbs: Breadcrumb[] = [];

	private route = inject(ActivatedRoute);
	private translate = inject(TranslateService);
	private auth = inject(Authentication);
	private context = inject(UserFormContext);

	ngOnInit() {
		this.context.set(
			UserFormResourceType.User,
			this.route.snapshot.data.action === PermissionAction.Invite ? PermissionAction.Invite : PermissionAction.Add,
		);

		this.breadcrumbs.push({
			name: this.translate.instant(DiscoverTranslationKey.UsersLabel),
			urlSegments: ['..'],
		}, {
			name: this.breadCrumbsLabel,
		});

		if (!this.auth.getGrantedInfoWithoutCondition(PermissionsFunctions.getUsersPath(), this.context.action).granted) {
			this.error = this.translate.instant(ShellTranslationKey.ErrorAccessDenied);
		}
	}

	private get breadCrumbsLabel(): string {
		return this.context.action === PermissionAction.Invite ?
			this.translate.instant(SharedTermsTranslationKey.ActionInvite) as string :
			this.translate.instant(SharedTermsTranslationKey.ActionCreate) as string;
	}

}
