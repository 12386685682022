<uf-panel class="container">
	<div class="header-pane">
		<uf-breadcrumbs [breadcrumbs]="breadcrumbs" />
	</div>

	<div class="content-pane scrollable">
		@if (error) {
			<uf-blockquote [content]="error" icon="errorSolid" class="col-12 error" />
		}
		@if (!error) {
			<uf-tabs>
				<uf-tab [label]="discoverTK.InputUsersLabel | translate" path="input" />
				<uf-tab [label]="discoverTK.UploadBasicCSVLabel | translate" path="upload-basic-csv" />
				<uf-tab [label]="discoverTK.UploadAdvancedCSVLabel | translate" path="upload-advanced-csv" />
			</uf-tabs>
		}
	</div>
</uf-panel>
