<uf-progress class="large" />
<div class="uf-app-bar">
	@if (data.guard !== true) {
		<button (click)="close()" type="button" class="uf-action tertiary">
			<uf-icon name="close" />
		</button>
	}
	<h3>{{ shellTK.ChangeLanguageTitle | translate }}</h3>
</div>

<div class="uf-grid pad">
	<ul class="uf-list col-12">
		@for (language of languages; track language) {
			<li (click)="data.current = language.identifier" class="uf-list-button language">
				<img src="assets/svg/language-{{ language.identifier }}.svg" />
				<div class="multi-text">
					{{ language.name }}
				</div>
				@if (language.identifier === data.current) {
					<uf-icon name="tick" class="small" />
				}
			</li>
		}
	</ul>

	<div class="col-12 error">
		@if (error) {
			<span>{{ error | translate }}</span>
		}
	</div>
</div>

<div class="uf-form-actions">
	@if (data.guard !== true && !loading) {
		<button (click)="close()" type="button" class="uf-button tertiary small left">
			{{ sharedTermsTK.ActionCancel | translate }}
		</button>
	}
	<button [disabled]="loading" (click)="confirm()" type="button" class="uf-button primary small">
		{{ sharedTermsTK.ActionChange | translate }}
	</button>
</div>
