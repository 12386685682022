import { Component, HostBinding, inject } from '@angular/core';
import { Modal, ModalData, ModalRuntime, SharedTermsTranslationKey } from '@unifii/library/common';

export interface PdfViewerModalData {
	url: string;
	title: string;
}

@Component({
	selector: 'us-pdf-viewer-modal',
	templateUrl: './pdf-viewer-modal.html',
	styleUrls: ['./pdf-viewer-modal.less'],
	standalone: false,
})
export class PdfViewerModalComponent implements Modal<PdfViewerModalData, undefined> {

	@HostBinding('class.uf-form-card') class = true;

	runtime = inject<ModalRuntime<PdfViewerModalData, undefined>>(ModalRuntime);
	data = inject<PdfViewerModalData>(ModalData);

	protected readonly sharedTermsTK = SharedTermsTranslationKey;

	close() {
		this.runtime.close();
	}

}
