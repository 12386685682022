import { ColumnDisplayDescriptor, columnDescriptorToColumDisplayDescriptor, isCustomColumn } from '@unifii/library/common';
import { ColumnDescriptor, Table } from '@unifii/sdk';

import { Config } from 'config';

/**
 * Check if the Table allows 'export' for any of the roles in input
 * @param table
 * @param roles of the user in context
 */
export const checkExportAllowedForRoles = (table: Table, roles: string[]): boolean => {
    if (table.exportVisibleTo && table.exportVisibleTo.length > 0) {
        return roles.some((role) => table.exportVisibleTo?.includes(role));
    }

    return true;
};

/**
 * Check if the showCount feature is enable for this table
 * @param config
 * @param table
 * @returns
 */
export const checkShowCount = (config: Config, table: Table): boolean =>
    !!(config.unifii.tenantSettings?.features.indexing && table.showCount);

/**
 * Transform the columns to ColumnDisplayDescriptor and filter the custom ones
 * @param columns
 */
export const getTableCustomColumnsDisplayDescriptors = (columns: ColumnDescriptor[] | undefined): ColumnDisplayDescriptor[] => {
    return columns?.map(columnDescriptorToColumDisplayDescriptor).filter(isCustomColumn) ?? [];
};
