import { Directive, Input, OnInit, TemplateRef, ViewContainerRef, inject } from '@angular/core';

import { ShellFeatureFlagService } from 'shell/services/shell-feature-flag.service';
import { ShellFeatureFlagKey } from 'shell/shell-model';

@Directive({
	selector: '[usFeatureFlag]',
	standalone: false,
})
export class ShellFeatureFlagDirective implements OnInit {

	@Input({ required: true }) usFeatureFlag: ShellFeatureFlagKey;

	private vcr = inject(ViewContainerRef);
	private tpl = inject(TemplateRef);
	private service = inject(ShellFeatureFlagService);

	async ngOnInit() {
		if (await this.service.isEnabled(this.usFeatureFlag)) {
			this.vcr.createEmbeddedView(this.tpl);
		}
	}

}
