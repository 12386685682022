import { Component, OnDestroy, OnInit, ViewChild, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Breadcrumb, FilterEntry, FilterEntryAdapter, FilterManager, FilterSerializer, FilterValue, HierarchyUnitProvider, RuntimeDefinition, RuntimeField, SortStatus, TableComponent, TableConfig, TableConfigColumn } from '@unifii/library/common';
import { DataSourceType, Dictionary, FieldOption, FieldType, StructureNode } from '@unifii/sdk';
import { Subscription } from 'rxjs';

import { ReportFilterSerializer } from 'discover/reports/report-filter-serializer';
import { findField } from 'discover/reports/report-functions';
import { ReportCustomFilterConfig, ReportService } from 'discover/reports/report-service';
import { RiskMatrixDatasource } from 'discover/reports/risk-matrix/risk-matrix-datasource';
import { RiskMatrixConfig, RiskMatrixEntry, RiskMatrixService } from 'discover/reports/risk-matrix/risk-matrix-service';
import { UsFormService, UsFormServiceFactoryLogic, formServiceFactory } from 'shell/form/form-service.service';
import { NavigationService } from 'shell/nav/navigation.service';
import { BreadcrumbsService } from 'shell/services/breadcrumbs.service';
import { FormDataPath, TableSearchMinLength } from 'shell/shell-constants';

const managementMatrixTag = 'risk-management-matrix';
const hierarchyMatrixTag = 'risk-matrix-hierarchy';
const matrixFormIdTag = 'MatrixForm';
const matrixBucketIdTag = 'MatrixBucket';

const enterpriseMatrixTag = 'RiskLevel:Enterprise';
const groupMatrixTag = 'RiskLevel:Group';
const functionalMatrixTag = 'RiskLevel:Functional';

const formStates = [
	'AnalysisCompleted',
	'RiskMitigated',
	'FurtherMitigation',
];

const riskAssessmentFormStates = [
	'RiskAnalysisCompleted',
	'HighRiskAnalysisCompleted',
	'RiskAnalysisApproved',
	'HighRiskAnalysisApproved',
	'ActionsCreated',
	'ActionsCreatedHighRisk',
	'ActionsCompleted',
	'ActionsCompletedHighRisk',
	'RiskMitigated',
	'HighRiskMitigated',
	'RiskUnMitigated',
	'HighRiskUnmitigated',
	'FurtherControlsDefined',
	'FurtherControlsDefinedHighRisk',
	'FurtherControlsApproved',
	'FurtherControlsApprovedHighRisk',
	'FurtherActionsCreated',
	'FurtherActionsCreatedHighRisk',
	'FurtherActionsCompleted',
	'FurtherActionsCompletedHighRisk',
	'RiskReMitigated',
	'HighRiskRemitigated',
];

interface CellLabels {
	rows: string[];
	columns: string[];
}

interface DisplayMatrix {
	title: string;
	yAxisLabel?: string;
	xAxisLabel?: string;
	labels: CellLabels;
	data: any;
}

interface Options {
	riskCategory: FieldOption[];
	riskEnterprise: FieldOption[];
	riskType: FieldOption[];
	riskSource: FieldOption[];
	state: FieldOption[];
}

@Component({
	selector: 'ud-risk-matrix',
	templateUrl: './risk-matrix.html',
	styleUrls: ['./risk-matrix.less'],
	providers: [
		RiskMatrixService,
		ReportService,
		BreadcrumbsService,
		{ provide: UsFormService, useFactory: formServiceFactory, deps: [UsFormServiceFactoryLogic] },
	],
	standalone: false,
})
export class RiskMatrixComponent implements OnInit, OnDestroy {

	@ViewChild(TableComponent) table: TableComponent<RiskMatrixEntry>;

	protected readonly mitigatedRowFieldId = 'consequenceMitigated';
	protected readonly mitigatedColumnFieldId = 'likelihoodMitigated';
	protected readonly unmitigatedRowFieldId = 'consequenceUnmitigated';
	protected readonly unmitigatedColumnFieldId = 'likelihoodUnmitigated';

	protected breadcrumbs: Breadcrumb[] = [];
	protected colorsLookup: Dictionary<string> = {
		blue: '#03a9f4',
		green: '#8bc34a',
		yellow: '#ffeb3b',
		orange: '#ff9800',
		red: '#f44336',
	};
	protected config: RiskMatrixConfig[][] | undefined;
	protected datasource: RiskMatrixDatasource;
	protected gridData: DisplayMatrix[];
	protected gridSize: number;
	protected filterManager = new FilterManager<FilterValue, FilterEntry>([], inject(HierarchyUnitProvider), new ReportFilterSerializer(inject<FilterSerializer<FilterValue, FilterEntry>>(FilterSerializer)), null);
	protected filterValues: Dictionary<FilterValue> = {};
	protected showFilters = false;
	protected sort?: SortStatus;
	protected tableConfig: TableConfig<RiskMatrixEntry>;

	private currentFilterValues: Dictionary<FilterValue>;
	private firstEntry = true;
	private formDefinition?: RuntimeDefinition;
	private isManagementMatrix: boolean;
	private isEnterpriseMatrix: boolean;
	private isGroupMatrix: boolean;
	private isFunctionalMatrix: boolean;

	private matrixFormId: string;
	private matrixBucketId: string;
	private hasHierarchies: boolean;
	private subscriptions = new Subscription();

	private router = inject(Router);
	private route = inject(ActivatedRoute);
	private translate = inject(TranslateService);
	private riskMatrixService = inject(RiskMatrixService);
	private breadcrumbsService = inject(BreadcrumbsService);
	private nav = inject(NavigationService);
	private formService = inject(UsFormService);
	private filterEntryAdapter = inject(FilterEntryAdapter);
	private reportService = inject(ReportService);

	constructor(
	) {
		this.breadcrumbsService.title = 'Risk Matrix';
		this.breadcrumbs = this.breadcrumbsService.getBreadcrumbs();
	}

	async ngOnInit() {
		const node = this.nav.current;

		// Configuring functionality based on tags
		this.isManagementMatrix = !!node?.tags?.includes(managementMatrixTag);
		this.isEnterpriseMatrix = !!node?.tags?.includes(enterpriseMatrixTag);
		this.isGroupMatrix = !!node?.tags?.includes(groupMatrixTag);
		this.isFunctionalMatrix = !!node?.tags?.includes(functionalMatrixTag);
		this.hasHierarchies = !!node?.tags?.includes(hierarchyMatrixTag);
		this.matrixFormId = this.getFormId(node);
		this.matrixBucketId = this.getBucketId(node);

		await this.loadFormDefinition();
		await this.loadConfig();
		await this.initFilters();
		this.initTable();

		this.subscriptions.add(this.route.params.subscribe(() => void this.routeChanged()));
	}

	ngOnDestroy() {
		this.subscriptions.unsubscribe();
	}

	protected downloadTableAsCsv() {
		this.datasource.downloadTableAsCsv(this.tableConfig.columns);
	}

	protected filtersChange() {
		const serialized = this.filterManager.serializeAll(this.filterValues);

		void this.router.navigate([{ ...serialized }], { relativeTo: this.route });
	}

	private reload(filters: Dictionary<string | null>) {
		this.datasource = new RiskMatrixDatasource(
			this.riskMatrixService,
			filters,
			this.sort,
		);
	}

	private async routeChanged() {
		const params = Object.assign({}, this.route.snapshot.params);

		const nextFilters = await this.filterManager.deserializeAll(params);

		const changed = JSON.stringify(nextFilters) !== JSON.stringify(this.currentFilterValues);

		this.filterValues = nextFilters;

		this.currentFilterValues = JSON.parse(JSON.stringify(nextFilters));

		if (changed || this.firstEntry) {
			void this.loadData();
		}

		this.firstEntry = false;
	}

	private getFormId(node: StructureNode | null): string {
		const formId = node?.tags?.find((t) => t.startsWith(`${matrixFormIdTag}:`))?.replace(`${matrixFormIdTag}:`, '');

		if (formId) {
			return formId;
		}

		return this.isManagementMatrix ? 'hs-rm' : 'risk-assessment';
	}

	private getBucketId(node: StructureNode | null): string {
		const bucketID = node?.tags?.find((t) => t.startsWith(`${matrixBucketIdTag}:`))?.replace(`${matrixBucketIdTag}:`, '');

		if (bucketID) {
			return bucketID;
		}

		return this.isManagementMatrix ? 'risk-management' : 'risk-assessment';
	}

	private async loadFormDefinition() {
		try {
			this.formDefinition = await this.formService.getFormDefinition(this.matrixFormId);
		} catch (e) {
			console.error(e);
		}
	}

	private async loadConfig() {
		const config = await this.riskMatrixService.getConfig();

		if (config?.length) {
			this.config = config;
			this.gridSize = config.length;
		}
		this.config = config ? config.reverse() : undefined;
	}

	private async initFilters() {
		try {
			await this.createFilterEntries();
			await this.routeChanged();
		} catch (e) {
			console.error(e);
		}
	}

	private async createFilterEntries() {
		const configs = await this.getFilterConfigs();

		for (const config of configs) {
			const loader = this.reportService.createFilterLoader(config.loader);
			// TODO Fix the create signature to accept directly a Loader
			const filterEntry = this.filterEntryAdapter.transform({
				type: this.reportService.getFilterType(config.type, loader),
				identifier: config.identifier,
				label: config.label,
				options: config.options,
				loader,
				translateService: this.translate,
				searchMinLength: TableSearchMinLength,
			});

			if (filterEntry) {
				this.filterManager.add(filterEntry);
			}
		}
	}

	private async loadData() {
		try {
			let filters = this.filterManager.serializeAll(this.filterValues);

			if (this.isEnterpriseMatrix) {
				filters = { ...filters, riskEnterprise: 'Enterprise' };
			} else if ( this.isGroupMatrix) {
				filters = { ...filters, riskEnterprise: 'Group' };
			} else if (this.isFunctionalMatrix) {
				filters = { ...filters, riskEnterprise: 'Functional' };
			}
			this.reload(filters);
			const mitigatedData = await this.riskMatrixService.getMitigatedOccurrences(filters);
			const unmitigatedData = await this.riskMatrixService.getUnmitigatedOccurrences(filters);

			this.createDisplayData(mitigatedData, unmitigatedData);
		} catch (e) {
			console.error(e);
		}
	}

	private createDisplayData(mitigatedData?: number[][], unmitigatedData?: number[][]) {
		if (this.formDefinition) {
			this.gridData = this.createDisplayMatrix(this.formDefinition, mitigatedData, unmitigatedData);
		}
	}

	private createDisplayMatrix(formDefinition: RuntimeDefinition, mitigatedData?: number[][], unmitigatedData?: number[][]): DisplayMatrix[] {
		const diagrams: DisplayMatrix[] = [];

		const unmitigatedRowField = findField(formDefinition.fields, this.unmitigatedRowFieldId);
		const unmitigatedColumnField = findField(formDefinition.fields, this.unmitigatedColumnFieldId);

		if (unmitigatedData && unmitigatedRowField && unmitigatedColumnField) {
			diagrams.push(this.createDiagram(unmitigatedRowField, unmitigatedColumnField, 'Unmitigated', unmitigatedData));
		}

		const mitigatedRowField = findField(formDefinition.fields, this.mitigatedRowFieldId);
		const mitigatedColumnField = findField(formDefinition.fields, this.mitigatedColumnFieldId);

		if (mitigatedData && mitigatedRowField && mitigatedColumnField) {
			diagrams.push(this.createDiagram(mitigatedRowField, mitigatedColumnField, 'Mitigated', mitigatedData));
		}

		return diagrams;
	}

	private createDiagram(rowField: RuntimeField, columnField: RuntimeField, title: string, data: number[][]): DisplayMatrix {
		return {
			title,
			yAxisLabel: columnField.label,
			xAxisLabel: rowField.label,
			labels: this.createLabels(rowField, columnField),
			data: data.reverse(),
		};
	}

	private createLabels(rowField: RuntimeField, columnField: RuntimeField): CellLabels {
		return {
			rows: rowField.options.map((option) => option.name),
			columns: columnField.options.map((option) => option.name).reverse(),
		};
	}

	private initTable() {

		const columns: TableConfigColumn<RiskMatrixEntry>[] = [
			{ name: 'formNumber', label: 'Form Number' },
			{ name: 'riskTitle', label: 'Risk Title' },
		];

		if (this.hasHierarchies) {
			columns.push({ name: 'hierarchy', label: 'Hierarchy' });
		} else {
			columns.push({ name: 'location', label: 'Location' });
		}

		columns.push(
			{ name: 'riskSource', label: 'Risk Source' },
			{ name: 'riskOwner', label: 'Risk Owner' },
			{ name: 'unmitigatedRiskRating', label: 'Unmitigated Rating' },
			{ name: 'mitigatedRiskRating', label: 'Mitigated Rating' },
			{ name: 'formState', label: 'State' },
		);

		this.tableConfig = {
			id: 'urs-risk-matrix',
			columns,
			pageSize: 100,
			columnToggles: false,
			row: {
				link: (entry: RiskMatrixEntry) =>
					['/', FormDataPath, this.matrixBucketId, entry.id],
			},
		};
	}

	private async getFilterConfigs(): Promise<ReportCustomFilterConfig[]> {
		const options = await this.getOptions(this.isManagementMatrix);
		const configs = [{
			identifier: 'locations',
			label: 'Locations',
			type: FieldType.Lookup,
			loader: {
				type: DataSourceType.Collection,
				id: 'locations',
				identifierProperty: 'id',
				nameProperty: 'location',
			},
		}, {
			identifier: 'riskTypes',
			label: 'Risk Types',
			type: FieldType.MultiChoice,
			options: options.riskType,
		}, {
			identifier: 'riskSources',
			label: 'Risk Sources',
			type: FieldType.MultiChoice,
			options: options.riskSource,
		}, {
			identifier: 'formNumber',
			label: 'Risk Number',
			type: FieldType.Text,
		}, {
			identifier: 'dateIdentified',
			label: 'Date Identified',
			type: FieldType.Date,
			range: true,
		}, {
			identifier: 'riskOwnerUsername',
			label: 'Risk Owner',
			type: FieldType.Choice,
			loader: {
				type: DataSourceType.Users,
			},
		}, {
			identifier: 'unmitigatedRiskRating',
			label: 'Unmitigated Risk Rating',
			type: FieldType.Number,
			range: true,
		}, {
			identifier: 'mitigatedRiskRating',
			label: 'Mitigated Risk Rating',
			type: FieldType.Number,
			range: true,
		}];

		// Swap filters based on tag TODO filters will be customized on back end in future
		if (!this.isManagementMatrix) {
			configs.splice(1, 0, {
				identifier: 'riskCategories',
				label: 'Risk Categories',
				type: FieldType.MultiChoice,
				options: options.riskCategory,
			});
		}
		if (this.hasHierarchies) {
			configs.splice(0, 1, {
				identifier: 'hierarchy',
				label: 'Hierarchy',
				type: FieldType.Hierarchy,
			});
		}
		if (!this.isEnterpriseMatrix && !this.isGroupMatrix && !this.isFunctionalMatrix) {
			configs.splice(1, 0, {
				identifier: 'riskEnterprise',
				label: 'Risk Levels',
				type: FieldType.MultiChoice,
				options: options.riskEnterprise,
			});
		}

		return configs;
	}

	private async getOptions(isManagementMatrix?: boolean): Promise<Options> {
		const options: Options = {
			riskCategory: [],
			riskEnterprise: [],
			riskType: [],
			riskSource: [],
			state: [],
		};

		let definition;

		try {
			definition = await this.formService.getFormDefinition(this.matrixFormId);
		} catch (e) {
			console.error(`Failed to load form ${this.matrixFormId}`, e);
		}

		if (definition) {
			options.riskCategory = findField(definition.fields, 'riskCategory')?.options ?? [];
			options.riskEnterprise = findField(definition.fields, 'riskEnterprise')?.options ?? [];
			options.riskSource = findField(definition.fields, 'riskSource')?.options ?? [];
			options.riskType = findField(definition.fields, 'riskType')?.options ?? [];
		}

		// TODO make RM filters configurable
		if (isManagementMatrix) {
			options.state = riskAssessmentFormStates.map((state) => ({ identifier: state, name: state }));
		} else {
			options.state = formStates.map((state) => ({ identifier: state, name: state }));
		}

		return options;
	}

}
