import { Injectable, inject } from '@angular/core';
import { DataDisplayInfo, DataDisplayService, DataDisplaySpecificEntitiesService, DataDisplaySpecificEntitySinglePropertyInfo, DataLookupService, DataPropertyDescriptor, SEPARATOR_COMMA, TableConfigColumn, WindowWrapper, columnDescriptorToColumDisplayDescriptor, dataDescriptorToDataDisplayInfo, isSortableColumn } from '@unifii/library/common';
import { ColumnDescriptor, Company, FormData, TableSourceType, UserInfo, isNotNull } from '@unifii/sdk';

import { MobileScreenWidth } from 'shell/shell-constants';

import { TableData } from './models';

/**
 * @description
 * TableColumnFactory is used by TableContainerManager classes
 */
@Injectable({ providedIn: 'root' })
export class TableColumnFactory {

	private dataLookupService = inject(DataLookupService);
	private dataDisplayService = inject(DataDisplayService);
	private dataDisplayEntityService = inject(DataDisplaySpecificEntitiesService);

	// TODO should be centralized in library somewhere
	private isMobileScreen = (inject(WindowWrapper) as Window).innerWidth <= MobileScreenWidth;

	create(
		sourceType: TableSourceType.Bucket,
		columnDescriptors: ColumnDescriptor[],
		propertyDescriptors: Map<string, DataPropertyDescriptor>,
		isPageTable: boolean,
	): TableConfigColumn<FormData>[];
	create(
		sourceType: TableSourceType.Company,
		columnDescriptors: ColumnDescriptor[],
		propertyDescriptors: Map<string, DataPropertyDescriptor>,
		isPageTable: boolean,
	): TableConfigColumn<Company>[];
	create(
		sourceType: TableSourceType.Users,
		columnDescriptors: ColumnDescriptor[],
		propertyDescriptors: Map<string, DataPropertyDescriptor>,
		isPageTable: boolean,
	): TableConfigColumn<UserInfo>[];
	create(
		sourceType: TableSourceType,
		columnDescriptors: ColumnDescriptor[],
		propertyDescriptors: Map<string, DataPropertyDescriptor>,
		isPageTable: boolean,
	): TableConfigColumn<TableData>[];
	create(
		sourceType: TableSourceType,
		columnDescriptors: ColumnDescriptor[],
		propertyDescriptors: Map<string, DataPropertyDescriptor>,
		isPageTable: boolean,
	): TableConfigColumn<TableData>[] {
		return columnDescriptors.map((column) => {

			const propertyDescriptor = propertyDescriptors.get(column.identifier);

			if (propertyDescriptor && !propertyDescriptor.asDisplay) {
				return;
			}

			const cellDisplayDescriptor = columnDescriptorToColumDisplayDescriptor(column);

			const columnConfig: TableConfigColumn<TableData> = {
				name: column.identifier,
				label: column.heading ?? propertyDescriptor?.label,
				sortable: isPageTable && propertyDescriptor?.asSort && isSortableColumn(cellDisplayDescriptor),
				hidden: this.isMobileScreen ? column.hideOnMobile : column.hideOnDesktop,
			};

			if (!propertyDescriptor) {
				return columnConfig;
			}

			columnConfig.value = (row) =>
				this.getColumnDisplayValue(sourceType, row, column, propertyDescriptor);

			return columnConfig;
		}).filter(isNotNull);
	}

	private getColumnDisplayValue(entityType: TableSourceType, row: TableData, columnDescriptor: ColumnDescriptor, propertyDescriptor: DataPropertyDescriptor): unknown {

		const overrideDataDisplayInfo = {
			format: columnDescriptor.defaultFormat,
			template: columnDescriptor.itemTemplate,
			separator: SEPARATOR_COMMA,
		} as Partial<DataDisplayInfo>;

		const entitySingleDisplayPropertyInfo: DataDisplaySpecificEntitySinglePropertyInfo = {
			overrideDataDisplayInfo,
		};

		switch (entityType) {
			case TableSourceType.Users: {
				return this.dataDisplayEntityService.displayUserPropertyAsString(
					row as UserInfo,
					propertyDescriptor,
					entitySingleDisplayPropertyInfo,
				);
			}
			case TableSourceType.Company: {
				return this.dataDisplayEntityService.displayCompanyPropertyAsString(
					row as Company,
					propertyDescriptor,
					entitySingleDisplayPropertyInfo,
				);
			}
			case TableSourceType.Bucket: {
				const value = this.dataLookupService.lookupData(row as FormData, columnDescriptor.identifier);
				const displayInfo = dataDescriptorToDataDisplayInfo(propertyDescriptor, overrideDataDisplayInfo);

				return this.dataDisplayService.displayAsDataDisplayValue(value, displayInfo);
			}
		}

	}

}
