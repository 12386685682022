import { Component, OnInit, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CommonTranslationKey } from '@unifii/library/common';
import { AuthProvider, Dictionary, OAuthWithCode, decrypt } from '@unifii/sdk';

import { Environment } from 'config';
import { ProjectSelectionPath, UserAccessRootPath } from 'discover/discover-constants';
import { Authentication } from 'shell/services/authentication';
import { Auth0DirectoryURL, AzureDirectoryURL, SSOService } from 'shell/services/sso.service';
import { UserAccessManager } from 'shell/services/user-access-manager';

@Component({
	selector: 'ud-sso',
	templateUrl: './sso.html',
	standalone: false,
})
export class SSOComponent implements OnInit {

	private env = inject(Environment);
	private router = inject(Router);
	private route = inject(ActivatedRoute);
	private auth = inject(Authentication);
	private translate = inject(TranslateService);
	private userAccessManager = inject(UserAccessManager);
	private ssoService = inject(SSOService);

	async ngOnInit() {

		const queryParams = this.route.snapshot.queryParams as Dictionary<string>;

		const { state, code, reason } = queryParams;
		let providerId = queryParams.provider_id;

		try {
			let redirectUri;

			if (reason) {
				throw new Error(reason);
			}

			if (!code) {
				return;
			}

			if (state) {
				const decryptedState = await this.decryptState(state);

				providerId = decryptedState.providerId;
				redirectUri = decryptedState.redirectUri;
			}

			if (!redirectUri) {
				redirectUri = this.getRedirectUri(providerId);
			}

			await this.auth.login({ code, redirect_uri: redirectUri, provider_id: providerId } satisfies OAuthWithCode);

			// Redirect on successful login
			void this.router.navigate(['/', UserAccessRootPath, ProjectSelectionPath]);

		} catch (e) {
			const message = (e as Error).message || this.getProviderErrorMessage(providerId);

			void this.userAccessManager.deny({ error: message });
		}
	}

	private getProviderErrorMessage(providerId: string | undefined): string {

		const provider = providerId ? this.ssoService.getProvider(providerId) : undefined;
		const type = provider?.type;

		switch (type) {
			case AuthProvider.Azure: return this.translate.instant(CommonTranslationKey.SsoErrorAzureAuthenticationFailedMessage) as string;
			case AuthProvider.Auth0: return this.translate.instant(CommonTranslationKey.SsoErrorAuth0AuthenticationFailedMessage) as string;
			default: return this.translate.instant(CommonTranslationKey.SsoErrorAuthenticaionFailedMessage) as string;
		}
	}

	private async decryptState(state: string): Promise<{ redirectUri: string; providerId: string }> {

		const decrypted = await decrypt({ byteString: decodeURIComponent(state), key: this.env.unifii.appId as string });
		const params = new URLSearchParams(decodeURIComponent(decrypted));

		return {
			providerId: params.get('providerId') as string,
			redirectUri: params.get('redirectUri') as string,
		};
	}

	private getRedirectUri(providerId: string | undefined): string {

		const provider = providerId ? this.ssoService.getProvider(providerId) : undefined;

		if (provider?.useDirectory === false) {
			return this.ssoService.loginRedirectUri;
		}

		switch (provider?.type) {
			case AuthProvider.Azure: return AzureDirectoryURL;
			case AuthProvider.Auth0: return Auth0DirectoryURL;
			default: throw new Error(this.translate.instant(CommonTranslationKey.SsoErrorAuthenticaionFailedMessage));
		}
	}

}
