import { Injectable, OnDestroy, inject } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ModalConfirmData, ModalService, Repository, SharedTermsTranslationKey, WindowWrapper } from '@unifii/library/common';
import { compare, validate } from 'compare-versions';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

import { DeviceService } from 'capacitor/device.service';
import { Config } from 'config';
import { DiscoverTranslationKey } from 'discover/discover.tk';
import { TenantSettingsService } from 'shell/services/tenant-settings.service';

import { RequiredUpdateModalComponent } from './required-update-modal.component';

const LastUpdateRequestKey = 'UfLastUpdateRequest';
const ForcedUpdateRequestKey = 'UfForcedUpdateRequest';

@Injectable({ providedIn: 'root' })
export class AppUpdateService implements OnDestroy {

	private subscriptions = new Subscription();

	private config = inject(Config);
	private window = inject<Window>(WindowWrapper);
	private repo = inject(Repository);
	private modalService = inject(ModalService);
	private translate = inject(TranslateService);
	private router = inject(Router);
	private tenantSettings = inject(TenantSettingsService);
	private deviceService = inject(DeviceService);

	init() {
		this.checkAppInfo().catch(() => { /* */ });

		this.subscriptions.add(this.router.events
			.pipe(filter((event) => event instanceof NavigationStart))
			.subscribe(() => {
				this.checkAppInfo().catch(() => { /* */ });
			}));
	}

	ngOnDestroy() {
		this.subscriptions.unsubscribe();
	}

	private async checkAppInfo() {

		await this.tenantSettings.sync();

		const appInfo = this.config.unifii.tenantSettings?.appInfo;

		// guard no appInfo or no/invalid config.version
		if (!appInfo || !validate(this.config.version)) {
			return;
		}

		// current version is below minVersion
		if (compare(this.config.version, appInfo.minVersion, '<')) {

			if (!this.deviceService.isNative()) {

				const previousForcedUpdateVersion = this.repo.loadString(ForcedUpdateRequestKey);

				// never forced update before or forced a previous minVersion
				if (!previousForcedUpdateVersion || compare(previousForcedUpdateVersion, appInfo.minVersion, '<')) {
					this.repo.storeString(ForcedUpdateRequestKey, appInfo.minVersion);
					this.window.location.reload();

					return;
				}

			}

			// after reload, block with modal
			void this.modalService.openFullScreen(
				RequiredUpdateModalComponent, appInfo, { guard: true },
			);

			return;
		}

		// current version is below latestVersion
		if (compare(this.config.version, appInfo.latestVersion, '<')) {

			const previousPopupVersion = this.repo.loadString(LastUpdateRequestKey);

			//  previous version is up to date with latest version
			if (previousPopupVersion && compare(previousPopupVersion, appInfo.latestVersion, '>=')) {
				return;
			}

			this.repo.storeString(LastUpdateRequestKey, appInfo.latestVersion);

			if (!this.deviceService.isNative()) {
				this.window.location.reload();

				return;
			}

			const message: ModalConfirmData = {
				title: this.translate.instant(DiscoverTranslationKey.UpdateAvailableTitle),
				message: this.translate.instant(DiscoverTranslationKey.UpdateAvailableMessage, { version: appInfo.latestVersion }),
				confirmLabel: this.translate.instant(SharedTermsTranslationKey.UpdateLabel),
				cancelLabel: this.translate.instant(SharedTermsTranslationKey.ActionCancel),
			};

			const update = await this.modalService.openConfirm(message);

			// route to link
			if (update) {
				window.open(appInfo.appStoreLink, '_system ');
			}
		}
	}

}
