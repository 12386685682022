import { Injectable } from '@angular/core';
import { DataDisplayInfo, DataDisplayRouterLinkValue, DataDisplayService, UfDataDisplayService, getContentLinkLabel } from '@unifii/library/common';
import { DataType, LinkContentType, isContentLinkFormData } from '@unifii/sdk';

import { DataDisplayPdfViewerValue } from 'shell/common/data-display/data-display-pdf-viewer.component';

@Injectable()
export class ShellDataDisplayService extends UfDataDisplayService implements DataDisplayService {

    override displayAsDataDisplayValue(data: unknown, info?: DataDisplayInfo): unknown {

        // Content Link
		if (info?.type === DataType.Link && isContentLinkFormData(data)) {
            const label = getContentLinkLabel(data, this.translateService, info.hideType);

            switch (data.type) {
                case LinkContentType.Form:
                case LinkContentType.Page:
                    return { label, routerLink: ['/', data.id] } satisfies DataDisplayRouterLinkValue;
                case LinkContentType.Attachment:
                    if (data.useViewer) {
                        return { label, pdfAttachmentId: data.id } satisfies DataDisplayPdfViewerValue;
                    }
            }
        }

        return super.displayAsDataDisplayValue(data, info);
    }

}
