import { Injectable, inject } from '@angular/core';
import { Repository, TablePreferences, TablePreferencesProvider } from '@unifii/library/common';
import { Dictionary } from '@unifii/sdk';

import { Config } from 'config';
import { Authentication } from 'shell/services/authentication';

const UserPreferencesKey = 'UfUserPreferences';

export interface UserPreferences {
	tables: Dictionary<TablePreferences>;
}

@Injectable()
export class UserPreferencesService implements TablePreferencesProvider {

	private config = inject(Config);
	private auth = inject(Authentication);
	private repo = inject(Repository);

	get userKey() {

		const userId = this.auth.isAuthenticated ? this.auth.userInfo?.id : undefined;

		return `${UserPreferencesKey}-${this.config.unifii.tenant}-${userId}-${this.config.unifii.projectId}`;
	}

	get userPreferences(): UserPreferences | null {

		if (!this.auth.isAuthenticated) {
			return null;
		}

		return this.repo.load<UserPreferences>(this.userKey);
	}

	set userPreferences(v: UserPreferences | null) {

		if (!this.auth.isAuthenticated) {
			return;
		}

		this.repo.store(this.userKey, v);
	}

	loadTablePreferences(id: string): TablePreferences | null {

		if (!this.userPreferences) {
			return null;
		}

		return this.userPreferences.tables[id] ?? null;
	}

	saveTablePreferences(id: string, v?: TablePreferences) {

		const preferences = this.userPreferences ?? { tables: {} };

		if (v != null) {
			preferences.tables[id] = v;
		} else {
			delete preferences.tables[id];
		}

		this.userPreferences = preferences;
	}

}
