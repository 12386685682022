<div [class.hasTable]="reportConfig.chartType === 'table'" class="chart">
	<uf-progress class="large" />

	@if (reportConfig) {
		<div class="uf-card pad gap-bottom">
			<div class="uf-app-bar small">
				@if (reportConfig.title) {
					<div class="title">{{ reportConfig.title }}</div>
				}
				@if (reportConfig.description) {
					<button (click)="showHelpModal(reportConfig.description)" type="button" class="uf-action tertiary">
						<uf-icon name="info" />
					</button>
				}
				@if (reportConfig.chartType !== "table" && !hideChart) {
					<button (click)="downloadChartAsImage()" type="button" class="uf-action tertiary">
						<uf-icon name="download" />
					</button>
				}
				@if (reportConfig.chartType === "table" && !hideChart) {
					<button (click)="downloadTableAsCsv()" type="button" class="uf-action tertiary">
						<uf-icon name="download" />
					</button>
				}
			</div>
			@if (chartConfig && !hideChart) {
				<us-chart [config]="chartConfig" />
			}
			@if (hideChart) {
				<uf-message icon="alertSolid" class="info">
					<h3>{{ shellTK.ReportResultNoDataLabel | translate }}</h3>
				</uf-message>
			}
			@if (reportConfig.chartType === "table" && reportData) {
				<div (scroll)="onScroll($event)" class="table">
					<table class="uf-table accent">
						<thead>
							<tr>
								@for (label of reportData.labels; track label) {
									<th>
										{{ $any(label).value ?? label }}
									</th>
								}
							</tr>
						</thead>
						<tbody>
							@for (dataset of reportData.datasets; track dataset) {
								<tr>
									@for (datum of dataset.data; track datum) {
										<td [ngClass]="{ bold: datum.bold === true, italic: datum.italic === true }">
											<!-- TODO migrate hyperlink to start with / -->
											@if (datum.hyperlink) {
												<a [routerLink]="'/' + datum.hyperlink">
													<ng-container *ngTemplateOutlet="cellValue" />
												</a>
											} @else {
												{{ datum.value ?? datum }}
											}
											<ng-template #cellValue>
												{{ datum.value ?? datum }}
											</ng-template>
										</td>
									}
								</tr>
							}
							@if (!reportData.datasets || !reportData.datasets.length) {
								<tr>
									<td [attr.colspan]="reportData.labels.length" class="empty">
										{{ shellTK.ReportResultNoDataLabel | translate }}
									</td>
								</tr>
							}
						</tbody>
					</table>
				</div>
			}
		</div>
	}
</div>
