import { Component, HostBinding, OnInit, ViewChild, ViewContainerRef, inject } from '@angular/core';

import { IconsComponentSelector } from 'shell/core/icons-selector';

/**
 * Provides an entry point via the selector for icon overrides
 */
@Component({
	selector: 'us-icons',
	template: '<div #container></div>',
	standalone: false,
})
export class IconsComponent implements OnInit {

	@HostBinding('style.display') hostDisplay = 'none';
	@ViewChild('container', { read: ViewContainerRef, static: true }) private container: ViewContainerRef;

	private selector = inject(IconsComponentSelector, { optional: true });

	ngOnInit() {

		if (!this.selector) {
			return;
		}

		const component = this.selector.get();

		this.container.createComponent(component, { index: 0 });
	}

}
