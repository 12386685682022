import { Injectable, inject } from '@angular/core';
import { Client } from '@unifii/sdk';

import { Config } from 'config';

import { DeviceService } from './device.service';

@Injectable({ providedIn: 'root' })
export class DeviceRegistrationService {

	private config = inject(Config);
	private deviceService = inject(DeviceService);
	private client = inject(Client);

	async register(): Promise<void> {

		const device = await this.deviceService.device();

		// guard if we have no tenant or device or notificationToken
		// we will retry again after we have a tenant
		if (!this.config.unifii.tenant || !(device?.notificationToken)) {
			return;
		}
		try {
			await this.client.registerDevice(device);
		} catch {
			// Fail silently
		}
	}

}
