@if (show) {
	<div class="row item">
		@if (node.type === "Empty") {
			<div [style.margin-left.px]="level * 12" class="grow row heading">
				<!-- <uf-icon *ngIf="node.icon" [name]="node.icon"></uf-icon> -->
				{{ node.name }}
			</div>
			@if (accessInfo.hasAccessibleChildren) {
				<div (click)="expanded = !expanded" class="clickable expander-button">
					<uf-icon [name]="expanded ? 'menuItemCollapse' : 'menuItemExpand'" />
				</div>
			}
		} @else {
			<!-- href and routerLink don't play well -->
			@if (href) {
				<a [attr.href]="href" [class.active]="isActive" [style.margin-left.px]="level * 12" class="grow clickable selectable">
					<!-- <uf-icon *ngIf="node.icon" [name]="node.icon"></uf-icon> -->
					<div>{{ node.name }}</div>
				</a>
			}
			<!-- Hence the 2 variations -->
			@if (link) {
				<a [routerLink]="link" [class.active]="isActive" [style.margin-left.px]="level * 12" class="grow clickable selectable">
					<!-- <uf-icon *ngIf="node.icon" [name]="node.icon"></uf-icon> -->
					<div>{{ node.name }}</div>
				</a>
			}
			<!-- Not accessible node, just show its label -->
			@if (!href && !link) {
				<div [style.margin-left.px]="level * 12" class="grow row heading">
					<!-- <uf-icon *ngIf="node.icon" [name]="node.icon"></uf-icon> -->
					{{ node.name }}
				</div>
			}
			<!-- Expand/Collapse toggle -->
			@if (accessInfo.hasAccessibleChildren) {
				<a (click)="expanded = !expanded" class="clickable expander-button">
					<uf-icon [name]="expanded ? 'menuItemCollapse' : 'menuItemExpand'" />
				</a>
			}
		}
	</div>
	@if (expanded) {
		@for (n of node.children; track n) {
			<us-nav-item [node]="n" [level]="level + 1" />
		}
	}
}
