<div class="shell-body">
	<ng-content select="us-header" />
	<div [class.push-down]="header" class="shell-content">
		<ng-content />
	</div>
</div>
<ng-content select="us-menu-drawer" />
<ng-content select="us-drawer" />
<ng-content select="us-drawer[right]" />

<us-icons />
