import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild, inject } from '@angular/core';
import { ProgressComponent, ThemeProvider, WindowWrapper } from '@unifii/library/common';
import { Theme } from '@unifii/sdk';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { DeviceRegistrationService } from 'capacitor/device-registration.service';
import { DeviceService } from 'capacitor/device.service';
import { PushNotificationService } from 'capacitor/push-notification.service';
import { Config } from 'config';
import { DiscoverContext } from 'discover/discover-context';
import { ShellService } from 'shell/core/shell.service';
import { OfflineQueue } from 'shell/offline/forms/offline-queue';
import { OfflineManager } from 'shell/offline/offline-manager';

import { AppUpdateService } from './app-update/app-update.service';

@Component({
	selector: 'ud-main',
	templateUrl: './main.html',
	standalone: false,
})
export class MainComponent implements OnInit, AfterViewInit, OnDestroy {

	@ViewChild(ProgressComponent) private progressBar: ProgressComponent | undefined;

	protected imgUrl: string;
	protected shell = inject(ShellService);

	private destroyed = new Subject<void>();
	private subscriptions = new Subscription();

	private config = inject(Config);
	private context = inject(DiscoverContext);
	private offlineQ = inject(OfflineQueue);
	private offlineManager = inject(OfflineManager);
	private window = inject<Window>(WindowWrapper);
	private appUpdate = inject(AppUpdateService);
	private themeService = inject(ThemeProvider);
	private deviceService = inject(DeviceService);
	private notificationService = inject(PushNotificationService);
	private deviceRegistrationService = inject(DeviceRegistrationService);

	ngOnInit() {

		if (this.theme && !this.config.themeConfig?.disableProjectTheme) {
			this.themeService.theme = this.theme;
		}

		if (this.config.unifii.projectLogoUrl) {
			this.imgUrl = this.config.unifii.projectLogoUrl;
		}

		this.appUpdate.init();
		this.initOfflineQ();

		// setup push notifications
		if (this.config.unifii.tenantSettings?.arePushNotificationsEnabled && this.deviceService.isNative()) {

			void this.notificationService.init().then((token) => {

				if (!token) {
					console.warn('NotificationService no token found!');

					return;
				}

				this.deviceService.notificationToken = token;

				void this.deviceRegistrationService.register();
			});
		}

		// clean up offline content (background async run)
		void this.offlineManager.cleanUp();

		// reset offline content notifications
		this.shell.reset('OfflineContent');

		// Update app progress
		this.subscriptions.add(this.shell.busyEvents.subscribe((e) => {

			if (this.progressBar != null && e) {
				this.progressBar.start();
			} else if (this.progressBar != null) {
				this.progressBar.complete();
			}
		}));
	}

	ngAfterViewInit() {
		/** Guard incase for angular universal */
		if (!(this.window.document as Document | undefined)) {
			return;
		}
	}

	ngOnDestroy() {
		this.destroyed.next();
		this.destroyed.complete();

		this.themeService.theme = this.config.themeConfig?.cssVariables ?? this.config.theme ?? {};

		this.subscriptions.unsubscribe();
	}

	private initOfflineQ() {

		this.subscriptions.add(this.offlineQ.additions.pipe(takeUntil(this.destroyed))
			.subscribe(() => { this.shell.notify('OfflineQ'); }));

		this.subscriptions.add(this.offlineQ.deletions.pipe(takeUntil(this.destroyed))
			.subscribe(() => { this.shell.done('OfflineQ'); }));

		void this.offlineQ.count().then((count) => { this.shell.reset('OfflineQ', count); });
		void this.offlineQ.prune();
	}

	private get theme(): Theme | undefined {
		return this.context.project?.theme;
	}

}
