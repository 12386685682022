export const afterDraw = (chart: any) => {
	const ctx = chart.ctx;

	ctx.save();
	const leftLabelCoordinates: any[] = [];
	const rightLabelCoordinates: any[] = [];
	const chartCenterPoint = {
		x:
            (chart.chartArea.right - chart.chartArea.left) / 2 +
            chart.chartArea.left,
		y:
            (chart.chartArea.bottom - chart.chartArea.top) / 2 +
            chart.chartArea.top,
	};

	chart.config.data.labels.forEach((_: any, i: number) => {
		const meta = chart.getDatasetMeta(0);
		const arc = meta.data[i];
		const dataset = chart.config.data.datasets[0];

		// Prepare data to draw
		// important point 1
		const centerPoint = arc.getCenterPoint();
		const color: any = chart.config._config.data.datasets[0].backgroundColor[i];

		const angle = Math.atan2(
			centerPoint.y - chartCenterPoint.y,
			centerPoint.x - chartCenterPoint.x,
		);
		// important point 2, this point overlapped with existed points
		// so we will reduce y by 14 if it's on the right
		// or add by 14 if it's on the left
		const originPoint = getOriginPoints(chartCenterPoint, centerPoint, arc.outerRadius);
		const point2X =
            chartCenterPoint.x + Math.cos(angle) * (centerPoint.x < chartCenterPoint.x ? arc.outerRadius + 10 : arc.outerRadius + 10);
		let point2Y =
            chartCenterPoint.y + Math.sin(angle) * (centerPoint.y < chartCenterPoint.y ? arc.outerRadius + 15 : arc.outerRadius + 15);

		let suitableY;

		if (point2X < chartCenterPoint.x) {
			// on the left
			suitableY = getSuitableY(point2Y, leftLabelCoordinates as any, 'left');
		} else {
			// on the right
			suitableY = getSuitableY(point2Y, rightLabelCoordinates as any, 'right');
		}

		point2Y = suitableY;

		const value = formatTooltip(dataset.tooltips[i]);

		const edgePointX = point2X < chartCenterPoint.x ? chartCenterPoint.x - arc.outerRadius - 10 : chartCenterPoint.x + arc.outerRadius + 10;

		if (point2X < chartCenterPoint.x) {
			leftLabelCoordinates.push(point2Y);
		} else {
			rightLabelCoordinates.push(point2Y);
		}

		// DRAW CODE
		// first line: connect between arc's center point and outside point
		ctx.lineWidth = 2;
		ctx.strokeStyle = color;
		ctx.beginPath();
		ctx.moveTo(originPoint.x, originPoint.y);
		ctx.lineTo(point2X, point2Y);
		ctx.stroke();
		// second line: connect between outside point and chart's edge
		ctx.beginPath();
		ctx.moveTo(point2X, point2Y);
		ctx.lineTo(edgePointX, point2Y);
		ctx.stroke();
		const labelAlignStyle =
            edgePointX < chartCenterPoint.x ? 'right' : 'left';
		const labelX = edgePointX < chartCenterPoint.x ? edgePointX : edgePointX + 0;
		const labelY = point2Y + 7;

		ctx.textAlign = labelAlignStyle;
		ctx.textBaseline = 'bottom';
		ctx.fillText(value, labelX, labelY);
	});
	ctx.restore();
};

const formatTooltip = (tooltips: undefined | string | string[]): string =>
	Array.isArray(tooltips) ? tooltips.join(', ') : tooltips ?? '';

const getOriginPoints = (source: any, center: any, l: any) => {
	const a = { x: 0, y: 0 };
	const dx = (center.x - source.x) / l;
	const dy = (center.y - source.y) / l;

	a.x = center.x + l * dx;
	a.y = center.y + l * dy;

	return a;
};

const getSuitableY = (y: any, yArray: any[] = [], direction: any) => {
	let result = y;

	yArray.forEach((existedY) => {
		if (existedY - 14 < result && existedY + 14 > result) {
			if (direction === 'right') {
				result = existedY + 14;
			} else {
				result = existedY - 14;
			}
		}
	});

	return result;
};
