@if (ready) {
	<uf-panel ufMasterDetail class="container">
		<!-- It shows tabs on the right (at the top for Mobile) if it's MenuView and it renders only TableDetailComponent if PageView (default) -->
		@if (pageMode === "MenuView") {
			<uf-tabs [responsive]="true" class="vertical">
				<uf-tab label="Details" path="" />
				@for (tableModule of tableModules; track tableModule) {
					@if (tableModule.detailModule.title) {
						<uf-tab [label]="tableModule.detailModule.title" [path]="tableModule.detailModule.identifier" />
					}
				}
			</uf-tabs>
		}
	</uf-panel>
}
@if (ready && pageMode !== "MenuView") {
	<router-outlet (activate)="onActivate($event)" />
}
