<uf-panel class="container">
	<div class="uf-container-md">
		<div class="uf-grid condensed">
			<br />

			@if (logo) {
				<div class="col-12 gap-lg-top gap-sides">
					<img [src]="logo" alt="{{ logo }}" class="logo" />
				</div>
			}

			@if (description) {
				<div [markdownRenderer]="description" class="col-12 gap-sides body-copy"></div>
			}
		</div>

		<div class="uf-grid directory-template row-gap-lg">
			@for (card of cards; track card) {
				@if (isCardHref(card)) {
					<a [attr.href]="card.href" ufTarget="_blank" class="uf-card centered">
						<ng-container *ngTemplateOutlet="cardTemplate; context: { card }" />
					</a>
				}
				@if (isCardLink(card)) {
					<a [routerLink]="card.link" class="uf-card centered">
						<ng-container *ngTemplateOutlet="cardTemplate; context: { card }" />
					</a>
				}
				@if (isCardLogout(card)) {
					<a (click)="logout()" class="uf-card centered">
						<ng-container *ngTemplateOutlet="cardTemplate; context: { card }" />
					</a>
				}
			}
		</div>
	</div>
</uf-panel>

<ng-template #cardTemplate let-card="card">
	<div class="uf-img-16x9">
		@if (card.imgUrl) {
			<img [src]="card.imgUrl" alt="Card Image" />
		}
	</div>
	<div class="uf-card-title">
		@if (card.label) {
			<h4>{{ card.label }}</h4>
		}
	</div>
</ng-template>
