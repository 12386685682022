@if (field && tableRows.length) {
	<uf-field-table [heading]="field.label || null" [rows]="tableRows" [columns]="tableColumns" [cssModifiers]="field.tags" />
}
<br />
@if (linkList.length) {
	<ul>
		@for (item of linkList; track item) {
			<li>
				<us-custom-link [field]="field" [content]="item" />
			</li>
		}
	</ul>
}
