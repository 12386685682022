import { Component, Input, OnInit } from '@angular/core';
import { CompoundType, Field, FieldType } from '@unifii/sdk';

export enum Template {
	Link, Button,
}

@Component({
	selector: 'us-custom-link',
	templateUrl: './link.html',
	styleUrls: ['./link.less'],
	standalone: false,
})
export class LinkComponent implements OnInit {

	@Input() content: any;
	@Input() field: Field;

	button: boolean;
	paths: string[];
	name: string;
	template: Template = Template.Link;
	templateOptions = Template;

	ngOnInit() {

		this.paths = this.createPaths();
		this.name = this.getName();

		if (this.field.tags && this.field.tags.includes('button')) {
			this.template = Template.Button;
		}

	}

	private createPaths(): any[] {

		const paths: any[] = [];

		if (this.content == null) {
			return paths;
		}

		switch (this.field.type) {
			case FieldType.Link:
			case FieldType.LinkList:

				switch (this.field.compoundType ?? this.content.compoundType) {
					case CompoundType.View:
					case CompoundType.Page:
						paths.push('/', this.content.definitionIdentifier);
						break;
					case CompoundType.Collection:
						paths.push('/', this.content.definitionIdentifier, this.content.id);
						break;
				}

				break;

			case FieldType.DefinitionLink:
				paths.push('/', this.content.identifier);
				break;
		}

		return paths;
	}

	private getName(): string {

		if (this.content == null) {
			return '';
		}

		// Should default to title
		if (this.content._title != null) {
			return this.content._title;
		}

		// Needs to be removed, recordName should not exist in discover
		if (this.content.recordName != null) {
			return this.content.recordName;
		}

		if (this.content.definitionLabel != null) {
			return this.content.definitionLabel;
		}

		if (this.content.name != null) {
			return this.content.name;
		}

		for (const key in this.content) {
			if (!key.startsWith('_')) {
				return this.content[key];
			}
		}

		return '';
	}

}
