import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { Capacitor } from '@capacitor/core';
import { encrypt } from '@unifii/sdk';

import { Config, Environment } from 'config';
import { AuthorizePath, ExternalPath } from 'discover/discover-constants';
import { AppModule } from 'shell/app.module';
import { createIdentityRedirectUri, createIdentityUrl } from 'shell/services/identity-functions';
import { TokenService } from 'shell/services/token.service';

import 'hammerjs';

void (async() => {
	let config: Config;
	const configValue = window.document.getElementById('env')?.innerHTML ?? '';

	try {
		config = JSON.parse(configValue);
	} catch {
		const response = await fetch('environment.json');

		config = await response.json();
		// fix goLang variable for localhost
		window.document.title = config.env;
	}

	const isProduction = !['localhost', 'dev'].includes(config.env);

	if (isProduction) {
		enableProdMode();
	}

	// 8031 server connect to localhost unifii-api server
	if (config.env === 'localhost' && window.location.port === '8031') {
		config.unifii.tenant = 'default';
		config.unifii.baseUrl = 'http://localhost:5171';
	}

	// if configured authProvider, not capacitor & not authenticated, perform identity authorization.
	if (config.authProvider && !Capacitor.isNativePlatform() && !window.location.pathname.includes(`/${AuthorizePath}`)) {

		const tokenService = new TokenService(localStorage, config);

		if (!tokenService.token) {
			const redirectUri = new URL(createIdentityRedirectUri(window));

			if (window.location.pathname.startsWith(`/${ExternalPath}/`)) {
				redirectUri.searchParams.append('next', window.location.pathname);
			}

			const encryptedState = await encrypt(config.unifii.appId, new URLSearchParams({ redirectUri: redirectUri.toString() }).toString());
			const identityUrl = createIdentityUrl(config.authProvider.authorizationEndpoint, config.unifii.appId ?? '', redirectUri.toString(), encryptedState.byteString);

			window.location.href = identityUrl;

			return;
		}

	}

	const environment = structuredClone(config);

	platformBrowserDynamic([
		{ provide: Environment, useValue: environment },
		{ provide: Config, useValue: config },
	])
		.bootstrapModule(AppModule)
		.catch((err) => { console.error(err); });

})();
