export enum DiscoverTranslationKey {
	ActionSignInWithUnifii = 'action_sign_in_with_unifii', // Sign in with Unifii
	FaceIDLabel = 'face_id_label', // FaceID
	ProjectLabel = 'project_label', // Project
	VersionLabel = 'version_label', // Version:
	ProductVersionLabel = 'product_version_label', // Product Version:
	PrivacyPolicyLabel = 'privacy_policy_label', // Privacy Policy
	InputUsersLabel = 'input_users_label', // Input Users
	UploadBasicCSVLabel = 'upload_basic_csv_label', // Upload Basic CSV
	UploadAdvancedCSVLabel = 'upload_advanced_csv_label', // Upload Advanced CSV
	UpdateProgressMessage = 'update_progress_message', // Updating to version {{version}}
	UpdateProgressErrorUpdateFail = 'update_progress_error_update_fail', // Content failed to update
	OfflineFormFeedbackStoreSuccess = 'offline_form_feedback_store_success', // Stored form updated!
	OfflineFormErrorSave = 'offline_form_error_save', // Form failed to save
	OfflineFormErrorLoad = 'offline_form_error_load', // Offline form data load failed
	OfflineFormsTitle = 'offline_forms_title', // Stored Forms
	OfflineFormsStatusLabel = 'offline_forms_status_label', // Status
	OfflineFormsSizeLabel = 'offline_forms_size_label', // File Size
	OfflineFormsSyncLabel = 'offline_forms_sync_label', // Sync Status
	OfflineFormsConflictLabel = 'offline_forms_conflict_label', // Conflict Detected
	OfflineFormsErrorUpload = 'offline_forms_error_upload', // Something went wrong with your upload. Check your internet connection and try again
	OfflineFormsWaitingSyncLabel = 'offline_forms_waiting_sync_label', // Waiting to sync
	OfflineFormsActionUploadSuccess = 'offline_forms_action_upload_success', // Uploaded
	OfflineFormsActionUploadFail = 'offline_forms_action_upload_fail', // Upload failed
	SettingsModalChangeModeTitle = 'settings_modal_change_mode_title', // Change mode
	SettingsModalChangeModeProductionMessage = 'settings_modal_change_mode_production_message', // Switch to Production
	SettingsModalChangeModePreviewMessage = 'settings_modal_change_mode_preview_message', // Switch to Preview
	SettingsModalChangeProductionFailMessage = 'settings_modal_change_production_fail_message', // Trying to access Production content
	SettingsModalChangePreviewFailMessage = 'settings_modal_change_preview_fail_message', // Trying to access Preview content
	SettingsModalUpdateVersionTitle = 'settings_modal_update_version_title', // Offline Content
	SettingsModalUpdateVersionMessage = 'settings_modal_update_version_message', // Update to version {{version}}
	SettingsDataVersionLabel = 'settings_data_version_label', // Data Version {{version}}
	SettingsUpdateToLabel = 'settings_update_to_label', // Update to {{version}}
	SettingsActionChangeMode = 'settings_action_change_mode', // Preview Mode
	SettingsActionChangeProject = 'settings_action_change_project', // Change project
	SettingsOfflineFormsLabel = 'settings_offline_forms_label', // Sync Forms
	CompleteRegistrationErrorInvalidLink = 'complete_registration_error_invalid_link', // Your Registration link is invalid
	CompleteRegistrationDescription = 'complete_registration_description', // Please complete your registration
	CompleteRegistrationActionRegister = 'complete_registration_action_register', // Complete Registration
	CompleteRegistrationActionRegisterFail = 'complete_registration_action_register_fail', // An error occured when completing your registration
	ContactInfoTitle = 'contact_info_title', // Problem?
	ContactInfoMessage = 'contact_info_message', // If you are having difficulties please contact your administrator.
	ContactInfoNameLabel = 'contact_info_name_label', // Name:
	ContactInfoEmailLabel = 'contact_info_email_label', // Email:
	ContactInfoPhoneLabel = 'contact_info_phone_label', // Phone:
	ContactInfoLabel = 'contact_info_label', // Contact Info
	CompanyHelpMessage = 'company_help_message', // Please enter your company name to show login options and administrator contact
	LanguageLabel = 'language_label', // Language
	LoginUseCompanyMessage = 'login_use_company_message', // Please log in to Company <strong>{{company}}</strong> to proceed
	LoginRemeberMeInputLabel = 'login_remeber_me_input_label', // Remember Me
	LoginWithUsernameLabel = 'login_with_username_label', // Login with Username
	LoginRemoveMeUserMessage = 'login_remove_me_user_message', // Are you sure you want to remove this user?
	LoginRemoveMeUserTitle = 'login_remove_me_user_title', // Remove User?
	ActionRequestPasswordChange = 'action_request_password_change', // Request Password Change
	ActionChangeCompany = 'action_change_company', // Change Company
	PasswordChangeErrorInvalidLink = 'password_change_error_invalid_link', // Your Reset Password link is invalid
	PasswordChangeDirectMessage = 'password_change_direct_message', // Please change your password
	PasswordChangeErrorChangePassword = 'password_change_error_change_password', // An error occured when changing your password
	PasswordChangeErrorInvalidPassword = 'password_change_error_invalid_password', // Invalid Password
	PasswordChangeErrorCannotBeSame = 'password_change_error_cannot_be_same', // New password can't be the same as old password
	PasswordChangeActionChangePassword = 'password_change_action_change_password', // Change Password
	RequestPasswordChangeFeedbackEmailSentHeading = 'request_password_change_feedback_email_sent_heading', // Success
	RequestPasswordChangeFeedbackEmailSent = 'request_password_change_feedback_email_sent', // An email with reset password instructions has been sent to you
	RequestPasswordChangeErrorRequestFail = 'request_password_change_error_request_fail', // An error occured when requesting password change
	RemeberUserMessage = 'remeber_user_message', // Remember me for future logins?
	RemeberUserTitle = 'remeber_user_title', // Remember Me
	SelectProjectErrorProjectRequired = 'select_project_error_project_required', // Please select a project
	SelectProjectPlaceholder = 'select_project_placeholder', // Select Project
	SelectProjectErrorNoProjects = 'select_project_error_no_projects', // No Projects Available
	SelectProjectErrorLoadingProject = 'select_project_error_loading_project',
	SelectProjectErrorLoadingProjects = 'select_project_error_loading_projects',
	UpdateAvailableTitle = 'update_available_title', // Update Available
	UpdateAvailableMessage = 'update_available_message', // A new software version (x.x.x) is available for download.
	UpdateRequiredTitle = 'update_required_title', // Update Required
	UpdateRequiredMessage = 'update_required_message', // The current version is no longer supported. Please update to the new version (x.x.x) now. <br> If you're unable to update for any reason, please use the website instead.
	UpdateUseWebsiteLabel = 'update_use_website_label', // Use Website
	CompanyCreatedMessage = 'company_created_message',
	CompanySavedMessage = 'company_saved_message',
	UsersLabel = 'users_label', // Users
	UserModalResendInviteTitle = 'user_modal_resend_invite_title', // Confirm resend invite
	UserModalResendInviteMessage = 'user_modal_resend_invite_message', // Resend invite to {{email}}?
	UserFeedbackResendInviteSuccess = 'user_feedback_resend_invite_success', // Invite sent
	UserFeedbackResendInviteFail = 'user_feedback_resend_invite_fail', // Resend invite failed
	UserDetailsModalCompleteRegistrationTitle = 'user_details_modal_complete_registration_title', // Confirm Username
	UserDetailsModalCompleteRegistrationMessage = 'user_details_modal_complete_registration_message', // You are about to complete registration for {{username}}. This will be their username.
	UserDetailsErrorUnauthorized = 'user_details_error_unauthorized', // You are not authorized to access this user details
	UserDetailsPendingStatusUnsavedMessage = 'user_details_pending_status_unsaved_message', // Please save your changes before resending the invitation
	UserCanChangeUsernameHelp = 'user_can_change_username_help', // Leave blank if you’d like the user to choose their own username
	UserInviteTitle = 'user_invite_title', // Invite User
	UserCreateTitle = 'user_create_title', // Create User
	UsersAddTitle = 'users_add_title', // Add Users
	UserAddAction = 'user_add_action', // Add User
	UserEditAction = 'user_edit_action', // Edit User
	UserCreateNewPasswordLabel = 'user_create_new_password_label', // All users must change their password on first login
	UserCreateFeedbackUsernameAsEmail = 'user_create_feedback_username_as_email', // This user's email address will be their username
	UserInviteModalMessage = 'user_invite_modal_message', // Invite users?
	UserInviteDeleteModalMessage = 'user_invite_delete_modal_message', // Delete invite for users?
	UsersBasicCSVTemplateTitle = 'users_basic_csv_template_title', // Basic CSV file to upload all of your users in single go
	UsersBasicCSVTemplateMessage = 'users_basic_csv_template_message', // This template includes basic user details only (User-name, First-name, Last-name, email, phone (optional).
	UsersBasicCSVTemplateUsageMessage = 'users_basic_csv_template_usage_message', // Use the basic CSV to load user and then allocate roles below.
	UsersAddMultipleBasicCSVTitle = 'users_add_multiple_basic_csv_title', // Add multiple users via Basic CSV (Email Address, First Name, Last name, Password)
	UsersAddMultipleBasicCSVMessage = 'users_add_multiple_basic_csv_message', // Drop CSV here or click on field below to locate your file via the browser finder
	UsersAdvancedCSVTemplateTitle = 'users_advanced_csv_template_title', // Download your Advanced CSV Template
	UsersAdvancedCSVTemplateMessage = 'users_advanced_csv_template_message', // This template includes all user details (User-name, First-name, Last-name, email, phone (optional) and will also include user Roles to be allocated (Roles must have been set up in your project settings) and specific user claims.
	UsersAddMultipleAdvancedCSVTitle = 'users_add_multiple_advanced_csv_title', // Add multiple users via Advanced CSV (Email Address, First Name, Last name, Password)
	UsersAddMultipleAdvancedCSVMessage = 'users_add_multiple_advanced_csv_message', // Drop CSV here or click on field below to locate your file via the browser finder
	UsersAddMultipleAdvancedCSVMessageCompany = 'users_add_multiple_advanced_csv_message_company',
	UsersDownloadCSVTemplate = 'user_download_csv_template', // Download CSV Template
	UsersUploadResultSuccessLabel = 'user_upload_result_success_label', // Successfully Uploaded
	UsersUploadResultFailLabel = 'user_upload_result_fail_label', // Failed to upload
	UsersUploadResultStatusTitle = 'users_upload_result_status_title', // Upload Status
	UsersUploadResultFailedEntriesTitle = 'users_upload_failed_entries_title', // Failed Entries
	UsersUploadResultFailedEntriesMessage = 'users_upload_failed_entries_message', // This is a list of all the entries that couldn't be processed. You can amend and save them here.
	UsersUploadResultLineNumberLabel = 'users_upload_result_line_number_label', // Line number
	UsersUploadResultUserNumberLabel = 'users_upload_result_user_number_label', // User #
	UsersUploadModalTitle = 'users_upload_modal_title', // Please wait
	UsersUploadModalMessage = 'users_upload_modal_message', // Uploading CSV and processing users. Do not close this tab or navigate away.
	UsersInviteSuccessToast = 'users_invite_success_toast', // Successfully invited {{ count }} users
	UsersCreateSuccessToast = 'users_create_success_toast', // Successfully created {{ count }} users
	UsersInviteFailedToast = 'users_invite_failed_toast', // Invited {{ count }} out of {{ total }} users. Check errors.
	UsersCreateFailedToast = 'users_create_failed_toast', // Created {{ count }} out of {{ total }} users. Check errors.
	UserAuthProviderDetailTitle = 'user_auth_provider_detail_title', // Provider Information
	UserAuthProviderTypeLabel = 'user_auth_provider_type_label', // Type
	UserAuthProviderTenantLabel = 'user_auth_provider_tenant_label', // Tenant
	UserActionResendInvite = 'user_action_resend_invite', // Resend Invite
	UserDetailsPendingStatusMessage = 'user_details_pending_status_message', // An email has been sent to this user to complete the registration
	ExternalGuardModalWrongTenantTitle = 'external_guard_modal_wrong_tenant_title', // You're not logged into the correct Company
	ExternalGuardModalWrongTenantMessage = 'external_guard_modal_wrong_tenant_message', // Please log in to Company <strong>{{name}}</strong> to proceed
	ExternalGuardModalWrongProjectTitle = 'external_guard_modal_wrong_project_title', // You're not logged into the correct Project
	ExternalGuardModalWrongProjectMessage = 'external_guard_modal_wrong_project_message', // Please select Project <strong>{{name}}</strong> to proceed
	SignInSignInButtonLabel = 'sign_in_sign_in_button_label',
	SignInSignOutButtonLabel = 'sign_in_sign_out_button_label',
	SignInSignOutPendingMessage = 'sign_in_sign_out_pending_message',
	SignInSignedInLabel = 'sign_in_signed_in_label',
	SignInHistoryHeading = 'sign_in_history_heading',
	SignInErrorLoadingHistory = 'sign_in_error_loading_history',
	ReportDateRangeToday = 'report_date_range_today', // Today
	ReportDateRange7Days = 'report_date_range_7_days', // 7 Days
	ReportDateRange14Days = 'report_date_range_14_days', // 14 Days
	ReportDateRange21Days = 'report_date_range_21_days', // 21 Days
	ReportDateRange28Days = 'report_date_range_28_days', // 28 Days
	ReportDateRangeThisMonth = 'report_date_range_this_month', // This Month
	ReportDateRangeLastMonth = 'report_date_range_last_month', // Last Month
	ReportDateRange3Months = 'report_date_range_3_months', // 3 Months
	ReportDateRange6Months = 'report_date_range_6_months', // 6 Months
	ReportDateRange9Months = 'report_date_range_9_months', // 9 Months
	ReportDateRange12Months = 'report_date_range_12_months', // 12 Months
	ReportErrorNotConfigured = 'report_error_not_configured', // Report not configured
	PleaseChooseLabel = 'please_choose_label', // Please choose
	RecordCountErrorMessage = 'record_count_error_message', // Record Count unavailable
	SupportLoginLabel = 'support_login_label', // Support Login
}

/**
 * These keys are at App build time
 * this enum exists to prevent unused key console warnings
 */
// TODO correct key case to use underscore separators
export enum IOSPermissionKey {
	CameraAccess = 'cameraAccess',
	MicrophoneUsageDescription = 'microphoneUsageDescription',
	PhotoLibraryReadAccess = 'photoLibraryReadAccess',
	PhotoLibraryReadWriteAccess = 'photoLibraryReadWriteAccess',
	LocationServicesAlwaysWhenInUse = 'locationServicesAlwaysWhenInUse',
	LocationServicesWhenInUse = 'locationServicesWhenInUse',
	LocationServicesAlways = 'locationServicesAlways',
}
