import { isDictionary, isString } from '@unifii/sdk';

export type DataDisplayPdfViewerValue = {
	label: string;
	pdfAttachmentId: string;
};

export const isDataDisplayPdfViewerValue = (data: unknown): data is DataDisplayPdfViewerValue =>
	isDictionary(data) &&
    Object.keys(data).length === 2 &&
    isString(data.label) &&
    isString(data.pdfAttachmentId);
