import { Component, Input } from '@angular/core';
import { DataDisplayComponentType } from '@unifii/library/common';
import { isDictionary, isString } from '@unifii/sdk';

export type DataDisplayPdfViewerValue = {
	label: string;
	pdfAttachmentId: string;
};

@Component({
	selector: 'us-data-display-pdf-viewer',
	templateUrl: './data-display-pdf-viewer.html',
})
export class DataDisplayPdfViewerComponent implements DataDisplayComponentType {

	@Input({ required: true }) data: DataDisplayPdfViewerValue;

	static isDataAccepted(data: unknown): boolean {
		return isDictionary(data) &&
			Object.keys(data).length === 2 &&
			isString(data.label) &&
			isString(data.pdfAttachmentId);
	}

}
