import { Inject, Injectable, Optional } from '@angular/core';
import { TableInputManager } from '@unifii/components';
import { ContextProvider, ExpressionParser, FilterAstNodeAdapter, FilterEntries, FilterEntry, FilterSerializer, FilterValue, HierarchyUnitProvider, UserInfoIdentifiers, normalizeAstNode } from '@unifii/library/common';
import { AstNode, NodeType, QueryOperators, Table, TableSourceType, mergeAstNodes } from '@unifii/sdk';

import { Config } from 'config';

/**
 * TableInputManagerFactory
 * Responsible for the creation of TableInputManger classes from a Table interface
 * and also provides a centralized place for merging static and context filters
 */
@Injectable()
export class TableInputManagerFactory {

	// Users with 'isTester' flag are filtered out on Live context
	private usersIsTesterLiveFilterNode: AstNode | undefined;

	// Keep this constructor for usage outside of DI context
	constructor(
		private contextProvider: ContextProvider,
		private expressionParser: ExpressionParser,
		@Optional() @Inject(FilterEntries) private entries: FilterEntry[] | undefined | null,
		@Inject(HierarchyUnitProvider) private hierarchyUnitProvider: HierarchyUnitProvider,
		@Optional() @Inject(FilterAstNodeAdapter) private filterAstNodeAdapter: FilterAstNodeAdapter<FilterValue, FilterEntry> | null,
		@Optional() @Inject(FilterSerializer) private serializer: FilterSerializer<FilterValue, FilterEntry> | null,
		@Optional() @Inject(Config) private config: Config | null,
	) {
		if (!this.config?.unifii.preview) {
			this.usersIsTesterLiveFilterNode = {
				type: NodeType.Operator,
				op: QueryOperators.Equal,
				args: [{
					type: NodeType.Identifier,
					value: UserInfoIdentifiers.IsTester,
				}, {
					type: NodeType.Value,
					value: false,
				}],
			};
		}
	}

	create(table: Table, contextFilter?: AstNode): TableInputManager<FilterValue, FilterEntry> {
		const inputManager = new TableInputManager(this.entries ?? [], this.hierarchyUnitProvider, this.serializer ?? null, this.filterAstNodeAdapter ?? null);

		inputManager.staticFilterNode = normalizeAstNode(
			mergeAstNodes('and',
				table.filter,
				contextFilter,
				table.sourceType === TableSourceType.Users ? this.usersIsTesterLiveFilterNode : undefined,
			),
			this.expressionParser,
			Object.assign({ self: null, root: {} }, this.contextProvider.get()),
		);

		return inputManager;
	}

}
