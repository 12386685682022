<uf-panel ufMasterDetail class="container bg-gray">
	<div class="uf-container bg-solid">
		<div class="uf-app-bar transparent pad-sm-left">
			<uf-breadcrumbs [breadcrumbs]="breadcrumbs" [minimize]="true" class="small left" />
		</div>

		@if (error) {
			<uf-message [content]="error.message" icon="error" class="error" />
		}

		<div class="uf-grid pad-sm-sides">
			<div [formGroup]="form" class="uf-box col-12 ng-untouched ng-pristine ng-valid ng-star-inserted">
				<div class="uf-app-bar accent medium">
					<div class="title">
						{{ shellTK.DetailsTitle | translate }}
					</div>
				</div>
				<div class="gap-top pad-sides pad-bottom">
					@if (companyDisabledIdentifiers.length && company) {
						<uf-company-description [company]="company" [showOnly]="companyDisabledIdentifiers" class="pad-bottom" />
					}
					@if (nameControl && nameControl.enabled) {
						<uf-text [label]="commonTK.CompanyNameLabel | translate" [control]="nameControl" class="pad-bottom">
							@if (isNameControlRequired) {
								<span class="suffix">*</span>
							}
						</uf-text>
					}
					@if (statusControl && statusControl.enabled) {
						<uf-select [control]="statusControl" [label]="commonTK.CompanyStatusLabel | translate" [options]="companyStatusOptions" valueProperty="identifier" nameProperty="name" class="pad-bottom">
							@if (isStatusControlRequired) {
								<span class="suffix">*</span>
							}
						</uf-select>
					}
				</div>
			</div>
		</div>

		<div class="row gap-lg-top">
			<button (click)="back()" type="button" class="uf-button tertiary left">
				{{ sharedTK.ActionCancel | translate }}
			</button>
			@if (form.enabled) {
				<button (click)="save()" type="button" class="uf-button primary">
					{{ (company ? sharedTK.ActionSave : sharedTK.ActionCreate) | translate }}
				</button>
			}
		</div>
	</div>
</uf-panel>

<router-outlet />
