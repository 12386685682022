import { Component, EventEmitter, HostBinding, Input, OnDestroy, OnInit, Output, ViewChild, inject } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { TableInputs } from '@unifii/components';
import { ColumnDisplayDescriptor, Context, ContextProvider, ExpressionParser, FilterValue, HierarchyUnitProvider, ModalService, SharedTermsTranslationKey, SingleChoiceListModalComponent, SortStatus, TableComponent, TableConfig, TableDataSource, getTableCustomColumnsDisplayDescriptors } from '@unifii/library/common';
import { AstNode, Client, CompaniesClient, Company, DataType, Dictionary, Option, PermissionAction, Table, TableDetailTemplate, TableSourceType, UserInfo, UsersClient, coerceDataToTarget, ensureUfRequestError, hasLengthAtLeast, mapUserToUserContext } from '@unifii/sdk';
import { Subscription } from 'rxjs';

import { Config } from 'config';
import { TableDisplayMode } from 'shell/content/content-node.component';
import { ErrorService } from 'shell/errors/error.service';
import { AppError } from 'shell/errors/errors';
import { UsFormService, UsFormServiceFactoryLogic, formServiceFactory } from 'shell/form/form-service.service';
import { Authentication } from 'shell/services/authentication';
import { PermissionsFunctions } from 'shell/services/permissions-functions';
import { FormDataPath, NewItemPath } from 'shell/shell-constants';
import { ShellTranslationKey } from 'shell/shell.tk';
import { CompanyTableDataSource } from 'shell/table/companies/company-table-datasource';
import { BucketTableDataSource } from 'shell/table/form-data/bucket-table-datasource';
import { TableData } from 'shell/table/models';
import { TableColumnFactory } from 'shell/table/table-column-factory';
import { checkShowCount } from 'shell/table/table-functions';
import { TableInputManagerFactory } from 'shell/table/table-input-manager-factory';
import { UsersTableDataSource } from 'shell/table/users/users-table-datasource';
import { TableDetailContextProvider } from 'shell/table-detail/table-detail-context-provider';

import { TableModule } from './table-detail.component';

@Component({
	selector: 'us-table-module',
	templateUrl: './table-module.html',
	styleUrls: ['../dashboard/dashboard-table.less'],
	providers: [{ provide: UsFormService, useFactory: formServiceFactory, deps: [UsFormServiceFactoryLogic] }],
	standalone: false,
})
export class TableModuleComponent implements OnInit, OnDestroy {

	@ViewChild(TableComponent) tableComponent: TableComponent<TableData> | null;

	@Input() module: TableModule;
	@Input() item: TableData;
	@Input() detailContextProvider: TableDetailContextProvider;
	@Input() pageMode = TableDetailTemplate.PageView;
	@Output() hideChange = new EventEmitter<boolean>();

	@HostBinding('class.hide') protected _hide: boolean;

	protected readonly sharedTK = SharedTermsTranslationKey;
	protected readonly shellTK = ShellTranslationKey;
	protected title: string;
	protected tableConfig: TableConfig<any>;
	protected customColumns: ColumnDisplayDescriptor[] = [];
	protected tableLink: any[];
	protected error: AppError | undefined;
	protected dataSource?: TableDataSource<TableData>;
	protected recordCount?: number;
	protected showCount: boolean;

	private usersClient = inject(UsersClient);
	private expParser = inject(ExpressionParser);
	private formService = inject(UsFormService);
	private config = inject(Config);
	private auth = inject(Authentication);
	private contextProvider = inject(ContextProvider);
	private hierarchyUnitProvider = inject(HierarchyUnitProvider);
	private router = inject(Router);
	private client = inject(Client);
	private errorService = inject(ErrorService);
	private modalService = inject(ModalService);
	private translate = inject(TranslateService);
	private tableColumnFactory = inject(TableColumnFactory);
	private dataSubscription: Subscription | null;

	get showSeeMoreRow() {
		return this.tableComponent?.status.exhausted === false;
	}

	private set hide(v: boolean) {
		if (v === this._hide) {
			return;
		}
		this._hide = v;
		this.hideChange.emit(v);
	}

	ngOnInit() {

		const { detailModule, pageConfig } = this.module;

		try {
			// Guard for non matching roles with those requested by the TableModule
			if (detailModule.roles?.length && !detailModule.roles.some((r) => this.auth.userInfo?.roles?.includes(r))) {
				return;
			}

			const { table, propertyDescriptors } = pageConfig;

			this.dataSource = this.createDataSource(table, detailModule.filter);

			if (detailModule.title) {
				this.title = detailModule.title;
			} else {
				this.title = table.title;
			}

			this.customColumns = getTableCustomColumnsDisplayDescriptors(table.columns);

			this.tableConfig = {
				columns: this.tableColumnFactory.create(table.sourceType, table.columns ?? [], propertyDescriptors, false),
				pageSize: detailModule.limit ?? 5,
				rowAction: (item: TableData) => {
					if (!!item.id && this.canRouteToItem(item, table.sourceType, table.source)) {
						this.routeToItem(detailModule.identifier, item.id, table);
					}
				},
			};

			this.tableLink = [table.identifier];

			void this.loadPageCount();

		} catch (e) {
			console.error('TableModuleComponent.ngOnInit error: ', e);
			this.error = ensureUfRequestError(e, this.errorService.unknownErrorMessage);
		}
	}

	ngOnDestroy() {
		this.dataSubscription?.unsubscribe();
	}

	/** Based on TableModule.canAdd and TableModule.filter */
	async addLinked() {

		const { moduleConfig, pageConfig } = this.module;

		if (!moduleConfig?.addOptions || !pageConfig.bucket || !moduleConfig.filterLink) {
			return;
		}

		const expressionValue = this.expParser.resolve(
			moduleConfig.filterLink.expression,
			this.detailContextProvider.get() as Context,
			undefined,
			`TableDetailModule: failed to parse ${moduleConfig.filterLink.expression}`,
		);

		const expressionValueString = coerceDataToTarget(expressionValue, { type: DataType.String });

		if (!expressionValueString) {
			return;
		}

		let selected: Option | undefined;

		if (moduleConfig.addOptions.length === 1 && hasLengthAtLeast(moduleConfig.addOptions, 1)) {
			selected = moduleConfig.addOptions[0];
		} else {
			selected = await this.modalService.openMedium(SingleChoiceListModalComponent, {
				title: this.translate.instant(ShellTranslationKey.FormBucketDialogAddFormTitle),
				options: moduleConfig.addOptions,
			});
		}

		if (!selected) {
			return;
		}

		const params: Dictionary<string> = {};

		params.$definition = selected.identifier;
		params[moduleConfig.filterLink.identifier] = expressionValueString;
		void this.router.navigate([FormDataPath, pageConfig.bucket, NewItemPath, params]);
	}

	private createDataSource(table: Table, moduleFilter?: AstNode): TableDataSource<TableData> {
		const factory = new TableInputManagerFactory(this.detailContextProvider, this.expParser, undefined, this.hierarchyUnitProvider, null, null, this.config);
		const tableInputManager = factory.create(table, moduleFilter);

		let inputFilters: TableInputs<FilterValue> | undefined;
		const sort = SortStatus.fromString(table.defaultSort) ?? undefined;

		if (sort) {
			inputFilters = { sort };
		}

		let datasource: TableDataSource<TableData>;

		switch (table.sourceType) {
			case TableSourceType.Users:
				datasource = new UsersTableDataSource(
					this.usersClient,
					table.identifier,
					tableInputManager,
					inputFilters,
				) as TableDataSource<TableData>;
				break;

			case TableSourceType.Company:
				datasource = new CompanyTableDataSource(
					new CompaniesClient(this.client),
					table.identifier,
					tableInputManager,
					inputFilters,
				) as TableDataSource<TableData>;
				break;

			case TableSourceType.Bucket:
				if (!table.source) {
					throw new Error(`Source not defined for table ${table.sourceType}|${table.identifier}`);
				}
				this.formService.init(table.source);

				datasource = new BucketTableDataSource({
					formService: this.formService,
					tableIdentifier: table.identifier,
					tableInputManager,
					tableInputs: inputFilters,
					showCount: checkShowCount(this.config, table),
				});
				break;
		}

		/**
         * listen to the datasource first load (no pagination for dashboard-table)
         * decide if shows the table expander container or keep it hidden
        */
		this.dataSubscription?.unsubscribe();
		this.dataSubscription = datasource.connect().subscribe({
			next: (result) => {
				this.hide = !result.error && !result.data?.length && this.pageMode === TableDetailTemplate.PageViewHideEmptyTables;
			},
			error: () => {
				this.hide = false;
			},
		});

		return datasource;
	}

	private routeToItem(identifier: string, id: string | undefined, table: Table) {

		if (!id) {
			return;
		}

		const segments: any[] = [];

		// go directly to form-data if no form detail
		if (table.sourceType === TableSourceType.Bucket && !table.detail) {
			segments.push(FormDataPath, table.source, id);
			void this.router.navigate(segments);

			return;
		}

		switch (table.sourceType) {
			case TableSourceType.Bucket:
			case TableSourceType.Users:
				segments.push(identifier, id);
				break;
			case TableSourceType.Company:
				return;
		}

		if (table.detail) {
			segments.push({ mode: TableDisplayMode.Detail });
		}

		void this.router.navigate(segments);
	}

	private canRouteToItem(item: TableData, tableSource: TableSourceType, bucket?: string): boolean {
		switch (tableSource) {

			case TableSourceType.Bucket:
				return !!bucket && !!item.id && this.auth.getGrantedInfo(
					PermissionsFunctions.getBucketDocumentPath(this.config.unifii.projectId, bucket, item.id),
					PermissionAction.Read,
					item as FormData,
					this.contextProvider.get(),
				).granted;

			case TableSourceType.Company:
				return this.auth.getGrantedInfo(
					PermissionsFunctions.getCompanyPath(item.id),
					PermissionAction.Read,
					item as Company,
					this.contextProvider.get(),
				).granted;

			case TableSourceType.Users: {
				const userInfo = item as UserInfo;

				return !!userInfo.id && this.auth.getGrantedInfo(
					PermissionsFunctions.getUserPath(+userInfo.id),
					PermissionAction.Read,
					mapUserToUserContext(userInfo),
					this.contextProvider.get(),
				).granted;
			}

		}
	}

	private async loadPageCount() {
		this.showCount = !!this.module.pageConfig.table.showCount;
		if (this.dataSource && this.config.unifii.tenantSettings?.features.indexing) {
			this.tableConfig.exhaustAhead = true;
			this.recordCount = await this.dataSource.count;
		}
	}

}
