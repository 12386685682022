import { Component, HostBinding, OnInit, inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DataDisplayListItem, DataDisplayService, Modal, ModalData, ModalRuntime, SharedTermsTranslationKey, UfControl, ValidatorFunctions, WindowWrapper } from '@unifii/library/common';
import { AppAuthProviderConfiguration, DataType, TenantClient, TenantSettings } from '@unifii/sdk';

import { InAppBrowserService } from 'capacitor/in-app-browser.service';
import { Config } from 'config';
import { DiscoverTranslationKey } from 'discover/discover.tk';
import { ErrorService } from 'shell/errors/error.service';
import { SSOService } from 'shell/services/sso.service';
import { UserAccessManager } from 'shell/services/user-access-manager';

export interface ContactInfoModalData {
	tenantSettings?: TenantSettings;
}

@Component({
	selector: 'ud-contact-info-modal',
	templateUrl: './contact-info-modal.html',
	styleUrls: ['../../shell/styles/external-branding.less'],
	standalone: false,
})
export class ContactInfoModalComponent implements Modal<ContactInfoModalData, void>, OnInit {

	@HostBinding('class.ud-card') class = true;
	@HostBinding('class.grid') class1 = true;

	data = inject<ContactInfoModalData>(ModalData);
	runtime = inject<ModalRuntime<ContactInfoModalData, void>>(ModalRuntime);
	contactDetails: DataDisplayListItem[] | null;
	companyControl: UfControl;
	company: string;
	name: string | null;

	protected readonly discoverTK = DiscoverTranslationKey;
	protected providers: AppAuthProviderConfiguration[] = [];

	private config = inject(Config);
	private tenantClient = inject(TenantClient);
	private translate = inject(TranslateService);
	private dataDisplayService = inject(DataDisplayService);
	private ssoService = inject(SSOService);
	private userAccessManager = inject(UserAccessManager);
	private inAppBrowser = inject(InAppBrowserService);
	private errorService = inject(ErrorService);
	private window = inject(WindowWrapper);

	ngOnInit() {
		this.contactDetails = this.getContactDetails(this.data.tenantSettings);

		this.providers = this.ssoService.buildProviderWithLabels(true);

		this.companyControl = new UfControl([
			ValidatorFunctions.custom((v) => this.config.unifii.tenant != null || (v != null && v !== ''), this.translate.instant(SharedTermsTranslationKey.ValidatorValueRequired)),
			ValidatorFunctions.pattern(/^[A-Za-z0-9.-]+$/, this.translate.instant(SharedTermsTranslationKey.ValidatorInvalidCharacters)),
			ValidatorFunctions.custom((v) => !/\s/.test(v), this.translate.instant(SharedTermsTranslationKey.ValidatorInvalidSpaces)),
		]);
	}

	close() {
		this.runtime.close();
	}

	retrieve() {
		this.companyControl.setSubmitted(true);

		if (this.companyControl.invalid) {
			return;
		}

		this.name = this.company;
		void this.load();
	}

	async load() {

		this.config.unifii.tenant = this.name ?? undefined;
		const settings = await this.tenantClient.getSettings();

		this.contactDetails = this.getContactDetails(settings);
	}

	protected async providerSignIn(provider: AppAuthProviderConfiguration, username?: string): Promise<void> {

		this.userAccessManager.showError(null);

		try {

			const redirectUri = this.ssoService.loginRedirectUri;
			const providerUrl = await this.ssoService.getProviderUrl(provider, redirectUri, username);

			if (!providerUrl) {
				throw new Error('');
			}

			if (this.inAppBrowser.isAvailable) {
				this.inAppBrowser.open(providerUrl);
			} else {
				this.window.location.href = providerUrl;
			}

		} catch (error) {
			/** device registration fail,  */
			this.userAccessManager.showError(this.errorService.createError('', error));
		}
	}

	private getContactDetails(tenantSettings: TenantSettings | undefined): DataDisplayListItem[] | null {

		if (tenantSettings == null) {
			return null;
		}

		const details = [];

		if (tenantSettings.contactName != null) {
			details.push({
				term: this.translate.instant(DiscoverTranslationKey.ContactInfoNameLabel),
				data: tenantSettings.contactName,
			});
		}

		if (tenantSettings.contactEmail != null) {
			details.push({
				term: this.translate.instant(DiscoverTranslationKey.ContactInfoEmailLabel),
				data: this.dataDisplayService.displayAsDataDisplayValue(tenantSettings.contactEmail, { type: DataType.Email }),
			});
		}

		if (tenantSettings.contactPhone != null) {
			details.push({
				term: this.translate.instant(DiscoverTranslationKey.ContactInfoPhoneLabel),
				data: this.dataDisplayService.displayAsDataDisplayValue(tenantSettings.contactPhone, { type: DataType.Phone }),
			});
		}

		return details;
	}

}
