import { Component, OnInit, inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { SharedTermsTranslationKey, UfControl, ValidatorFunctions } from '@unifii/library/common';
import { ProjectInfo, TenantClient, isStringNotEmpty } from '@unifii/sdk';

import { DiscoverContext } from 'discover/discover-context';
import { DiscoverTranslationKey } from 'discover/discover.tk';
import { ErrorService } from 'shell/errors/error.service';
import { OfflineManager } from 'shell/offline/offline-manager';
import { Authentication } from 'shell/services/authentication';
import { SavedUsersService } from 'shell/services/saved-users.service';
import { UserAccessManager } from 'shell/services/user-access-manager';

@Component({
	selector: 'ud-select-project',
	templateUrl: 'select-project.html',
	standalone: false,
})
export class SelectProjectComponent implements OnInit {

	protected readonly sharedTermsTK = SharedTermsTranslationKey;
	protected readonly discoverTK = DiscoverTranslationKey;
	protected control: UfControl;
	protected projects: ProjectInfo[] = [];
	protected busy: boolean;

	private tenantClient = inject(TenantClient);
	private route = inject(ActivatedRoute);
	private auth = inject(Authentication);
	private savedUsers = inject(SavedUsersService);
	private errorService = inject(ErrorService);
	private translate = inject(TranslateService);
	private context = inject(DiscoverContext);
	private offlineManager = inject(OfflineManager);
	private accessManager = inject(UserAccessManager);

	ngOnInit() {

		this.control = new UfControl(ValidatorFunctions.required(this.translate.instant(DiscoverTranslationKey.SelectProjectErrorProjectRequired)));
		this.projects = this.auth.allowedProjects;

		if (!this.projects.length) {
			this.accessManager.showError(this.errorService.createError(this.translate.instant(DiscoverTranslationKey.SelectProjectErrorNoProjects)));
		}

		if (this.route.snapshot.queryParams.autofill !== 'false') {
			this.autofillProject();
		}

		const { projectId } = this.route.snapshot.params;

		if (isStringNotEmpty(projectId)) {
			this.control.setValue(projectId);

			void this.submit();
		}
	}

	async submit() {

		this.accessManager.showError(null);

		if (this.control.invalid) {
			this.control.setSubmitted();

			return;
		}

		try {
			this.busy = true;

			this.context.project = await this.tenantClient.getProject(this.control.value);
			this.savedUsers.updateProject(this.auth.userInfo, this.control.value);

			// Close previous project DB reference
			this.offlineManager.projectChanged();

			this.accessManager.grant();

		} catch (error) {
			this.accessManager.showError(this.errorService.createLoadError('project', error));
		} finally {
			this.busy = false;
		}
	}

	logout() {
		void this.auth.logout();
	}

	private autofillProject() {

		const userProject = this.savedUsers.userProject(this.auth.userInfo);

		if (userProject && this.projects.find((p) => p.id === userProject)) {
			this.control.setValue(userProject);
		}
	}

}
