import { Injectable, inject } from '@angular/core';
import { DATE_TIME_DATA_FORMAT, ErrorType, TenantClient, TenantSettings } from '@unifii/sdk';
import { Duration, add, format } from 'date-fns';

import { Config } from 'config';
import { ErrorService } from 'shell/errors/error.service';
import { AppError } from 'shell/errors/errors';

/**
 * Cache TenantSettings with a duration based expire strategy
 */
@Injectable({ providedIn: 'root' })
export class TenantSettingsService {

	// How long the cache last
	private readonly environmentDuration = new Map<string, Duration>([
		['localhost', { minutes: 2 }],
		['dev', { minutes: 2 }],
	]);

	// Set on TenantSettings load memoized
	private cacheExpiryDate?: string;
	// Memoize load request, avoid race condition
	private tenantSettingsPromise?: Promise<TenantSettings>;

	private tenantClient = inject(TenantClient);
	private errorService = inject(ErrorService);
	private config = inject(Config);

	/** Update TenantSettings */
	sync() {
		// Guard for configured Tenant
		if (!this.config.unifii.tenant) {
			throw this.errorService.createError(this.errorService.invalidClientErrorMessage);
		}

		// Load/Update settings
		return this.updateTenantSettings();
	}

	setTenant(tenant: string) {
		const previousTenant = this.config.unifii.tenant;
		const nextTenant = tenant.toLowerCase();

		if (previousTenant !== nextTenant) {
			this.cacheExpiryDate = undefined;
			this.tenantSettingsPromise = undefined;
			this.config.unifii.tenantSettings = undefined;
			this.config.unifii.tenant = nextTenant;
		}

		return this.sync();
	}

	private async updateTenantSettings() {

		if (this.hasCacheExpired || !this.tenantSettingsPromise) {
			this.cacheExpiryDate = this.getNowWithOffset(this.cacheDuration);
			this.tenantSettingsPromise = this.tenantClient.getSettings();
		}

		try {
			this.config.unifii.tenantSettings = await this.tenantSettingsPromise;
		} catch (err) {
			if (!this.config.unifii.tenantSettings) {
				if ((err as AppError).type === ErrorType.Unauthorized) {
					throw this.errorService.createError(this.errorService.unauthorizedRequestErrorMessage);
				}
				throw this.errorService.createError(this.errorService.invalidClientErrorMessage);
			}
			console.warn('TenantSettingsService failed to update settings');
		}
	}

	private getNowWithOffset(offset: Duration = {}) {
		return format(add(new Date(), offset), DATE_TIME_DATA_FORMAT);
	}

	private get hasCacheExpired() {
		return !this.cacheExpiryDate || this.cacheExpiryDate < this.getNowWithOffset();
	}

	private get cacheDuration() {
		return this.environmentDuration.get(this.config.env) ?? { minutes: 10 };
	}

}
