<uf-panel class="container bg-gray">
	<div class="uf-container-md">
		<div class="uf-card pad gap-top">
			<div class="uf-grid">
				<uf-search-result [options]="searchOptions" [clearOnSelect]="false" [blurOnSelect]="true" (searchChange)="searchChange.next($event)" (valueChange)="searchResult.next($event)" placeholder="Document Search" nameProperty="name" class="col-12">
					<ng-template #listBox let-options="options" let-select="select" let-active="active">
						<ul class="uf-list help-search-results">
							@for (option of options; track option; let i = $index) {
								<li [class.active]="i === active" (click)="select(option)" class="pad-left pad-right clickable">
									@if (option.name) {
										<h4>{{ option.name }}</h4>
									}
									@if (option.snippet) {
										<p class="small">{{ option.snippet }}</p>
									}
								</li>
							}
						</ul>
					</ng-template>
				</uf-search-result>
			</div>
		</div>
	</div>
</uf-panel>
