@if (error) {
	<div>
		<uf-message [content]="error.message" class="col-1of1 error padded" icon="errorSolid">
			<h3>{{ error.code }}</h3>
		</uf-message>
	</div>
}

@if (tableConfig) {
	<uf-expander [actionBtn]="true">
		<!-- header -->
		<div expanderHeader class="uf-app-bar medium accent">
			@if (tableLink) {
				<a [routerLink]="tableLink" class="title">
					@if (recordCount && showCount) {
						<span class="record-count"> {{ recordCount }} - </span>
					}
					<span>
						{{ title }}
					</span>
					<uf-icon class="small" name="moreInfo" />
				</a>
			}
		</div>
		<div expanderBody class="pad-none gap-none uf-box flat-md table-page">
			<!-- TODO remove ?? null from datasource input once uf-table accept undefined as datasource value -->
			<uf-table [config]="tableConfig" [datasource]="dataSource ?? null" class="small-message list-md">
				@for (descriptor of customColumns; track descriptor) {
					<ng-container *cellFor="descriptor.name; let item; let index = index; let column = column">
						<uf-custom-cell [index]="index" [item]="item" [column]="column" [descriptor]="descriptor" />
					</ng-container>
				}
			</uf-table>
			@if (showSeeMoreRow || module.moduleConfig?.addOptions?.length) {
				<div class="row center-all float-right pad-sm-sides">
					<div class="header">
						@if (showSeeMoreRow) {
							<a [routerLink]="tableLink">
								<span>
									{{ shellTK.DashboardBucketActionSeeMore | translate }}
								</span>
							</a>
						}
					</div>
					@if (module.moduleConfig?.addOptions?.length) {
						<button [title]="sharedTK.ActionAdd | translate" (click)="addLinked()" type="button" class="uf-action primary gap-left">
							<uf-icon name="add" />
						</button>
					}
				</div>
			}
		</div>
	</uf-expander>
}
