import { Injectable, ViewContainerRef, inject } from '@angular/core';
import { ContentType, StructureNodeType } from '@unifii/sdk';

import { DirectoryTemplateComponent } from 'discover/components/directory-template/directory-template.component';
import { ContentComponentFactory, ContentFactoryArgs } from 'shell/content/content-component-factory';
import { ContentDataResolver } from 'shell/content/content-data-resolver';
import { DiscoverContentType } from 'shell/content/content-types';
import { ShellContentComponentFactory } from 'shell/content/shell-content-component-factory';

import { CustomReport } from './discover-constants';
import { ReportPageComponent } from './reports/report-page.component';

@Injectable()
export class DiscoverContentComponentFactory implements ContentComponentFactory {

	private dataResolver = inject(ContentDataResolver);
	private shellFactory = new ShellContentComponentFactory();

	async create(
		container: ViewContainerRef,
		type: ContentType | StructureNodeType | DiscoverContentType,
		args: ContentFactoryArgs,
	): Promise<any> {

		const { identifier, tags } = args;

		if (identifier === 'directory-template' || (identifier && tags?.includes('directory'))) {
			const { compound } = await this.dataResolver.getView(identifier);
			const { instance } = container.createComponent(DirectoryTemplateComponent, { index: 0, injector: container.injector });

			instance.compound = compound;

			return instance;
		}

		if (identifier === CustomReport || (identifier && tags?.includes(CustomReport))) {
			const { instance } = container.createComponent(ReportPageComponent, { index: 0, injector: container.injector });

			return instance;
		}

		return this.shellFactory.create(container, type, args);
	}

}
