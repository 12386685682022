import { AfterContentChecked, Component, HostBinding, Input } from '@angular/core';

@Component({
	selector: 'us-menu-drawer',
	templateUrl: './menu-drawer.html',
	styleUrls: ['./menu-drawer.less'],
	standalone: false,
})
export class MenuDrawerComponent implements AfterContentChecked {

	@Input() overlay = false;
	@Input() open = false;

	readonly icon = 'hamburger';

	private ready: boolean;

	@HostBinding('class.ready') get readyClass() {
		return this.ready;
	}

	ngAfterContentChecked() {
		/**
         * Transitions can now be added to avoid content flashing animations onload
         */
		this.ready = true;
	}

}
