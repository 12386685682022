import { Injectable, inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ModalService } from '@unifii/library/common';
import { ProjectInfo } from '@unifii/sdk';

import { Config } from 'config';
import { DiscoverContext } from 'discover/discover-context';
import { ConfirmExtendedData, ConfirmExtendedModalComponent } from 'shell/common/modals/confirm-extended-modal.component';
import { OfflineManager } from 'shell/offline/offline-manager';
import { Authentication } from 'shell/services/authentication';

import { DiscoverTranslationKey } from './discover.tk';

/**
 * Responsible for decoding route from route params
 * and redirecting to intended route or redirecting to
 * user access route if needed
 */
@Injectable({ providedIn: 'root' })
export class ExternalService {

	private context = inject(DiscoverContext);
	private config = inject(Config);
	private modalService = inject(ModalService);
	private offlineManager = inject(OfflineManager);
	private translate = inject(TranslateService);
	private auth = inject(Authentication);

	async validateTenant(tenant: string) {
		if (!this.isTenantValid(tenant)) {

			if (!await this.redirectToTenantConsent(tenant)) {
				throw new Error('User Cancelled');
			}

			return false;
		}

		return true;
	}

	async validateProject(projectId: string) {
		if (!this.isValidProject(projectId as string)) {

			if (!await this.redirectToProjectConsent(projectId as string)) {
				throw new Error('User Cancelled');
			}

			return false;
		}

		return true;
	}

	selectProject(projectId: string) {
		const project: ProjectInfo | undefined = (this.auth.allowedProjects || []).find((p) => p.id === projectId);

		if (!project) {
			throw new Error('Invalid Project');
		}

		this.context.project = project;

		// Close previous project DB reference
		this.offlineManager.projectChanged();
	}

	private isTenantValid(tenant: string): boolean {
		return this.config.unifii.tenant != null && this.config.unifii.tenant === tenant;
	}

	private isValidProject(projectId: string): boolean {
		return this.context.project != null && this.context.project.id === projectId;
	}

	private redirectToTenantConsent(name: string): Promise<boolean | undefined> {

		const title = this.translate.instant(DiscoverTranslationKey.ExternalGuardModalWrongTenantTitle);
		const content = this.translate.instant(DiscoverTranslationKey.ExternalGuardModalWrongTenantMessage, { name });

		return this.modalService.openFit<ConfirmExtendedData, boolean>(ConfirmExtendedModalComponent, { title, content });
	}

	private redirectToProjectConsent(projectId: string): Promise<boolean | undefined> {

		const project: ProjectInfo | undefined = (this.auth.allowedProjects || []).find((p) => p.id === projectId);
		const name = project ? project.name : projectId;

		const title = this.translate.instant(DiscoverTranslationKey.ExternalGuardModalWrongProjectTitle);
		const content = this.translate.instant(DiscoverTranslationKey.ExternalGuardModalWrongProjectMessage, { name });

		return this.modalService.openFit<ConfirmExtendedData, boolean>(ConfirmExtendedModalComponent, { title, content });

	}

}
