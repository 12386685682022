<uf-panel class="container" ufMasterDetail>
	@if (!busy) {
		<div class="uf-container-md pad-lg-top pad-sides">
			@if (activeSignIn) {
				<a [routerLink]="[activeSignIn.id]" class="uf-card centered gap-lg-bottom">
					<div class="card-info">
						<uf-icon name="sign-out" />
					</div>
					<div class="pad">
						<h4 class="gap-none">{{ discoverTK.SignInSignOutButtonLabel | translate }}</h4>
						<p class="small gap-none-bottom">
							<b>{{ activeSignIn.location }}</b
							><br />
							{{ discoverTK.SignInSignedInLabel | translate }}: {{ activeSignIn.signIn | dataDisplay }}<br />
							{{ activeSignIn.signIn | dataDisplay }}
						</p>
					</div>
				</a>
			}
			<a (click)="signIn()" class="uf-card centered">
				@if (!activeSignIn) {
					<div class="card-info">
						<uf-icon name="sign-in" class="large" />
					</div>
				}
				<div class="pad">
					<h4 class="gap-none">{{ discoverTK.SignInSignInButtonLabel | translate }}</h4>
				</div>
			</a>
			<!-- spacer -->
			<div class="pad-top"></div>
			@if (error) {
				<uf-message [content]="error.message" class="error" icon="error" />
			}
			@if (history.length) {
				<h2>{{ discoverTK.SignInHistoryHeading | translate }}</h2>
			}
			@for (item of history; track item) {
				<a [routerLink]="[item.id]" class="uf-card row gap-bottom pad center-all">
					<div [class.success]="item.signOut" [class.warning]="!item.signOut" class="uf-avatar gap-right">
						<uf-icon [name]="item.signOut ? 'tick' : 'warning'" />
					</div>
					<div class="grow">
						<p class="gap-none small">
							@if (item.title) {
								<b>{{ item.title }}</b
								><br />
							}
							@if (!item.multiday) {
								{{ item.signIn | dataDisplay }}<br />
								{{ item.signIn | dataDisplay }} -
								@if (item.signOut) {
									<span>{{ item.signOut | dataDisplay }}</span>
								} @else {
									{{ discoverTK.SignInSignOutPendingMessage | translate }}
								}
							} @else {
								{{ item.signIn | dataDisplay }}<br />
								{{ item.signOut | dataDisplay }}<br />
							}
							<ng-template #multiday>
								{{ item.signIn | dataDisplay }}<br />
								{{ item.signOut | dataDisplay }}<br />
							</ng-template>
						</p>
					</div>
				</a>
			}
		</div>
	}
</uf-panel>
<router-outlet />
