import { Component, OnInit, inject } from '@angular/core';
import { Breadcrumb, DataDisplayGroup, DataDisplayInfo, DataDisplayListItem, DataDisplayService, DataDisplaySpecificEntitiesService, DataLookupService, DataPropertyDescriptor, FormDefinitionMetadataIdentifiers } from '@unifii/library/common';
import { Company, DataSourceType, FormData, TableDetailModule, TableDetailTemplate, TableFieldDescriptor, TableIdentifierFieldDescriptor, TableSourceType, UserInfo, fieldTypeToDataType, isDictionary, isString } from '@unifii/sdk';

import { DiscoverContext } from 'discover/discover-context';
import { DiscoverTranslationKey } from 'discover/discover.tk';
import { TableItemLink } from 'shell/content/content-types';
import { ShellService } from 'shell/core/shell.service';
import { displayDataToFormDataRouterLink } from 'shell/functions/display-functions';
import { Authentication } from 'shell/services/authentication';
import { BreadcrumbsService } from 'shell/services/breadcrumbs.service';
import { TableData } from 'shell/table/models';
import { TableModuleConfig, TablePageConfig } from 'shell/table/table-page-config';

import { TableDetailContextProvider } from './table-detail-context-provider';
import { TableDetailPageComponent } from './table-detail-page.component';

export interface TableModule {
	detailModule: TableDetailModule;
	pageConfig: TablePageConfig;
	moduleConfig?: TableModuleConfig;
}

@Component({
	selector: 'us-table-detail',
	templateUrl: './table-detail.html',
	styleUrls: ['./table-detail.less'],
	providers: [BreadcrumbsService],
	standalone: false,
})
export class TableDetailComponent implements OnInit {

	itemLink: TableItemLink | undefined;

	protected readonly discoverTK = DiscoverTranslationKey;
	protected readonly templateEnumVales = TableDetailTemplate;

	protected groups: DataDisplayGroup[] = [];
	protected breadcrumbs: Breadcrumb[];
	protected tableModules: TableModule[] = [];
	protected detailContextProvider: TableDetailContextProvider;
	protected item: TableData;
	protected showModules: boolean;
	protected showNoEmptyMessage = false;
	protected parent = inject(TableDetailPageComponent);
	protected emptyMessage?: string;

	private title: string;
	private sourceType: TableSourceType;
	private fields: TableFieldDescriptor[];
	private propertyDescriptors: Map<string, DataPropertyDescriptor>;
	private hiddenTablesSet = new Set<TableModule>();
	private dataLookupService = inject(DataLookupService);
	private dataDisplayService = inject(DataDisplayService);
	private dataDisplayEntityService = inject(DataDisplaySpecificEntitiesService);
	private shell = inject(ShellService);
	private breadcrumbsService = inject(BreadcrumbsService);
	private context = inject(DiscoverContext);
	private auth = inject(Authentication);

	ngOnInit() {
		this.loadTableDetailServiceData();
		this.refreshDetails();

		this.breadcrumbs = this.breadcrumbsService.getBreadcrumbs();

		// Remove breadcrumb from mode=details
		this.breadcrumbs.splice(1, 1);

		this.emptyMessage = this.parent.tablePageConfig.table.detail?.emptyMessage;
	}

	protected updateHiddenTablesSet(tableInfo: TableModule, hidden: boolean) {
		if (!hidden) {
			this.hiddenTablesSet.delete(tableInfo);
		} else {
			this.hiddenTablesSet.add(tableInfo);
		}

		this.showNoEmptyMessage = !!this.tableModules.length && this.tableModules.length === this.hiddenTablesSet.size;
	}

	private refreshDetails() {

		const groups: DataDisplayGroup[] = [{ items: [] }];

		for (const field of this.fields) {
			if (field.type === 'Heading') {
				groups.push({ heading: field.value, items: [] });
			} else {
				const item = this.toDescription(field);

				if (item) {
					(groups[groups.length - 1]?.items as DataDisplayListItem[] | undefined)?.push(item);
				}
			}
		}

		this.groups = groups.filter((i) => Array.isArray(i.items) && i.items.length);
	}

	private toDescription(tableFieldDescriptor: TableIdentifierFieldDescriptor): DataDisplayListItem | undefined | null {

		const dataPropertyDescriptor = this.propertyDescriptors.get(tableFieldDescriptor.identifier);

		switch (this.sourceType) {

			case TableSourceType.Users: {
				return this.dataDisplayEntityService.displayUserPropertyAsDescriptionItem(
					this.item as UserInfo,
					this.propertyDescriptors.get(tableFieldDescriptor.identifier),
				);

			}

			case TableSourceType.Company: {
				return this.dataDisplayEntityService.displayCompanyPropertyAsDescriptionItem(
					this.item as Company,
					this.propertyDescriptors.get(tableFieldDescriptor.identifier),
				);
			}

			case TableSourceType.Bucket: {
				if (!dataPropertyDescriptor) {
					return;
				}

				const formData = this.item as FormData;
				const propertyValue = this.dataLookupService.lookupData(formData, dataPropertyDescriptor.identifier);

				const displayInfo = {
					type: fieldTypeToDataType(dataPropertyDescriptor.type),
					template: tableFieldDescriptor.itemTemplate,
					options: dataPropertyDescriptor.options,
				} as DataDisplayInfo;

				let data = this.dataDisplayService.displayAsDataDisplayValue(propertyValue, displayInfo);

				// TODO extract a common function for this logic and the one in DiscoverFieldDescriptionService
				// Detect a DS descriptor for identifier '_seqId' and optionally build the routerLink to the FormData
				if (dataPropertyDescriptor.identifier.endsWith(`.${FormDefinitionMetadataIdentifiers.SeqId}`)) {
					const parentDataPropertyDescriptor = this.propertyDescriptors.get(dataPropertyDescriptor.identifier.substring(0, tableFieldDescriptor.identifier.lastIndexOf('.')));
					const parentDataValue = parentDataPropertyDescriptor ? this.dataLookupService.lookupData(formData, parentDataPropertyDescriptor.identifier) : undefined;
					const bucketId = parentDataPropertyDescriptor?.sourceConfig?.type === DataSourceType.Bucket ? parentDataPropertyDescriptor.sourceConfig.id : undefined;
					const formDataId = isDictionary(parentDataValue) && isString(parentDataValue._id) ? parentDataValue._id : undefined;

					data = displayDataToFormDataRouterLink(data, this.auth, this.context.project?.id, bucketId, formDataId);
				}

				return {
					term: tableFieldDescriptor.label ?? dataPropertyDescriptor.label,
					data,
				} satisfies DataDisplayListItem;
			}
		}
	}

	private loadTableDetailServiceData() {
		this.title = this.parent.title;
		this.fields = this.parent.fields;
		this.item = this.parent.item;
		this.itemLink = this.parent.itemLink;
		this.sourceType = this.parent.sourceType;
		this.propertyDescriptors = this.parent.propertyDescriptors;
		this.tableModules = this.parent.tableModules;
		this.detailContextProvider = this.parent.detailContextProvider;

		this.shell.setTitle(this.title);
		this.breadcrumbsService.title = this.title;
	}

}
