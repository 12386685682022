import { Component, Input, OnDestroy, OnInit, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CommonTranslationKey, ModalService, SharedTermsTranslationKey, WindowWrapper } from '@unifii/library/common';
import { AppAuthProviderConfiguration, Dictionary, TenantSettings } from '@unifii/sdk';
import { Subscription } from 'rxjs';

import { DeviceRegistrationService } from 'capacitor/device-registration.service';
import { DeviceService } from 'capacitor/device.service';
import { InAppBrowserService } from 'capacitor/in-app-browser.service';
import { Config, Environment } from 'config';
import { SSOPath, TenantSelectionPath, UnifiiLoginPath, UserAccessRootPath } from 'discover/discover-constants';
import { DiscoverContext } from 'discover/discover-context';
import { DiscoverTranslationKey } from 'discover/discover.tk';
import { ErrorService } from 'shell/errors/error.service';
import { AppError } from 'shell/errors/errors';
import { Authentication } from 'shell/services/authentication';
import { SavedUser, SavedUsersService } from 'shell/services/saved-users.service';
import { SSOService } from 'shell/services/sso.service';
import { TenantSettingsService } from 'shell/services/tenant-settings.service';
import { UserAccessManager } from 'shell/services/user-access-manager';

interface SavedUserItem extends SavedUser {
	provider?: AppAuthProviderConfiguration;
}

@Component({
	selector: 'ud-login',
	templateUrl: './login.html',
	styleUrls: ['../../../shell/styles/external-branding.less', './login.less'],
	standalone: false,
})
export class LoginComponent implements OnDestroy, OnInit {

	@Input() authProviders: AppAuthProviderConfiguration[] = [];

	protected readonly sharedTermsTK = SharedTermsTranslationKey;
	protected readonly commonTK = CommonTranslationKey;
	protected readonly discoverTK = DiscoverTranslationKey;
	protected context = inject(DiscoverContext); // referenced by forked app extended component (BS)
	protected savedUsersService = inject(SavedUsersService); // referenced by forked app extended component (BS)
	protected providers: AppAuthProviderConfiguration[] = [];
	protected inProgress: boolean;
	protected showLoginWithUsernameButton: boolean;

	private router = inject(Router);
	private config = inject(Config);
	private auth = inject(Authentication);
	private window = inject(WindowWrapper);
	private env = inject(Environment);
	private deviceService = inject(DeviceService);
	private errorService = inject(ErrorService);
	private translate = inject(TranslateService);
	private ssoService = inject(SSOService);
	private inAppBrowser = inject(InAppBrowserService);
	private userAccessManager = inject(UserAccessManager);
	private modalService = inject(ModalService);
	private route = inject(ActivatedRoute);
	private tenantService = inject(TenantSettingsService);
	private deviceRegistrationService = inject(DeviceRegistrationService);
	private subscriptions = new Subscription();
	private projectId: string | undefined;

	async ngOnInit() {

		this.providers = this.ssoService.buildProviderWithLabels(false);

		this.showLoginWithUsernameButton = !!this.config.unifii.tenantSettings?.isPasswordAuthSupported;

		if (this.deviceService.isNative()) {
			this.subscriptions.add(this.inAppBrowser.loadStart.subscribe((url) => { this.inAppBrowserInterceptor(url); }));
		}

		// Sign In to Unifii via Okta app buttons
		const { iss } = this.route.snapshot.queryParams;

		if (iss) {
			const provider = this.ssoService.getProviderByUrl(iss);

			if (provider) {
				void this.providerSignIn(provider);
			} else {
				this.userAccessManager.showError(this.errorService.createError(this.translate.instant(CommonTranslationKey.SsoErrorAuthenticaionFailedMessage)));
			}

			return;
		}

		const { projectId, tenant } = this.route.snapshot.params;

		this.projectId = projectId as string | undefined;

		if (!projectId || !tenant) {
			return;
		}

		try {
			await this.tenantService.setTenant(tenant);
			this.providers = this.ssoService.buildProviderWithLabels(false);
			this.showLoginWithUsernameButton = !!this.config.unifii.tenantSettings?.isPasswordAuthSupported;
		} catch (e) {
			this.userAccessManager.showError(e as AppError);

			return;
		}

		void this.deviceRegistrationService.register();
	}

	ngOnDestroy() {
		this.subscriptions.unsubscribe();
		this.userAccessManager.showError(null);
	}

	protected get canChangeCompany(): boolean {
		return !this.env.unifii.tenant;
	}

	protected get tenantSettings(): TenantSettings | undefined {
		return this.config.unifii.tenantSettings;
	}

	protected get savedUsers(): SavedUserItem[] {

		return (this.savedUsersService.users as SavedUserItem[]).map((user) => {

			if (user.providerId) {
				user.provider = this.ssoService.getProvider(user.providerId);
			}

			return user;
		});
	}

	protected async removeUser(event: MouseEvent, user: SavedUser) {

		event.stopPropagation();

		const proceed = await this.modalService.openConfirm({
			title: this.translate.instant(DiscoverTranslationKey.LoginRemoveMeUserTitle),
			message: this.translate.instant(DiscoverTranslationKey.LoginRemoveMeUserMessage),
			confirmLabel: this.translate.instant(CommonTranslationKey.YesLabel),
			cancelLabel: this.translate.instant(CommonTranslationKey.NoLabel),
		});

		if (!proceed) {
			return;
		}

		this.savedUsersService.remove(user.username);
	}

	protected rememberUserLogin(user: SavedUserItem) {

		if (user.provider) {
			void this.providerSignIn(user.provider, user.username);

			return;
		}

		const params: Dictionary<unknown> = { username: user.username, rememberMe: true };

		if (this.projectId) {
			params.projectId = this.projectId;
		}

		void this.router.navigate(['/', UserAccessRootPath, UnifiiLoginPath, params]);
	}

	protected async providerSignIn(provider: AppAuthProviderConfiguration, username?: string): Promise<void> {

		this.userAccessManager.showError(null);
		this.inProgress = true;

		try {

			const redirectUri = this.ssoService.loginRedirectUri;
			const providerUrl = await this.ssoService.getProviderUrl(provider, redirectUri, username);

			if (!providerUrl) {
				throw new Error('');
			}

			if (this.inAppBrowser.isAvailable) {
				this.inAppBrowser.open(providerUrl);
			} else {
				this.window.location.href = providerUrl;
			}

		} catch (e) {
			/** device registration fail,  */
			this.userAccessManager.showError(this.errorService.createError('', e));
		} finally {
			this.inProgress = false;
		}
	}

	protected changeTenant() {

		const { tenant } = this.config.unifii;

		// Ensure auth information is clear
		this.auth.clear();

		this.config.unifii.tenantSettings = undefined;
		this.config.unifii.tenant = undefined;

		void this.router.navigate(['/', UserAccessRootPath, TenantSelectionPath, { tenant }]);
	}

	protected loginWithUser() {
		const params = this.projectId ? { projectId: this.projectId } : {};

		void this.router.navigate([UserAccessRootPath, UnifiiLoginPath, params]);
	}

	private inAppBrowserInterceptor(url: string) {

		const params = new URL(url).search;
		const urlSearchParams = new URLSearchParams(params);

		if (url.startsWith(this.ssoService.loginRedirectUri)) {
			void this.router.navigateByUrl(`/${UserAccessRootPath}/${SSOPath}?${urlSearchParams.toString()}`);
			this.inAppBrowser.close();
		}
	}

}
