import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ModalService, WindowWrapper } from '@unifii/library/common';
import { ProjectInfo, hasLengthAtLeast } from '@unifii/sdk';

import { DiscoverContext } from 'discover/discover-context';
import { ShellFeatureFlagService } from 'shell/services/shell-feature-flag.service';
import { TranslationsService } from 'shell/services/translations.service';
import { ShellTranslationKey } from 'shell/shell.tk';
import { ChangeLanguageComponent, ChangeLanguageModalData } from 'shell/translations/change-language.component';

import { ProjectSelectionPath, UserAccessRootPath } from './discover-constants';

export const projectLanguageGuard: CanActivateFn = async() => {

	const router = inject(Router);
	const context = inject(DiscoverContext);
	const flagService = inject(ShellFeatureFlagService);
	const translationsService = inject(TranslationsService);
	const modalService = inject(ModalService);
	const translate = inject(TranslateService);
	const window = inject(WindowWrapper) as Window;
	const project = context.project as ProjectInfo;
    
	// TODO maybe possible to remove !project expression, was causing an app to break on Maydown PROD
	// possibly a timing issue on with a lag with local storage cleared between previous guard and this guard
	//  to be on the safe side treating project as if it can be null
	if (!project) {
		router.navigate(['/', UserAccessRootPath, ProjectSelectionPath]);

		return false;
	}

	const allowedLanguages = translationsService.availableProjectLanguages(project);

	// If no allowedLanguages available show a dialog error and go back to projects
	if (!hasLengthAtLeast(allowedLanguages, 1)) {
		await modalService.openAlert({
			title: translate.instant(ShellTranslationKey.NoLanguageAvailableTitle),
			message: translate.instant(ShellTranslationKey.NoLanguageAvailableMessage),
		});

		router.navigate(['/', UserAccessRootPath, ProjectSelectionPath]);

		return false;
	}

	// No user language set
	if (!context.language) {
		return true;
	}

	// User language available for this project
	if (allowedLanguages.findIndex((code) => code === context.language) >= 0) {
		return true;
	}

	// No translations UI feature, silent select the language
	if (!await flagService.isEnabled('translations')) {
		translationsService.use(allowedLanguages[0]);

		return false;
	}

	const data: ChangeLanguageModalData = {
		languages: allowedLanguages,
		current: null as any as string,
		guard: true,
		reload: false,
	};

	const selected = await modalService.openFit<ChangeLanguageModalData, boolean>(ChangeLanguageComponent, data, {
		guard: true,
	});

	if (!selected) {
		console.info('ProjectLanguageGuard: No language selected, redirecting to projects');
		context.project = null;
		router.navigate(['/', UserAccessRootPath, ProjectSelectionPath]);

		return false;
	}

	window.location.href = window.location.origin;

	return true;
};
