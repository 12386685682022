import { Component, OnInit, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CommonTranslationKey } from '@unifii/library/common';
import { OAuthWithCode, decrypt, ensureUfRequestError } from '@unifii/sdk';

import { Config } from 'config';
import { ExternalPath, ProjectSelectionPath, UserAccessRootPath } from 'discover/discover-constants';
import { Authentication } from 'shell/services/authentication';
import { TenantSettingsService } from 'shell/services/tenant-settings.service';
import { UserAccessManager } from 'shell/services/user-access-manager';

interface IdentityParams {
	tenant?: string;
	code?: string;
	error?: string;
	error_description?: string;
	state?: string;
}

@Component({
	selector: 'ud-identity-authorize',
	templateUrl: './authorize.html',
	standalone: false,
})
export class AuthorizeComponent implements OnInit {

	protected errorMessage?: string;

	private route = inject(ActivatedRoute);
	private router = inject(Router);
	private tenantSettingsService = inject(TenantSettingsService);
	private userAccessManager = inject(UserAccessManager);
	private translate = inject(TranslateService);
	private config = inject(Config);
	private auth = inject(Authentication);

	async ngOnInit() {
		try {
			const { code, tenant, state } = this.parseQueryParams();

			await this.tenantSettingsService.setTenant(tenant);

			const providerId = this.config.unifii.tenantSettings?.authProviders?.find((provider) => provider.clientId === this.config.unifii.appId)?.id;

			if (!providerId) {
				throw new Error('Configured Unifii identity provider not found in tenant settings');
			}

			await this.auth.login({ code, provider_id: `${providerId}` } satisfies OAuthWithCode);

			if (state) {
				const value = await decrypt({ key: this.config.unifii.appId ?? '', byteString: decodeURIComponent(state) });
				const redirectUri = new URLSearchParams(value).get('redirectUri') ?? '';
				const url = new URL(decodeURIComponent(redirectUri));
				const route = url.searchParams.get('next');

				if (route?.startsWith(`/${ExternalPath}/${tenant}/`)) {
					void this.router.navigateByUrl(route);

					return;
				}
			}

			void this.router.navigate([`/${UserAccessRootPath}`, ProjectSelectionPath]);

		} catch (e) {
			// TODO - rename in library
			const error = ensureUfRequestError(e, this.translate.instant(CommonTranslationKey.SsoErrorAuthenticaionFailedMessage) as string);

			void this.userAccessManager.deny({ error: error.message });
		}
	}

	private parseQueryParams(): { code: string; tenant: string; state?: string } {
		// eslint-disable-next-line @typescript-eslint/naming-convention
		const { code, tenant, error, error_description, state } = this.route.snapshot.queryParams as IdentityParams;

		if (error || error_description || !code || !tenant) {
			throw new Error(error_description ?? error);
		}

		return { code, tenant, state };
	}

}
