import { Component, Input } from '@angular/core';

@Component({
	selector: 'us-section-accordion',
	templateUrl: './section-accordion.html',
	styleUrls: ['./section-accordion.less'],
	standalone: false,
})
export class SectionAccordionComponent {

	@Input() open = false;
	@Input() name: string;

	protected toggle() {
		this.open = !this.open;
	}

}
