<uf-panel class="content-pane content scrollable bg-gray">
	@if (!uploadResult && form) {
		<div [formGroup]="form" class="uf-container-lg bg-solid">
			<div class="uf-grid pad-top row-gap-lg">
				@if (usersControl) {
					<div class="uf-box col-12">
						<div class="uf-app-bar accent medium">
							<div class="title">{{ discoverTK.UsersLabel | translate }}</div>
						</div>
						<div class="uf-grid pad">
							<!-- Mobile View -->
							@if (usersControl.value.length) {
								<div class="col-12 col space-children margin--top user-card-display">
									@for (userControl of usersControl.controls; track userControl; let i = $index) {
										<div class="uf-card">
											<div class="pad">
												<button [title]="sharedTermsTK.ActionRemove | translate" (click)="removeUser(i)" type="button" class="uf-action tertiary small float-right">
													<uf-icon name="delete" />
												</button>
												<button [title]="sharedTermsTK.ActionEdit | translate" (click)="editUser(i)" type="button" class="uf-action tertiary small float-right">
													<uf-icon name="edit" />
												</button>
												<uf-data-display-list class="small-label">
													@if (userControl.get(userInfoKeys.Username)?.enabled) {
														<dt>{{ commonTK.UsernameLabel | translate }}</dt>
														<dd>{{ userControl.get(userInfoKeys.Username)?.value }}</dd>
													}
													@if (userControl.get(userInfoKeys.FirstName)?.enabled) {
														<dt>{{ commonTK.FirstNameLabel | translate }}</dt>
														<dd>{{ userControl.get(userInfoKeys.FirstName)?.value }}</dd>
													}
													@if (userControl.get(userInfoKeys.LastName)?.enabled) {
														<dt>{{ commonTK.LastNameLabel | translate }}</dt>
														<dd>{{ userControl.get(userInfoKeys.LastName)?.value }}</dd>
													}
													@if (userControl.get(userInfoKeys.Email)?.enabled) {
														<dt>{{ commonTK.EmailLabel | translate }}</dt>
														<dd>{{ userControl.get(userInfoKeys.Email)?.value }}</dd>
													}
													@if (userControl.get(userInfoKeys.Phone)?.enabled) {
														<dt>{{ commonTK.PhoneLabel | translate }}</dt>
														<dd>{{ userControl.get(userInfoKeys.Phone)?.value }}</dd>
													}
												</uf-data-display-list>
											</div>
										</div>
									}
								</div>
							}
							<!-- Desktop view -->
							@if (usersControl.value.length) {
								<div class="col-12 user-table-display">
									<table class="uf-table break-word">
										<thead>
											<tr>
												@if (usersControl.at(0).get(userInfoKeys.Username)?.enabled) {
													<th>{{ commonTK.UsernameLabel | translate }}</th>
												}
												@if (usersControl.at(0).get(userInfoKeys.FirstName)?.enabled) {
													<th>{{ commonTK.FirstNameLabel | translate }}</th>
												}
												@if (usersControl.at(0).get(userInfoKeys.LastName)?.enabled) {
													<th>{{ commonTK.LastNameLabel | translate }}</th>
												}
												@if (usersControl.at(0).get(userInfoKeys.Email)?.enabled) {
													<th>{{ commonTK.EmailLabel | translate }}</th>
												}
												@if (usersControl.at(0).get(userInfoKeys.Phone)?.enabled) {
													<th>{{ commonTK.PhoneLabel | translate }}</th>
												}
												<th></th>
											</tr>
										</thead>
										<tbody>
											@for (userControl of usersControl.controls; track userControl; let i = $index) {
												<tr>
													@if (userControl.get(userInfoKeys.Username)?.enabled) {
														<td>
															{{ userControl.get(userInfoKeys.Username)?.value }}
														</td>
													}
													@if (userControl.get(userInfoKeys.FirstName)?.enabled) {
														<td>
															{{ userControl.get(userInfoKeys.FirstName)?.value }}
														</td>
													}
													@if (userControl.get(userInfoKeys.LastName)?.enabled) {
														<td>
															{{ userControl.get(userInfoKeys.LastName)?.value }}
														</td>
													}
													@if (userControl.get(userInfoKeys.Email)?.enabled) {
														<td>
															{{ userControl.get(userInfoKeys.Email)?.value }}
														</td>
													}
													@if (userControl.get(userInfoKeys.Phone)?.enabled) {
														<td>
															{{ userControl.get(userInfoKeys.Phone)?.value }}
														</td>
													}
													<td class="pad-right">
														<div class="row space-children">
															<button [title]="sharedTermsTK.ActionEdit | translate" (click)="editUser(i)" type="button" class="uf-action tertiary small right">
																<uf-icon name="edit" />
															</button>
															<button [title]="sharedTermsTK.ActionRemove | translate" (click)="removeUser(i)" type="button" class="uf-action tertiary small">
																<uf-icon name="delete" />
															</button>
														</div>
													</td>
												</tr>
											}
										</tbody>
									</table>
								</div>
							}
							<div class="col-12 row center-all">
								<uf-error [control]="usersControl" />
								<button (click)="addUser()" type="button" class="uf-button right small">
									{{ discoverTK.UserAddAction | translate }}
								</button>
							</div>
						</div>
					</div>
				}
				@if (changePasswordOnNextLoginControl && changePasswordOnNextLoginControl.enabled) {
					<div class="col-12 uf-box">
						<div class="uf-app-bar accent medium">
							<div class="title">{{ commonTK.PasswordLabel | translate }}</div>
						</div>
						<div class="uf-grid pad">
							<uf-checkbox [label]="discoverTK.UserCreateNewPasswordLabel | translate" [control]="changePasswordOnNextLoginControl" class="col-12" />
						</div>
					</div>
				}
				@if (companyControl && companyControl.enabled) {
					<div class="col-12 uf-box">
						<div class="uf-app-bar accent medium">
							<div class="title">{{ commonTK.CompanyLabel | translate }}</div>
						</div>
						<div class="pad">
							<uf-company-input [control]="companyControl" [editableCompanies]="allowedCompanies" class="col-12 col-md-6" />
						</div>
					</div>
				}
				@if (unitsControl && unitsControl.enabled) {
					<div class="uf-box col-12 gap-top-sm">
						<div class="uf-app-bar flat medium accent">
							<div class="title">{{ commonTK.HierarchyLabel | translate }}</div>
						</div>
						<div class="uf-grid pad-bottom">
							<uf-hierarchy-input [control]="unitsControl" class="col-12" />
						</div>
					</div>
				}
				@if (rolesControl && rolesControl.enabled) {
					<div class="uf-box col-12">
						<div class="uf-app-bar medium accent">
							<div class="title">{{ commonTK.RolesLabel | translate }}</div>
						</div>
						<div class="pad">
							<uf-role-input [control]="rolesControl" [editableRoles]="allowedRoles" />
						</div>
					</div>
				}
				@if (showClaimsSection && claimsControl) {
					<div class="col-12 uf-box">
						<div class="uf-app-bar accent medium">
							<div class="title">{{ commonTK.ClaimsLabel | translate }}</div>
						</div>
						<uf-claim-editor [control]="claimsControl" [editableClaimsValues]="allowedClaimsValues" [width]="fieldWidth.Half" class="col-12 pad-sm-bottom" />
					</div>
				}
				<div class="col-12 row">
					<button (click)="back()" type="button" class="uf-button tertiary left">
						{{ sharedTermsTK.ActionCancel | translate }}
					</button>
					<button (click)="save()" type="button" class="uf-button primary">
						{{ saveButtonLabel }}
					</button>
				</div>
			</div>
		</div>
	}
	@if (uploadResult) {
		<ud-user-upload-result [uploadResult]="uploadResult" [users]="usersControl?.value" (resolved)="back(true)" />
	}
</uf-panel>
