import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Breadcrumb, ModalService, RuntimeDefinition, RuntimeDefinitionAdapter, SharedTermsTranslationKey, SingleChoiceRadioModalComponent, ToastService } from '@unifii/library/common';
import { FormConfiguration, FormSettings, getPrintFormStyleChoiceModalData } from '@unifii/library/smart-forms';
import { PrintConfig, SubmitArgs } from '@unifii/library/smart-forms/input';
import { FormData, FormStyle, UfError } from '@unifii/sdk';
import { Subscription } from 'rxjs';

import { DiscoverContext } from 'discover/discover-context';
import { DiscoverTranslationKey } from 'discover/discover.tk';
import { ErrorService } from 'shell/errors/error.service';
import { FormDataState } from 'shell/offline/forms/interfaces';
import { OfflineFileUploader } from 'shell/offline/forms/offline-file-uploader';
import { OfflineQueue } from 'shell/offline/forms/offline-queue';
import { BreadcrumbsService } from 'shell/services/breadcrumbs.service';

@Component({
	selector: 'ud-offline-form',
	templateUrl: './offline-form.html',
	styleUrls: ['./offline-form.less'],
	providers: [BreadcrumbsService],
	standalone: false,
})
export class OfflineFormComponent implements OnDestroy, OnInit {

	protected readonly discoverTK = DiscoverTranslationKey;
	protected error: Error | undefined;
	protected definition: RuntimeDefinition;
	protected formData: FormData;
	protected isDisabled = false;
	protected config: FormConfiguration;
	protected printConfig: PrintConfig | null;
	protected breadcrumbs: Breadcrumb[];

	private router = inject(Router);
	private route = inject(ActivatedRoute);
	private context = inject(DiscoverContext);
	private settings = inject<FormSettings>(FormSettings);
	private offlineQ = inject(OfflineQueue);
	private toastService = inject(ToastService);
	private errorService = inject(ErrorService);
	private translate = inject(TranslateService);
	private breadcrumbsService = inject(BreadcrumbsService);
	private modalService = inject(ModalService);
	private runtimeDefinitionAdapter = inject(RuntimeDefinitionAdapter);
	private subscriptions = new Subscription();

	ngOnInit() {
		this.config = {
			optionalCancelButtonLabel: this.translate.instant(SharedTermsTranslationKey.ActionCancel),
			optionalSubmitButtonLabel: this.translate.instant(SharedTermsTranslationKey.ActionSubmit),
		};

		this.subscriptions.add(this.route.params.subscribe((p) => {
			void this.loadData(p.key);
		}));
	}

	ngOnDestroy() {
		this.subscriptions.unsubscribe();
	}

	back() {
		void this.router.navigate(['..'], { relativeTo: this.route });
	}

	async submit(submitArgs: SubmitArgs) {
		try {
			this.error = undefined;
			const savedData = await this.offlineQ.save(submitArgs.data, this.definition);

			submitArgs.done(savedData);
			this.toastService.success(this.translate.instant(DiscoverTranslationKey.OfflineFormFeedbackStoreSuccess));
			this.back();
		} catch (error) {
			this.error = this.errorService.createError(this.errorService.offlineFormSaveErrorMessage, error);
		}
	}

	async print() {

		if (!this.settings.uploader) {
			return;
		}

		let logo: string | undefined;

		// Set project logo
		if (this.context.project?.logo?.url) {
			logo = this.context.project.logo.url;
		}

		const formStyle = (await this.modalService.openFit(SingleChoiceRadioModalComponent, getPrintFormStyleChoiceModalData(this.translate)))?.identifier as FormStyle | undefined;

		if (formStyle) {
			this.printConfig = {
				definition: JSON.parse(JSON.stringify(this.definition)),
				data: JSON.parse(JSON.stringify(this.formData)),
				logoUrl: logo,
				uploader: this.settings.uploader,
				summary: formStyle === FormStyle.Summary,
			};
		}
	}

	private async loadData(dataId: string) {

		this.error = undefined;

		try {
			const info = await this.offlineQ.getFormInfo(dataId);
			const data = await this.offlineQ.getData(dataId);

			if (info == null || data == null) {
				throw new UfError(this.errorService.offlineFormLoadErrorMessage);
			}

			if (info.status === FormDataState.Conflicted) {
				this.isDisabled = true;
			}

			this.settings.uploader = new OfflineFileUploader(this.offlineQ, dataId);
			this.definition = await this.runtimeDefinitionAdapter.transform(info.form);
			this.formData = data;

			this.breadcrumbsService.title = this.definition.label;
			this.breadcrumbs = this.breadcrumbsService.getBreadcrumbs();

		} catch (error) {
			this.error = this.errorService.createError(this.errorService.offlineFormLoadErrorMessage, error);
		}
	}

}
