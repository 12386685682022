import { Component, OnInit, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CommonTranslationKey, RuntimeDefinition, RuntimeField, SharedTermsTranslationKey, ToastService, fieldIterator } from '@unifii/library/common';
import { FormConfiguration, FormFunctions, FormSettings, amendFormData } from '@unifii/library/smart-forms';
import { SubmitArgs } from '@unifii/library/smart-forms/input';
import { DataSourceType, Dictionary, FormData, PublishedContent, ensureUfError, objectKeys } from '@unifii/sdk';

import { BucketIdentifier, FormIdentifier, LocationFieldIdentifier } from 'discover/components/sign-in/sign-in-constants';
import { SignInHistoryComponent } from 'discover/components/sign-in/sign-in-history.component';
import { ContentDataResolver } from 'shell/content/content-data-resolver';
import { FormContent } from 'shell/content/content-types';
import { editedData } from 'shell/decorator/edited-data.decorator';
import { SaveOutput, UsFormService, UsFormServiceFactoryLogic, formServiceFactory } from 'shell/form/form-service.service';
import { ShellTranslationKey } from 'shell/shell.tk';

@Component({
	selector: 'ud-sign-in',
	templateUrl: './sign-in.html',
	styleUrls: ['./sign-in.less'],
	providers: [
		{ provide: UsFormService, useFactory: formServiceFactory, deps: [UsFormServiceFactoryLogic] },
	],
	standalone: false,
})
export class SignInComponent implements OnInit, FormContent {

	@editedData protected edited: boolean;

	definition: RuntimeDefinition;
	formData?: FormData;
	title: string;

	protected readonly sharedTK = SharedTermsTranslationKey;
	protected readonly shellTK = ShellTranslationKey;
	protected readonly commonTK = CommonTranslationKey;
	protected formConfig: FormConfiguration = { optionalCancelButtonLabel: inject(TranslateService).instant(SharedTermsTranslationKey.ActionCancel) };

	private route = inject(ActivatedRoute);
	private toastService = inject(ToastService);
	private translate = inject(TranslateService);
	private router = inject(Router);
	private formService = inject(UsFormService);
	private content = inject(PublishedContent);
	private dataResolver = inject(ContentDataResolver);
	private settings = inject(FormSettings);
	private parent = inject(SignInHistoryComponent, { optional: true });

	async ngOnInit() {

		this.formService.init(BucketIdentifier);

		const { id, location } = this.route.snapshot.params;

		try {
			const { definition, formData } = await this.getFormContent(id);

			this.applyURLParamsAutofill(definition);

			this.definition = definition;
			this.formData = formData;

			if (location) {
				const locationData = await this.getLocation(location, this.definition);

				this.formData.location = locationData;
			}

			if (this.formData.id) {
				this.settings.uploader = this.formService.getFileUploader(this.formData.id);
			}
		} catch (e) {
			console.error('SignInComponent.ngOnInit', e);
			this.toastService.error(ensureUfError(e).message);
		}
	}

	protected back() {
		if (this.parent) {
			void this.router.navigate(['..'], { relativeTo: this.route });
		} else {
			void this.router.navigate(['/']);
		}
	}

	protected async save(args: SubmitArgs) {

		let savedOutput: SaveOutput;

		try {
			savedOutput = await this.formService.save(args.data, this.definition);
			args.done(savedOutput.data);

			this.edited = false;
			this.toastService.success(this.translate.instant(ShellTranslationKey.FormFeedbackSaved));

			if (this.parent != null) {
				void this.parent.update();
			}
			this.back();

		} catch (error) {
			console.error('SignInComponent.save', error);
			this.toastService.error(ensureUfError(error).message);
		}
	}

	private async getFormContent(formDataId: string): Promise<{ definition: RuntimeDefinition; formData: FormData }> {

		if (formDataId === 'new') {
			const definition = await this.dataResolver.getForm(FormIdentifier);
			const formData = amendFormData(undefined, definition);

			return { definition, formData };
		} else {
			const { definition, formData } = await this.dataResolver.getFormData(BucketIdentifier, formDataId);

			return { definition, formData: amendFormData(formData) };
		}
	}

	private async getLocation(id: string, definition: RuntimeDefinition): Promise<Dictionary<any> | undefined> {

		const locationField = this.getLocationField(definition);

		if (locationField?.sourceConfig?.type !== DataSourceType.Collection) {
			return;
		}

		const collectionIdentifier = locationField.sourceConfig.id;
		const mappingsTo = locationField.sourceConfig.mappingsTo;

		const result: Dictionary<any> = {};

		try {
			const locationItem = await this.content.getCollectionItem(collectionIdentifier, id);

			objectKeys(mappingsTo).forEach((key) => {
				const mappingFrom = mappingsTo[key]?.from;

				if (mappingFrom) {
					result[key] = locationItem[mappingFrom];
				}
			});
		} catch (e) {
			return;
		}

		return result;
	}

	private getLocationField(definition: RuntimeDefinition): RuntimeField | undefined {
		for (const { field } of fieldIterator(definition.fields)) {
			if (field.identifier === LocationFieldIdentifier) {
				return field;
			}
		}

		return undefined;
	}

	/** Override the Definition fields autofill based on the params */
	private applyURLParamsAutofill(definition: RuntimeDefinition) {

		if (this.route.snapshot.params.id !== 'new') {
			return;
		}

		const autoFillsParamMap = this.route.snapshot.paramMap;
		const autoFills = {} as Dictionary<any>;

		for (const k of autoFillsParamMap.keys.filter((key) => !['id', 'location'].includes(key) )) {
			autoFills[k] = autoFillsParamMap.get(k);
		}

		FormFunctions.amendDefinitionAutofills(definition, autoFills);
	}

}
