import { Component, HostBinding, Input, inject } from '@angular/core';

import { Config, Environment } from 'config';
import { grow } from 'shell/core/drawer-animations';
import { DrawerComponent } from 'shell/core/drawer.component';
import { MenuDrawerComponent } from 'shell/core/menu-drawer.component';
import { Mode, ShellService } from 'shell/core/shell.service';
import { NavigationService } from 'shell/nav/navigation.service';

@Component({
	selector: 'us-header',
	templateUrl: './header.html',
	styleUrls: ['./header.less'],
	animations: [grow],
	standalone: false,
})
export class HeaderComponent {

	@Input() leftDrawer: DrawerComponent | null;
	@Input() rightDrawer: DrawerComponent | null;
	@Input() menuDrawer: MenuDrawerComponent | null;
	@Input({ required: true }) title: string;

	@HostBinding('class.preview') get previewClass() {
		return this.shell.mode === Mode.Preview;
	}
	@HostBinding('class.uf-app-bar') appBar = true;

	protected readonly previewMode = Mode.Preview;
	protected disablePreviewToggle = inject<Config>(Environment).unifii.preview;
	protected shell = inject(ShellService);

	private navigationService = inject(NavigationService);

	protected back() {
		this.navigationService.back();
	}

}
