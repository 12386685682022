import { Injectable, inject } from '@angular/core';
import { Client, RequestAnalytics } from '@unifii/sdk';

import { Config } from 'config';
import { DiscoverContext } from 'discover/discover-context';

export interface RiskMatrixConfig {
	colour: string;
	risk: number;
}
export interface RiskMatrixEntry {
	id: string;
	formNumber: string;
	locations: string;
	riskEnterprise: string;
	riskType: string;
	riskSource: string;
	riskOwner: string;
	unmitigatedRating: number;
	mitigatedRating: number;
	formState: string;
}
@Injectable()
export class RiskMatrixService {

	private client = inject(Client);
	private config = inject(Config);
	private context = inject(DiscoverContext);

	getConfig(): Promise<RiskMatrixConfig[][] | undefined> {
		const configUrl = this.url(['config']);

		if (configUrl) {
			return this.client.get(configUrl) as Promise<RiskMatrixConfig[][] | undefined>;
		}

		return Promise.resolve(undefined);
	}

	getMitigatedOccurrences(filters: Record<string, unknown> = {}): Promise<number[][] | undefined> {
		const matrixUrl = this.url(['mitigated-occurrences']);

		if (matrixUrl) {
			return this.client.get(matrixUrl, { params: filters }) as Promise<number[][] | undefined>;
		}

		return Promise.resolve(undefined);
	}

	getUnmitigatedOccurrences(filters: Record<string, unknown> = {}): Promise<number[][] | undefined> {
		const matrixUrl = this.url(['unmitigated-occurrences']);

		if (matrixUrl) {
			return this.client.get(matrixUrl, { params: filters }) as Promise<number[][] | undefined>;
		}

		return Promise.resolve(undefined);
	}

	getEntries(filters: Record<string, unknown> = {}, analytics?: RequestAnalytics): Promise<RiskMatrixEntry[] | undefined> {
		const tableUrl = this.url(['entries']);

		if (tableUrl) {
			return this.client.get(tableUrl, { params: filters, analytics }) as Promise<RiskMatrixEntry[] | undefined>;
		}

		return Promise.resolve(undefined);
	}

	// TODO Split configuration guard from url composer logic
	private url(parts: string[] = []): string | undefined {

		if (!this.config.unifii.reportingApiUrl) {
			console.warn('No reportingApiUrl provided');

			return undefined;
		}

		if (this.context.project && this.config.unifii.tenant) {
			parts = parts.map((p) => encodeURIComponent(p));
			parts.unshift('risk-report');
			if (this.config.unifii.preview) {
				parts.unshift('preview');
			}
			parts.unshift(this.context.project.id);
			parts.unshift(this.config.unifii.tenant);
			parts.unshift(this.config.unifii.reportingApiUrl);

			return parts.join('/');
		}

		return undefined;
	}

}
