import { AssetProfile, Compound, ContentType, Definition, Dictionary, ImageProfile, Page, Progress, Schema, Structure, Table } from '@unifii/sdk';

export interface ContentPackage {
	info: ContentInfo;
	structure?: Structure;
	pages: Page[];
	views: { definition: Definition; compound: Compound }[];
	collections: { definition: Definition; compounds: Compound[] }[];
	forms: { definition: Definition; versions: Definition[] }[];
	buckets: Schema[];
	assets: (AssetProfile | ImageProfile)[];
	tables: Table[];
	identifiers: Dictionary<{ type: ContentType }>;
}

export interface ContentInfo {
	tenant: string;
	projectId: string;
	name: string;
	version: number;
	preview?: number;
	state: ContentState;
}

export enum ContentState {
	Previous = 'Previous',
	Active = 'Active',
	Next = 'Next',
	Delete = 'Delete',
}

export enum TenantStores {
	Assets = 'assets',
	Projects = 'projects',
	Versions = 'versions',
}

export enum ContentStores {
	Indexes = 'indexes',
	Assets = 'assets',
	// eslint-disable-next-line @typescript-eslint/no-shadow
	Structure = 'structure',
	Views = 'views',
	ViewDefinitions = 'view-definitions',
	Pages = 'pages',
	Collections = 'collections',
	Forms = 'forms',
	FormVersions = 'form-versions',
	Buckets = 'buckets',
	Tables = 'tables',
	Identifiers = 'identifiers',
}

export const parseVersionName = (versionName: string): { version: number; preview?: number } => {

	const [version, preview] = versionName.split('preview');

	return {
		version: version != null ? +version : 0,
		preview: preview ? +preview : undefined,
	};
};

export const buildOfflineAssetUrl = (profile: ImageProfile | AssetProfile): string => {

	if ((profile as ImageProfile).height) {
		return buildOfflineImageUrl(profile as ImageProfile);
	}

	return buildOfflineFileUrl(profile);

};

export const buildOfflineImageUrl = (imageProfile: ImageProfile): string => {

	const { id: name, crop } = imageProfile;

	if (!crop) {
		return `${name}`;
	}

	const { x, y, width, height } = crop;
	const cropSuffix = `(${x},${y},${width},${height})`;

	return `${name}_${cropSuffix}`;
};

export const buildOfflineFileUrl = (assetProfile: AssetProfile): string => `${assetProfile.id}`;

export const blobToArrayBuffer = (blob: Blob): Promise<{ data: ArrayBuffer; type: string }> =>

	new Promise((resolve, reject) => {

		const fileReader = new FileReader();

		fileReader.onabort = fileReader.onerror = reject;

		fileReader.onload = (e) => {
			resolve({ data: (e.target as FileReader).result as ArrayBuffer, type: blob.type });
		};

		fileReader.readAsArrayBuffer(blob);
	});

/**
 * Map subProgress to a new progress within the fromPercentage and toPercentage range
 */
export const getStepsDone = (name: string, fromPercentage: number, toPercentage: number, subProgress: Progress = { total: 1, done: 1 }): Progress => {

	const total = 1000;
	const allocatedPercentage = toPercentage - fromPercentage;
	const stepsAvailable = Math.floor(total / 100 * allocatedPercentage);
	const stepsDone = Math.floor(stepsAvailable * (subProgress.done / subProgress.total));

	return {
		id: name,
		total,
		done: Math.min(((total / 100 * fromPercentage) + stepsDone), total),
	};
};

/**
 * key used as key to in the tenantDb.Versions store
 * db used as project db name
 */
export const getProjectNames = (info: ContentInfo): { key: string; db: string } => {

	const key = `${info.projectId}-${info.name}`;

	return {
		key,
		db: `content-${info.tenant}-${key}`,
	};
};

/**
 * @param from active content
 * @param to possible update content
 * @param isPreview application mode
 * @returns null no valid update, false diff update, true full update
 *
 *  Check logic:
 *  1   TO null => null
 *  2   TO is Preview and App is Stable (not compatible) => null
 *  3   FROM is null => (Full)
 *  4   App Stable and FROM is Preview (FROM not compatible) => (Full)
 *  5   Both are Stable and TO is major => (Diff)
 *  6   Both are Preview and TO is major => (Diff)
 *  7   Different => (Full)
 *  => null
 */
// eslint-disable-next-line complexity
export const checkUpdateType = (from: ContentInfo, to: ContentInfo, isPreview: boolean): boolean | null => {

	// Condition 1
	if (!to) {
		return null;
	}

	// Condition 2
	if (to.preview && !isPreview) {
		return null;
	}

	// Condition 3
	if (!from) {
		return true;
	}

	// Condition 4
	if (!isPreview && from.preview) {
		return true;
	}

	// Condition 5
	if ((!from.preview && !to.preview) && to.version > from.version) {
		return false;
	}

	// Condition 6
	if ((from.preview && to.preview) && (
		(to.version > from.version) || (to.version === from.version && to.preview > from.preview)
	)) {
		return false;
	}

	// Condition 7
	if ((from.preview && !to.preview) || (!from.preview && to.preview)) {

		const fromCompare = Object.assign(from);

		fromCompare.preview = fromCompare.preview || Number.MAX_VALUE;

		const toCompare = Object.assign(to);

		toCompare.preview = toCompare.preview || Number.MAX_VALUE;

		if ((toCompare.version > fromCompare.version) ||
            (toCompare.version === fromCompare.version && toCompare.preview > fromCompare.preview)) {
			return false;
		}
	}

	return null;
};
