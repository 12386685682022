import { Injectable, inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AppContext, CommonTranslationKey, Context, ExpressionParser, ModalService, Repository, Scope, SharedTermsTranslationKey, astNodeToExpression } from '@unifii/library/common';
import { AstNode, Client, MeClient, MfaChallengeType, MfaErrorData, MfaStatus, OAuthCredentials, PasswordChangeRequiredErrorData, Permission, PermissionAction, ProjectInfo, TokenStorage, TokenStorageInterface, UfRequestError, UserInfo, ensureUfError, getErrorType, isDictionary, isMfaErrorData, isNotNull, isOAuthWithCode, isOAuthWithInvitationToken, isOAuthWithMfaDevice, isOAuthWithMfaDeviceSetup, isOAuthWithMfaRecoveryCode, isOAuthWithMfaSms, isOAuthWithPassword, isOAuthWithResetToken, isOAuthWithVirtualMfa, isPasswordChangeRequiredErrorData } from '@unifii/sdk';
import { StatusCodes } from 'http-status-codes';
import { Observable, Subject } from 'rxjs';

import { Config } from 'config';
import { Authentication, LogoutArgs, PermissionGrantedResult, UserPermissionInfo } from 'shell/services/authentication';
import { PermissionsFunctions } from 'shell/services/permissions-functions';
import { SavedUsersService } from 'shell/services/saved-users.service';
import { SSOService } from 'shell/services/sso.service';
import { UserAccessManager } from 'shell/services/user-access-manager';
import { Resources } from 'shell/shell-constants';
import { Resource } from 'shell/shell-model';
import { ShellTranslationKey } from 'shell/shell.tk';

import { EditedDataService } from './edited-data.service';

const UserKey = 'UfUser';
const UserPermissionsKey = 'UfUserPermissions';
const AllowedProjectsKey = 'DiscoverAllowedProjects'; // Old key used
const PermissionRefusedLog = 'PermissionRefusedLog';

@Injectable()
export class ShellAuthenticationService implements Authentication {

	private _logouts = new Subject<void>();
	private _userPermissionsInfo: UserPermissionInfo[] | null;
	private _allowedProjects: ProjectInfo[] | null;

	private repo = inject(Repository);
	private client = inject(Client);
	private meClient = inject(MeClient);
	private modalService = inject(ModalService);
	private translateService = inject(TranslateService);
	private accessManager= inject(UserAccessManager);
	private ssoService = inject(SSOService);
	private savedUsersService = inject(SavedUsersService);
	private expressionParser = inject(ExpressionParser);
	private editedDataService = inject(EditedDataService);
	private config = inject(Config);
	private tokenStorage = inject(TokenStorage) as TokenStorageInterface;

	get isAuthenticated(): boolean {
		return this.tokenStorage.token != null;
	}

	get userInfo(): UserInfo | null {
		return this.repo.load<UserInfo>(UserKey);
	}

	set userInfo(u: UserInfo | null) {
		this.repo.store(UserKey, u);
	}

	get allowedProjects(): ProjectInfo[] {

		if (this._allowedProjects == null) {

			if (!this.isAuthenticated) {
				this._allowedProjects = [];
			} else {
				let projects = this.repo.load<ProjectInfo[] | undefined>(AllowedProjectsKey) ?? [];

				// Restrict by claim 'ProjectId'
				const allowed = this.getClaimValues('ProjectId');

				if (allowed.length) {
					projects = projects.filter((p) => allowed.includes(p.id));
				}

				// Restrict by ACLs
				this._allowedProjects = projects.filter((p) => {
					// console.log(PermissionsFunctions.getProjectPath(p.id), result);
					return this.getGrantedInfoWithoutCondition(PermissionsFunctions.getProjectPath(p.id), PermissionAction.Read).granted;
				});
			}
		}

		return this._allowedProjects;
	}

	set allowedProjects(v: ProjectInfo[]) {
		this.repo.store(AllowedProjectsKey, v || []);
		this._allowedProjects = null;
	}

	get userPermissionsInfo(): UserPermissionInfo[] {

		if (this._userPermissionsInfo == null) {
			this._userPermissionsInfo = this.userPermissions.map(PermissionsFunctions.mapToUserPermissionInfo);
		}

		return this._userPermissionsInfo;
	}

	get userPermissions(): Permission[] {
		return this.repo.load(UserPermissionsKey) ?? [];
	}

	set userPermissions(p: Permission[]) {
		this.repo.store(UserPermissionsKey, p);
		this._userPermissionsInfo = null;
	}

	get canAccessPreview(): boolean {
		return !!this.userInfo?.roles?.includes('Previewer');
	}

	get isPermissionRefusedLogEnabled(): boolean {
		return this.repo.load(PermissionRefusedLog) === true;
	}

	get logouts(): Observable<void> {
		return this._logouts;
	}

	getClaimValues(type: string): string[] {
		return this.userInfo?.claims?.filter((c) => c.type === type).map((c) => c.value) ?? [];
	}

	// eslint-disable-next-line complexity
	async login(info: OAuthCredentials, rememberMe?: boolean): Promise<void> {

		try {

			let providerId: string | undefined;
			let skipMFASetup = false;
			let skipPasswordChange = false;

			if (isOAuthWithPassword(info)) {
				const response = await this.client.authenticateWithPassword(info);

				if (response.mfa_challenge && response.mfa_accepted_challenges) {
					throw this.getMfaError(MfaStatus.MfaVerifyRequired, response.mfa_challenge, response.mfa_accepted_challenges);
				}

			} else if (isOAuthWithResetToken(info)) {
				const response = await this.client.authenticateWithResetToken(info);

				if (response.mfa_challenge && response.mfa_accepted_challenges) {
					throw this.getMfaError(MfaStatus.MfaVerifyRequired, response.mfa_challenge, response.mfa_accepted_challenges);
				}

				skipPasswordChange = true;
				skipMFASetup = true;

			} else if (isOAuthWithInvitationToken(info)) {
				await this.client.authenticateWithInvitationToken(info);
				skipMFASetup = true;
			} else if (isOAuthWithMfaSms(info)) {
				await this.client.authenticateWithMfaSms(info);
				skipMFASetup = true;
			} else if (isOAuthWithCode(info)) {
				await this.client.authenticateWithCode(info);
				providerId = info.provider_id;
			} else if (isOAuthWithMfaRecoveryCode(info)) {
				await this.client.authenticateWithMfaRecoveryCode(info);
			} else if (isOAuthWithVirtualMfa(info)) {
				await this.client.authenticateWithVirtualMfa(info);
			} else if (isOAuthWithMfaDeviceSetup(info)) {
				skipMFASetup = true;
				await this.client.authenticateWithMfaDeviceSetup(info);
			} else if (isOAuthWithMfaDevice(info)) {
				await this.client.authenticateWithMfaDevice(info);
			} else {
				throw new Error('Insufficient login information');
			}

			this.userInfo = await this.meClient.get();

			const permissions = await this.meClient.getPermissions();

			this.userPermissions = PermissionsFunctions.normalizePermissions(permissions);

			// store provider type for reference when logging out
			this.ssoService.authenticatedProviderId = providerId;

			// ask to remember unifii details
			await this.savedUsersService.rememberUser(this.userInfo, rememberMe, providerId);

			if (!skipPasswordChange && this.userInfo.changePasswordOnNextLogin) {
				throw this.getPasswordChangeError();
			}

			if (!skipMFASetup && this.isMfaSetupRequired()) {
				throw this.getMfaError(MfaStatus.MfaSetupRequired);
			}

		} catch (e) {

			const error = ensureUfError(e);

			if (isMfaErrorData(error.data) || isPasswordChangeRequiredErrorData(error.data)) {
				throw error;
			}

			throw this.getAuthError(error);
		}
	}

	/* returns true if user is required to setup MFA */
	isMfaSetupRequired(): boolean {
		return !!this.config.unifii.tenantSettings?.isMfaEnforced && !!this.userInfo && !this.userInfo.isMfaOptional && !this.userInfo.isMfaEnabled;
	}

	async logout(args: LogoutArgs = {}): Promise<boolean> {

		const { askConfirmation } = args;

		if ((this.editedDataService.edited || askConfirmation) && !await this.modalService.openConfirm({
			title: this.translateService.instant(this.editedDataService.edited ? ShellTranslationKey.UnsavedChangesModalTitle : ShellTranslationKey.LogOutModalTitle),
			message: this.translateService.instant(this.editedDataService.edited ? ShellTranslationKey.UnsavedChangesModalMessage : ShellTranslationKey.LogOutModalMessage),
			cancelLabel: this.translateService.instant(SharedTermsTranslationKey.ActionCancel),
			confirmLabel: this.translateService.instant(ShellTranslationKey.LogOutModalActionConfirm),
		})) {
			return false;
		}

		this._logouts.next();
		await this.accessManager.deny(args);

		return true;
	}

	async clear() {

		if (this.ssoService.authenticatedProviderId != null) {
			await this.ssoService.logout();
		}

		// Clear token storage
		this.tokenStorage.limitedToken = null;
		this.tokenStorage.token = null;
		this.tokenStorage.expiresAt = null;
		await this.tokenStorage.setRefreshToken(null);

		// clear user info
		this.userInfo = null;
		this.allowedProjects = [];
	}

	getGrantedInfo(path: string[], action: PermissionAction, target: any, context: AppContext, field?: string): PermissionGrantedResult {
		return this.checkPermissions(path, action, { skipCondition: false, target, context, field });
	}

	getGrantedInfoWithoutCondition(path: string[], action: PermissionAction, field?: string): PermissionGrantedResult {
		return this.checkPermissions(path, action, { skipCondition: true, field });
	}

	private checkPermissions(path: string[], action: PermissionAction, options: { skipCondition: boolean; target?: any; context?: AppContext; field?: string } ): PermissionGrantedResult {

		const res = this.lookupResource(Resources, path);

		if (!res) {
			console.warn(`AuthService.checkPermissions path ${path.join('/')} not available`);
		}

		if (!res?.actions?.find((a) => a.name === action)) {
			console.warn(`AuthService.checkPermissions action ${action} not defined for path ${path.join('/')}`);
		}

		const pathString = path.join('/');

		// Permissions matching { path, action, [condition], [field] }
		const matches = this.userPermissionsInfo.filter((permissionInfo) => {
			permissionInfo.pathRegEx.lastIndex = 0;
			const matchPath = permissionInfo.pathRegEx.test(pathString);
			const matchAction = permissionInfo.actions.find((a) => a.name === action);

			if (!matchPath || !matchAction) {
				return false;
			}

			const matchedCondition = options.skipCondition || this.evaluateCondition(matchAction.condition, options.target, options.context);

			if (!matchedCondition) {
				return false;
			}

			if (!options.field) {
				return true;
			}

			return PermissionsFunctions.isFieldGranted(options.field, action, permissionInfo);
		});

		// No matching permissions, granted failed
		if (!matches.length) {
			if (this.isPermissionRefusedLogEnabled) {
				console.warn(`[Permission refused] path: "${path.join('/')}" action: "${action}"${options?.field ? ' field: "' + options.field + '"' : ''}`);
			}

			return { granted: false, condition: undefined, fieldsPermissions: {} };
		}

		// Find the action condition, favour undefined condition first
		const actions = matches.map((m) => m.actions.find((a) => a.name === action)).filter(isNotNull);
		const condition = (actions.find((a) => !a.condition) ?? actions[0])?.condition;

		return { granted: true, condition, fieldsPermissions: PermissionsFunctions.mergePermissionsFields(matches) };
	}

	private evaluateCondition(condition: AstNode | undefined, scope: Scope | undefined, context: AppContext | undefined): boolean {
		// No condition is equivalent to approved
		if (!condition) {
			return true;
		}

		const temporaryContext = Object.assign({}, context ?? {}) as Context;
		const conditionExpression = astNodeToExpression(condition, temporaryContext);

		// Wrongly configured condition is equivalent to approved
		if (!conditionExpression) {
			return true;
		}

		// Valid condition without context or scope is equivalent to rejected
		if (scope == null || context == null) {
			return false;
		}

		const result = this.expressionParser.resolve(conditionExpression, temporaryContext, scope);

		// Expression evaluation error return a null, fallback to approved
		return result === true || result == null;
	}

	private lookupResource(resource: Resource, path: string[]): Resource | undefined {

		if (!path.length) {
			return resource;
		}

		if (!resource.children) {
			return;
		}

		const nextResource = resource.children.find((c) => c.segment === path[0] || c.segment === '?');

		if (!nextResource) {
			return;
		}

		path = [...path];
		path.shift();

		return this.lookupResource(nextResource, path);
	}

	private getPasswordChangeError(): UfRequestError {

		const code = StatusCodes.UNAUTHORIZED;

		return new UfRequestError('Password Change Required', getErrorType(code), { passwordChangeRequired: true } satisfies PasswordChangeRequiredErrorData, code);
	}

	private getMfaError(mfaStatus: MfaStatus.MfaSetupRequired): UfRequestError;
	private getMfaError(mfaStatus: MfaStatus.MfaVerifyRequired, challenge: `${MfaChallengeType}`, acceptedChallenges: string): UfRequestError;
	private getMfaError(mfaStatus: MfaStatus, challenge?: `${MfaChallengeType}`, acceptedChallenges?: string): UfRequestError {

		const code = StatusCodes.UNAUTHORIZED;

		return new UfRequestError(this.translateService.instant(CommonTranslationKey.MfaRequiredLabel), getErrorType(code), { mfaStatus, challenge, acceptedChallenges } satisfies MfaErrorData, code);
	}

	/* responsible for extracting the message out of the response data rather than returning generic message */
	private getAuthError(error: UfRequestError): UfRequestError {

		if (!isDictionary(error.data)) {
			return error;
		}

		error.message = error.data.error_description || error.data.statusText || this.translateService.instant(SharedTermsTranslationKey.ErrorUnknown);

		return error;

	}

}
