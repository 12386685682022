import { Injectable, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AddActionConfig, DownloadConfig, TableContainerManager, TableInputManager, TableInputs } from '@unifii/components';
import { AngularRouterLink, ColumnDisplayDescriptor, CommonTranslationKey, CompanyIdentifiers, ContextProvider, DataPropertyDescriptor, FilterEntry, FilterValue, ModalService, SharedTermsTranslationKey, TableAction, TableConfig, ToastService, getDefaultTableConfig, getTableCustomColumnsDisplayDescriptors } from '@unifii/library/common';
import { Client, Option, PermissionAction, Table, TableSourceType, UserInfo, UserInvite, UserStatus, UsersClient, getUserStatus, isNotNull } from '@unifii/sdk';
import { UserKeys } from '@unifii/user-provisioning';
import { Subject } from 'rxjs';

import { Config } from 'config';
import { DiscoverTranslationKey } from 'discover/discover.tk';
import { TableDisplayMode } from 'shell/content/content-node.component';
import { Authentication } from 'shell/services/authentication';
import { PermissionsFunctions } from 'shell/services/permissions-functions';
import { CreateUserPath, InviteUserPath, TableSearchMinLength } from 'shell/shell-constants';
import { ShellTranslationKey } from 'shell/shell.tk';
import { TableColumnFactory } from 'shell/table/table-column-factory';
import { TableInputManagerFactory } from 'shell/table/table-input-manager-factory';
import { ModuleInfo, TablePageConfig } from 'shell/table/table-page-config';
import { UsersTableDataSource } from 'shell/table/users/users-table-datasource';

import { checkExportAllowedForRoles, checkShowCount } from '../table-functions';

@Injectable()
export class UsersTableContainerManager implements TableContainerManager<UserInfo, FilterValue, FilterEntry> {

	tableConfig: TableConfig<UserInfo>;
	tableIdentifier: string;
	showSearch: boolean;
	searchMinLength = TableSearchMinLength;
	addActionConfig?: AddActionConfig;
	downloadConfig?: DownloadConfig;
	customColumns: ColumnDisplayDescriptor[] = [];
	defaultSort: string | undefined;
	help?: string;
	reload = new Subject<void>();
	update = new Subject<TableInputs<FilterValue>>();
	updateItem = new Subject<UserInfo | { item: UserInfo; trackBy: keyof UserInfo }>();
	inputManager: TableInputManager<FilterValue, FilterEntry>;

	protected readonly discoverTK = DiscoverTranslationKey;

	private tableInputs?: TableInputs<FilterValue>;
	private showCount?: boolean;
	// dependencies
	private client: Client;
	private auth: Authentication;
	private contextProvider: ContextProvider;
	private translate: TranslateService;
	private usersClient: UsersClient;
	private modalService: ModalService;
	private toastService: ToastService;
	private router: Router;
	private route: ActivatedRoute;
	private columnFactory: TableColumnFactory;
	private moduleInfo: ModuleInfo;
	private config: Config;

	// TODO refactor this constructor
	constructor() {
		this.client = inject(Client);
		this.auth = inject(Authentication);
		this.contextProvider = inject(ContextProvider);
		this.usersClient = inject(UsersClient);
		this.translate = inject(TranslateService);
		this.modalService = inject(ModalService);
		this.toastService = inject(ToastService);
		this.router = inject(Router);
		this.route = inject(ActivatedRoute);
		this.columnFactory = inject(TableColumnFactory);
		this.moduleInfo = inject<ModuleInfo>(ModuleInfo);
		this.config = inject(Config);

		const { table, propertyDescriptors, addOptions, isSearchable } = inject(TablePageConfig);

		this.tableIdentifier = table.identifier;
		this.defaultSort = table.defaultSort;
		this.showSearch = isSearchable;
		this.help = table.help;

		this.inputManager = inject(TableInputManagerFactory).create(table, this.moduleInfo?.filter);

		if (table.hideExport !== true && checkExportAllowedForRoles(table, this.auth.userInfo?.roles ?? [])) {
			this.downloadConfig = {
				name: `${table.title}.csv`,
				getUrl: this.getDownloadUrl.bind(this),
			};
		}

		this.addActionConfig = this.createAddConfig(table, addOptions ?? []);
		this.showCount = checkShowCount(this.config, table);
		this.customColumns = getTableCustomColumnsDisplayDescriptors(table.columns);
		this.tableConfig = this.getTableConfig(table, propertyDescriptors);
	}

	createDataSource(inputs?: TableInputs<FilterValue>) {
		this.tableInputs = inputs;

		return new UsersTableDataSource(this.usersClient, this.tableIdentifier, this.inputManager, inputs, this.showCount);
	}

	addActionCallback = (identifier: string) => {
		const path = identifier === `${PermissionAction.Add}` ? CreateUserPath : InviteUserPath;

		void this.router.navigate([path], { relativeTo: this.route });
	};

	private async getDownloadUrl(): Promise<string> {
		const dataSource = this.createDataSource(this.tableInputs);
		const url = dataSource.getDownloadUrl();

		if (!url) {
			throw new Error('Failed to get download url');
		}

		const { token } = await this.client.getDownloadToken(url);

		return `${url}&_dlt=${token}`;
	}

	private getTableConfig(table: Table, propertyDescriptors: Map<string, DataPropertyDescriptor>): TableConfig<UserInfo> {
		const canInvite = this.auth.getGrantedInfoWithoutCondition(PermissionsFunctions.getUsersPath(), PermissionAction.Invite).granted;
		const canDelete = this.auth.getGrantedInfoWithoutCondition(PermissionsFunctions.getUserPath(), PermissionAction.Delete).granted;
		const usersListPermissionFields = PermissionsFunctions.mergePermissionsFields([
			this.auth.getGrantedInfoWithoutCondition(PermissionsFunctions.getUsersPath(), PermissionAction.List).fieldsPermissions,
		]);

		// Filter readable column based on the read fields permission
		const readableColumns = table.columns?.filter((columnDescriptor) => {

			if (!usersListPermissionFields.readFields) {
				// All user fields are read access allowed
				return true;
			}

			if (this.customColumns.find((customColumnDescriptor) => customColumnDescriptor.name === columnDescriptor.identifier)) {
				// Custom columns are allowed
				return true;
			}

			if (columnDescriptor.identifier === `${CompanyIdentifiers.Status}`) {
				// 'status' is available when isActive is available
				return usersListPermissionFields.readFields.includes(`${UserKeys.IsActive}`);
			}

			// standard column are visible when the identifier match a readable field
			return usersListPermissionFields.readFields.includes(columnDescriptor.identifier);
		}) ?? [];

		const columns = this.columnFactory.create(TableSourceType.Users, readableColumns, propertyDescriptors, true);
		const tableConfig = getDefaultTableConfig(columns, `table_${table.identifier}`);

		tableConfig.row = { link: (item) => this.getRowLink(item, table) };
		tableConfig.actions = this.getActions(canInvite);

		if (!table.detail && (canInvite || canDelete)) {
			tableConfig.selectable = 100;
		}

		return tableConfig;
	}

	private getRowLink(userInfo: UserInfo, table: Table): AngularRouterLink {
		if (!userInfo.id) {
			return [];
		}

		const isGranted = this.auth.getGrantedInfo(PermissionsFunctions.getUserPath(+(userInfo.id)), PermissionAction.Read, userInfo, this.contextProvider.get()).granted;

		if (isGranted) {
			if (table.detail) {

				// if table detail module
				if (this.moduleInfo) {
					return ['/', this.moduleInfo.identifier, userInfo.id, { mode: TableDisplayMode.Detail }];
				}

				return [userInfo.id, { mode: TableDisplayMode.Detail }];
			}

			// if table detail module
			if (this.moduleInfo) {
				return ['/', this.moduleInfo.identifier, userInfo.id];
			}

			return [userInfo.id];
		}

		return [];
	}

	private getActions(canInvite = false): TableAction<UserInfo>[] | undefined {
		return [{
			label: this.translate.instant(SharedTermsTranslationKey.ActionDelete),
			predicate: (row) => this.canDeleteUser(row.$implicit),
			action: (rows) => {
				const users = rows.map((r) => r.$implicit);

				void this.delete(users);
			},
		}, {
			label: this.translate.instant(DiscoverTranslationKey.UserActionResendInvite),
			predicate: (row) => this.canResendInvite(row.$implicit, canInvite),
			action: (rows) => {
				const users = rows.map((row) => row.$implicit);

				void this.reInviteUsers(users);
			},
		}];
	}

	private canDeleteUser = (userInfo: UserInfo) => {
		if (!userInfo.id) {
			return false;
		}

		// TODO reuse Discover UserFormPermissionsController.canDelete(user)
		const id = +userInfo.id;
		const path = PermissionsFunctions.getUserPath(id);

		return getUserStatus(userInfo) === UserStatus.Pending &&
            this.auth.getGrantedInfo(path, PermissionAction.Delete, userInfo, this.contextProvider.get()).granted;
	};

	private canResendInvite = (user: UserInfo, canInvite: boolean) => canInvite && getUserStatus(user) === UserStatus.Pending;

	private async delete(users: UserInfo[]): Promise<void> {
		const confirmed = await this.modalService.openConfirm({ message: this.translate.instant(DiscoverTranslationKey.UserInviteDeleteModalMessage) });

		if (!confirmed) {
			return;
		}

		try {
			for (const { id } of users) {
				await this.usersClient.delete(id + '');
			}
			this.toastService.success(this.translate.instant(ShellTranslationKey.DeleteUserModalSuccess));
			this.reload.next();
		} catch (e) {
			this.toastService.error(this.translate.instant(ShellTranslationKey.DeleteUserModalFail));
		}
	}

	private async reInviteUsers(users: UserInfo[]): Promise<void> {
		const confirmed = await this.modalService.openConfirm({ message: this.translate.instant(DiscoverTranslationKey.UserInviteModalMessage) });

		if (!confirmed) {
			return;
		}

		try {

			const invites: UserInvite[] = users.map((user) => {

				if (!user.email) {
					return;
				}

				return {
					email: user.email,
					username: user.username,
					company: user.company,
					canChangeUsername: false,
				};
			}).filter(isNotNull);

			await this.usersClient.bulkInvite(invites);
			this.toastService.success(this.translate.instant(CommonTranslationKey.FeedbackSuccess));
			this.reload.next();
		} catch (e) {
			this.toastService.error(this.translate.instant(CommonTranslationKey.FeedbackFail));
		}
	}

	private createAddConfig(table: Table, options: Option[]): AddActionConfig | undefined {
		/**
         * Hide actions if table has a detail, actions are only used when a table is configured with a direct user form
         * as it's assumed that table is created with the goal of editing users. In the future we may add configuration around allowing table actions in the configuration
         */
		if (table.detail != null || !options.length) {
			return;
		}

		return {
			label: this.translate.instant(this.discoverTK.UsersAddTitle),
			options,
		};
	}

}

