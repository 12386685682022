import { InjectionToken, ViewContainerRef } from '@angular/core';
import { ContentType, StructureNodeType } from '@unifii/sdk';

import { DiscoverContentType } from 'shell/content/content-types';
import { DashboardPageComponent } from 'shell/dashboard/dashboard-page.component';
import { TablePageConfig } from 'shell/table/table-page-config';
import { TableDetailPageComponent } from 'shell/table-detail/table-detail-page.component';
import { TableDetailComponent } from 'shell/table-detail/table-detail.component';

export interface ContentFactoryArgs {
	identifier?: string;
	id?: string | number;
	bucket?: string;
	hasRollingVersion?: boolean;
	tags?: string[];
	parentData?: TablePageConfig | TableDetailComponent | TableDetailPageComponent | DashboardPageComponent;
}

export interface ContentComponentFactory {
	create: <T>(container: ViewContainerRef, type: ContentType | StructureNodeType | DiscoverContentType, args: ContentFactoryArgs) => Promise<T>;
}

export const ContentComponentFactory = new InjectionToken<ContentComponentFactory>('ContentComponentFactory');
