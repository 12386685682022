@if (challenge) {
	@switch (challenge) {
		@case (mfaChallengeType.Virtual) {
			<uf-virtual-mfa [label]="label" [issuer]="issuer" [mfaStatus]="mfaStatus" [setVirtualMfaCode]="setVirtualMfaCode.bind(this)" [verifyToken]="verifyVirtualMfaToken.bind(this)" />
		}
		@case (mfaChallengeType.RecoveryCode) {
			<uf-recovery-codes [mfaStatus]="mfaStatus" [setRecoveryCodes]="setRecoveryCodes.bind(this)" [verifyCode]="verifyRecoveryCode.bind(this)" />
		}
		@case (mfaChallengeType.Sms) {
			<uf-sms [mfaStatus]="mfaStatus" [smsChallenges]="getSmsChallenges.bind(this)" [verifyCode]="verifySmsCode.bind(this)" />
		}
		@case (mfaChallengeType.Device) {
			<uf-web-authn [mfaStatus]="mfaStatus" [setupCredential]="setupCredential.bind(this)" [getSetupChallenge]="getSetupChallenge.bind(this)" [getVerifyChallenge]="getVerifyChallenge.bind(this)" [verifyCredential]="verifyCredential.bind(this)" />
		}
	}
} @else {
	<uf-mfa-select [mfaStatus]="mfaStatus" [mfaAcceptedChallenge]="acceptedChallenges" (selectProvider)="selectProvider($event)" />
}

<div class="col center-all">
	@if (challenge && acceptedChallenges.length > 1) {
		<button (click)="challenge = undefined" type="button" class="uf-button tertiary">
			{{ commonTK.MfaChooseMethod | translate }}
		</button>
	}

	@if (challenge !== mfaChallengeType.RecoveryCode || mfaStatus !== mfaStatuses.MfaSetupRequired) {
		<button (click)="logout()" type="button" class="uf-button tertiary">
			{{ sharedTermsTK.ActionLogout | translate }}
		</button>
	}
</div>
