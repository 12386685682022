<div class="uf-app-bar flat transparent">
	<div class="title">{{ context.project?.name }}</div>
	<button (click)="close()" type="button" class="uf-action tertiary">
		<uf-icon name="close" class="close" />
	</button>
</div>

<uf-panel class="grow">
	<div class="settings-items col">
		<ul>
			@if (contentInfo) {
				<li>
					<a [class.disabled]="!updateAvailable" (click)="update()">
						<uf-icon name="data" />
						@if (!updateAvailable) {
							<span>
								{{ discoverTK.SettingsDataVersionLabel | translate: { version: contentInfo.name } }}
							</span>
						}
						@if (updateAvailable) {
							<span>
								{{ discoverTK.SettingsUpdateToLabel | translate: { version: updateAvailable.name } }}
							</span>
						}
						@if (updateAvailable) {
							<uf-icon name="warning--solid" />
						}
					</a>
				</li>
			}
			@if (!config.flags?.hidePreviewMode && previewAvailable) {
				<li>
					<a [class.preview]="preview" (click)="toggle()">
						<uf-icon name="preview" />
						<span>{{ discoverTK.SettingsActionChangeMode | translate }}</span>
						<uf-icon [name]="preview ? 'd-checkbox--true' : 'd-checkbox'" />
					</a>
				</li>
			}
			@if (!config.flags?.hideRefreshApp) {
				<li>
					<a (click)="reload()">
						<uf-icon name="refresh" />
						<span>{{ sharedTermsTK.ActionRefresh | translate }}</span>
					</a>
				</li>
			}
			@if (showChangeProject) {
				<li>
					<a [routerLink]="['/login', 'projects']" [queryParams]="{ autofill: false }">
						<uf-icon name="change" />
						<span>{{ discoverTK.SettingsActionChangeProject | translate }}</span>
					</a>
				</li>
			}
			@if (!config.flags?.hideSyncForms) {
				<li>
					<a [routerLink]="['/offline-forms']" (click)="close()" class="has-notification">
						<uf-icon name="sync" />
						<span>{{ discoverTK.SettingsOfflineFormsLabel | translate }}</span>
						@if (offlineSyncRequired) {
							<uf-icon name="warning--solid" />
						}
					</a>
				</li>
			}
			<ng-template [usFeatureFlag]="'translations'">
				@if (availableLanguages.length > 1) {
					<li>
						<a (click)="showChangeLanguage()" class="language">
							<img src="assets/svg/language-{{ translations.currentLanguage }}.svg" />
							<span>{{ shellTK.ChangeLanguageTitle | translate }}</span>
						</a>
					</li>
				}
			</ng-template>
		</ul>

		<footer>
			<ul>
				@if (showMyProfileLink && auth.userInfo; as user) {
					<li>
						<a [routerLink]="[userProfilePath]">
							<uf-icon name="edit" />
							@if (user.firstName || user.lastName) {
								<span> {{ user.firstName }} {{ user.lastName }} </span>
							}
							@if (!user.firstName && !user.lastName) {
								<span>
									{{ user.username }}
								</span>
							}
						</a>
					</li>
				}
				@if (context.project?.privacyPolicyUrl) {
					<li>
						<a href="{{ context.project?.privacyPolicyUrl }}" ufTarget="_blank">
							<uf-icon name="privacyPolicy" />
							<span>{{ discoverTK.PrivacyPolicyLabel | translate }}</span>
						</a>
					</li>
				}
				@if (!config.flags?.hideLogout) {
					<li>
						<a (click)="auth.logout({ askConfirmation: true })">
							<uf-icon name="login" />
							<span>{{ sharedTermsTK.ActionLogout | translate }}</span>
						</a>
					</li>
				}
			</ul>
			<div class="logo-wrap row">
				<uf-data-display-list [items]="versionInfo" class="small small-label white gap-left" />
				<img src="{{ config.unifii.productLogoSecondary }}" alt="Unifii" />
			</div>
		</footer>
	</div>
</uf-panel>
