import { Component, HostBinding, OnInit, ViewChild, inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Modal, ModalData, ModalRuntime, ModalService, ProgressComponent, SharedTermsTranslationKey } from '@unifii/library/common';
import { Option } from '@unifii/sdk';

import { EditedDataService } from 'shell/services/edited-data.service';
import { TranslationsService } from 'shell/services/translations.service';
import { ShellTranslationKey } from 'shell/shell.tk';

export interface ChangeLanguageModalData {
	languages: string[];
	current: string;
	guard?: boolean;
	userAction?: boolean;
	reload?: boolean;
}

@Component({
	selector: 'us-change-language',
	templateUrl: './change-language.html',
	styleUrls: ['./change-language.less'],
	standalone: false,
})
export class ChangeLanguageComponent implements Modal<ChangeLanguageModalData, boolean>, OnInit {

	@HostBinding('class.uf-form-card') class = true;
	@ViewChild(ProgressComponent, { static: true }) private progressComponent: ProgressComponent;

	data = inject<ChangeLanguageModalData>(ModalData);
	runtime = inject<ModalRuntime<ChangeLanguageModalData, boolean>>(ModalRuntime);

	protected readonly sharedTermsTK = SharedTermsTranslationKey;
	protected readonly shellTK = ShellTranslationKey;
	protected languages: Option[];
	protected loading = false;
	protected error: string | null;

	private previousLanguage = this.data.current;
	private translations = inject(TranslationsService);
	private editedDataService = inject(EditedDataService);
	private modalService = inject(ModalService);
	private translateService = inject(TranslateService);

	ngOnInit() {
		this.languages = this.data.languages.map((language) => ({
			identifier: language,
			name: this.languageLabel(language),
		}));
	}

	close() {
		this.runtime.close();
	}

	protected async confirm() {

		if (this.editedDataService.edited && !await this.modalService.openConfirm({
			title: this.translateService.instant(ShellTranslationKey.UnsavedChangesModalTitle),
			message: this.translateService.instant(ShellTranslationKey.UnsavedChangesModalMessage),
		})) {
			this.runtime.close();

			return;
		}

		if (!this.data.current) {
			this.error = ShellTranslationKey.ValidatorLanguageRequired;

			return;
		}

		this.error = null;

		if (this.data.current !== this.previousLanguage) {
			this.progressComponent.start();

			await new Promise((resolve) => {
				// wait for progress to animate
				this.loading = true;
				setTimeout(() => { resolve(undefined); }, 500);
			});

			await this.translations.use(this.data.current, this.data.userAction, this.data.reload);

			if (this.data.reload === false) {
				this.runtime.close(true);
			} else {
				return;
			}
		}

		this.runtime.close();
	}

	private languageLabel(language: string) {
		switch (language.replace('-', '').toLowerCase()) {
			case 'en': return 'English';
			case 'it': return 'Italiano';
			case 'fr': return 'Français';
			case 'ru': return 'Русский';
			case 'ptbr': return 'Português';
			default: return language;
		}
	}

}
