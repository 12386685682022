import { Injectable, NgZone, inject } from '@angular/core';
import { Router } from '@angular/router';
import { App, URLOpenListenerEvent } from '@capacitor/app';
import { Capacitor } from '@capacitor/core';
import { Device as CapacitorDevice, OperatingSystem } from '@capacitor/device';
import { SplashScreen } from '@capacitor/splash-screen';
import { TextZoom } from '@capacitor/text-zoom';
import { DeviceInfo } from '@unifii/library/common';
import { Device } from '@unifii/sdk';
import { ReplaySubject, Subject } from 'rxjs';

import { CompleteRegistrationPath, PasswordChangePath } from 'discover/discover-constants';

/**
 * Small class that URL's that are opened
 * the app via a uri scheme
 */
export class OpenedUrl {

	constructor(public url: string | undefined) { }

	done() {
		this.url = undefined;
	}

}

@Injectable({ providedIn: 'root' })
export class DeviceService extends DeviceInfo {

	pause = new Subject<void>();
	lastPauseTS: number;
	resume = new Subject<number>();
	notificationToken: string;
	openURL = new ReplaySubject<OpenedUrl>(1);

	private zone = inject(NgZone);
	private router = inject(Router);

	// TODO ?? can this constructor be refactored ??
	constructor() {
		super();

		if (!this.isNative()) {
			return;
		}

		/* TODO investigate capacitor appStateChange
        * https://github.com/ionic-team/capacitor/issues/4597
        */

		document.addEventListener('pause', () => {
			this.zone.run(() => {
				this.updateAppState(false);
			});
		}, false);

		document.addEventListener('resume', () => {
			this.zone.run(() => {
				this.updateAppState(true);
			});
		});

		void this.setTextZoom();
	}

	/** Gets capacitor device information */
	async device(): Promise<Device | null> {

		if (!this.isNative()) {
			return null;
		}

		const deviceId = await CapacitorDevice.getId();
		const deviceInfo = await CapacitorDevice.getInfo();

		return {
			id: deviceId.identifier,
			name: deviceInfo.name,
			os: this.capacitorOS(deviceInfo.operatingSystem),
			osVersion: deviceInfo.osVersion,
			manufacturer: deviceInfo.manufacturer,
			model: deviceInfo.model,
			notificationToken: this.notificationToken,
		};
	}

	/** capacitor platform android? */
	isCapacitorAndroid() {
		return Capacitor.getPlatform() === 'android';
	}

	/** capacitor platform iOS? */
	isCapacitorIOS() {
		return Capacitor.getPlatform() === 'ios';
	}

	async hideSplashScreen() {
		if (!(this.isNative() && Capacitor.isPluginAvailable('SplashScreen'))) {
			return;
		}

		await SplashScreen.hide();
	}

	// TODO Upgrade
	initDeepLinks() {

		if (!this.isNative()) {
			return;
		}

		void App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
			this.zone.run(() => {

				const url = decodeURIComponent(event.url);
				const origin = new URL(url).origin;
				const path = url.replace(origin, '');

				// only accept external routes
				const validRoutes = new RegExp(`^(/e/|/${PasswordChangePath}|/${CompleteRegistrationPath})`);

				if (validRoutes.test(path)) {
					void this.router.navigateByUrl(path);
				}
			});
		});

	}

	private async setTextZoom() {
		if (!(this.isNative() && Capacitor.isPluginAvailable('TextZoom'))) {
			return;
		}

		await TextZoom.set({ value: 1 });
		const scale = (await TextZoom.getPreferred()).value;

		window.document.documentElement.style.fontSize = `${scale * 16}px`;
	}

	private updateAppState(isActive: boolean) {
		setTimeout(() => {
			if (!isActive) {
				this.lastPauseTS = new Date().getTime();
				this.pause.next();
			} else {
				this.resume.next(new Date().getTime() - this.lastPauseTS);
			}
		}, 0);
	}

	// fixes case sensitivity to align with unifii literals
	private capacitorOS(os: OperatingSystem) {
		return os === 'ios' ? 'iOS' : os.charAt(0).toUpperCase() + os.slice(1);
	}

}
