import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
	selector: 'us-pill',
	templateUrl: './pill.html',
	styleUrls: ['./pill.less'],
	standalone: false,
})
export class PillComponent {

	@Input() label: string | number | null | undefined;

	@Output() remove: EventEmitter<void> = new EventEmitter<void>();

	onRemove(event: Event) {
		event.stopPropagation();
		event.preventDefault();
		this.remove.emit();
	}

}
