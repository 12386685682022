@if (form) {
	<uf-panel class="container bg-gray">
		<div [class.pad-top]="!breadcrumbs.length" class="uf-container-lg bg-solid">
			@if (breadcrumbs.length) {
				<div class="uf-app-bar transparent">
					<uf-breadcrumbs [breadcrumbs]="breadcrumbs" [minimize]="true" class="small left">
						@if (edited) {
							<span class="suffix">*</span>
						}
					</uf-breadcrumbs>
				</div>
			}
			@if (!loading) {
				<div [formGroup]="form" class="uf-grid row-gap-lg medium">
					<div class="uf-box col-12">
						<div class="uf-app-bar accent">
							<div class="title">
								{{ shellTK.DetailsTitle | translate }}
							</div>
						</div>
						<div class="uf-grid pad-sides pad-top pad-bottom">
							@if (originalUserStatus === userStatus.Pending) {
								@if (emailControl?.dirty) {
									<uf-message [content]="discoverTK.UserDetailsPendingStatusUnsavedMessage | translate" icon="warningSolid" class="col-12 warning" />
								}
								<uf-message [content]="discoverTK.UserDetailsPendingStatusMessage | translate" icon="infoSolid" class="col-12 info">
									@if (canInvite && emailControl?.value) {
										<button [disabled]="emailControl?.dirty" (click)="reInvite()" type="button" class="uf-button small right">
											{{ discoverTK.UserActionResendInvite | translate }}
										</button>
									}
								</uf-message>
							}
							<uf-user-description [userInfo]="userInfo" [showOnly]="userDescriptionIdentifiers" class="col-12" />
							@if (usernameControl && usernameControl.enabled) {
								<uf-text [label]="labelDictionary[userInfoKeys.Username]" [control]="usernameControl" class="col-12 col-md-6">
									@if (usernameRequired) {
										<span class="suffix">*</span>
									}
								</uf-text>
							}
							@if (firstNameControl && firstNameControl.enabled) {
								<uf-text [label]="labelDictionary[userInfoKeys.FirstName]" [control]="firstNameControl" class="col-12 col-md-6">
									@if (firstNameRequired) {
										<span class="suffix">*</span>
									}
								</uf-text>
							}
							@if (lastNameControl && lastNameControl.enabled) {
								<uf-text [label]="labelDictionary[userInfoKeys.LastName]" [control]="lastNameControl" class="col-12 col-md-6">
									@if (lastNameRequired) {
										<span class="suffix">*</span>
									}
								</uf-text>
							}
							@if (emailControl && emailControl.enabled) {
								<uf-text [label]="labelDictionary[userInfoKeys.Email]" [control]="emailControl" class="col-12 col-md-6">
									@if (emailRequired) {
										<span class="suffix">*</span>
									}
								</uf-text>
							}
							@if (phoneControl && phoneControl.enabled) {
								<uf-text [label]="labelDictionary[userInfoKeys.Phone]" [control]="phoneControl" class="col-12 col-md-6">
									@if (phoneRequired) {
										<span class="suffix">*</span>
									}
								</uf-text>
							}
							@if (displayPhoneWarning) {
								<uf-message [content]="(isMe ? commonTK.MfaSmsMyAccountWarningMessage : commonTK.MfaSmsUserAdminWarningMessage) | translate" icon="warningSolid" class="warning gap col-12" />
							}
							@if (companyControl && companyControl.enabled) {
								<uf-company-input [label]="labelDictionary[userInfoKeys.Company]" [editableCompanies]="allowedCompanies" [control]="companyControl" class="col-12 col-md-6">
									@if (companyRequired) {
										<span class="suffix">*</span>
									}
								</uf-company-input>
							}
							@if (isTesterControl && isTesterControl.enabled) {
								<uf-checkbox [control]="isTesterControl" [label]="commonTK.IsTesterLabel | translate" class="col-12 col-md-6" />
							}
						</div>
						@if (passwordControl) {
							<div class="uf-grid pad-bottom">
								<uf-password-change [user]="userInfo" [control]="form" class="col-12" />
							</div>
						}
						@if (lastActivationReasonControl && isActiveControl && isActiveControl.enabled) {
							<div class="uf-grid pad-bottom">
								<uf-status-change [lastActivationReasonControl]="lastActivationReasonControl" [isActiveControl]="isActiveControl" [user]="userInfo" class="col-12" />
							</div>
						}
					</div>
					@if (managerInfo || managerControl?.enabled) {
						<div class="uf-box col-12">
							<div class="uf-app-bar accent">
								<div class="title">
									{{ commonTK.ManagerLabel | translate }}
								</div>
								@if (managerControl?.enabled && managerInfo) {
									<button [title]="sharedTermsTK.ActionDelete | translate" (click)="selectManager(null)" type="button" class="uf-action">
										<uf-icon name="delete" />
									</button>
								}
							</div>
							<div class="uf-grid pad">
								@if (managerControl?.enabled && !managerInfo) {
									<uf-search-result [options]="managerOptions" (searchChange)="searchUsers($event)" (valueChange)="selectManager($event)" nameProperty="_display" class="col-12 default" />
								}
								@if (managerInfo) {
									<uf-data-display-list [items]="managerInfo" class="col-12" />
								}
							</div>
						</div>
					}
					@if (mfaControl) {
						<div class="uf-box col-12">
							<div class="uf-app-bar accent">
								<div class="title">{{ commonTK.MfaLabel | translate }}</div>
								@if (mfaControl.enabled) {
									<button (click)="clearMfa()" type="button" class="uf-button small">
										{{ commonTK.MfaClearLabel | translate }}
									</button>
								}
							</div>
							<div class="uf-grid pad">
								<ul [formGroup]="mfaControl" class="uf-list uf-box flat col-12">
									@if (mfaControl.get(userMfaInfoKeys.VirtualCode)) {
										<li class="pad-left pad">
											<uf-virtual-mfa-code-input [formControlName]="userMfaInfoKeys.VirtualCode" [selfSetup]="isMe" (setupVirtualMfa)="setupVirtualMfa()" class="col-12" />
										</li>
									}
									@if (mfaControl.get(userMfaInfoKeys.IsSmsEnabled)) {
										<li class="pad-left pad">
											<uf-sms-input [formControlName]="userMfaInfoKeys.IsSmsEnabled" [selfSetup]="isMe" (setupSms)="setupSms()" class="col-12" />
										</li>
									}
									@if (mfaControl.get(userMfaInfoKeys.VirtualCode)) {
										<li class="pad-left pad">
											<uf-recovery-codes-input [formControlName]="userMfaInfoKeys.HasRecoveryCodes" [selfSetup]="isMe" (setupRecoverCodes)="setupRecoverCodes()" class="col-12" />
										</li>
									}
									@if (mfaControl.get(userMfaInfoKeys.Devices); as devicesControl) {
										@for (deviceControl of (devicesControl | asUfControlArray).controls; track deviceControl) {
											<li class="pad-left pad">
												<uf-device-input [control]="deviceControl | asUfControlGroup" (removeDevice)="removeDevice($event)" class="col-12" />
											</li>
										}
										@if (isMe) {
											<li class="pad-left pad">
												<uf-device-mfa-add (setupDevice)="setupDevice()" />
											</li>
										}
									}
								</ul>
							</div>
						</div>
					}
					@if (displayAuthProviderInfos.length) {
						<div class="uf-box col-12">
							<div class="uf-app-bar accent">
								<div class="title">
									{{ discoverTK.UserAuthProviderDetailTitle | translate }}
								</div>
							</div>
							@for (info of displayAuthProviderInfos; track info) {
								<div [class.border-bottom]="displayAuthProviderInfos.length" class="pad">
									<uf-data-display-list>
										<dt>{{ discoverTK.UserAuthProviderTypeLabel | translate }}</dt>
										<dd>{{ info.type }}</dd>
										<dt>{{ discoverTK.UserAuthProviderTenantLabel | translate }}</dt>
										<dd>{{ info.tenant }}</dd>
										@if (info.roles.length) {
											<dt>{{ commonTK.RolesLabel | translate }}</dt>
											<dd>{{ getRolesDisplays(info.roles).join(", ") }}</dd>
										}
										@if (info.claims.length) {
											<dt>{{ commonTK.ClaimsLabel | translate }}</dt>
											<dd>
												@for (claim of info.claims; track claim) {
													<span> {{ claim }}<br /> </span>
												}
											</dd>
										}
										@if (info.units.length) {
											<dt>Units</dt>
											<dd>
												@for (unit of info.units; track unit) {
													<span> {{ unit }}<br /> </span>
												}
											</dd>
										}
									</uf-data-display-list>
								</div>
							}
						</div>
					}
					@if (unitsControl && unitsControl.enabled) {
						<div class="uf-box col-12 gap-top-sm">
							<div class="uf-app-bar flat accent">
								<div class="title">{{ labelDictionary[userInfoKeys.Units] }}</div>
							</div>
							<div class="uf-grid pad-bottom">
								<uf-hierarchy-input [control]="unitsControl" [lockedUnits]="lockedConfig.units" class="col-12" />
							</div>
						</div>
					}
					@if (rolesControl) {
						<div class="uf-box col-12">
							<div class="uf-app-bar accent">
								<div class="title">{{ commonTK.RolesLabel | translate }}</div>
							</div>
							<div class="pad">
								<uf-role-input [control]="rolesControl" [lockedRoles]="lockedConfig.roles" [editableRoles]="allowedRoles" />
							</div>
						</div>
					}
					@if (claimsControl && showClaimsSection) {
						<div class="uf-box col-12">
							<div class="uf-app-bar accent">
								<div class="title">{{ commonTK.ClaimsLabel | translate }}</div>
							</div>
							<div class="uf-grid">
								@if (claimDescriptionIdentifiers.length) {
									<uf-user-description [userInfo]="userInfo" [showOnly]="claimDescriptionIdentifiers" class="col-12 pad border-bottom" />
								}
								@if (claimsControl.enabled) {
									<uf-claim-editor [control]="claimsControl" [editableClaimsValues]="allowedClaimsValues" [width]="fieldWidth.Half" class="col-12" />
								}
							</div>
						</div>
					}
				</div>
			}
			<div class="uf-form-actions gap-top">
				@if (isMe && canUpdate) {
					<button (click)="save()" type="button" class="uf-button primary right">
						{{ sharedTermsTK.ActionSave | translate }}
					</button>
				}
				@if (!isMe) {
					<button (click)="back()" type="button" class="uf-button tertiary left">
						{{ sharedTermsTK.ActionCancel | translate }}
					</button>
					@if (canDelete) {
						<button (click)="deleteUser()" type="button" class="uf-button">
							{{ sharedTermsTK.ActionDelete | translate }}
						</button>
					}
					@if (canUpdate) {
						<button (click)="save()" type="button" class="uf-button primary">
							{{ sharedTermsTK.ActionSaveAndClose | translate }}
						</button>
					}
				}
			</div>
		</div>
	</uf-panel>
}
