@if (template === templateOptions.Link) {
	<a [routerLink]="paths">
		{{ name }}
	</a>
}
@if (template === templateOptions.Button) {
	<button [routerLink]="paths" type="button" class="uf-button primary">
		{{ name }}
	</button>
}
