<uf-panel class="container">
	<!-- Emty pane required for table tool bar -->
	<div class="header-pane"></div>

	<uf-table [config]="tableConfig" [datasource]="datasource" class="content-pane accent">
		<!-- Special cell for uploading state-->
		<div *cellFor="'syc'; let info">
			<!-- Ready to Sync -->
			@if (isPending(info)) {
				<div>
					<div>{{ discoverTK.OfflineFormsWaitingSyncLabel | translate }}</div>
				</div>
			}
			<!-- Conflict Detected -->
			@if (isConflict(info)) {
				<div>
					<div>{{ discoverTK.OfflineFormsConflictLabel | translate }}</div>
				</div>
			}
			<!-- Upload completed -->
			@if (isCompleted(info)) {
				<div class="success">
					<uf-icon [title]="discoverTK.OfflineFormsActionUploadSuccess | translate" name="tick--solid" class="small" />
				</div>
			}
			<!-- Upload failed -->
			@if (isFailed(info)) {
				<div class="error-icon">
					<uf-icon [title]="discoverTK.OfflineFormsActionUploadFail | translate" name="warning--solid" class="small" />
				</div>
			}
			<!-- Uploading -->
			@if (isUploading(info)) {
				<div class="uploading">
					<div>{{ progress(info) | percent: ".0-0" }}</div>
					<uf-icon (click)="cancel(info)" name="close" class="small" />
				</div>
			}
		</div>
	</uf-table>
</uf-panel>
