import { Injectable, inject } from '@angular/core';
import { AppContext } from '@unifii/library/common';

import { DiscoverContext } from 'discover/discover-context';
import { ShellContextProvider } from 'shell/services/shell-context-provider';

/** ContextProvider is responsible for providing context for expression parsing */
@Injectable()
export class DiscoverContextProvider extends ShellContextProvider {

	private context = inject(DiscoverContext);

	override get(): AppContext {
		let appContext = super.get();

		// Add company to context if available
		if (this.context.company) {
			appContext = { ...appContext, company: this.context.company };
		}

		return appContext;
	}

}
