import { DataPropertyDescriptor, DataSourceDisplayTo, FormDefinitionMetadataIdentifiers, TableConfigColumn, isIdentifiersPathExpression } from '@unifii/library/common';
import { ColumnDescriptor, DataSourceType, FieldType, Table } from '@unifii/sdk';

import { Config } from 'config';

/**
 * Check if the Table allows 'export' for any of the roles in input
 * @param table
 * @param roles of the user in context
 */
export const checkExportAllowedForRoles = (table: Table, roles: string[]): boolean => {
	if (table.exportVisibleTo && table.exportVisibleTo.length > 0) {
		return roles.some((role) => table.exportVisibleTo?.includes(role));
	}

	return true;
};

/**
 * Check if the showCount feature is enable for this table
 * @param config
 * @param table
 * @returns
 */
export const checkShowCount = (config: Config, table: Table): boolean =>
	!!(config.unifii.tenantSettings?.features.indexing && table.showCount);

/**
 * Extract the list of identifiers to be used in the RQL include operator (current implementation works only with FormData)
 * @param columns - the table definition columns
 * @param tableConfigColumns - the table runtime config columns
 * @param propertyDescriptors - map of DataPropertyDescriptors
 * @returns data values' identifiers needed to display the table
 */
export const getTableRQLIncludeIdentifiers = <T>(columns: ColumnDescriptor[] | undefined, tableConfigColumns: TableConfigColumn<T>[] | undefined, propertyDescriptors: Map<string, DataPropertyDescriptor>): string[] => {

	const visibleColumnsNames = tableConfigColumns?.filter((column) => !column.hidden).map((column) => column.name) ?? [];

	const includeIdentifiers = (columns ?? []).filter((column) =>
		visibleColumnsNames.includes(column.identifier) &&
        isIdentifiersPathExpression(column.identifier),
	).map(({ identifier }) => {
		const property = propertyDescriptors.get(identifier);

		if (!property) {
			return [identifier];
		}

		if (property.type === FieldType.Repeat) {
			return [];
		}

		if (property.sourceConfig) {
			// unfortunately _createdBy and _lastModifiedBy are faked as SourceConfig in DDE to provide advanced filter support
			if (property.sourceConfig.type === DataSourceType.Users && [`${FormDefinitionMetadataIdentifiers.CreatedBy}`, `${FormDefinitionMetadataIdentifiers.LastModifiedBy}`].includes(identifier)) {
				return [identifier];
			}
			// DS mapped Company
			if (property.sourceConfig.type === DataSourceType.Company && property.type === FieldType.Text) {
				return [`${identifier}.name`];
			}
			// DS mapped Manager
			if (property.sourceConfig.type === DataSourceType.Users && property.type === FieldType.Text) {
				return [`${identifier}.firstName`, `${identifier}.lastName`];
			}

			// map DS field identifier to the mapped output identifiers `_display`
			return [`${identifier}.${DataSourceDisplayTo}`];
		}

		switch (property.type) {
			case FieldType.Address:
				return [
					`${identifier}.address1`,
					`${identifier}.address2`,
					`${identifier}.suburb`,
					`${identifier}.state`,
					`${identifier}.postcode`,
					`${identifier}.country`,
					`${identifier}.lat`,
					`${identifier}.lng`,
				];
			case FieldType.GeoLocation:
				return [
					`${identifier}.lat`,
					`${identifier}.lng`,
				];
			case FieldType.ZonedDateTime:
				return [
					`${identifier}.value`,
					`${identifier}.tz`,
				];
			case FieldType.Cost:
				return [
					`${identifier}.amount`,
					`${identifier}.currency`,
				];
			case FieldType.Link:
				return [
					`${identifier}.label`,
					`${identifier}.type`,
				];
			case FieldType.Hierarchy:
				return [
					`${identifier}.label`,
				];
			default:
				return [identifier];
		}
	})
		.flat(1);

	return [...new Set(includeIdentifiers)];
};
