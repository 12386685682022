/* eslint-disable @typescript-eslint/member-ordering */
import { Injectable, inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CommonTranslationKey, SharedTermsTranslationKey } from '@unifii/library/common';
import { ErrorType, ensureUfRequestError } from '@unifii/sdk';

import { DiscoverTranslationKey } from 'discover/discover.tk';
import { ShellTranslationKey } from 'shell/shell.tk';

import { AppError, LoadError, NotFoundError, SaveError } from './errors';

@Injectable({ providedIn: 'root' })
export class ErrorService {

	private translate = inject(TranslateService);
    
	// Auth errors
	readonly unknownErrorMessage = this.translate.instant(SharedTermsTranslationKey.ErrorUnknown) as string;
	readonly unhandledErrorMessage = this.translate.instant(ShellTranslationKey.ErrorUnhandled) as string;
	readonly unauthorizedRequestErrorMessage = this.translate.instant(ShellTranslationKey.ErrorRequestUnauthorized) as string;
	readonly forbiddenRequestErrorMessage = this.translate.instant(ShellTranslationKey.ErrorRequestForbidden) as string;
	readonly notFoundRequestErrorMessage = this.translate.instant(ShellTranslationKey.ErrorRequestNotFound) as string;
	// Login errors
	readonly noAllowedProjectsErrorMessage = this.translate.instant(ShellTranslationKey.ErrorNoAllowedProjects) as string;
	readonly invalidClientErrorMessage = this.translate.instant(ShellTranslationKey.ErrorInvalidClient) as string;
	readonly invalidUsernameOrPasswordErrorMessage = this.translate.instant(CommonTranslationKey.LoginErrorInvalidUsernameOrPassword) as string;
	// Password errors
	readonly passwordChangeInvalidLinkErrorMessage = this.translate.instant(DiscoverTranslationKey.PasswordChangeErrorInvalidLink) as string;
	readonly passwordChangeGenericErrorMessage = this.translate.instant(DiscoverTranslationKey.PasswordChangeErrorChangePassword) as string;
	readonly passwordChangeInvalidPasswordErrorMessage = this.translate.instant(DiscoverTranslationKey.PasswordChangeErrorInvalidPassword) as string;
	readonly passwordChangeCannotBeSameErrorMessage = this.translate.instant(DiscoverTranslationKey.PasswordChangeErrorCannotBeSame) as string;
	// Offline errors
	readonly offlineFormSaveErrorMessage = this.translate.instant(DiscoverTranslationKey.OfflineFormErrorSave) as string;
	readonly offlineFormLoadErrorMessage = this.translate.instant(DiscoverTranslationKey.OfflineFormErrorLoad) as string;

	createError(message: string, originalError?: unknown): AppError {
		return ensureUfRequestError(originalError, message);
	}

	createNotFoundError(argument: string, originalError?: unknown): NotFoundError {

		const error = ensureUfRequestError(originalError, this.translate.instant(ShellTranslationKey.ErrorNotFound, { argument }) as string);

		error.type = error.type === ErrorType.Unknown ? ErrorType.NotFound : error.type;
        
		return error;
	}

	createLoadError(argument: string, originalError?: unknown): LoadError {

		const message = this.translate.instant(ShellTranslationKey.ErrorLoad, { argument }) as string;
		const error = ensureUfRequestError(originalError, message);

		if (error.type === ErrorType.Unknown) {
			return new LoadError(message);
		}

		return error;
	}

	createSaveError(argument: string, originalError?: unknown): SaveError {

		const message = this.translate.instant(ShellTranslationKey.ErrorSave, { argument });
		const error = ensureUfRequestError(originalError, message);

		if (error.type === ErrorType.Unknown) {
			return new SaveError(message);
		}

		return error;
	}

	// TODO delete wrapper and replace references for ensureUfRequestError directly
	mergeError(originalError: Error, alternativeMessage: string): AppError {
		return ensureUfRequestError(originalError, alternativeMessage);
	}

}
