import { Inject } from '@angular/core';
import { FilterEntry, FilterSerializer, FilterType, FilterValue } from '@unifii/library/common';
import { Dictionary } from '@unifii/sdk';

enum RangeSuffix {
	From = 'Start',
	To = 'End',
}

export class ReportFilterSerializer implements FilterSerializer<FilterValue, FilterEntry> {

	constructor(@Inject(FilterSerializer) private filterSerializer: FilterSerializer<FilterValue, FilterEntry>) { }

	serialize(value: FilterValue, entry: FilterEntry): string | null {
		return this.filterSerializer.serialize(value, entry);
	}

	deserialize(value: string | null, entry: FilterEntry): Promise<FilterValue> {
		return this.filterSerializer.deserialize(value, entry);
	}

	serializeAll(values: Dictionary<FilterValue>, entries: FilterEntry[]): Dictionary<string | null> {
		const serialized: Dictionary<string | null> = {};

		for (const entry of entries) {
			const deserializedValue = values[entry.identifier];

			const value = deserializedValue ? this.filterSerializer.serialize(deserializedValue, entry) : undefined;

			if (value == null) {
				continue;
			}

			if (this.isRange(entry.type)) {
				const [from, to] = value.trim().split(',').map((p) => p.trim());

				serialized[entry.identifier + RangeSuffix.From] = from ?? null;
				serialized[entry.identifier + RangeSuffix.To] = to ?? null;
			} else {
				serialized[entry.identifier] = value;
			}
		}

		return serialized;
	}

	async deserializeAll(values: Dictionary<string | null>, entries: FilterEntry[]): Promise<Dictionary<FilterValue>> {
		const deserialized: Dictionary<FilterValue> = {};

		for (const entry of entries) {
			if (this.isRange(entry.type)) {
				const from = values[entry.identifier + RangeSuffix.From];
				const to = values[entry.identifier + RangeSuffix.To];

				if (from != null || to != null) {
					deserialized[entry.identifier] = await this.filterSerializer.deserialize([from ?? '', to ?? ''].join(','), entry);
				}
			} else {
				const serializedValue = values[entry.identifier];
				const value = serializedValue ? await this.filterSerializer.deserialize(serializedValue, entry) : null;

				if (value != null) {
					deserialized[entry.identifier] = value;
				}
			}
		}

		return deserialized;
	}

	private isRange(type: FilterType): boolean {
		return [FilterType.DateRange, FilterType.TimeRange, FilterType.NumberRange, FilterType.DatetimeRange, FilterType.ZonedDatetimeRange].includes(type);
	}

}
