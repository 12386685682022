<div ufmasterdetail class="container">
	@if (tableConfig && datasource) {
		<uf-panel [bottomThreshold]="150" [class.panelWidth]="showFilters" (scrollBottom)="pageTable.load()" class="container">
			<div class="wrapper">
				<div [class.disable-scroll]="showFilters" class="matrix-panel">
					<div class="header-pane uf-app-bar flat stretch">
						<uf-breadcrumbs [breadcrumbs]="breadcrumbs" />
					</div>
					<div class="uf-grid condensed column-gap-sm pad-sides">
						<div class="col-12">
							<uf-filter-display [manager]="filterManager" [value]="filterValues" (valueChange)="filtersChange()" />
						</div>
						@for (diagram of gridData; track diagram) {
							<div class="col-lg-6">
								<h2>{{ diagram.title }}</h2>
								<div class="matrix-container">
									@if (config && diagram.data && diagram.labels) {
										<div class="matrix">
											<div [style.flex]="0.5" class="matrix-y-axis-label">
												<div [style.flex]="gridSize" class="label">
													<span>{{ diagram.yAxisLabel }}</span>
												</div>
												<div [style.flex]="2"></div>
											</div>
											<div [style.flex]="gridSize + 1.5" class="matrix-body">
												@for (row of diagram.data; track row; let i = $index) {
													<div [style.flex]="1" class="matrix-row">
														<div [style.flex]="1.5" class="label">
															<span>{{ diagram.labels.columns[i] }}</span>
														</div>
														@for (column of row; track column; let j = $index) {
															<div [style.flex]="1" [style.backgroundColor]="colorsLookup[(config[i] ?? [])[j]?.colour ?? ''] || (config[i] ?? [])[j]?.colour" class="cell">
																<span class="small-number">{{ (config[i] ?? [])[j]?.risk }}</span>
																<span class="large-number">{{ column || "" }}</span>
															</div>
														}
													</div>
												}
												<div [style.flex]="1.5" class="matrix-row labels">
													<div [style.flex]="1.5"></div>
													@for (label of diagram.labels.rows; track label) {
														<div [style.flex]="1" class="label vertical">
															<span>{{ label }}</span>
														</div>
													}
												</div>
												<div [style.flex]="0.5" class="matrix-x-axis-label">
													<div [style.flex]="2"></div>
													<div [style.flex]="gridSize" class="label">
														<span>{{ diagram.xAxisLabel }}</span>
													</div>
												</div>
											</div>
										</div>
									}
								</div>
							</div>
						}
						<div class="col-12 pad-top">
							<div class="risk-matrix-table-buttons">
								<button (click)="downloadTableAsCsv()" type="button" class="uf-action tertiary">
									<uf-icon name="download" />
								</button>
							</div>
							<uf-table #pageTable [config]="tableConfig" [datasource]="datasource" [sort]="sort" class="accent" />
						</div>
					</div>
				</div>
			</div>
		</uf-panel>
	}

	<uf-drawer [showHandle]="true" [(open)]="showFilters" handleIcon="filter" class="share-space">
		@if (filterManager) {
			<uf-filter-inputs [manager]="filterManager" [value]="filterValues" (valueChange)="filtersChange()" (onClose)="showFilters = false" />
		}
	</uf-drawer>
</div>
