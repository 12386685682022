import { Injectable, Type } from '@angular/core';
import { ContentField } from '@unifii/library/smart-forms';
import { FieldType } from '@unifii/sdk';

import { UsPageComponentRegistry } from 'shell/content/page-component-registry';

import { FileListDisplayComponent } from './file-list.component';

@Injectable()
export class UdPageComponentRegistry extends UsPageComponentRegistry {

	override get(type: FieldType): Type<ContentField> | null {

		if (type === FieldType.FileList) {
			return FileListDisplayComponent;
		}

		return super.get(type);
	}

}
