import { Injectable, inject } from '@angular/core';
import { DataDisplayDataSeedComponent, DataDisplayInfo, DataDisplayRouterLinkValue, DataDisplayService, UfDataDisplayService, getContentLinkLabel } from '@unifii/library/common';
import { DataType, LinkContentType, isContentLinkFormData } from '@unifii/sdk';

import { DiscoverContext } from 'discover/discover-context';
import { DataDisplayPdfViewerValue } from 'shell/common/data-display/data-display-model';
import { injectFormDataRouterLink } from 'shell/functions/display-functions';

import { Authentication } from './authentication';

@Injectable()
export class ShellDataDisplayService extends UfDataDisplayService implements DataDisplayService {

	private auth = inject(Authentication);
	private context = inject(DiscoverContext);

	override displayAsDataDisplayValue(data: unknown, info?: DataDisplayInfo): unknown {

		// Content Link
		if (info?.type === DataType.Link && isContentLinkFormData(data)) {
			const label = getContentLinkLabel(data, this.translateService, info.hideType);

			switch (data.type) {
				case LinkContentType.Form:
				case LinkContentType.Page:
					return { label, routerLink: ['/', data.id] } satisfies DataDisplayRouterLinkValue;
				case LinkContentType.Attachment:
					if (data.useViewer) {
						return { label, pdfAttachmentId: data.id } satisfies DataDisplayPdfViewerValue;
					}
			}
		}

		const result = super.displayAsDataDisplayValue(data, info);

		if (DataDisplayDataSeedComponent.isDataAccepted(result)) {
			injectFormDataRouterLink(result, this.auth, this.context.project?.id);
		}

		return result;

	}

}
