import { Injectable } from '@angular/core';
import { UfDataDisplayComponentRegistry } from '@unifii/library/common';

import { DataDisplayPdfViewerComponent } from './data-display-pdf-viewer.component';

@Injectable()
export class ShellDataDisplayComponentRegistry extends UfDataDisplayComponentRegistry {

    constructor() {
        super();
        this.components.unshift(DataDisplayPdfViewerComponent);
    }

}
