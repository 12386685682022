<div class="uf-app-bar">
	<h3>{{ discoverTK.UpdateRequiredTitle | translate }}</h3>
</div>
<div class="uf-grid pad gaps">
	<div class="col-12">
		<p [innerHTML]="discoverTK.UpdateRequiredMessage | translate: { version: data.latestVersion }"></p>
	</div>
</div>
<div class="uf-form-actions">
	<a [href]="data.webLink" class="uf-button tertiary">
		{{ discoverTK.UpdateUseWebsiteLabel | translate }}
	</a>
	<a [href]="data.appStoreLink" class="uf-button primary">
		{{ sharedTermsTK.UpdateLabel | translate }}
	</a>
</div>
