<div ufMasterDetail class="table-page bg-solid">
	<uf-table-container (addItem)="add($event)" (dataSourceChange)="updateCount($event)" ufSyncRoute class="accent list-md">
		<ng-container title>
			@if (breadcrumbs.length > 1) {
				<uf-breadcrumbs [breadcrumbs]="breadcrumbs" [minimize]="true" class="small left" />
			}
		</ng-container>
		@if (showRecordCount) {
			<div info class="title">
				@if (recordCount != null) {
					{{ recordCount }} Results
				} @else {
					{{ discoverTK.RecordCountErrorMessage | translate }}
				}
			</div>
		}
	</uf-table-container>
</div>
<router-outlet />
