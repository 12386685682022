import { Injectable, inject } from '@angular/core';
import { DownloadConfig, TableContainerManager, TableInputManager, TableInputs } from '@unifii/components';
import { AngularRouterLink, ColumnDisplayDescriptor, ContextProvider, DataPropertyDescriptor, FilterEntry, FilterValue, TableConfig, getDefaultTableConfig, getTableCustomColumnsDisplayDescriptors } from '@unifii/library/common';
import { CompaniesClient, Company, PermissionAction, Table, TableSourceType } from '@unifii/sdk';
import { Subject } from 'rxjs';

import { TableDisplayMode } from 'shell/content/content-node.component';
import { Authentication } from 'shell/services/authentication';
import { PermissionsFunctions } from 'shell/services/permissions-functions';
import { TableSearchMinLength } from 'shell/shell-constants';
import { CompanyTableDataSource } from 'shell/table/companies/company-table-datasource';
import { TableColumnFactory } from 'shell/table/table-column-factory';
import { TableInputManagerFactory } from 'shell/table/table-input-manager-factory';
import { ModuleInfo, TablePageConfig } from 'shell/table/table-page-config';

import { COMPANY_REQUIRED_FIELDS } from './company-constants';

@Injectable()
export class CompanyTableContainerManager implements TableContainerManager<Company, FilterValue, FilterEntry> {

	tableConfig: TableConfig<Company>;
	tableIdentifier: string;
	showSearch: boolean;
	searchMinLength = TableSearchMinLength;
	addActionConfig: boolean;
	defaultSort: string | undefined;
	downloadConfig?: DownloadConfig | undefined;
	customColumns: ColumnDisplayDescriptor[] = [];
	help?: string;
	reload = new Subject<void>();
	update = new Subject<TableInputs<FilterValue>>();
	updateItem = new Subject<Company | { item: Company; trackBy: keyof Company }>();
	inputManager: TableInputManager<FilterValue, FilterEntry>;

	private auth: Authentication;
	private companiesClient: CompaniesClient;
	private columnFactory: TableColumnFactory;
	private contextProvider: ContextProvider;
	private moduleInfo: ModuleInfo;

	// TODO drop the constructor?
	constructor() {
		this.auth = inject(Authentication);
		this.companiesClient = inject(CompaniesClient);
		this.columnFactory = inject(TableColumnFactory);
		this.moduleInfo = inject<ModuleInfo>(ModuleInfo);
		this.contextProvider = inject(ContextProvider);

		const { table, propertyDescriptors, isSearchable } = inject(TablePageConfig);

		this.tableIdentifier = table.identifier;
		this.defaultSort = table.defaultSort;
		this.showSearch = isSearchable;
		this.help = table.help;

		this.inputManager = inject(TableInputManagerFactory).create(table, this.moduleInfo?.filter);

		this.setPermissionControls(table);
		this.customColumns = getTableCustomColumnsDisplayDescriptors(table.columns);
		this.tableConfig = this.getTableConfig(table, propertyDescriptors);
	}

	createDataSource(inputs?: TableInputs<FilterValue>) {
		return new CompanyTableDataSource(this.companiesClient, this.tableIdentifier, this.inputManager, inputs);
	}

	private getTableConfig(table: Table, propertyDescriptors: Map<string, DataPropertyDescriptor>): TableConfig<Company> {
		const columns = this.columnFactory.create(TableSourceType.Company, table.columns ?? [], propertyDescriptors, true);
		const tableConfig = getDefaultTableConfig(columns, `table_${table.identifier}`);

		tableConfig.row = { link: (item) => this.getRowLink(item, table) };

		return tableConfig;
	}

	private setPermissionControls(config: Table) {
		if (config.detail) {
			return;
		}

		const addAction = PermissionAction.Add;
		const addGrant = this.auth.getGrantedInfoWithoutCondition(PermissionsFunctions.getCompaniesPath(), addAction);

		this.addActionConfig = addGrant.granted &&
            COMPANY_REQUIRED_FIELDS[addAction].every((field) => PermissionsFunctions.isFieldGranted(field, addAction, addGrant.fieldsPermissions));
	}

	private getRowLink(company: Company, table: Table): AngularRouterLink {
		const isGranted = this.auth.getGrantedInfo(PermissionsFunctions.getCompanyPath(company.id), PermissionAction.Read, company, this.contextProvider.get()).granted;

		if (isGranted) {
			if (table.detail) {

				// if table detail module
				if (this.moduleInfo) {
					return ['/', this.moduleInfo.identifier, company.id, { mode: TableDisplayMode.Detail }];
				}

				return [company.id, { mode: TableDisplayMode.Detail }];
			}

			// if table detail module
			if (this.moduleInfo) {
				return ['/', this.moduleInfo.identifier, company.id];
			}

			return [company.id];
		}

		return [];
	}

}

