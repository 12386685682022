import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { TableContainerManager } from '@unifii/components';
import { CommonTranslationKey, FilterEntry, FilterValue, ModalService, SharedTermsTranslationKey, ToastService, UfControl, UfControlGroup } from '@unifii/library/common';
import { FieldWidth, PermissionAction, UserCreateResult, UserInfo, UsersBulkAddAdditionalData, UsersBulkInviteAdditionalData, UsersClient, ensureError } from '@unifii/sdk';
import { ClaimWithValues, UserControlService, UserCsvUploadType, UserFieldLabelService, UserFormContext, UserFormResourceType, UserKeys, UserProvisioningTranslationKey, UsersCreateUploadFormControl, UsersInputKeys, UsersInviteUploadFormControl } from '@unifii/user-provisioning';
import { Subscription } from 'rxjs';

import { DiscoverTranslationKey } from 'discover/discover.tk';
import { editedData } from 'shell/decorator/edited-data.decorator';

import { UserUploadProgressModalComponent } from './user-upload-progress-modal.component';

@Component({
	selector: 'ud-user-upload-csv',
	templateUrl: './user-upload-csv.html',
	styleUrls: ['./user-create.less', './user-management.less'],
	standalone: false,
})

export class UserUploadCsvComponent implements OnInit, OnDestroy {

	@editedData protected edited: boolean;

	protected readonly sharedTermsTK = SharedTermsTranslationKey;
	protected readonly commonTK = CommonTranslationKey;
	protected readonly discoverTK = DiscoverTranslationKey;
	protected readonly userProvisioningTK = UserProvisioningTranslationKey;
	protected readonly userInfoControlKeys = UserKeys;
	protected readonly userInputControlKey = UsersInputKeys;
	protected readonly userCsvUploadType = UserCsvUploadType;
	protected readonly fieldWidth = FieldWidth;
	protected readonly labelDictionary = inject(UserFieldLabelService).labelDictionary;

	protected formController: UsersInviteUploadFormControl | UsersCreateUploadFormControl;
	protected form: UfControlGroup;
	protected fileControl?: UfControl;
	protected claimsControl?: UfControlGroup;
	protected unitsControl?: UfControl;
	protected rolesControl?: UfControl;
	protected companyControl?: UfControl;
	protected changePasswordOnNextLoginControl?: UfControl;
	protected canChangeUsernameControl?: UfControl;

	protected error: Error | undefined;
	protected uploading: boolean;
	protected uploadResult?: UserCreateResult;
	protected uploadType: UserCsvUploadType;
	protected abortController: AbortController = new AbortController();
	protected saveButtonLabel: string;
	protected csvTemplatePath: string;
	protected allowedRoles?: string[];
	protected allowedCompanies?: string[];
	protected allowedClaimsValues: ClaimWithValues[];

	private router = inject(Router);
	private route = inject(ActivatedRoute);
	private usersClient = inject(UsersClient);
	private translate = inject(TranslateService);
	private toastService = inject(ToastService);
	private modalService = inject(ModalService);
	private context = inject(UserFormContext);
	private userControlService = inject(UserControlService);
	private inviteFormController = inject(UsersInviteUploadFormControl);
	private createFormController = inject(UsersCreateUploadFormControl);
	private tableManager = inject<TableContainerManager<UserInfo, FilterValue, FilterEntry>>(TableContainerManager, { optional: true });
	private subscriptions = new Subscription();

	async ngOnInit() {
		this.context.set(UserFormResourceType.User, this.route.snapshot.data.action);
		this.uploadType = this.route.snapshot.data.uploadType;

		if (this.context.action === PermissionAction.Invite) {
			this.formController = this.inviteFormController;
			this.saveButtonLabel = this.translate.instant(SharedTermsTranslationKey.ActionInvite);
			this.csvTemplatePath = this.uploadType === UserCsvUploadType.BASIC ? 'assets/csv/invite basic.csv' : 'assets/csv/invite advanced.csv';
		} else {
			this.formController = this.createFormController;
			this.saveButtonLabel = this.translate.instant(SharedTermsTranslationKey.ActionCreate);
			this.csvTemplatePath = this.uploadType === UserCsvUploadType.BASIC ? 'assets/csv/create basic.csv' : 'assets/csv/create advanced.csv';
		}

		this.allowedRoles = this.userControlService.getEditableRoles(undefined, undefined);
		this.allowedCompanies = this.userControlService.getEditableCompanies(undefined);
		this.allowedClaimsValues = this.userControlService.getEditableClaimsValues(undefined);
		const userInfoDefaults = await this.userControlService.getUserDefaultValueByConditions();

		this.form = this.formController.buildRoot(this.uploadType, userInfoDefaults);
		this.fileControl = this.form.get(UsersInputKeys.File) as UfControl | undefined;
		this.companyControl = this.form.get(UserKeys.Company) as UfControl | undefined;
		this.unitsControl = this.form.get(UserKeys.Units) as UfControl | undefined;
		this.rolesControl = this.form.get(UserKeys.Roles) as UfControl | undefined;
		this.claimsControl = this.form.get(UserKeys.Claims) as UfControlGroup | undefined;
		this.changePasswordOnNextLoginControl = this.form.get(UserKeys.ChangePasswordOnNextLogin) as UfControl | undefined;
		this.canChangeUsernameControl = this.form.get(UserKeys.CanChangeUsername) as UfControl | undefined;

		if (this.fileControl) {
			this.subscriptions.add(this.fileControl.valueChanges.subscribe((v) => {
				if (v.length > 0) {
					this.fileControl?.disable({ emitEvent: false });
				} else {
					this.fileControl?.enable({ emitEvent: false });
				}
			}));
		}

		this.subscriptions.add(this.form.valueChanges.subscribe(() => { this.edited = true; }));
	}

	ngOnDestroy() {
		this.subscriptions.unsubscribe();
		this.abortController.abort();
	}

	protected async upload() {
		if (this.uploading) {
			return;
		}

		this.form.setSubmitted();
		if (this.form.invalid) {
			return;
		}

		const data = this.formController.toDataModel(this.form, this.uploadType);
		let uploadModalTimeout: NodeJS.Timeout | undefined;

		try {
			uploadModalTimeout = setTimeout(() => void this.modalService.openFit(UserUploadProgressModalComponent, undefined, { guard: true }).then((result) => {
				if (!result) {
					this.abortController.abort();
				}
			}), 1000); // Only show if it's taking longer than 1 second

			this.uploading = true;

			const responseData = this.context.action === PermissionAction.Add ?
				await this.usersClient.bulkAddCsv(data.file, data.additionalData as UsersBulkAddAdditionalData, { signal: this.abortController.signal }) :
				await this.usersClient.bulkInviteCsv(data.file, data.additionalData as UsersBulkInviteAdditionalData, { signal: this.abortController.signal });

			this.uploading = false;

			clearTimeout(uploadModalTimeout);
			this.modalService.closeLatest();

			if (!responseData.errors?.length) {
				this.edited = false;
				if (this.context.action === PermissionAction.Add) {
					this.toastService.success(this.translate.instant(DiscoverTranslationKey.UsersCreateSuccessToast, { count: responseData.successCount }));
				} else {
					this.toastService.success(this.translate.instant(DiscoverTranslationKey.UsersInviteSuccessToast, { count: responseData.successCount }));
				}
				this.tableManager?.reload?.next();
				this.back();
			} else {
				this.uploadResult = responseData;
				if (this.context.action === PermissionAction.Add) {
					this.toastService.warning(this.translate.instant(DiscoverTranslationKey.UsersCreateFailedToast, { count: responseData.successCount, total: responseData.errors.length + responseData.successCount }));
				} else {
					this.toastService.warning(this.translate.instant(DiscoverTranslationKey.UsersInviteFailedToast, { count: responseData.successCount, total: responseData.errors.length + responseData.successCount }));
				}
			}
		} catch (e) {
			if (this.abortController.signal.aborted) {
				this.abortController = new AbortController();
				this.toastService.error(this.translate.instant(CommonTranslationKey.UploadCancelledMessage));
			} else {
				this.toastService.error(this.translate.instant(SharedTermsTranslationKey.ErrorGenericMessage));
			}

			this.error = ensureError(e);
			clearTimeout(uploadModalTimeout);
			this.modalService.closeLatest();
			this.uploading = false;
		}
	}

	protected back(canLeave?: boolean) {
		this.edited = canLeave ? false : this.edited;
		void this.router.navigate(['../../'], { relativeTo: this.route });
	}

	protected removeFile() {
		this.fileControl?.setValue([]);
	}

}
