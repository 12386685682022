import { Injectable, inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Repository, SharedTermsTranslationKey, WindowWrapper } from '@unifii/library/common';
import { AppAuthProviderConfiguration, AuthProvider, AuthProviderConfiguration, Dictionary, TenantClient, encrypt, isNotNull } from '@unifii/sdk';

import { DeviceService } from 'capacitor/device.service';
import { InAppBrowserService } from 'capacitor/in-app-browser.service';
import { Config } from 'config';
import { SSOPath, UserAccessRootPath } from 'discover/discover-constants';

const DirectoryBaseURL = 'https://directory.unifii.net';
const AuthProviderKey = 'AuthProvider';

export const Auth0DirectoryURL = DirectoryBaseURL + '/auth0/callback';
export const AzureDirectoryURL = DirectoryBaseURL + '/azure/callback';

/**
 *  Class duplicated in Console any changes need to be made in both,
 *  this class will be simplified when the getRedirectUrl is replaced with backend call
 *  will only need to render buttons
 */
@Injectable({ providedIn: 'root' })
export class SSOService {

	private config = inject(Config);
	private window = inject<Window>(WindowWrapper);
	private tenantClient = inject(TenantClient);
	private translate = inject(TranslateService);
	private inAppBrowserService = inject(InAppBrowserService);
	private repo = inject(Repository);
	private device = inject(DeviceService);

	get authenticatedProviderId(): string | undefined {
		return this.repo.load(AuthProviderKey) ?? undefined;
	}

	set authenticatedProviderId(v: string | undefined) {
		this.repo.store(AuthProviderKey, v);
	}

	get providers(): AppAuthProviderConfiguration[] {
		return this._providers.map((config) => this.getDisplayInfo(config)).filter(isNotNull);
	}

	// public because so it can be used by forked code bases, as some auth providers require logout redirect uris
	get redirectUri(): string {

		let redirectUri = `${this.window.location.origin}`;

		if (this.device.isCapacitorIOS()) {
			// hack for iOS webview and azure, can't register non https urls as a redirect URI and iOS web doesn't support a https as a baseURL
			redirectUri = redirectUri.replace('unifii://', 'https://');
		}

		return redirectUri;
	}

	get loginRedirectUri(): string {
		return `${this.redirectUri}/${UserAccessRootPath}/${SSOPath}`;
	}

	getProvider(id: string): AppAuthProviderConfiguration | undefined {
		return this.providers.find((provider) => provider.id === parseInt(id));
	}

	getProviderByUrl(url: string): AppAuthProviderConfiguration | undefined {
		return this.providers.find((provider) => provider.tenant && url.includes(provider.tenant));
	}

	logout(): Promise<void> {

		if (!this.authenticatedProviderId) {
			return Promise.resolve();
		}

		const provider = this.getProvider(this.authenticatedProviderId);

		this.authenticatedProviderId = undefined;

		if (provider != null && provider.type === AuthProvider.Azure) {
			return this.clearCache();
		}

		if (provider != null && provider.type === AuthProvider.Okta) {
			return this.clearCache();
		}

		return Promise.resolve();
	}

	getProviderUrl(provider: AuthProviderConfiguration, redirectUri: string, username?: string): Promise<string | undefined> {

		switch (provider.type) {
			case AuthProvider.Azure: return this.getAzureUrl(provider, redirectUri, username);
			case AuthProvider.Auth0: return this.getAuth0Url(provider, redirectUri, username);
			case AuthProvider.Okta: return this.getOktaUrl(provider, redirectUri, username);
			case AuthProvider.UnifiiIdentity: return this.getUnifiiIdentityUrl(provider, redirectUri);
			default: return Promise.resolve(undefined);
		}
	}

	async getManualOIDState(config: Dictionary<any>): Promise<string> {
		const params = new URLSearchParams(config);
		const encrypted = await encrypt(this.config.unifii.appId as string, params.toString());

		return encodeURIComponent(encrypted.byteString);
	}

	buildProviderWithLabels(isSupportProvider: boolean): AppAuthProviderConfiguration[] {
		const providersSupported = this.providers.filter((provider) => {
			if (isSupportProvider) {
				return provider.isSupportProvider;
			}

			return !provider.isSupportProvider;
		});

		return providersSupported.map((provider) => {
			let providerLoginLabel = provider.providerLoginLabel;

			if (!providerLoginLabel) {
				const multiplesOfSameProvider = providersSupported.filter((p) => p.type === provider.type).length > 1;

				providerLoginLabel = multiplesOfSameProvider || isSupportProvider ? provider.tenant : '';
			}

			return { ...provider, providerLoginLabel };
		});
	}

	private getDisplayInfo(config: AuthProviderConfiguration): AppAuthProviderConfiguration | undefined {

		const display = Object.assign({}, config);

		switch (config.type) {
			case AuthProvider.Azure:
				return Object.assign(display, {
					loginLabel: this.translate.instant(SharedTermsTranslationKey.ActionSignInWithMicrosoft),
					loginIcon: 'assets/svg/microsoft-logo.svg',
					features: {
						rememberUserMobile: true,
					},
				});
			case AuthProvider.Auth0:
				return Object.assign(display, {
					loginLabel: this.translate.instant(SharedTermsTranslationKey.ActionSignInWithAuth0),
					loginIcon: 'assets/svg/auth0.svg',
				});
			case AuthProvider.Okta:
				return Object.assign(display, {
					loginLabel: this.translate.instant(SharedTermsTranslationKey.ActionSignInWithOkta),
					loginIcon: 'assets/svg/okta.svg',
					features: {
						rememberUserMobile: true,
					},
				});
			case AuthProvider.UnifiiIdentity:
				return Object.assign(display, {
					loginLabel: this.translate.instant(SharedTermsTranslationKey.ActionSignInWithUnifii),
					loginIcon: 'assets/svg/unifii-identity.svg',
					features: {},
				});
			default: return;
		}
	}

	private async getUnifiiIdentityUrl(provider: AuthProviderConfiguration, redirectUri: string): Promise<string> {

		const state = await this.getManualOIDState({ redirectUri, providerId: provider.id });

		const params = new URLSearchParams([
			['response_type', 'code'],
			['client_id', provider.clientId as string],
			['state', state],
			['redirect_uri', redirectUri],
			['scope', 'openid profile email phone'],
		]);

		return `${provider.authorizationEndpoint}?${params.toString()}`;
	}

	private async getAuth0Url(provider: AuthProviderConfiguration, redirectUri: string, username?: string): Promise<string> {

		const params = new URLSearchParams([
			['response_type', 'code'],
			['scope', 'openid profile email phone'],
			['client_id', provider.clientId as string],
		]);

		if (username) {
			params.append('login_hint', username);
		}

		if (provider.useDirectory !== false) {
			// Apply state required by directory and set redirect_uri as directory
			const stateInfo = await this.tenantClient.getOIDCState(redirectUri, { providerId: '' + provider.id });

			params.append('response_mode', 'form_post');
			params.append('state', stateInfo.state);
			params.append('redirect_uri', Auth0DirectoryURL);

			return `${provider.authorizationEndpoint}?${params.toString()}`;
		}

		// Encrypt redirectUri and providerId into state for decryption on redirect
		const state = await this.getManualOIDState({ redirectUri, providerId: provider.id });

		params.append('state', state);

		return `${provider.authorizationEndpoint}?${params.toString()}&redirect_uri=${redirectUri}`;
	}

	private async getAzureUrl(provider: AuthProviderConfiguration, redirectUri: string, username?: string): Promise<string> {

		const params = new URLSearchParams([
			['response_type', 'code'],
			['scope', 'openid offline_access user.read'],
			['client_id', provider.clientId as string],
		]);

		if (username) {
			params.append('login_hint', username);
		}

		if (provider.useDirectory !== false) {
			const stateResponse = await this.tenantClient.getOIDCState(redirectUri, { providerId: '' + provider.id });

			params.append('response_mode', 'form_post');
			params.append('state', stateResponse.state);
			params.append('redirect_uri', AzureDirectoryURL);

			return `${provider.authorizationEndpoint}?${params.toString()}`;
		}

		const state = await this.getManualOIDState({ redirectUri, providerId: provider.id });

		params.append('state', state);

		// Unable to encode return URL at the moment
		return `${provider.authorizationEndpoint}?${params.toString()}&redirect_uri=${redirectUri}`;
	}

	private async getOktaUrl(provider: AuthProviderConfiguration, redirectUri: string, username?: string): Promise<string> {
		const params = new URLSearchParams([
			['response_type', 'code'],
			['scope', 'openid profile email phone'],
			['client_id', provider.clientId as string],
		]);

		if (username) {
			params.append('login_hint', username);
		}

		const state = await this.getManualOIDState({ redirectUri, providerId: provider.id });

		params.append('state', state);
		params.append('redirect_uri', redirectUri);

		return `${provider.authorizationEndpoint}?${params.toString()}`;
	}

	private clearCache(): Promise<void> {

		try {
			// cordova logout by clearing cache
			if (this.inAppBrowserService.isAvailable) {
				return this.inAppBrowserService.clearCache();
			}
		} catch (e) {
			console.warn(e);
		}

		return Promise.resolve();
	}

	private get _providers(): AuthProviderConfiguration[] {
		return this.config.unifii.tenantSettings?.authProviders ?? [];
	}

}
