import { PermissionAction } from '@unifii/sdk';
import { CompanyFormContextActionType, CompanyKeys, UserFormContextActionType, UserKeys } from '@unifii/user-provisioning';

export const USER_REQUIRED_FIELDS: Record<UserFormContextActionType, string[]> = {
	[PermissionAction.Add]: [
		UserKeys.FirstName,
		UserKeys.LastName,
		UserKeys.Username,
		UserKeys.Password,
	] as string[],

	[PermissionAction.Invite]: [
		UserKeys.FirstName,
		UserKeys.LastName,
		UserKeys.Email,
		UserKeys.Roles,
	] as string[],

	[PermissionAction.Update]: [] as string[],
};

// Only UPDATE action available for edit Me
export const ME_REQUIRED_FIELDS = [UserKeys.OldPassword] as string[];

export const COMPANY_REQUIRED_FIELDS: Record<CompanyFormContextActionType, string[]> = {
	[PermissionAction.Add]: [CompanyKeys.Name, CompanyKeys.Status],
	[PermissionAction.Update]: [CompanyKeys.Name, CompanyKeys.Status],
};
