@if (menuDrawer) {
	<a (click)="shell.toggleMenuDrawer()" class="uf-action tertiary">
		<uf-icon [name]="menuDrawer.icon" />
	</a>
}
@if (leftDrawer) {
	<a (click)="shell.openLeftDrawer()" class="uf-action tertiary">
		<uf-icon [name]="leftDrawer.icon" />
	</a>
}

<a (click)="back()" class="uf-action tertiary back-button">
	<uf-icon name="arrowBack" />
</a>

<div class="title primary">{{ title }}</div>

<ng-content />

@if (previewMode === shell.mode) {
	<button [disabled]="disablePreviewToggle" (click)="shell.toggleMode.next()" type="button" class="uf-action tertiary preview-icon">
		<uf-icon name="preview" />
	</button>
}

@if (rightDrawer) {
	<a (click)="shell.openRightDrawer()" class="uf-action tertiary rightDrawer-wrap">
		<uf-icon [name]="rightDrawer.icon" />
		@if (shell.getNotificationCount() > 0) {
			<uf-icon [@grow] name="warning--solid" class="badge" />
		}
	</a>
}
