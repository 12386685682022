@if (error) {
	<div>
		<uf-message [content]="error.message" class="col-1of1 error padded" icon="errorSolid">
			<h3>{{ error.code }}</h3>
		</uf-message>
	</div>
}

@if (tableConfig) {
	<uf-expander [actionBtn]="true">
		<!-- header -->
		<div expanderHeader class="uf-app-bar medium accent">
			@if (tableLink) {
				<a [routerLink]="tableLink" class="title">
					@if (recordCount) {
						<span class="record-count"> {{ recordCount }} - </span>
					}
					<span>
						{{ tableInfo.table.title }}
					</span>
					<uf-icon class="small" name="moreInfo" />
				</a>
			}
		</div>
		<!-- body -->
		<div expanderBody class="pad-none gap-none uf-box flat-md table-page">
			<uf-table [config]="tableConfig" [datasource]="datasource" class="small-message list-md">
				@for (descriptor of customColumns; track descriptor) {
					<ng-container *cellFor="descriptor.name; let item; let index = index; let column = column">
						<uf-custom-cell [index]="index" [item]="item" [column]="column" [descriptor]="descriptor" />
					</ng-container>
				}
			</uf-table>
			@if (showSeeMoreRow) {
				<div class="row center-all float-right pad-sm-sides">
					<div class="header">
						<a [routerLink]="tableLink">
							<span>
								{{ shellTK.DashboardBucketActionSeeMore | translate }}
							</span>
						</a>
					</div>
				</div>
			}
		</div>
	</uf-expander>
}
