import { Component, Input, OnInit, inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MessageLevel, SharedTermsTranslationKey } from '@unifii/library/common';
import { ErrorType, UfRequestError, ensureUfRequestError, isValueOfNumberEnumType } from '@unifii/sdk';

import { ComponentTitleRouteData } from 'shell/shell-model';
import { ShellTranslationKey } from 'shell/shell.tk';

export interface ErrorMessageRouteData extends ComponentTitleRouteData {
	messageTranslationKey?: string;
	messageParams?: Record<string, any>;
	level?: MessageLevel;
}

@Component({
	selector: 'us-error-message',
	templateUrl: './error-message.html',
	styles: [`:host { display: block; }`],
	standalone: false,
})
export class ErrorMessageComponent implements OnInit {

	private _title: string | undefined;
	private _message: string | undefined;
	private _level: MessageLevel | undefined;
	private _error: UfRequestError | undefined;

	private route = inject(ActivatedRoute);
	private translate = inject(TranslateService);
	private routeData = this.route.snapshot.data as ErrorMessageRouteData;

	ngOnInit() {
		const type = parseInt(this.route.snapshot.params.type);

		this.error = new UfRequestError('', isValueOfNumberEnumType(ErrorType)(type) ? type : undefined);
	}

	@Input() set error(v: unknown) {
		this._error = ensureUfRequestError(v);
	}

	get error(): UfRequestError | undefined {
		return this._error;
	}

	@Input() set title(v: string) {
		this._title = v;
	}

	get title(): string {

		if (this._title?.trim().length) {
			return this._title;
		}

		if (this.error) {
			switch (this.error.type) {
				case ErrorType.Server:
					return this.translate.instant(SharedTermsTranslationKey.ErrorServer) as string;
				case ErrorType.NotFound:
				default:
					return this.translate.instant(SharedTermsTranslationKey.Error) as string;
			}
		}

		// Route snapshot
		if (this.routeData.titleTranslationKey) {
			return this.translate.instant(this.routeData.titleTranslationKey, this.routeData.titleParams) as string;
		}

		// Default title
		return this.translate.instant(SharedTermsTranslationKey.Error) as string;
	}

	@Input() set message(v: string) {
		this._message = v;
	}

	get message(): string {

		if (this._message?.trim().length) {
			return this._message;
		}

		// An Error is binded
		if (this.error) {
			switch (this.error.type) {
				case ErrorType.Server:
					return this.translate.instant(SharedTermsTranslationKey.ErrorServer) as string;
				case ErrorType.NotFound:
					return this.translate.instant(ShellTranslationKey.ErrorContentNotFound) as string;
				case ErrorType.Forbidden:
					return this.translate.instant(ShellTranslationKey.ErrorRequestForbidden) as string;
				case ErrorType.Unauthorized:
					return this.translate.instant(ShellTranslationKey.ErrorRequestUnauthorized) as string;
			}
		}

		// Route snapshot
		if (this.routeData.messageTranslationKey) {
			return this.translate.instant(this.routeData.messageTranslationKey, this.routeData.messageParams) as string;
		}

		// Default message
		return this.translate.instant(SharedTermsTranslationKey.ErrorUnknown) as string;
	}

	@Input() set level(v: MessageLevel) {
		this._level = v;
	}

	get level(): MessageLevel {

		// A level is binded as input
		if (this._level) {
			return this._level;
		}

		// An Error is binded
		if (this.error) {
			return MessageLevel.Error;
		}

		// Route snapshot
		if (this.routeData.level) {
			return this.routeData.level;
		}

		// Default level
		return MessageLevel.Error;
	}

	get icon(): string {
		switch (this.level) {
			case MessageLevel.Info:
				return 'infoSolid';
			case MessageLevel.Success:
				return 'successSolid';
			case MessageLevel.Warning:
				return 'warningSolid';
			case MessageLevel.Error:
				return 'errorSolid';
		}
	}

}
