import { Component, OnInit, inject } from '@angular/core';
import { PublishedContent, StructureNode } from '@unifii/sdk';

import { NavigationService } from 'shell/nav/navigation.service';

import { PDFAsset } from './pdf-viewer.component';

@Component({
	selector: 'us-pdf-viewer-node',
	templateUrl: './pdf-viewer-node.html',
	standalone: false,
})
export class PdfViewerNodeComponent implements OnInit {

	pdfAsset: PDFAsset;

	private content = inject(PublishedContent);
	private navService = inject(NavigationService);

	async ngOnInit() {

		const { id } = this.navService.current as StructureNode;

		if (!id) {
			return;
		}

		const url = await this.content.getAssetUrl(id.toString());

		this.pdfAsset = { url };

	}

}
