import { Component, inject } from '@angular/core';
import { Modal, ModalRuntime } from '@unifii/library/common';

import { DiscoverTranslationKey } from 'discover/discover.tk';

@Component({
	selector: 'ud-user-upload-progress-modal',
	templateUrl: './user-upload-progress-modal.html',
	standalone: false,
})
export class UserUploadProgressModalComponent implements Modal<any, boolean> {

	runtime = inject<ModalRuntime<any, boolean>>(ModalRuntime);
    
	protected readonly discoverTk = DiscoverTranslationKey;

}
