@if (!error && !printConfig && definition && formData) {
	<uf-form [config]="config" [definition]="definition" [formData]="formData" [disabled]="isDisabled" (submit)="submit($event)" (cancelClick)="back()">
		<div header class="uf-app-bar flat">
			<uf-breadcrumbs [breadcrumbs]="breadcrumbs" [minimize]="true" class="small left" />
			<button (click)="print()" type="button" class="uf-action">
				<uf-icon name="print" />
			</button>
		</div>
	</uf-form>
}

@if (error) {
	<uf-panel class="container bg-gray">
		<div class="uf-container-lg bg-solid">
			<div class="pad">
				<uf-message [content]="error.message" icon="errorSolid" class="error gap" />
			</div>
		</div>
	</uf-panel>
}

@if (printConfig) {
	<uf-print-form [config]="printConfig" (done)="printConfig = null" />
}
