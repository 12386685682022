<uf-panel ufMasterDetail class="container">
	<div class="uf-container bg-solid">
		<div class="uf-app-bar transparent pad-sm-left">
			<uf-breadcrumbs [breadcrumbs]="breadcrumbs" [minimize]="true" class="small left" />
			@if (itemLink) {
				<a [routerLink]="itemLink.urlSegments" class="uf-button small primary right">
					{{ itemLink.name }}
				</a>
			}
		</div>
		<div class="pad-top gap-bottom">
			@for (group of groups; track group) {
				<uf-data-display-group [group]="group" />
			}
		</div>

		@if (showNoEmptyMessage && parent.pageMode === templateEnumVales.PageViewHideEmptyTables) {
			<us-empty-module-message [message]="this.emptyMessage" />
		}

		@if (tableModules.length && parent.pageMode !== templateEnumVales.MenuView) {
			<div class="uf-grid">
				@for (module of tableModules; track module; let i = $index) {
					<us-table-module [module]="module" [item]="item" [pageMode]="parent.pageMode" [detailContextProvider]="detailContextProvider" (hideChange)="updateHiddenTablesSet(module, $event)" class="col-12 gap-sm-top module" />
				}
			</div>
		}
	</div>
</uf-panel>
