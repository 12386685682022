import { Injectable, inject } from '@angular/core';
import { FeatureFlagProvider } from '@unifii/library/common';
import { Dictionary } from '@unifii/sdk';

import { Config } from 'config';

@Injectable()
export class ShellFeatureFlagProvider implements FeatureFlagProvider {

	private config = inject(Config);

	get(): Promise<Dictionary<boolean>> {
		return Promise.resolve(Object.assign({}, this.config.unifii.tenantSettings?.features, this.config.flags));
	}

}
