import { Component, Input, OnDestroy, OnInit, ViewChild, inject } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { PanelComponent } from '@unifii/library/common';
import { Structure } from '@unifii/sdk';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

import { DeviceService } from 'capacitor/device.service';
import { ShellService } from 'shell/core/shell.service';
import { NavigationService } from 'shell/nav/navigation.service';
import { ShellTranslationKey } from 'shell/shell.tk';

@Component({
	selector: 'us-nav',
	templateUrl: 'nav.html',
	styleUrls: ['nav.less'],
	standalone: false,
})
export class NavComponent implements OnDestroy, OnInit {

	@ViewChild(PanelComponent, { static: true }) panel: PanelComponent;

	@Input() img?: string;

	protected readonly shellTK = ShellTranslationKey;
	protected structure: Structure | null;
	protected isHomeActive: boolean;

	private deviceService = inject(DeviceService);
	private shell = inject(ShellService);
	private router = inject(Router);
	private nav = inject(NavigationService);
	private subscriptions = new Subscription();

	ngOnInit() {
		this.structure = this.nav.structure;

		this.subscriptions.add(this.router.events.pipe(filter((e) => e instanceof NavigationEnd)).subscribe(() => {
			this.isHomeActive = this.nav.isRoot(this.router.routerState.snapshot);
		}));

		this.subscriptions.add(this.shell.menuOpened.subscribe(() => {

			if (this.panel && this.forceReflow) {
				/**
                 * force reflow on panel for
                 * mobile iOS 12.2 scroll bug where scroll position freezes
                 */
				this.panel.reflow();
			}
		}));
	}

	ngOnDestroy() {
		this.subscriptions.unsubscribe();
	}

	protected get forceReflow(): boolean {
		return this.deviceService.isCapacitorIOS() || this.deviceService.isIosMobile();
	}

	protected close() {
		this.shell.closeMenuDrawer();
	}

}
