import { DataDisplayDataSeedValue, DataDisplayRouterLinkComponent, DataDisplayRouterLinkValue, DataSourceDisplayTo, FormDefinitionMetadataIdentifiers } from '@unifii/library/common';
import { DataSourceType, PermissionAction, isStringNotEmpty } from '@unifii/sdk';

import { Authentication } from 'shell/services/authentication';
import { PermissionsFunctions } from 'shell/services/permissions-functions';
import { FormDataPath } from 'shell/shell-constants';

/**
 * If possible transform the displayData to a DataDisplayRouterLinkValue with route to the FormData
 */
export const displayDataToFormDataRouterLink = (displayData: unknown, auth: Authentication, projectId: string | undefined, bucket: string | undefined, formDataId: string | undefined): unknown => {

	if (!projectId || !bucket || !formDataId) {
		return displayData;
	}

	const permissionPath = PermissionsFunctions.getBucketDocumentPath(projectId, bucket, formDataId);
	const granted = auth.getGrantedInfoWithoutCondition(permissionPath, PermissionAction.Read).granted;

	if (!granted) {
		return;
	}

	const routerLink = [`/`, FormDataPath, bucket, formDataId];

	if (DataDisplayRouterLinkComponent.isDataAccepted(displayData)) {
		return Object.assign({}, displayData, { routerLink } );
	}

	if (isStringNotEmpty(displayData)) {
		return {
			label: displayData,
			routerLink,
			target: '_blank',
		} satisfies DataDisplayRouterLinkValue;
	}

	return displayData;
};

/**
 * When possible transform the data.visibleItems entry '_seqId' by amend its data display item to the FormData RouterLink
 */
export const injectFormDataRouterLink = (data: DataDisplayDataSeedValue, auth: Authentication, projectId: string | undefined) => {
	if (!projectId) {
		return;
	}

	const config = data.sourceConfig;
	const formDataId = data.dataSeed?._id;

	if (!isStringNotEmpty(formDataId)) {
		return;
	}

	if (config.type !== DataSourceType.Bucket) {
		return;
	}

	const mapping = config.mappingsVisibles.find((visibleMapping) =>
		visibleMapping.from === FormDefinitionMetadataIdentifiers.SeqId as string &&
        visibleMapping.to !== DataSourceDisplayTo,
	);

	if (!mapping) {
		return;
	}

	const seqIdVisibleItem = data.visibleItems.find((visibleItem) => visibleItem.item.term === mapping.label);

	if (!seqIdVisibleItem || !isStringNotEmpty(seqIdVisibleItem.item.data)) {
		return;
	}

	seqIdVisibleItem.item.data = displayDataToFormDataRouterLink(seqIdVisibleItem.item.data, auth, projectId, config.id, formDataId);
};
