import { Injectable, inject } from '@angular/core';
import { AppContext, ContextProvider } from '@unifii/library/common';
import { DATE_TIME_DATA_FORMAT, mapUserToUserContext } from '@unifii/sdk';
import { format, set } from 'date-fns';

import { Authentication } from 'shell/services/authentication';

/** ContextProvider is responsible for providing context for expression parsing */
@Injectable()
export class ShellContextProvider implements ContextProvider {

	private auth = inject(Authentication);

	get(): AppContext {
		const now = format(set(new Date(), { seconds: 0 }), DATE_TIME_DATA_FORMAT);

		return {
			now,
			user: mapUserToUserContext(this.auth.userInfo),
		};
	}

}
