import { Injectable, inject } from '@angular/core';
import { FeatureFlagProvider, FeatureFlagService } from '@unifii/library/common';

import { ShellFeatureFlagKey } from 'shell/shell-model';

@Injectable()
export class ShellFeatureFlagService extends FeatureFlagService {

	private provider = inject(FeatureFlagProvider);

	override async isEnabled(key: ShellFeatureFlagKey): Promise<boolean> {
		return !!(await this.provider.get())[key];
	}

}
