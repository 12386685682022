<form (ngSubmit)="submit()" class="uf-grid">
	<uf-select [label]="discoverTK.ProjectLabel | translate" [options]="projects" [placeholder]="discoverTK.SelectProjectPlaceholder | translate" [control]="control" ufAutofocus class="col-12" nameProperty="name" valueProperty="id" />

	<div class="col-12 col center-all">
		<button [disabled]="busy" class="uf-button primary gap-md-bottom" type="submit">
			{{ sharedTermsTK.ActionGo | translate }}
		</button>

		<button (click)="logout()" type="button" class="uf-button tertiary">
			{{ sharedTermsTK.ActionLogout | translate }}
		</button>
	</div>
</form>
