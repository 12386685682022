import { ErrorHandler } from '@angular/core';
import { MissingTranslationHandler, MissingTranslationHandlerParams } from '@ngx-translate/core';

export class ShellMissingTranslationHandler implements MissingTranslationHandler {

	constructor(private errorHandler: ErrorHandler) {}

	handle(params: MissingTranslationHandlerParams) {
		const error = new Error(`Missing "${params.translateService.currentLang}" translation for ${params.key}`);

		this.errorHandler.handleError(error);
	}

}
