@if (url) {
	<iframe [src]="url" frameBorder="0"></iframe>
}

@if (error) {
	<uf-panel class="container">
		<div class="grid--fixed">
			<uf-message [content]="error.message" class="col-1of1 error" icon="errorSolid" />
		</div>
	</uf-panel>
}
