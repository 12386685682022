import { APP_BASE_HREF, HashLocationStrategy, LocationStrategy, PathLocationStrategy, PlatformLocation } from '@angular/common';
import { ErrorHandler, NgModule, PLATFORM_ID, inject, provideAppInitializer } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy, RouterModule } from '@angular/router';
import { MissingTranslationHandler, TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { DataLoaderFactory, GoogleMaps, HierarchyUnitProvider, OfflineStorageService, SessionStorageWrapper, StorageWrapper, TablePreferencesProvider, UfHierarchyUnitProvider, WindowResizeEventHandler, WindowWrapper, getGMaps, getSessionStorage, getStorage, getWindow } from '@unifii/library/common';
import { Client, ContentClient, Interceptor, PublishedContent, TenantClient, TokenStorage, TokenStorageInterface } from '@unifii/sdk';
import { UserProvisioningProvider } from '@unifii/user-provisioning';

import { Config, Environment } from 'config';
import { languages, modules, providers, routeReuseStrategy, translationEntries } from 'settings';
import { AppComponent } from 'shell/app.component';
import { AppRoutes } from 'shell/nav/routes';
import { SdkInterceptor } from 'shell/sdk-interceptor';
import { Authentication } from 'shell/services/authentication';
import { ShellAuthenticationService } from 'shell/services/shell-authentication.service';
import { TokenService } from 'shell/services/token.service';
import { TranslationsService } from 'shell/services/translations.service';
import { UserPreferencesService } from 'shell/services/user-preferences.service';
import { ShellModule } from 'shell/shell.module';
import { ShellMissingTranslationHandler } from 'shell/translations/shell-missing-translation-handler';

import { ShellFeatureFlagService } from './services/shell-feature-flag.service';
import { UserProvisioning } from './services/user-provisioning';
import { ShellTranslateLoader } from './translations/shell-translate-loader';

export const initIndexDB = () => () => {
	// temporary fix for https://bugs.webkit.org/show_bug.cgi?id=226547
	// window.indexedDB need to be referenced before use, iOS 14 issue
	// eslint-disable-next-line disable-autofix/@typescript-eslint/no-unnecessary-condition
	if (window.indexedDB) {
		const db = window.indexedDB;

		return Promise.resolve(db);
	}

	return undefined;
};

export const initTranslations = (translationsService: TranslationsService) =>
	() => translationsService.register(languages); // APP_INITIALIZER factory must return an executable function

export const createClient = (config: Config, tokenStorage: TokenStorageInterface, interceptor: Interceptor): Client =>
	new Client(config.unifii, tokenStorage, interceptor);

export const createContent = (config: Config, client: Client, tenantClient: TenantClient): PublishedContent =>
	new ContentClient(client, tenantClient, config.unifii);

export const createOfflineStorage = (config: Config): OfflineStorageService =>
// TODO: review this -Oliver
	new OfflineStorageService(config.unifii.projectId, config.unifii.preview);

export const createShellTranslateLoader = (window: Window, config: Config) =>
	new ShellTranslateLoader(window, `${config.unifii.translationsUrl}/${config.unifii.appId}`, translationEntries);

export const getLocationStrategy = (platform: PlatformLocation, config: Config, baseRef: string) => {

	if (config.useHashUrls) {
		return new HashLocationStrategy(platform, baseRef);
	}

	return new PathLocationStrategy(platform, baseRef);
};

@NgModule({
	bootstrap: [AppComponent],
	declarations: [AppComponent],
	imports: [
		BrowserAnimationsModule,
		RouterModule.forRoot(AppRoutes, { canceledNavigationResolution: 'computed' }),
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: createShellTranslateLoader,
				deps: [WindowWrapper, Config],
			},
			missingTranslationHandler: {
				provide: MissingTranslationHandler,
				useClass: ShellMissingTranslationHandler,
				deps: [ErrorHandler],
			},
		}),
		ShellModule,
		...modules,
	],
	providers: [
		// config
		{ provide: APP_BASE_HREF, useValue: '/' },
		provideAppInitializer(() => {
			const initializerFn = (initIndexDB)();

			return initializerFn();
		}),
		provideAppInitializer(() => {
			const initializerFn = (initTranslations)(inject(TranslationsService));

			return initializerFn();
		}),
		{ provide: LocationStrategy, useFactory: getLocationStrategy, deps: [PlatformLocation, Environment, APP_BASE_HREF] },
		{ provide: RouteReuseStrategy, useClass: routeReuseStrategy },
		// native
		{ provide: WindowWrapper, useFactory: getWindow, deps: [PLATFORM_ID] },
		{ provide: StorageWrapper, useFactory: getStorage, deps: [PLATFORM_ID] },
		{ provide: SessionStorageWrapper, useFactory: getSessionStorage, deps: [PLATFORM_ID] },
		// 3rd party
		{ provide: GoogleMaps, useFactory: getGMaps, deps: [PLATFORM_ID] },
		// unifii
		ShellFeatureFlagService,
		TranslationsService,
		{ provide: TokenStorage, useClass: TokenService },
		{ provide: Interceptor, useClass: SdkInterceptor },
		{ provide: Client, useFactory: createClient, deps: [Config, TokenStorage, Interceptor] },
		{ provide: HierarchyUnitProvider, useClass: UfHierarchyUnitProvider },
		{ provide: UserProvisioningProvider, useClass: UserProvisioning },
		{ provide: ContentClient, useFactory: createContent, deps: [Config, Client, TenantClient] },
		{ provide: PublishedContent, useExisting: ContentClient },
		DataLoaderFactory,
		{ provide: Authentication, useClass: ShellAuthenticationService },
		{ provide: OfflineStorageService, useFactory: createOfflineStorage, deps: [Config] },
		WindowResizeEventHandler,
		{ provide: TablePreferencesProvider, useClass: UserPreferencesService },
		...providers,
	],
})
export class AppModule {}
