import { Injectable } from '@angular/core';
import { DataDisplayComponentRegistry, UfDataDisplayComponentRegistry } from '@unifii/library/common';

import { DataDisplayPdfViewerComponent } from './data-display-pdf-viewer.component';

@Injectable()
export class ShellDataDisplayComponentRegistry extends UfDataDisplayComponentRegistry implements DataDisplayComponentRegistry {

	constructor() {
		super();
		this.components.unshift(DataDisplayPdfViewerComponent);
	}

}
