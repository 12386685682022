<div class="uf-grid uf-container-sm">
	<div class="col-12 row center-all justify-center">
		<h5 class="no-margin padded--horizontal">{{ tenantSettings?.name }}</h5>

		@if (canChangeCompany) {
			<div>
				<button [title]="discoverTK.ActionChangeCompany | translate" [disabled]="inProgress" (click)="changeTenant()" type="button" class="uf-action small tertiary right">
					<uf-icon name="change" />
				</button>
			</div>
		}
	</div>

	@if (savedUsersService.enabled && savedUsers.length) {
		<ul class="uf-list small col-12">
			@for (user of savedUsers; track user) {
				<li class="uf-list-button">
					<button [disabled]="inProgress" (click)="rememberUserLogin(user)" type="button">
						@if (user.provider) {
							<div class="uf-avatar info padded">
								<img [src]="user.provider.loginIcon" />
							</div>
						} @else {
							<div class="uf-avatar accent">
								{{ user.username.charAt(0).toUpperCase() }}
							</div>
						}
						<ng-container *ngTemplateOutlet="userInfo; context: { user: user }" />
					</button>
				</li>
			}
		</ul>
	}

	<div class="col-12 auth-providers col space-children">
		@for (provider of providers; track provider) {
			<div class="col">
				@if (provider.providerLoginLabel) {
					<label>{{ provider.providerLoginLabel }}</label>
				}
				<button [disabled]="inProgress" (click)="providerSignIn(provider)" type="button" class="authentication-provider grow">
					<img [src]="provider.loginIcon" />
					<p class="small">{{ provider.loginLabel }}</p>
				</button>
			</div>
		}

		@if (showLoginWithUsernameButton) {
			<div class="col">
				@if (tenantSettings?.usernameLoginLabel) {
					<label>{{ tenantSettings?.usernameLoginLabel }}</label>
				}
				<button [disabled]="inProgress" (click)="loginWithUser()" type="button" class="authentication-provider unifii">
					<uf-icon class="small" name="user" />
					<p class="small">{{ discoverTK.LoginWithUsernameLabel | translate }}</p>
				</button>
			</div>
		}
	</div>
</div>

<ng-template #userInfo let-user="user">
	<div class="grow shrink">
		<p class="no-margin row bold">{{ user.username }}</p>
		@if (user.email !== user.username) {
			<p class="no-margin row x-small">{{ user.email }}</p>
		}
	</div>

	<button [title]="sharedTermsTK.ActionRemove | translate" (click)="removeUser($event, user)" type="button" class="uf-action tertiary small">
		<uf-icon name="delete" />
	</button>
</ng-template>
