import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';

import { DeviceService } from 'capacitor/device.service';
import { Environment } from 'config';
import { IdentityPath, UserAccessRootPath } from 'discover/discover-constants';
import { Authentication } from 'shell/services/authentication';

export const capacitorIdentityGuard: CanActivateFn = () => {

	const deviceService = inject(DeviceService);
	const authenticationService = inject(Authentication);
	const env = inject(Environment);
	const router = inject(Router);

	if (!env.authProvider || !deviceService.isNative() || authenticationService.isAuthenticated) {
		return true;
	}

	void router.navigate(['/', UserAccessRootPath, IdentityPath], { state: { capacitorFirstLoad: true } } );

	return false;
};
