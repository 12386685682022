import { Component } from '@angular/core';
import { ContentField } from '@unifii/library/smart-forms';
import { ContentLinkFormData, Field } from '@unifii/sdk';

@Component({
    templateUrl: './content-link-description.html',
})
export class ContentLinkDescriptionComponent implements ContentField {

    field: Field;
    content: ContentLinkFormData;

}
