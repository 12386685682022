import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { Router } from '@angular/router';
import { Client, ensureUfRequestError } from '@unifii/sdk';
import { Subject, Subscription } from 'rxjs';

import { Config } from 'config';
import { FormDataPath } from 'shell/shell-constants';

export interface DocumentSearchResult {
	bucket: string;
	docId: string;
	rev: string;
	snippet: string;
}

@Component({
	selector: 'ud-document-search',
	templateUrl: './document-search.html',
	styleUrls: ['./document-search.less'],
	standalone: false,
})
export class DocumentSearchComponent implements OnInit, OnDestroy {

	protected searchChange = new Subject<string>();
	protected searchOptions: DocumentSearchResult[] = [];
	protected searchResult = new Subject<DocumentSearchResult>();

	private client = inject(Client);
	private config = inject(Config);
	private router = inject(Router);
	private subscriptions = new Subscription();

	ngOnInit() {

		this.subscriptions.add(this.searchChange.subscribe((search) => {
			this.search(search).then((value) => {
				this.searchOptions = value;

			}, (e) => {
				const error = ensureUfRequestError(e);

				console.log(error);
			});

		}));

		this.subscriptions.add(this.searchResult.subscribe((res) => {
			this.routeToDocument(res);
		}));
	}

	ngOnDestroy() {
		this.subscriptions.unsubscribe();
	}

	private routeToDocument(doc: DocumentSearchResult) {
		void this.router.navigate([FormDataPath, doc.bucket, doc.docId]);
	}

	// todo create a client service to handle this
	private search(q: string): Promise<DocumentSearchResult[]> {
		const url = `https://doc-index.dev.unifiicloud.net/search`;

		return this.client.get(url, { params: { q, tenant: this.config.unifii.tenant, project: this.config.unifii.projectId } });
	}

}
