import { Component, OnInit, inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ContextProvider, GoogleMapsApiKey, LocationProvider, ThemeProvider, ThemeService, WindowWrapper, googleLocationProviderFactory } from '@unifii/library/common';
import { Client, StructureNode, Theme } from '@unifii/sdk';

import { DeviceService } from 'capacitor/device.service';
import { Config } from 'config';
import { ShellService } from 'shell/core/shell.service';
import { InitStep } from 'shell/init-step';
import { NavigationService } from 'shell/nav/navigation.service';
import { ComponentTitleRouteData } from 'shell/shell-model';

@Component({
	selector: 'us-app',
	templateUrl: './app.html',
	styles: [`:host { background: white; }`],
	providers: [
		{
			provide: GoogleMapsApiKey,
			useFactory: (config: Config) => config.unifii.tenantSettings?.googleMapsApiKey,
			deps: [Config],
		},
		{
			provide: LocationProvider,
			useFactory: googleLocationProviderFactory,
			deps: [WindowWrapper, TranslateService, ContextProvider, GoogleMapsApiKey],
		},
	],
	standalone: false,
})
export class AppComponent implements OnInit {

	private route = inject(ActivatedRoute);
	private client = inject(Client);
	private shell = inject( ShellService);
	private nav = inject(NavigationService);
	private deviceService = inject(DeviceService);
	private translate = inject( TranslateService);
	private config = inject(Config);
	private themeService = inject<ThemeService>(ThemeProvider);
	private initSteps = inject<InitStep[]>(InitStep, { optional: true });

	ngOnInit() {
		this.initTheme(this.config.themeConfig?.cssVariables ?? this.config.theme, this.config.unifii.productBackground);

		this.nav.init();
		this.nav.navigationEnd.subscribe((node) => { this.shell.setTitle(this.getAppTitle(node)); });

		this.client.start = () => { this.shell.busy = true; };
		this.client.end = () => { this.shell.busy = false; };

		if (!this.initSteps) {
			return;
		}

		for (const step of this.initSteps) {
			step.run();
		}

		// mobile deepLinks
		this.deviceService.initDeepLinks();

		// splashscreen
		void this.deviceService.hideSplashScreen();
	}

	private initTheme(theme?: Theme, productBackground?: string) {
		if (theme) {
			this.themeService.theme = theme;
		}

		if (productBackground) {
			this.themeService.setStyle('ui-main-background-image', `url("${productBackground}")`);
		}
	}

	private getAppTitle(node?: StructureNode | null): string | undefined {

		if (node?.nodeId) {
			return node.name;
		}

		let title: string | undefined;
		let child = this.route.firstChild;

		while (child) {
			const componentTitleRouterData = child.snapshot.data as ComponentTitleRouteData;

			// Store title
			if (componentTitleRouterData.titleTranslationKey) {
				title = this.translate.instant(
					componentTitleRouterData.titleTranslationKey,
					componentTitleRouterData.titleParams,
				);
			}

			// Look for deeper route title (more specific)
			if (child.firstChild) {
				child = child.firstChild;
			} else {
				child = null;
			}
		}

		return title;
	}

}
