import { inject } from '@angular/core';
import { CanDeactivateFn } from '@angular/router';

import { Authentication } from 'shell/services/authentication';

export const loggedInGuard: CanDeactivateFn<any> = (component) => {

	const auth = inject(Authentication);

	if (component.navigationTrigger === 'popstate' && auth.isAuthenticated) {
		return false;
	}

	return true;
};
