import { Injectable, inject } from '@angular/core';
import { Breadcrumb } from '@unifii/library/common';

@Injectable()
export class BreadcrumbsService {

	parent = inject(BreadcrumbsService, { skipSelf: true, optional: true });

	private _title: string;

	get parentBreadcrumbs(): Breadcrumb[] {
		return [...this.parent?.parentBreadcrumbs ?? [], { name: this._title, urlSegments: ['..'] }];
	}

	getBreadcrumbs(): Breadcrumb[] {
		return [...this.parent?.parentBreadcrumbs ?? [], { name: this._title }];
	}

	set title(title: string) {
		this._title = title;
	}

}
