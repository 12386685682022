import { Injectable, inject } from '@angular/core';
import { AssetProfile, Compound, ContentClient, ContentType, Definition, Dictionary, ImageProfile, Page, Query, Schema, Structure, Table } from '@unifii/sdk';

import { Config } from 'config';
import { ContentLoader } from 'shell/offline/content-loader';
import { ContentInfo, ContentPackage, ContentState, blobToArrayBuffer } from 'shell/offline/offline-model';

@Injectable({ providedIn: 'root' })
export class OnlineContentLoader implements ContentLoader {

	private config = inject(Config);
	private contentClient = inject(ContentClient);

	async getLatestInfo(): Promise<ContentInfo> {
		const version = await this.contentClient.getLatestVersion();

		return {
			tenant: this.config.unifii.tenant as string,
			projectId: this.config.unifii.projectId,
			name: version.name,
			version: version.version,
			preview: version.preview,
			state: ContentState.Next,
		};
	}

	async load(info: ContentInfo): Promise<ContentPackage> {
		console.log('ContentLoader versionedContent', info.name);
		const versionedContent = this.contentClient.createVersionedContent(info.name);
		const views = await this.loadViews(versionedContent);
		const pages = await this.loadPages(versionedContent);
		const formData = await this.loadFormsAndBuckets(versionedContent, info.preview != null);
		const collections = await this.loadCollections(versionedContent);

		let tables: Table[] = [];

		try {
			tables = await this.loadTables(versionedContent);
		} catch (e) { /* */ }

		let identifiers: Dictionary<{ type: ContentType }> = {};

		try {
			identifiers = await versionedContent.getIdentifiers();
		} catch (e) { /* */ }

		let structure: Structure | undefined;

		try {
			structure = await versionedContent.getStructure();
		} catch (e) { /* */ }

		const mappedViews = views.definitions
			.map((definition, index) => ({ definition, compound: views.compounds[index] }))
			.filter((m): m is { definition: Definition; compound: Compound } => !!m.compound);

		const mappedCollections = collections.definitions
			.map((definition, index) => ({ definition, compounds: collections.compounds[index] ?? [] }));

		const mappedForms = formData.forms
			.map((definition, index) => ({ definition, versions: formData.versions[index] ?? [] }));

		const contentPackage: ContentPackage = {
			info,
			structure,
			pages,
			views: mappedViews,
			collections: mappedCollections,
			tables,
			forms: mappedForms,
			buckets: formData.buckets,
			assets: [],
			identifiers,
		};

		return contentPackage;
	}

	async loadAsset(asset: ImageProfile | AssetProfile): Promise<{ data: ArrayBuffer; type: string } | null> {
		const options = this.config.unifii.maxImageWidth ? { width: this.config.unifii.maxImageWidth } : undefined;

		const url = this.isImageProfile(asset) ?
			this.contentClient.buildImageUrl(asset, options) :
			asset.url;

		if (!url) {
			return null;
		}

		const response = await fetch(url);

		if (!response.ok) {
			return null;
		}

		const blob = await response.blob();

		return blobToArrayBuffer(blob);
	}

	private isImageProfile(profile: ImageProfile | AssetProfile): profile is ImageProfile {
		return !!(profile as ImageProfile).width;
	}

	private async loadPages(versionedContent: ContentClient, paginationSize = 50): Promise<Page[]> {

		let offset = 0;
		let q = new Query();

		q = q.limit(paginationSize, offset);

		let batch: Page[] = [];
		let all: Page[] = [];

		do {
			// load batch
			batch = await versionedContent.queryPages(q);
			all = all.concat(batch);
			// configure next page
			offset += paginationSize;
			q = q.limit(paginationSize, offset);
		} while (batch.length >= paginationSize);

		return all;
	}

	private async loadTables(versionedContent: ContentClient, paginationSize = 50): Promise<Table[]> {

		let offset = 0;
		let q = new Query();

		q = q.limit(paginationSize, offset);

		let batch: Table[] = [];
		let all: Table[] = [];

		do {
			// load batch
			batch = await versionedContent.queryTables(q);
			all = all.concat(batch);
			// configure next page
			offset += paginationSize;
			q = q.limit(paginationSize, offset);
		} while (batch.length >= paginationSize);

		return all;
	}

	private async loadViews(versionedContent: ContentClient, paginationSize = 50): Promise<{ definitions: Definition[]; compounds: Compound[] }> {

		let offset = 0;
		let q: Query = new Query();

		q = q.limit(paginationSize, offset);

		let batch: Compound[] = [];
		let compounds: Compound[] = [];
		const definitions: Definition[] = [];

		// Paginated load of views
		do {
			// Load batch
			batch = await versionedContent.queryViews(q);
			compounds = compounds.concat(batch);
			// Configure next page
			offset += paginationSize;
			q = q.limit(paginationSize, offset);
		} while (batch.length >= paginationSize);

		// Load each view definition
		for (const compound of compounds) {
			definitions.push(await versionedContent.getViewDefinition(compound.id as string));
		}

		// Compose result
		return { definitions, compounds };
	}

	private async loadFormsAndBuckets(versionedContent: ContentClient, preview: boolean, paginationSize = 50): Promise<{ forms: Definition[]; versions: Definition[][]; buckets: Schema[] }> {

		let offset = 0;
		let q: Query = new Query();

		q = q.limit(paginationSize, offset);

		let batch: Definition[] = [];
		let definitions: Definition[] = [];
		const versions: Definition[][] = [];
		const buckets: Schema[] = [];

		// Paginated load of forms definitions
		do {
			// Load batch
			batch = await versionedContent.queryForms(q);
			definitions = definitions.concat(batch);
			// Configure next page
			offset += paginationSize;
			q = q.limit(paginationSize, offset);
		} while (batch.length >= paginationSize);

		// Load forms versions
		for (const definition of definitions) {
			versions.push(preview ? [] : await versionedContent.getFormVersions(definition.identifier));
		}

		// Unique buckets identifiers
		const bucketIds = Array.from(new Set(definitions.map((d) => d.bucket ?? d.identifier)));

		// Load buckets unique from forms
		for (const id of bucketIds) {
			buckets.push(await versionedContent.getBucket(id));
		}

		return { forms: definitions, versions, buckets };
	}

	private async loadCollections(versionedContent: ContentClient /* , paginationSize: number = 50*/): Promise<{ definitions: Definition[]; compounds: Compound[][] }> {

		/* To be replaced when getCollections will accept pagination query
        let offset = 0;
        let q: Query = new Query();
        q = q.limit(paginationSize, offset);

        this.client.get(this.url('collections'), null, q.stringify())
        .expand((data: any[]) => {
            offset += paginationSize;
            q = q.limit(paginationSize, offset);
            return data.length >= paginationSize ?
            this.client.get(this.url('collections'), null, q.stringify()) :
                Observable.empty();
        })
        .reduce((all, data) => all.concat(data), [])*/

		let compoundQuery: Query = new Query();

		compoundQuery = compoundQuery.limit(10000);

		const definitions = await versionedContent.getCollections();
		const compounds: Compound[][] = [];

		for (const definition of definitions) {
			compounds.push(await versionedContent.queryCollection(definition.identifier, compoundQuery));
		}

		return { definitions, compounds };
	}

}
