<div class="uf-app-bar flat">
	<div class="img-wrap">
		@if (img) {
			<img [attr.src]="img" />
		}
	</div>
	<a (click)="close()" class="uf-action tertiary">
		<uf-icon name="close" />
	</a>
</div>

<uf-panel [forceReflow]="forceReflow" class="grow">
	<!-- home menu -->
	<a [class.active]="isHomeActive" [routerLink]="['/']" class="home">
		<uf-icon name="home" />
		{{ structure?.name || (shellTK.MenuLinkHomeLabel | translate) }}
	</a>
	<!-- structure children -->
	@for (node of structure?.children; track node) {
		<us-nav-item [node]="node" />
	}
</uf-panel>
