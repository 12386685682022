import { Injectable, inject } from '@angular/core';
import { DataDisplayListItem, DataDisplayPrimitiveComponent, DataDisplayRouterLinkComponent, DataDisplayRouterLinkValue, FieldDescriptionService, FormDefinitionMetadataIdentifiers, RuntimeField, Scope } from '@unifii/library/common';
import { DataSourceType, PermissionAction, isStringTrimmedNotEmpty } from '@unifii/sdk';

import { DiscoverContext } from 'discover/discover-context';
import { Authentication } from 'shell/services/authentication';
import { PermissionsFunctions } from 'shell/services/permissions-functions';
import { FormDataPath } from 'shell/shell-constants';

/**
 * Extends the library field description service and modify or add values that are useful to the app context
 * eg: Add router link to app content
 */
@Injectable()
export class DiscoverFieldDescriptionService extends FieldDescriptionService {

    private auth = inject(Authentication);
    private context = inject(DiscoverContext);
    private projectId = this.context.project?.id ?? '';

    override transform(value: unknown, field: RuntimeField, scope?: Scope, format?: string): DataDisplayListItem[] | null {
        return super.transform(value, field, undefined, format)?.map((item) => this.applyRouterLinkToParentSeqId(item, field, scope)) ?? null;
    }

    // TODO extract a common function for this logic and the one in TableDetailComponent.toDescription:TableSourceType.Bucket
    /**
     ** Amend the item with the RouterLink to it's parent form
     */
    private applyRouterLinkToParentSeqId(item: DataDisplayListItem, field: RuntimeField, scope: Scope | undefined): DataDisplayListItem {

        const parentFormDataRepository = field.parent?.sourceConfig?.type === DataSourceType.Bucket ? field.parent.sourceConfig.id : undefined;
        const parentFormDataId = scope?._id;

        if (field.identifier !== FormDefinitionMetadataIdentifiers.SeqId || !parentFormDataRepository || !isStringTrimmedNotEmpty(parentFormDataId)) {
            return item;
        }

        const permissionPath = PermissionsFunctions.getBucketDocumentPath(this.projectId, parentFormDataRepository, parentFormDataId);
        const granted = this.auth.getGrantedInfoWithoutCondition(permissionPath, PermissionAction.Read).granted;

        if (!granted) {
            return item;
        }

        const routerLink = [`/`, FormDataPath, parentFormDataRepository, parentFormDataId];

        if (DataDisplayRouterLinkComponent.isDataAccepted(item.data)) {
            item.data.routerLink = routerLink;
        } else if (DataDisplayPrimitiveComponent.isDataAccepted(item.data)) {
            item.data = { label: `${item.data}`, routerLink, target: '_blank' } satisfies DataDisplayRouterLinkValue;
        }

        return item;
    }

}
