import { Location } from '@angular/common';
import { Injectable, Injector, inject } from '@angular/core';
import { Router } from '@angular/router';
import { DOMExceptionAbortErrorName, ErrorType, Interceptor, ensureUfError } from '@unifii/sdk';

import { UserAccessRootPath } from 'discover/discover-constants';
import { ErrorService } from 'shell/errors/error.service';
import { Authentication } from 'shell/services/authentication';

@Injectable()
export class SdkInterceptor implements Interceptor {

	private router = inject(Router);
	private errorService = inject(ErrorService);
	private injector = inject(Injector);
	private _auth: Authentication;
	private location = inject(Location);

	intercept(source: Promise<any>): Promise<any> {

		return source.catch((originalError: Error) => {
			const message = this.getMessage(ensureUfError(originalError).type);
			const error = ensureUfError(originalError, message);

			// For unauthorized errors outside of the login process, logout with later redirect to the current url
			if (error.type === ErrorType.Unauthorized && !this.router.url.startsWith(`/${UserAccessRootPath}`)) {
				// Pass in current url so user app can restart in the same place
				return this.auth.logout({ grantedRedirectURL: this.location.path() }).then(() => {
					// Error has been handled, throws DOMException AbortError to 'abort' the promise without register the error (Sentry ignores AbortError)
					throw new DOMException('Unauthorized error handled and operation Aborted', DOMExceptionAbortErrorName);
				});
			}

			// throw original error
			throw originalError;
		});
	}

	private getMessage(type: ErrorType): string | undefined {

		switch (type) {
			case ErrorType.Forbidden: return this.errorService.forbiddenRequestErrorMessage;
			case ErrorType.Unknown: return this.errorService.unknownErrorMessage;
			case ErrorType.NotFound: return this.errorService.notFoundRequestErrorMessage;
			default: return undefined;
		}
	}

	get auth() {

		if (!this._auth) {
			// TODO Decouple dependency loop AuthService <- Client <- SdkInterceptor <- AuthService
			// Inject sit here to avoid the loop dependency
			this._auth = this.injector.get(Authentication);
		}

		return this._auth;
	}

}
