<div ufmasterdetail class="container">
	<uf-panel [class.scroll-all]="table" [class.col]="table" [class.panelWidth]="showFilters" class="container bg-gray">
		<div class="wrapper">
			<div [class.disable-scroll]="showFilters" class="report-panel">
				<div class="header-pane uf-app-bar flat stretch">
					@if (dateFilterConfigs.intervals) {
						<div class="col-lg-3">
							<uf-radio [columns]="incrementOptions.length" [options]="incrementOptions" [columns]="3" [value]="interval" (valueChange)="incrementChange($event)" valueProperty="identifier" />
						</div>
					}
					<button (click)="print()" type="button" class="uf-action">
						<uf-icon name="print" />
					</button>
				</div>

				@if (!error) {
					<div class="uf-grid condensed column-gap-sm pad-sides gap-bottom report">
						@if (dateFilterConfigs.startDate && this.dateFilterConfigs.endDate && this.dateFilterConfigs.presetRanges) {
							<div class="row space-children wrap gap-md-top">
								@for (preset of dateIntervalPresets; track preset; let i = $index) {
									<button (click)="dateIntervalPresetSelected(i)" type="button" class="uf-button tertiary x-small">{{ preset.label }}</button>
								}
							</div>
						}
						<div class="col-12 gap-md-top">
							<uf-filter-display [manager]="filterManagerAll" [value]="filterValues" (valueChange)="filtersChange($event)" />
						</div>
						@for (reportNotFound of reportsNotFound; track reportNotFound) {
							<uf-message [content]="reportNotFound" icon="warning" class="col-12 warning gap-bottom" />
						}
						@for (reportConfig of reportConfigs; track reportConfig) {
							<us-report class="col-lg-{{ reportConfig.width }}" [reportConfig]="reportConfig" [class.paginated]="table" [bottomThreshold]="50" />
						}
					</div>
				}
			</div>
		</div>
	</uf-panel>

	<uf-drawer [showHandle]="true" [(open)]="showFilters" handleIcon="filter" class="share-space">
		@if (filterManagerAll.entries.length && filterValues) {
			<uf-filter-inputs [manager]="filterManagerAll" [value]="filterValues" (valueChange)="filtersChange($event)" (onClose)="showFilters = false" />
		}
	</uf-drawer>
</div>
