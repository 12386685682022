import { Component, Input, inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DataDisplayAttachmentValue, DataDisplayHrefValue, DataDisplayRouterLinkValue, getContentLinkLabel } from '@unifii/library/common';
import { ContentLinkFormData, LinkContentType } from '@unifii/sdk';

import { DataDisplayPdfViewerValue } from '../data-display/data-display-pdf-viewer.component';

type ContentLinkDataDisplayValue =
    DataDisplayAttachmentValue |
    DataDisplayPdfViewerValue |
    DataDisplayRouterLinkValue |
    DataDisplayHrefValue;

@Component({
    selector: 'us-content-link-url',
    templateUrl: './content-link-url.html',
    styleUrls: ['./content-link-url.less'],
})
export class ContentLinkUrlComponent {

    @Input() showType = false;

    protected dataDisplayValue: ContentLinkDataDisplayValue | undefined;

    private translateService = inject(TranslateService);
    private _content: ContentLinkFormData | undefined;

    @Input({ required: true }) set content(v: ContentLinkFormData | undefined) {
        this._content = v;
        this.dataDisplayValue = this.getDataDisplayValue();
    }

    get content(): ContentLinkFormData | undefined {
        return this._content;
    }

    private getDataDisplayValue(): ContentLinkDataDisplayValue | undefined {
        if (!this.content) {
            return;
        }

        const label = getContentLinkLabel(this.content, this.translateService);

        switch (this.content.type) {

            // TODO Asset will be introduce later on
            case LinkContentType.Asset:
                return;

            case LinkContentType.Attachment:
                if (this.content.useViewer) {
                    return {
                        label,
                        pdfAttachmentId: this.content.id,
                    } satisfies DataDisplayPdfViewerValue;
                }

                return {
                    label,
                    attachmentId: this.content.id,
                } satisfies DataDisplayAttachmentValue;

            case LinkContentType.Form:
            case LinkContentType.Page:
                return {
                    label,
                    routerLink: ['/', this.content.id],
                } satisfies DataDisplayRouterLinkValue;

            case LinkContentType.Url:
                return {
                    label,
                    href: this.content.id,
                    target: '_blank',
                } satisfies DataDisplayHrefValue;
        }
    }

}
