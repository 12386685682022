<uf-panel class="container bg-solid">
	@if (breadcrumbs.length) {
		<div class="header-pane">
			<uf-breadcrumbs [breadcrumbs]="breadcrumbs" [minimize]="true" class="small left" />
		</div>
	}

	@if (definition && compound) {
		<uf-collection-item [definition]="definition" [compound]="compound" />
	}
</uf-panel>
