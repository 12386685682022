import { Component, HostBinding } from '@angular/core';

@Component({
	selector: 'ud-icons',
	templateUrl: './icons.html',
	styleUrls: ['./icons.less'],
	standalone: false,
})
export class DiscoverIconsComponent {

	@HostBinding('style.display') hostDisplay = 'none';

}
