import { Injectable, InjectionToken, inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ContextProvider, DataDescriptor, DataDescriptorService, DataPropertyDescriptor, FilterEntry, FormDefinitionMetadataIdentifiers, RuntimeDefinition, RuntimeDefinitionAdapter, RuntimePage, SharedTermsTranslationKey, UserInfoIdentifiers } from '@unifii/library/common';
import { amendFormData, getUTCTime } from '@unifii/library/smart-forms';
import { DisplayService } from '@unifii/library/smart-forms/display';
import { AstNode, CompaniesClient, Company, Compound, DetailModule, ErrorType, FieldType, MeClient, NodeType, Option, PermissionAction, PublishedContent, Table, TableDetail, TableDetailModule, TableIdentifierFieldDescriptor, TableSourceType, UfRequestError, UserAuthProvider, UsersClient, VisibleFilterDescriptor, hasLengthAtLeast, isUUID, mapUserToUserContext } from '@unifii/sdk';
import { UserControlService, UserFormContext, UserFormContextActionType, UserFormResourceType } from '@unifii/user-provisioning';

import { Config } from 'config';
import { DiscoverTranslationKey } from 'discover/discover.tk';
import { CollectionContent, CollectionItemContent, CompanyContent, FormContent, TableDetailData, UserContent, ViewContent } from 'shell/content/content-types';
import { ErrorService } from 'shell/errors/error.service';
import { AppError } from 'shell/errors/errors';
import { UsFormService, UsFormServiceFactoryLogic, UsFormServiceFactoryLogicInterface } from 'shell/form/form-service.service';
import { Authentication } from 'shell/services/authentication';
import { PermissionsFunctions } from 'shell/services/permissions-functions';
import { FormDataPath } from 'shell/shell-constants';
import { ShellTranslationKey } from 'shell/shell.tk';
import { TableFilterEntryFactory } from 'shell/table/table-filter-entry-factory';
import { TableModuleConfig, TablePageConfig } from 'shell/table/table-page-config';
import { USER_REQUIRED_FIELDS } from 'shell/table/users/users-constants';

export interface ContentDataResolver {
	getView(identifier: string): Promise<ViewContent>;
	/**
     * @param identifier - backwards compatibility with id
     */
	getPage(identifier: string): Promise<RuntimePage>;
	getCollection(identifier: string): Promise<CollectionContent>;
	getCollectionItem(identifier: string, id: number): Promise<CollectionItemContent>;
	getTableData(identifier: string): Promise<{ tablePageConfig: TablePageConfig; filterEntries: FilterEntry[] }>;
	getTableDetailData(itemId: string, tableIdentifier: string, tablePageConfig?: TablePageConfig, source?: string): Promise<TableDetailData>;
	getForm(identifier: string, version?: number): Promise<RuntimeDefinition>;
	getFormData(bucket: string, id: string, hasRollingVersion?: boolean): Promise<FormContent>;
	getCompanyContent(id?: string): Promise<CompanyContent>;
	getUserContent(id: string): Promise<UserContent>;
	getProfileContent(): Promise<UserContent>;
}

export const ContentDataResolver = new InjectionToken<ContentDataResolver>('UfContentDataResolver');

/**
 * Resolves Data for content components
 *  - Checks permissions
 *  - Catches errors
 */
@Injectable()
export class ShellContentDataResolver implements ContentDataResolver {

	private display = inject(DisplayService);
	private content = inject(PublishedContent);
	private errorService = inject(ErrorService);
	private config = inject(Config);
	private auth = inject(Authentication);
	private contextProvider = inject(ContextProvider);
	private translate = inject(TranslateService);
	private usersClient = inject(UsersClient);
	private companiesClient = inject(CompaniesClient);
	private meClient = inject(MeClient);
	private dataDescriptorService = inject(DataDescriptorService);
	private tableFilterEntryFactory = inject(TableFilterEntryFactory);
	private runtimeDefinitionAdapter = inject(RuntimeDefinitionAdapter);
	private userFormContext = inject(UserFormContext);
	private userControlService = inject(UserControlService);

	/**
     * Create new instance of formService multiple instances can cause timing issues when
     * bucket is set by multiple active components
     */
	private formService: UsFormService;

	constructor() {
		const formServiceFactory = inject<UsFormServiceFactoryLogicInterface>(UsFormServiceFactoryLogic);

		this.formService = formServiceFactory();
	}

	async getView(identifier: string): Promise<ViewContent> {
		try {
			const data = await this.display.getView(identifier) as { definition: RuntimeDefinition; compound: Compound };

			return { definition: data.definition, compound: data.compound, title: `${data.definition?.label}` };
		} catch (e) {
			throw this.errorService.createLoadError(identifier, e);
		}
	}

	async getPage(identifier: string): Promise<RuntimePage> {
		try {
			const response = await this.display.getPage(identifier);

			return response.page as RuntimePage;
		} catch (e) {
			throw this.errorService.createLoadError(identifier, e);
		}
	}

	async getCollection(identifier: string): Promise<CollectionContent> {
		try {
			const definition = await this.runtimeDefinitionAdapter.transform(await this.content.getCollectionDefinition(identifier));
			const compounds = await this.content.queryCollection(identifier);

			return { definition, compounds, title: definition.label };
		} catch (e) {
			throw this.errorService.createLoadError(identifier, e);
		}
	}

	async getCollectionItem(identifier: string, id: number): Promise<CollectionItemContent> {
		try {
			const data = await this.display.getCollectionItem(identifier, id as any as string) as { definition: RuntimeDefinition; compound: Compound };

			return { definition: data.definition, compound: data.compound, title: `${data.definition?.label}` };
		} catch (e) {
			throw this.errorService.createLoadError(identifier, e);
		}
	}

	getForm(identifier: string, version?: number): Promise<RuntimeDefinition> {
		return this.formService.getFormDefinition(identifier, version);
	}

	async getFormData(bucket: string, id: string, hasRollingVersion = false): Promise<FormContent> {
		try {
			this.formService.init(bucket);
			const formData = await this.formService.getFormData(id);
			const identifier = formData._definitionIdentifier as string;
			const version = hasRollingVersion ? undefined : formData._definitionVersion;
			const definition = await this.getForm(identifier, version);

			return { definition, formData, title: definition.label };

		} catch (e) {
			throw this.errorService.createLoadError(bucket, e);
		}
	}

	async getCompanyContent(id?: string): Promise<CompanyContent> {
		try {
			let company: Company | undefined;

			if (id) {
				company = await this.companiesClient.get(id);
			}

			return { company, title: `${company?.name ?? this.translate.instant(SharedTermsTranslationKey.NewLabel)}` };
		} catch (e) {
			throw this.errorService.createLoadError('company info', e);
		}
	}

	async getUserContent(id: string): Promise<UserContent> {
		try {
			if (!this.auth.getGrantedInfoWithoutCondition(PermissionsFunctions.getUserPath(+id), PermissionAction.Read).granted) {
				throw this.userDetailsForbiddenError;
			}

			const user = await this.usersClient.get(id);

			let userAuthProviders: UserAuthProvider[] = [];

			if (user.isExternal) {
				userAuthProviders = await this.usersClient.getAuthProviders(id);
			}

			return { userInfo: user, userAuthProviders, title: `${user.firstName} ${user.lastName}` };
		} catch (e) {
			throw this.errorService.createLoadError(id, e);
		}
	}

	async getProfileContent(): Promise<UserContent> {
		try {
			if (!this.auth.getGrantedInfoWithoutCondition(PermissionsFunctions.getMePath(), PermissionAction.Read).granted) {
				throw this.userDetailsForbiddenError;
			}
			const user = await this.meClient.get();
			let userAuthProviders: UserAuthProvider[] = [];

			if (user.isExternal && user.id) {
				userAuthProviders = await this.usersClient.getAuthProviders(user.id);
			}

			return { userInfo: user, userAuthProviders, title: `${user.firstName} ${user.lastName}` };
		} catch (e) {
			throw this.errorService.createLoadError('"me"', e);
		}
	}

	async getTableData(identifier: string): Promise<{ tablePageConfig: TablePageConfig; filterEntries: FilterEntry[] }> {
		try {
			const table = await this.content.getTable(identifier);

			// Check ACL for Bucket and BucketDocuments
			if (!this.canAccessTable(table)) {
				throw this.forbiddenError;
			}

			const tablePageConfig = await this.getTablePageConfig(table);
			const filterEntries = this.createFilterEntries(tablePageConfig.propertyDescriptors, table.visibleFilters, table.sourceType, table.identifier, table.filter);

			return { tablePageConfig, filterEntries };
		} catch (e) {
			throw this.errorService.createLoadError(identifier, e);
		}
	}

	async getTableDetailData(itemId: string, tableIdentifier: string, tablePageConfig?: TablePageConfig, source?: string): Promise<TableDetailData> {
		try {

			let sourceType = tablePageConfig?.sourceType;
			let propertyDescriptors = tablePageConfig?.propertyDescriptors;
			let detail = tablePageConfig?.table.detail;

			// if content node doesn't have tablePageConfig, we need to retrieve everything
			if (!sourceType || !propertyDescriptors) {
				const tableData = await this.getTableData(tableIdentifier);
				const table = tableData.tablePageConfig.table;

				sourceType = table.sourceType;
				propertyDescriptors = tableData.tablePageConfig.propertyDescriptors;
				detail = table.detail;
				source = table.source;
			}

			if (!detail) {
				console.error(`Table "${tableIdentifier}" missing page detail`);
				throw this.notFoundError;
			}

			const modules: DetailModule[] = [];
			const userRoles = this.auth.userInfo?.roles ?? [];

			for (const module of detail.modules) {

				if (!userRoles.some((role) => !module.roles?.length || module.roles.includes(role))) {
					continue;
				}

				modules.push(module);
			}

			detail.modules = modules;

			switch (sourceType) {
				case TableSourceType.Users: return await this.getUserDetailData(itemId, detail, propertyDescriptors);
				case TableSourceType.Company: return await this.getCompanyDetailData(itemId, detail, propertyDescriptors);
				case TableSourceType.Bucket: return await this.getBucketDetailData(itemId, source as string, detail, propertyDescriptors);
			}

		} catch (e) {
			throw this.errorService.createLoadError(tableIdentifier, e);
		}
	}

	private async getTablePageConfig(table: Table): Promise<TablePageConfig> {

		const properties = [
			...(table.columns ?? []).filter((c) => !isUUID(c.identifier)).map((cd) => cd.identifier),
			...(table.visibleFilters ?? []).map((vfo) => vfo.identifier),
			...((table.detail?.fields ?? [])
				.filter((tfd) => tfd.type === 'Field') as TableIdentifierFieldDescriptor[])
				.map((fd: TableIdentifierFieldDescriptor) => fd.identifier),
		];

		const dataDescriptor = await this.getDataDescriptor(table.sourceType, table.source, properties);

		if (dataDescriptor == null) {
			throw new Error(`Failed create dependencies for table: ${table.identifier}`);
		}

		if (dataDescriptor.skippedProperties && dataDescriptor.skippedProperties.length > 0) {
			console.warn(`DataDescriptor for ${table.sourceType}${table.source ? '[' + table.source + ']' : ''} skipped ${dataDescriptor.skippedProperties.length} properties`);
			for (const sp of dataDescriptor.skippedProperties) {
				console.warn(`${sp.identifier}: ${sp.name}`);
			}
		}

		const propertyDescriptorsMap = dataDescriptor.propertyDescriptorsMap;

		// TODO - Should this be done in DDE ?
		if (table.sourceType === TableSourceType.Users) {
			const units = propertyDescriptorsMap.get(UserInfoIdentifiers.Units);

			if (units) {
				units.type = FieldType.Hierarchy;
				units.icon = 'hierarchy';
			}
		}

		const config: TablePageConfig = {
			table,
			propertyDescriptors: propertyDescriptorsMap,
			sourceType: table.sourceType,
			isSearchable: dataDescriptor.isSearchable === true,
		};

		if (table.sourceType === TableSourceType.Users) {
			config.addOptions = this.getUserTableAddOptions();
		}

		if (table.sourceType === TableSourceType.Bucket) {
			config.bucket = table.source as string;
			config.addOptions = await this.getBucketTableAddOptions(config.bucket, propertyDescriptorsMap.get(FormDefinitionMetadataIdentifiers.DefinitionIdentifier));

			const schema = await this.content.getBucket(table.source as string);

			config.hasRollingVersion = schema.hasRollingVersion;
		}

		// AddOptions for modules
		const modules = await Promise.all(table.detail?.modules.map((m) => this.getTableModuleConfig(m)) ?? []);

		config.modules = modules;

		return config;
	}

	/**
     * TableModuleConfig contains info to add a new item to the TableModule's Table (aka TMT) that is linked to the parent Table page details.
     * This functionality is available only under the following conditions:
     * 1. TMT is of type Table and has canAdd flag enabled
     * 2. Permission Read for TMT's Table
     * 3. TMT's Table has sourceType FormData
     * 4. Permission Read for TMT's Table Schema
     * 5. TMT's Table has a filter with expression '$detail.<anyValue>' and map to an existing schema field
     * 6. Permission Add for TMT's Table FormDataRepository
     * 7. Filter each FormDefinition of the Schema by Permission Read
     */
	private async getTableModuleConfig(module: TableDetailModule): Promise<TableModuleConfig> {

		const { identifier, filter } = module;

		// Following guard is type-wise unnecessary, keep it to block the execution for cases different from 'Table', in the scenario of more types added to TableDetailModule.type
		// eslint-disable-next-line disable-autofix/@typescript-eslint/no-unnecessary-condition
		if (module.type !== 'Table') {
			return {};
		}

		// 1. TM is of type Table and has canAdd flag enabled
		if (!module.canAdd || !filter) {
			return {};
		}

		// 2. ACL Read access to the TM's Table
		if (!this.auth.getGrantedInfoWithoutCondition(PermissionsFunctions.getTablePath(this.config.unifii.projectId, module.identifier), PermissionAction.Read).granted) {
			return {};
		}

		const moduleTable = await this.content.getTable(identifier);

		// 3. TM's Table is a FormData table
		if (moduleTable.sourceType !== TableSourceType.Bucket || !moduleTable.source) {
			return {};
		}

		// 4. Permission Read for TM's Table Schema
		const bucketDescriptor = await this.dataDescriptorService.getBucketDataDescriptor(moduleTable.source);

		if (!bucketDescriptor) {
			return {};
		}

		// 5. TM's Table has a filter with expression '$detail.<anyValue>' and map to an existing schema field
		const filterLink = this.getFilterLink(filter, bucketDescriptor.propertyDescriptorsMap);

		if (!filterLink) {
			return {};
		}

		// 6. Permission Add for TM's Table FormDataRepository
		// 7. Filter each FormDefinition of the Schema by Permission Read
		const addOptions = await this.getBucketTableAddOptions(moduleTable.source, bucketDescriptor.propertyDescriptorsMap.get(FormDefinitionMetadataIdentifiers.DefinitionIdentifier));

		if (!addOptions.length) {
			return {};
		}

		return { addOptions, filterLink };
	}

	private async getBucketTableAddOptions(bucketId: string, definitionDescriptor?: DataPropertyDescriptor): Promise<Option[]> {

		const addOptions: Option[] = [];

		if (!definitionDescriptor?.options) {
			return Promise.resolve(addOptions);
		}

		for (const option of definitionDescriptor.options) {
			// Guard READ Form definition
			if (!this.auth.getGrantedInfoWithoutCondition(
				PermissionsFunctions.getFormPath(this.config.unifii.projectId, option.identifier),
				PermissionAction.Read,
			).granted) {
				continue;
			}

			const formData = amendFormData({}, await this.getForm(option.identifier));

			formData._openedAt = getUTCTime();

			// Guard bucket ADD with condition
			if (!this.auth.getGrantedInfo(
				PermissionsFunctions.getBucketDocumentsPath(this.config.unifii.projectId, bucketId),
				PermissionAction.Add,
				// to run the potential permission condition against the single Form definitionIdentifier
				formData,
				this.contextProvider.get(),
			).granted) {
				continue;
			}

			addOptions.push(option);
		}

		return Promise.resolve(addOptions.sort((a, b) => {
			if (a.name.toLowerCase() < b.name.toLowerCase()) {
				return -1;
			}
			if (a.name.toLowerCase() > b.name.toLowerCase()) {
				return 1;
			}

			return 0;
		}));

	}

	/** This need to respect the field identifier transformation done for the AstNode in the FilterEditor */
	private getFilterLink(filter: AstNode, properties: Map<string, DataPropertyDescriptor>): { identifier: string; expression: string } | undefined {

		if (!filter.args) {
			return;
		}

		// Find potential node as first 'complete' node with a valid $detail expression
		const node = filter.args.find((arg) => {
			if (!arg.args || arg.args.length !== 2 || !hasLengthAtLeast(arg.args, 2)) {
				return false;
			}

			if (arg.args[1].type !== NodeType.Expression) {
				return false;
			}

			const nodeIdentifier = arg.args[0].value as string | undefined;
			const nodeExpression = arg.args[1].value as string | undefined;

			if (!nodeIdentifier || !nodeExpression) {
				return false;
			}

			// target FormDefinitionMetadataIdentifiers.Id is not allowed because it would create a new FormData with the same id of the TablePageDetails item (already existing)
			return nodeExpression.startsWith('$detail.') && nodeIdentifier !== FormDefinitionMetadataIdentifiers.Id as string;
		});

		// No potential node
		if (!node?.args || !hasLengthAtLeast(node.args, 2)) {
			return;
		}

		// Lookup for the potential node identifier Field among the available properties
		// Filter editor identifier transformation exceptions
		const originalIdentifier = node.args[0].value;
		const expression = node.args[1].value;
		let modifiedIdentifier;
		let dp: DataPropertyDescriptor | undefined;

		// DS field
		if (originalIdentifier.endsWith('._id')) {
			modifiedIdentifier = originalIdentifier.slice(0, -'._id'.length);
			dp = properties.get(modifiedIdentifier);
			if (dp?.sourceConfig && [FieldType.Choice, FieldType.Lookup].includes(dp.type)) {
				return { identifier: modifiedIdentifier, expression };
			}
		}
		// ZoneDateTime field
		if (originalIdentifier.endsWith('.value')) {
			modifiedIdentifier = originalIdentifier.slice(0, -'.value'.length);
			dp = properties.get(modifiedIdentifier);
			if (dp?.type === FieldType.ZonedDateTime) {
				return { identifier: modifiedIdentifier, expression };
			}
		}

		dp = properties.get(originalIdentifier);
		if (dp) {
			return { identifier: originalIdentifier, expression };
		}

		return undefined;
	}

	private getUserTableAddOptions(): Option[] {
		const options: Option[] = [];
		const originalUserFormContext: [UserFormResourceType, UserFormContextActionType] = [this.userFormContext.type, this.userFormContext.action];

		this.userFormContext.set(UserFormResourceType.User, PermissionAction.Invite);
		if (USER_REQUIRED_FIELDS[PermissionAction.Invite].every((field) => this.userControlService.isFieldEditable(field, undefined, undefined))) {
			options.push({ identifier: PermissionAction.Invite, name: this.translate.instant(SharedTermsTranslationKey.ActionInvite) });
		}

		this.userFormContext.set(UserFormResourceType.User, PermissionAction.Add);
		if (USER_REQUIRED_FIELDS[PermissionAction.Add].every((field) => this.userControlService.isFieldEditable(field, undefined, undefined))) {
			options.push({ identifier: PermissionAction.Add, name: this.translate.instant(SharedTermsTranslationKey.ActionCreate) });
		}

		this.userFormContext.set(originalUserFormContext[0], originalUserFormContext[1]);

		return options;
	}

	private createFilterEntries(
		propertyDescriptors: Map<string, DataPropertyDescriptor>,
		filters: VisibleFilterDescriptor[] = [],
		source: TableSourceType,
		tableIdentifier: string,
		staticFilter?: AstNode,
	): FilterEntry[] {
		return filters.map((f) => this.tableFilterEntryFactory.create(f, propertyDescriptors, source, tableIdentifier, staticFilter))
			.filter((f) => f != null) as FilterEntry[];
	}

	private getDataDescriptor(type: TableSourceType, bucket?: string, properties?: string[]): Promise<DataDescriptor | undefined> {
		switch (type) {
			case TableSourceType.Users: return this.dataDescriptorService.getUserDataDescriptor(properties);
			case TableSourceType.Company: return this.dataDescriptorService.getCompanyDataDescriptor(properties);
			case TableSourceType.Bucket: return !bucket ? Promise.resolve(undefined) : this.dataDescriptorService.getBucketDataDescriptor(bucket, [FormDefinitionMetadataIdentifiers.DefinitionIdentifier, ...(properties ?? [])]);
			default: throw new Error('Could not result DataDescriptor type');
		}
	}

	private canAccessTable(table: Table): boolean {
		switch (table.sourceType) {
			case TableSourceType.Users:
				return this.auth.getGrantedInfoWithoutCondition(PermissionsFunctions.getUsersPath(), PermissionAction.List).granted;
			case TableSourceType.Company:
				return this.auth.getGrantedInfoWithoutCondition(PermissionsFunctions.getCompaniesPath(), PermissionAction.List).granted;
			case TableSourceType.Bucket: {
				return !!table.source && this.auth.getGrantedInfoWithoutCondition(PermissionsFunctions.getBucketPath(this.config.unifii.projectId, table.source), PermissionAction.Read).granted &&
                    this.auth.getGrantedInfoWithoutCondition(PermissionsFunctions.getBucketDocumentsPath(this.config.unifii.projectId, table.source), PermissionAction.List).granted;
			}
			default: return true;
		}
	}

	private async getUserDetailData(id: string, detail: TableDetail, propertyDescriptors: Map<string, DataPropertyDescriptor>): Promise<TableDetailData> {

		if (!this.auth.getGrantedInfoWithoutCondition(PermissionsFunctions.getUserPath(+id), PermissionAction.Read).granted) {
			throw this.forbiddenError;
		}

		const userInfo = await this.usersClient.get(id);
		let itemLink;

		if (this.auth.getGrantedInfo(PermissionsFunctions.getUserPath(+id), PermissionAction.Update, mapUserToUserContext(userInfo), this.contextProvider.get()).granted) {
			itemLink = {
				name: this.translate.instant(SharedTermsTranslationKey.ActionEdit),
				urlSegments: ['../', id],
			};
		}

		return {
			sourceType: TableSourceType.Users,
			...detail,
			item: userInfo,
			propertyDescriptors,
			itemLink,
		};
	}

	private async getCompanyDetailData(id: string, detail: TableDetail, propertyDescriptors: Map<string, DataPropertyDescriptor>): Promise<TableDetailData> {

		const item = await this.companiesClient.get(id);
		let itemLink;

		if (this.auth.getGrantedInfo(PermissionsFunctions.getCompanyPath(id), PermissionAction.Update, item, this.contextProvider.get()).granted) {
			itemLink = {
				name: this.translate.instant(SharedTermsTranslationKey.ActionEdit),
				urlSegments: ['../', id],
			};
		}

		return {
			...detail,
			sourceType: TableSourceType.Company,
			item,
			propertyDescriptors,
			itemLink,
		};
	}

	private async getBucketDetailData(id: string, bucket: string, detail: TableDetail, propertyDescriptors: Map<string, DataPropertyDescriptor>): Promise<TableDetailData> {

		this.formService.init(bucket);
		const item = await this.formService.getFormData(id);

		return {
			...detail,
			sourceType: TableSourceType.Bucket,
			item,
			propertyDescriptors,
			itemLink: {
				name: this.translate.instant(SharedTermsTranslationKey.ActionView),
				urlSegments: ['/', FormDataPath, bucket, id],
			},
		};
	}

	private get forbiddenError(): AppError {
		return new UfRequestError(this.translate.instant(ShellTranslationKey.ErrorRequestForbidden), ErrorType.Forbidden);
	}

	private get userDetailsForbiddenError(): AppError {
		return new UfRequestError(this.translate.instant(this.translate.instant(DiscoverTranslationKey.UserDetailsErrorUnauthorized)), ErrorType.Forbidden);
	}

	private get notFoundError(): AppError {
		return new UfRequestError(this.translate.instant(ShellTranslationKey.ErrorContentNotFound), ErrorType.NotFound);
	}

}
