import { Injectable, Injector, StaticProvider, Type, ViewContainerRef, inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TableContainerManager } from '@unifii/components';
import { Context, ContextProvider, ExpressionParser, FilterAstNodeAdapter, FilterEntries, FilterEntry, FilterSerializer, HierarchyUnitProvider, RuntimePage, SharedTermsTranslationKey, normalizeAstNode } from '@unifii/library/common';
import { ContentType, DataType, Dictionary, ErrorType, PermissionAction, StructureNodeType, TableSourceType, UfError, coerceDataToTarget } from '@unifii/sdk';
import { UserFormContext, UserFormResourceType } from '@unifii/user-provisioning';

import { Config } from 'config';
import { ContentComponentFactory, ContentFactoryArgs } from 'shell/content/content-component-factory';
import { ContentComponentSelector } from 'shell/content/content-component-selector';
import { ContentDataResolver } from 'shell/content/content-data-resolver';
import { IFrameComponent } from 'shell/content/iframe.component';
import { DashboardPageComponent } from 'shell/dashboard/dashboard-page.component';
import { UsFormService, UsFormServiceFactoryLogic, formServiceFactory } from 'shell/form/form-service.service';
import { Authentication } from 'shell/services/authentication';
import { PermissionsFunctions } from 'shell/services/permissions-functions';
import { ShellTranslationKey } from 'shell/shell.tk';
import { CompanyTableContainerManager } from 'shell/table/companies/company-table-container-manager';
import { BucketTableContainerManager } from 'shell/table/form-data/bucket-table-container-manager';
import { TableInputManagerFactory } from 'shell/table/table-input-manager-factory';
import { ModuleInfo, TablePageConfig } from 'shell/table/table-page-config';
import { UsersTableContainerManager } from 'shell/table/users/users-table-container-manager';
import { TableDetailContextProvider } from 'shell/table-detail/table-detail-context-provider';
import { TableDetailPageComponent } from 'shell/table-detail/table-detail-page.component';

import { DiscoverContentType } from './content-types';

@Injectable()
export class ShellContentComponentFactory implements ContentComponentFactory {

	dataResolver: ContentDataResolver;
	contentComponentSelector: ContentComponentSelector;

	private translate: TranslateService;
	private expParser: ExpressionParser;
	private contextProvider: ContextProvider;
	private userFormContext: UserFormContext;
	private config: Config;
	private auth: Authentication;

	// TODO drop the constructor?
	constructor() {
		this.dataResolver = inject(ContentDataResolver);
		this.contentComponentSelector = inject(ContentComponentSelector);
		this.translate = inject(TranslateService);
		this.expParser = inject(ExpressionParser);
		this.contextProvider = inject(ContextProvider);
		this.userFormContext = inject(UserFormContext);
		this.config = inject(Config);
		this.auth = inject(Authentication);
	}

	// eslint-disable-next-line complexity
	async create(
		container: ViewContainerRef,
		type: ContentType | StructureNodeType | DiscoverContentType,
		{ identifier, id, bucket, hasRollingVersion, parentData }: ContentFactoryArgs,
	): Promise<any> { // TODO add explicit types ComponentRef<T>.instance
		if (type === StructureNodeType.IFrame) {
			this.createComponent(IFrameComponent, container);

			return;
		}

		if (type === StructureNodeType.Dashboard) {
			return this.createComponent(this.contentComponentSelector.getDashboardComponent(), container);
		}

		if (type === ContentType.View && identifier) {
			const { definition, compound } = await this.dataResolver.getView(identifier);
			const instance = this.createComponent(this.contentComponentSelector.getViewComponent(), container);

			instance.title = definition.label;
			instance.definition = definition;
			instance.compound = compound;

			return instance;
		}

		if (type === ContentType.Page && identifier) {
			let page: RuntimePage;

			// TODO - Remove Legacy id Route
			// fall back to id if available and offline content hasn't updated yet
			try {
				page = await this.dataResolver.getPage(identifier);
			} catch {
				console.warn('falling back to page id');
				page = await this.dataResolver.getPage(id as string);
			}

			const instance = this.createComponent(this.contentComponentSelector.getPageComponent(), container);

			instance.page = page;
			instance.title = `${page.title}`;

			return instance;
		}

		// TODO - Remove Legacy id Route
		if (type === ContentType.Page && id) {
			const page = await this.dataResolver.getPage(id as string);
			const instance = this.createComponent(this.contentComponentSelector.getPageComponent(), container);

			instance.page = page;
			instance.title = `${page.title}`;

			return instance;
		}

		if (type === ContentType.Collection && identifier) {
			const { definition, compounds } = await this.dataResolver.getCollection(identifier);
			const instance = this.createComponent(this.contentComponentSelector.getCollectionComponent(), container);

			instance.definition = definition;
			instance.compounds = compounds;
			instance.title = definition.label;

			return instance;
		}

		if (type === ContentType.CollectionItem && identifier) {
			const { definition, compound } = await this.dataResolver.getCollectionItem(identifier, id as number);
			const instance = this.createComponent(this.contentComponentSelector.getCollectionItemComponent(), container);

			instance.definition = definition;
			instance.compound = compound;
			instance.title = definition.label;

			return instance;
		}

		if (type === ContentType.Table && identifier) {
			const { tablePageConfig, filterEntries } = await this.dataResolver.getTableData(identifier);
			let moduleInfo: ModuleInfo = null;

			// FormMetadataField '_parent' was an alias for '_parent.seqId' and since 1.32.0 '_parent.seqId' is fully supported in Discover
			// This re-map legacy configurations using the '_parent' alias
			tablePageConfig.table.columns?.filter((c) => c.identifier === '_parent').forEach((c) => { c.identifier = '_parent.seqId'; });

			if (parentData instanceof TableDetailPageComponent) {
				moduleInfo = this.getTableModuleInfo(parentData, identifier);
			} else if (parentData instanceof DashboardPageComponent) {
				const bucketSource = tablePageConfig.table.source;
				const canAdd = bucketSource ? this.auth.getGrantedInfoWithoutCondition(PermissionsFunctions.getBucketPath(this.config.unifii.projectId, bucketSource), PermissionAction.Add).granted : false;

				moduleInfo = { identifier, canAdd };
			}

			const injector = this.createTableInjector(tablePageConfig, filterEntries, container, moduleInfo);
			const instance = this.createComponent(this.contentComponentSelector.getTableComponent(tablePageConfig.sourceType), container, injector);

			instance.title = tablePageConfig.table.title;

			return instance;
		}

		if (type === ContentType.Detail && identifier && id) {
			const { title, fields, modules, sourceType, propertyDescriptors, item, itemLink } = await this.dataResolver.getTableDetailData(id as string, identifier, parentData as TablePageConfig, bucket);
			const instance = this.createComponent(this.contentComponentSelector.getTableDetailComponent(identifier, id as string), container);

			instance.title = title;
			instance.fields = fields;
			instance.modules = modules;
			instance.sourceType = sourceType;
			instance.propertyDescriptors = propertyDescriptors;
			instance.item = item;
			instance.itemLink = itemLink;

			return instance;
		}

		if (type === ContentType.Form && bucket && id) {
			const { definition, formData } = await this.dataResolver.getFormData(bucket, id as string, hasRollingVersion);
			const instance = this.createComponent(this.contentComponentSelector.getFormComponent(), container);

			instance.definition = definition;
			instance.formData = formData;
			instance.title = definition.label;

			return instance;
		}

		if (type === ContentType.Form && identifier) {
			const definition = await this.dataResolver.getForm(identifier);
			const instance = this.createComponent(this.contentComponentSelector.getFormComponent(), container);

			instance.definition = definition;
			instance.title = definition.label;

			return instance;
		}

		if (type === DiscoverContentType.Company) {
			const { company } = await this.dataResolver.getCompanyContent(id as string);
			const instance = this.createComponent(this.contentComponentSelector.getCompanyComponent(), container);

			instance.company = company;
			instance.title = `${company?.name ?? this.translate.instant(SharedTermsTranslationKey.NewLabel)}`;

			return instance;
		}

		if (type === DiscoverContentType.User && id) {
			const { userInfo: userInfo, userAuthProviders } = await this.dataResolver.getUserContent(id as string);

			this.userFormContext.set(UserFormResourceType.User, PermissionAction.Update);

			const instance = this.createComponent(this.contentComponentSelector.getUserComponent(), container);

			instance.userInfo = userInfo;
			instance.userAuthProviders = userAuthProviders;
			instance.title = userInfo.firstName + ' ' + userInfo.lastName;

			return instance;
		}

		if (type === DiscoverContentType.UserProfile) {
			const { userInfo: userInfo, userAuthProviders } = await this.dataResolver.getProfileContent();

			this.userFormContext.set(UserFormResourceType.Me, PermissionAction.Update);

			const instance = this.createComponent(this.contentComponentSelector.getUserComponent(), container);

			instance.userInfo = userInfo;
			instance.userAuthProviders = userAuthProviders;
			instance.title = userInfo.firstName + ' ' + userInfo.lastName;

			return instance;
		}

		throw this.notFoundError;
	}

	private get notFoundError(): UfError {
		return new UfError(this.translate.instant(ShellTranslationKey.ErrorContentNotFound), ErrorType.NotFound);
	}

	private createComponent<T>(component: Type<T>, container: ViewContainerRef, injector?: Injector): T {
		const ref = container.createComponent(component, { index: 0, injector: injector ?? container.injector });

		return ref.instance;
	}

	private createTableInjector(tablePageConfig: TablePageConfig, filterEntries: FilterEntry[], container: ViewContainerRef, moduleInfo: ModuleInfo): Injector {
		const tableManager = this.getTableManagerClass(tablePageConfig.sourceType);
		const providers: StaticProvider[] = [
			{ provide: UsFormService, useFactory: formServiceFactory, deps: [UsFormServiceFactoryLogic] },
			{ provide: FilterEntries, useValue: filterEntries },
			{ provide: TablePageConfig, useValue: tablePageConfig },
			{ provide: ModuleInfo, useValue: moduleInfo },
			{ provide: TableContainerManager, useClass: tableManager, deps: [] },
			{ provide: TableInputManagerFactory, useClass: TableInputManagerFactory, deps: [
				ContextProvider, ExpressionParser, FilterEntries, HierarchyUnitProvider, FilterAstNodeAdapter, FilterSerializer, Config,
			] },
		];

		return Injector.create({
			providers,
			parent: container.injector,
		});
	}

	private getTableManagerClass(type: TableSourceType): Type<TableContainerManager<any, any, any>> {
		switch (type) {
			case TableSourceType.Users: return UsersTableContainerManager;
			case TableSourceType.Company: return CompanyTableContainerManager;
			case TableSourceType.Bucket: return BucketTableContainerManager;
			default: throw new Error('Could not result DataDescriptor type');
		}
	}

	private getTableModuleInfo(tableDetail: TableDetailPageComponent, identifier: string): ModuleInfo {
		const detailContextProvider = new TableDetailContextProvider(this.contextProvider, tableDetail.item);
		const moduleIndex = tableDetail.modules.findIndex((m) => m.identifier === identifier);
		const module = tableDetail.modules[moduleIndex];
		const filter = normalizeAstNode(module?.filter, this.expParser, detailContextProvider.get());
		let filterLink: Dictionary<string> | undefined;

		const moduleConfig = tableDetail.tablePageConfig.modules ? tableDetail.tablePageConfig.modules[moduleIndex] : undefined;

		if (moduleConfig?.filterLink?.expression) {

			const expressionValue = this.expParser.resolve(
				moduleConfig.filterLink.expression,
				detailContextProvider.get() as Context,
				undefined,
				`ShellContentComponentFactory: failed to parse ${moduleConfig.filterLink.expression}`,
			);

			const expressionStringValue = coerceDataToTarget(expressionValue, { type: DataType.String });

			if (expressionStringValue) {
				filterLink = { [moduleConfig.filterLink.identifier]: expressionStringValue };
			}
		}

		return { identifier, filter, filterLink, canAdd: module?.canAdd };
	}

}
