import { ChangeDetectorRef, Component, OnDestroy, OnInit, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { TableContainerManager } from '@unifii/components';
import { Breadcrumb, CommonTranslationKey, CompanyIdentifiers, DataDisplayListItem, DataDisplayRouterLinkValue, DataDisplayService, FilterEntry, FilterValue, ModalService, SEPARATOR_SLASH, SharedTermsTranslationKey, ToastService, UfControl, UfControlArray, UfControlGroup, ValidatorFunctions } from '@unifii/library/common';
import { stringsCaseInsensitiveLocalCompare } from '@unifii/library/smart-forms';
import { AuthProvider, DataSeed, DataType, Dictionary, ErrorType, FieldWidth, HierarchyStep, Manager, MeClient, OAuthWithMfaDevice, OAuthWithMfaDeviceSetup, OAuthWithVirtualMfa, PermissionAction, Query, UserAuthProvider, UserInfo, UserStatus, UsersClient, arrayBufferToBase64Url, ensureUfRequestError, getUserFullName, getUserStatus, isNotNull } from '@unifii/sdk';
import { ClaimWithValues, DeviceMfaNameModalComponent, LockedConfig, MFA_DEFAULT_ISSUER, UserControlService, UserFieldLabelService, UserFormContext, UserFormProvider, UserFormResourceType, UserKeys, UserMfaInfoFormControl, UserMfaInfoKeys, UserProvisioningCache, UserSetupDeviceMfaModalComponent, UserSetupRecoveryCodesModalComponent, UserSetupSmsModalComponent, UserSetupVirtualMfaModalComponent, UserUpdateFormControl, UserUpdateMeFormControl, getClaimDescriptionIdentifiers, getUserDescriptionIdentifiers, isAuthenticatorAssertionResponse, isAuthenticatorAttestationResponse } from '@unifii/user-provisioning';
import { Subscription, interval } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';

import { Config } from 'config';
import { ConflictDetectionInterval } from 'discover/discover-constants';
import { DiscoverTranslationKey } from 'discover/discover.tk';
import { DiscoverContentType, UserContent } from 'shell/content/content-types';
import { editedData } from 'shell/decorator/edited-data.decorator';
import { ErrorService } from 'shell/errors/error.service';
import { ConflictModalComponent } from 'shell/form/conflict-modal.component';
import { Authentication } from 'shell/services/authentication';
import { BreadcrumbsService } from 'shell/services/breadcrumbs.service';
import { ShellTranslationKey } from 'shell/shell.tk';

import { UserFormPermissionsProvider } from './user-form-permissions-provider';
import { getClaimsSectionVisibility } from './user-management-functions';

interface AuthProviderInfo {
	type: AuthProvider;
	tenant: string;
	claims: string[];
	roles: string[];
	units: string[];
}
@Component({
	selector: 'ud-user-details',
	templateUrl: './user-details.html',
	providers: [BreadcrumbsService],
	standalone: false,
})
export class UserDetailsComponent implements OnDestroy, OnInit, UserContent {

	@editedData protected edited: boolean;

	userInfo: UserInfo;
	userAuthProviders: UserAuthProvider[];
	title: string;

	protected readonly isMe = inject(ActivatedRoute).snapshot.data.contentType === DiscoverContentType.UserProfile;
	protected readonly userMfaInfoKeys = UserMfaInfoKeys;
	protected readonly sharedTermsTK = SharedTermsTranslationKey;
	protected readonly commonTK = CommonTranslationKey;
	protected readonly shellTK = ShellTranslationKey;
	protected readonly discoverTK = DiscoverTranslationKey;
	protected readonly userInfoKeys = UserKeys;
	protected readonly userStatus = UserStatus;
	protected readonly fieldWidth = FieldWidth;

	// Permissions
	protected canUpdate: boolean;
	protected canInvite: boolean;
	protected canDelete: boolean;

	// Controls
	protected form: UfControlGroup;
	protected usernameControl?: UfControl;
	protected usernameRequired: boolean;
	protected firstNameControl?: UfControl;
	protected firstNameRequired: boolean;
	protected lastNameControl?: UfControl;
	protected lastNameRequired: boolean;
	protected emailControl?: UfControl;
	protected emailRequired: boolean;
	protected phoneControl?: UfControl;
	protected phoneRequired: boolean;
	protected companyControl?: UfControl;
	protected companyRequired: boolean;
	protected isTesterControl?: UfControl;
	protected isTesterRequired: boolean;
	protected passwordControl?: UfControl;
	protected passwordRequired: boolean;
	protected oldPasswordControl?: UfControl;
	protected oldPasswordRequired: boolean;
	protected changePasswordOnNextLoginControl?: UfControl;
	protected changePasswordOnNextLoginRequired: boolean;
	protected isActiveControl?: UfControl;
	protected isActiveRequired: boolean;
	protected mfaControl?: UfControlGroup;
	protected lastActivationReasonControl?: UfControl;
	protected lastActivationReasonRequired: boolean;
	protected unitsControl?: UfControl;
	protected unitsRequired: boolean;
	protected rolesControl?: UfControl;
	protected rolesRequired: boolean;
	protected claimsControl?: UfControlGroup;
	protected claimsRequired: boolean;
	protected managerControl?: UfControl;
	protected managerRequired: boolean;

	protected labelDictionary: Dictionary<string>;
	protected originalUserStatus: UserStatus | undefined;
	protected displayAuthProviderInfos: AuthProviderInfo[] = [];
	protected showClaimsSection: boolean;
	protected breadcrumbs: Breadcrumb[] = [];
	protected managerInfo: DataDisplayListItem[] | undefined;
	protected lockedConfig: LockedConfig;
	protected pinTimeout: number | null;
	protected userDescriptionIdentifiers: string[];
	protected claimDescriptionIdentifiers: string[] = [];
	protected loading = true; // this is important for refreshing binding of [formGroup] when we create new instance of form after save
	protected allowedRoles: string[] | undefined;
	protected allowedCompanies: string[] | undefined;
	protected allowedClaimsValues: ClaimWithValues[];
	protected managerOptions: DataSeed[] = [];
	protected displayPhoneWarning = false;

	private readonly userUpdateFormController = this.isMe ? inject(UserUpdateMeFormControl) : inject(UserUpdateFormControl);
	private subscriptions = new Subscription();
	private revisionSubscription: Subscription | undefined;
	private userLookup = new Map<string, UserInfo>();
	private cd = inject(ChangeDetectorRef);
	private router = inject(Router);
	private route = inject(ActivatedRoute);
	private errorService = inject(ErrorService);
	private usersClient = inject(UsersClient);
	private translate = inject(TranslateService);
	private modalService = inject(ModalService);
	private toastService = inject(ToastService);
	private dataDisplayService = inject(DataDisplayService);
	private config = inject(Config);
	private meClient = inject(MeClient);
	private auth = inject(Authentication);
	private breadcrumbsService = inject(BreadcrumbsService);
	private userFieldLabelService = inject(UserFieldLabelService);
	private userFormContext = inject(UserFormContext);
	private userControlService = inject(UserControlService);
	private userProvisioningCache = inject(UserProvisioningCache);
	private userMfaInfoController = inject(UserMfaInfoFormControl);
	private permissionCtrl = inject<UserFormPermissionsProvider>(UserFormProvider);
	private tableManager = inject<TableContainerManager<UserInfo, FilterValue, FilterEntry>>(TableContainerManager, { optional: true });
	private deviceMfaChallengeKey: string | undefined;

	ngOnInit() {
		this.userFormContext.set(
			this.isMe ? UserFormResourceType.Me : UserFormResourceType.User,
			PermissionAction.Update,
		);
		this.labelDictionary = this.userFieldLabelService.labelDictionary;
		this.originalUserStatus = getUserStatus(this.userInfo);

		this.init();
	}

	ngOnDestroy() {
		this.subscriptions.unsubscribe();
		this.revisionSubscription?.unsubscribe();
	}

	protected async reInvite() {

		if (!this.userInfo.email || !await this.modalService.openConfirm({
			title: this.translate.instant(DiscoverTranslationKey.UserModalResendInviteTitle),
			message: this.translate.instant(DiscoverTranslationKey.UserModalResendInviteMessage, { email: this.userInfo.email }),
		})) {
			return;
		}

		try {
			await this.usersClient.bulkInvite([{ email: this.userInfo.email, username: this.userInfo.username, company: this.userInfo.company, canChangeUsername: false }]);
			this.toastService.success(this.translate.instant(DiscoverTranslationKey.UserFeedbackResendInviteSuccess));
		} catch (e) {
			console.error('UserDetails.reInvite', e);
			this.toastService.error(this.translate.instant(DiscoverTranslationKey.UserFeedbackResendInviteFail));
		}
	}

	protected async deleteUser() {

		if (!this.userInfo.id || !await this.modalService.openConfirm({
			title: this.translate.instant(ShellTranslationKey.DeleteUserModalTitle),
			message: this.translate.instant(ShellTranslationKey.DeleteUserModalMessage, { argument: getUserFullName(this.userInfo) }),
		})) {
			return;
		}

		try {
			await this.usersClient.delete(this.userInfo.id);
			this.toastService.success(this.translate.instant(ShellTranslationKey.DeleteUserModalSuccess));
			this.tableManager?.reload?.next();
			this.back();
		} catch (e) {
			console.error('UserDetails.deleteUser', e);
			this.toastService.error(this.translate.instant(ShellTranslationKey.DeleteUserModalFail));
		}
	}

	protected async save() {
		this.form.setSubmitted();

		if (this.form.invalid) {
			return;
		}

		if (this.originalUserStatus === UserStatus.Pending && this.passwordControl?.enabled && this.usernameControl?.value.indexOf('invitation_') === 0) {
			// Ask confirmation to activate the user with the generated username
			if (!await this.modalService.openConfirm({
				title: this.translate.instant(DiscoverTranslationKey.UserDetailsModalCompleteRegistrationTitle),
				message: this.translate.instant(DiscoverTranslationKey.UserDetailsModalCompleteRegistrationMessage, { username: this.userInfo.username }),
			})) {
				return;
			}
		}

		const user = this.userUpdateFormController.toDataModel(this.form, this.userInfo);

		try {
			this.userInfo = await this.updateUser(user);
			this.edited = false;
			this.toastService.success(this.translate.instant(SharedTermsTranslationKey.ActionSaveFeedbackSuccess));

			this.tableManager?.updateItem?.next(this.userInfo);

			if (this.breadcrumbs.length) {
				this.back();
			} else {
				this.subscriptions.unsubscribe();
				this.subscriptions = new Subscription();
				this.init();
			}
		} catch (e) {
			const error = this.errorService.createSaveError(user.username, e);

			if (error.type === ErrorType.Conflict) {
				void this.onConflictDetected();

				return;
			}

			this.toastService.error(error.message);
		}
	}

	protected back() {
		void this.router.navigate(['..'], { relativeTo: this.route });
	}

	protected selectManager(seed: DataSeed | null) {
		let user = null;

		this.managerInfo = undefined;

		if (seed != null) {
			user = this.userLookup.get(seed._id);
			this.managerInfo = this.getManagerInfo(user as Manager);
		}

		this.managerControl?.setValue(user);

		this.edited = true;
	}

	protected async searchUsers(q: string) {

		try {
			let query: Query | undefined;

			if (q.trim().length) {
				query = new Query();
				query.q(`${q}*`);
			}

			const users = await this.usersClient.query(query);

			this.managerOptions = users
				.map((user) => {
					if (user.id == null) {
						return null;
					}
					const seed: DataSeed = {
						_display: `${user.firstName} ${user.lastName} (${user.username})`,
						_id: user.id,
					};

					this.userLookup.set(seed._id, user);

					return seed;
				})
				.filter(isNotNull);
		} catch (e) { /** */ }
	}

	protected getRolesDisplays(roles: string[]): string[] {
		return roles.map((name) => this.userProvisioningCache.rolesByName[name]?.display ?? name);
	}

	protected async setupVirtualMfa() {
		const result = await this.modalService.openMedium(UserSetupVirtualMfaModalComponent,
			{ label: `(${this.config.unifii.tenantSettings?.name}) ${this.auth.userInfo?.username ?? ''}`,
				issuer: this.config.unifii.companyName ?? MFA_DEFAULT_ISSUER });

		if (!result) {
			return;
		}

		try {
			const response = await this.meClient.setVirtualMfaCode(result.secret);

			await this.auth.login({ mfa_token: result.token } satisfies OAuthWithVirtualMfa);
			(this.mfaControl?.get(UserMfaInfoKeys.VirtualCode) as UfControl | undefined)?.setValue(response.secret);
		} catch (e) {
			this.toastService.error(ensureUfRequestError(e).message);
		}

	}

	protected async setupSms() {
		const oAuthWithMfaSms = await this.modalService.openMedium(UserSetupSmsModalComponent, { smsChallenges: () => this.meClient.getSmsChallenges() });

		if (!oAuthWithMfaSms) {
			return;
		}

		try {
			await this.auth.login(oAuthWithMfaSms);
			await this.meClient.setSmsMfaEnabled();
			(this.mfaControl?.get(UserMfaInfoKeys.IsSmsEnabled) as UfControl | undefined)?.setValue(true);
		} catch (e) {
			this.toastService.error(ensureUfRequestError(e).message);
		}

	}

	protected async setupRecoverCodes() {
		const recoveryCodes = await this.modalService.openMedium(UserSetupRecoveryCodesModalComponent);

		if (!recoveryCodes) {
			return;
		}

		try {
			await this.meClient.setRecoveryCodes(recoveryCodes);

			(this.mfaControl?.get(UserMfaInfoKeys.HasRecoveryCodes) as UfControl | undefined)?.setValue(true);
		} catch (e) {
			this.toastService.error(ensureUfRequestError(e).message);
		}
	}

	protected setupDevice() {
		void this.modalService.openMedium(UserSetupDeviceMfaModalComponent, {
			setupCredential: (credential) => this.setupCredential(credential),
			getSetupChallenge: () => this.getSetupChallenge(),
			getVerifyChallenge: () => this.getVerifyChallenge(),
			verifyCredential: (credential: PublicKeyCredential) => this.verifyCredential(credential),
		});
	}

	protected async setupCredential(credential: PublicKeyCredential) {

		if (!this.deviceMfaChallengeKey || !isAuthenticatorAttestationResponse(credential.response)) {
			return;
		}

		const params: OAuthWithMfaDeviceSetup = {
			id: credential.id,
			raw_id: arrayBufferToBase64Url(credential.rawId),
			type: credential.type,
			challenge_key: this.deviceMfaChallengeKey,
			client_data_json: arrayBufferToBase64Url(credential.response.clientDataJSON),
			attestation_object: arrayBufferToBase64Url(credential.response.attestationObject),
		};

		try {
			await this.auth.login( params );
			const name = await this.modalService.openMedium(DeviceMfaNameModalComponent) ?? '';

			await this.meClient.completeDeviceMfaSetup(this.deviceMfaChallengeKey, name);
			const deviceControl = this.userMfaInfoController.buildDeviceControlGroup({ name, id: credential.id });

			(this.mfaControl?.get(UserMfaInfoKeys.Devices) as UfControlArray | undefined)?.push(deviceControl);
		} catch (e) {
			this.toastService.error(ensureUfRequestError(e).message);
		}
	}

	protected async getSetupChallenge(): Promise<CredentialCreationOptions> {
		const { publicKey, challengeKey } = await this.meClient.setupDeviceMfa(this.config.unifii.baseUrl);

		this.deviceMfaChallengeKey = challengeKey;

		return { publicKey };
	}

	// TODO - currently not used in functionality but required by component, consider making optional
	protected getVerifyChallenge(): Promise<CredentialRequestOptions> {
		return this.meClient.getDeviceMfaChallenge(this.config.unifii.baseUrl);
	}

	// TODO - currently not used in functionality but required by component, consider making optional
	protected async verifyCredential(credential: PublicKeyCredential) {
		if (!isAuthenticatorAssertionResponse(credential.response)) {
			return;
		}

		const params: OAuthWithMfaDevice = {
			id: credential.id,
			raw_id: arrayBufferToBase64Url(credential.rawId),
			type: credential.type,
			client_data_json: arrayBufferToBase64Url(credential.response.clientDataJSON),
			authenticator_data: arrayBufferToBase64Url(credential.response.authenticatorData),
			signature: arrayBufferToBase64Url(credential.response.signature),
		};

		try {
			await this.auth.login( params );
		} catch (e) {
			this.toastService.error(ensureUfRequestError(e).message);
		}
	}

	protected removeDevice(deviceControl: UfControlGroup) {
		const devicesControlArray = this.mfaControl?.get(UserMfaInfoKeys.Devices) as UfControlArray | undefined;

		if (!devicesControlArray) {
			return;
		}

		const index = devicesControlArray.controls.findIndex((control) => control === deviceControl);

		(this.mfaControl?.get(UserMfaInfoKeys.Devices) as UfControlArray).removeAt(index);
	}

	protected async clearMfa() {
		if ( !await this.modalService.openConfirm()) {
			return;
		}

		const resetValue: Dictionary<unknown> = {
			[UserMfaInfoKeys.VirtualCode]: null,
			[UserMfaInfoKeys.HasRecoveryCodes]: false,
		};

		if (this.config.unifii.tenantSettings?.isDeviceMfaEnabled) {
			resetValue[UserMfaInfoKeys.Devices] = [];
		}

		if (this.config.unifii.tenantSettings?.isSmsMfaEnabled) {
			resetValue[UserMfaInfoKeys.IsSmsEnabled] = false;
		}

		this.mfaControl?.markAsDirty();
		this.mfaControl?.setValue(resetValue);
	}

	private initConflictDetection() {
		this.revisionSubscription?.unsubscribe();
		this.revisionSubscription = interval(ConflictDetectionInterval).pipe(
			switchMap(async() => await this.usersClient.hasConflict(this.userInfo)),
			filter((conflict) => conflict),
		).subscribe(() => void this.onConflictDetected());
	}

	private async onConflictDetected() {
		this.revisionSubscription?.unsubscribe();

		const result = await this.modalService.openMedium(ConflictModalComponent, ['Discard', 'Close'], { guard: true });

		if (!result || result !== 'Discard' || !this.userInfo.id) {
			return;
		}

		this.userInfo = await this.usersClient.get(this.userInfo.id);
		this.edited = false;
		this.init();
	}

	private initPermissions() {
		this.canUpdate = this.permissionCtrl.canUpdate(this.userInfo);
		this.canInvite = this.permissionCtrl.canInvite();
		this.canDelete = this.permissionCtrl.canDelete(this.userInfo);
		this.allowedRoles = this.userControlService.getEditableRoles(this.userInfo, this.lockedConfig);
		this.allowedCompanies = this.userControlService.getEditableCompanies(this.userInfo);
		this.allowedClaimsValues = this.userControlService.getEditableClaimsValues(this.userInfo);
	}

	private initAuthProvidersProperties() {

		this.lockedConfig = { fields: [], roles: [], units: [], claimTypes: [] };
		this.displayAuthProviderInfos = [];

		if (!this.userInfo.isExternal) {
			return;
		}

		for (const provider of this.userAuthProviders) {
			this.lockedConfig.fields = this.lockedConfig.fields.concat(provider.lockedFields);
			this.lockedConfig.roles = this.lockedConfig.roles.concat(provider.lockedRoles).sort(stringsCaseInsensitiveLocalCompare);
			this.lockedConfig.claimTypes = this.lockedConfig.claimTypes.concat(provider.lockedClaims);
			this.lockedConfig.units = this.lockedConfig.units.concat(provider.lockedUnits);
		}

		this.displayAuthProviderInfos = this.userAuthProviders
		// Hide UnifiiIdentity provider from the visible AuthProviderInfo
			.filter((uap) => ![AuthProvider.UnifiiIdentity].includes(uap.type))
			.map<AuthProviderInfo>((authProvider) => ({
				type: authProvider.type,
				tenant: authProvider.tenant,
				claims: authProvider.lockedClaims.map((claim) => this.claimMapper(claim, this.userInfo)),
				roles: authProvider.lockedRoles.filter((role) => this.userInfo.roles?.includes(role)).sort(stringsCaseInsensitiveLocalCompare),
				units: this.getLockedUnits(this.userInfo.unitPaths, authProvider.lockedUnits),
			}),
			);
	}

	private initForm() {

		this.form = this.userUpdateFormController.buildRoot({ user: this.userInfo, lockedConfig: this.lockedConfig, userAuthProvidersInfo: this.userAuthProviders });

		// TODO Move to provisioning?
		if (this.originalUserStatus === UserStatus.Pending) {
			this.form.removeControl(UserKeys.LastActivationReason);
		}

		this.subscriptions.add(this.form.statusChanges.pipe(filter(() => !this.form.pristine)).subscribe(() => {
			this.edited = this.form.dirty;
		}));

		this.usernameControl = this.form.get(UserKeys.Username) as UfControl | undefined;
		this.usernameRequired = this.userControlService.isFieldRequired(UserKeys.Username);

		this.firstNameControl = this.form.get(UserKeys.FirstName) as UfControl | undefined;
		this.firstNameRequired = this.userControlService.isFieldRequired(UserKeys.FirstName);

		this.lastNameControl = this.form.get(UserKeys.LastName) as UfControl | undefined;
		this.lastNameRequired = this.userControlService.isFieldRequired(UserKeys.LastName);

		this.emailControl = this.form.get(UserKeys.Email) as UfControl | undefined;
		this.emailRequired = this.userControlService.isFieldRequired(UserKeys.Email);

		this.phoneControl = this.form.get(UserKeys.Phone) as UfControl | undefined;
		this.phoneRequired = this.userControlService.isFieldRequired(UserKeys.Phone);

		this.companyControl = this.form.get(UserKeys.Company) as UfControl | undefined;
		this.companyRequired = this.userControlService.isFieldRequired(UserKeys.Company);

		this.isTesterControl = this.form.get(UserKeys.IsTester) as UfControl | undefined;
		this.isTesterRequired = this.userControlService.isFieldRequired(UserKeys.IsTester);

		this.passwordControl = this.form.get(UserKeys.Password) as UfControl | undefined;
		this.passwordRequired = this.userControlService.isFieldRequired(UserKeys.Password);

		this.oldPasswordControl = this.form.get(UserKeys.OldPassword) as UfControl | undefined;
		this.oldPasswordRequired = this.userControlService.isFieldRequired(UserKeys.OldPassword);

		this.changePasswordOnNextLoginControl = this.form.get(UserKeys.ChangePasswordOnNextLogin) as UfControl | undefined;
		this.changePasswordOnNextLoginRequired = this.userControlService.isFieldRequired(UserKeys.ChangePasswordOnNextLogin);

		this.isActiveControl = this.form.get(UserKeys.IsActive) as UfControl | undefined;
		this.isActiveRequired = this.userControlService.isFieldRequired(UserKeys.IsActive);

		this.lastActivationReasonControl = this.form.get(UserKeys.LastActivationReason) as UfControl | undefined;
		this.lastActivationReasonRequired = this.userControlService.isFieldRequired(UserKeys.LastActivationReason);

		this.unitsControl = this.form.get(UserKeys.Units) as UfControl | undefined;
		this.unitsRequired = this.userControlService.isFieldRequired(UserKeys.Units);

		this.rolesControl = this.form.get(UserKeys.Roles) as UfControl | undefined;
		this.rolesRequired = this.userControlService.isFieldRequired(UserKeys.Roles);

		this.claimsControl = this.form.get(UserKeys.Claims) as UfControlGroup | undefined;
		this.claimsRequired = this.userControlService.isFieldRequired(UserKeys.Claims);

		this.managerControl = this.form.get(UserKeys.Manager) as UfControl | undefined;
		this.managerRequired = this.userControlService.isFieldRequired(UserKeys.Manager);

		// TODO temporary hide before UNIFII-7832
		if (this.config.unifii.tenantSettings?.isMfaEnforced) {
			this.mfaControl = this.form.get(UserKeys.Mfa) as UfControlGroup | undefined;
		}

		if (!this.config.unifii.tenantSettings?.isDeviceMfaEnabled) {
			this.mfaControl?.removeControl(UserMfaInfoKeys.Devices);
		}

		if (!this.config.unifii.tenantSettings?.isSmsMfaEnabled) {
			this.mfaControl?.removeControl(UserMfaInfoKeys.IsSmsEnabled);
		}

		if (this.mfaControl && this.phoneControl) {
			this.subscriptions.add(this.phoneControl.valueChanges.subscribe(() => {
				if (this.mfaControl?.get(UserMfaInfoKeys.IsSmsEnabled)?.value === true) {
					this.displayPhoneWarning = true;
				}
			}));
		}

	}

	private initPageInfo() {

		const descriptorSkippedProperties = [UserKeys.Roles];

		if (!this.displayAuthProviderInfos.length) {
			descriptorSkippedProperties.push(UserKeys.IsExternal);
		}

		// TODO temporary hide before UNIFII-7832
		if (!this.config.unifii.tenantSettings?.isMfaEnforced) {
			descriptorSkippedProperties.push(UserKeys.IsMfaEnabled);
		}

		this.breadcrumbsService.title = this.title;
		this.breadcrumbs = this.breadcrumbsService.getBreadcrumbs();
		this.userDescriptionIdentifiers = getUserDescriptionIdentifiers(this.form, descriptorSkippedProperties);
		this.userDescriptionIdentifiers.unshift(CompanyIdentifiers.Status);

		if (this.claimsControl) {
			this.claimDescriptionIdentifiers = getClaimDescriptionIdentifiers(this.claimsControl, this.lockedConfig.claimTypes).filter((claimsKey) => {
				// Exclude the claims that are empty
				const control = this.claimsControl?.get(claimsKey.substring('claims.'.length)) as UfControl | undefined;

				return !ValidatorFunctions.isEmpty(control?.getRawValue());
			});
		}
	}

	private updateUser(userInfo: UserInfo): Promise<UserInfo> {

		switch (this.userFormContext.type) {
			case UserFormResourceType.User:
				return this.usersClient.save(userInfo);
			case UserFormResourceType.Me:
				return this.meClient.update(userInfo);
		}
	}

	// TODO move to user provisioning functions and use in console too.
	private claimMapper(source: string, user: UserInfo): string {
		const claims = user.claims?.filter((c) => c.type === source).map((v) => v.value).join(', ');

		return `${source}: ${claims ?? ''}`;
	}

	private getManagerInfo(manager: Manager | undefined): DataDisplayListItem[] | undefined {

		if (!this.managerControl || !manager) {
			return;
		}

		return [
			{ term: this.translate.instant(CommonTranslationKey.UsernameLabel), data: { label: manager.username, routerLink: ['../', manager.id] } satisfies DataDisplayRouterLinkValue },
			{ term: this.translate.instant(CommonTranslationKey.FirstNameLabel), data: manager.firstName },
			{ term: this.translate.instant(CommonTranslationKey.LastNameLabel), data: manager.lastName },
			{ term: this.translate.instant(CommonTranslationKey.EmailLabel), data: this.dataDisplayService.displayAsDataDisplayValue(manager.email, { type: DataType.Email }) },
			{ term: this.translate.instant(CommonTranslationKey.PhoneLabel), data: this.dataDisplayService.displayAsDataDisplayValue(manager.phone, { type: DataType.Phone }) },
		].filter((item) => !!item.data) as DataDisplayListItem[];
	}

	private normalizeUserInfo() {
		this.userInfo.roles = this.userInfo.roles?.sort(stringsCaseInsensitiveLocalCompare);
		this.userInfo.systemRoles = this.userInfo.systemRoles?.sort(stringsCaseInsensitiveLocalCompare);
	}

	private getLockedUnits(userUnitsPaths: HierarchyStep[][] | undefined, lockedUnits: string[] | undefined): string[] {

		if (!lockedUnits || !userUnitsPaths) {
			return [];
		}

		return lockedUnits.map((unitId) => {
			const matchedUnit = userUnitsPaths.find((unit) => unit[unit.length - 1]?.id === unitId);

			if (!matchedUnit) {
				return;
			}

			return matchedUnit.map((d) => d.label).join(SEPARATOR_SLASH);

		}).filter((label) => !!label) as string[];
	}

	private init() {
		this.displayPhoneWarning = false;
		this.loading = true;
		this.cd.detectChanges();
		this.normalizeUserInfo();
		this.initAuthProvidersProperties();
		this.initPermissions();
		this.initForm();
		this.initPageInfo();
		this.managerInfo = this.getManagerInfo(this.userInfo.manager);
		this.showClaimsSection = getClaimsSectionVisibility(this.claimsControl);
		this.initConflictDetection();
		this.loading = false;
	}

}
