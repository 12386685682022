import { Injectable, Type } from '@angular/core';
import { ComponentRegistryOptions, ContentField } from '@unifii/library/smart-forms';
import { UfFormDescriptionListRegistry } from '@unifii/library/smart-forms/input';

@Injectable()
export class UdFormDescriptionListRegistry extends UfFormDescriptionListRegistry {

	override get(name: string, options?: ComponentRegistryOptions): Type<ContentField> | null {
		return super.get(name, options);
	}

}
