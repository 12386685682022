import { Directive, HostBinding, OnDestroy, OnInit, inject } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

@Directive({
	selector: '[ufMasterDetail]',
	standalone: false,
})
export class MasterDetailDirective implements OnDestroy, OnInit {

	@HostBinding('class.master-detail') isDetails: boolean;

	private router = inject(Router);
	private route = inject(ActivatedRoute);
	private subscriptions = new Subscription();

	ngOnInit() {

		this.subscriptions.add(
			this.router.events.pipe(filter((e) => e instanceof NavigationEnd))
				.subscribe(() => {
					this.isDetails = !!this.route.children.length;
				}),
		);

		this.isDetails = !!this.route.children.length;
	}

	ngOnDestroy() {
		this.subscriptions.unsubscribe();
	}

}
