import { Component, HostBinding, inject } from '@angular/core';
import { Modal, ModalData, ModalRuntime, SharedTermsTranslationKey } from '@unifii/library/common';

import { ShellTranslationKey } from 'shell/shell.tk';

export type ConflictModalAction = 'Close' | 'Discard' | 'Save';

@Component({
	selector: 'us-conflict-modal',
	templateUrl: './conflict-modal.html',
	standalone: false,
})
export class ConflictModalComponent implements Modal<ConflictModalAction[] | undefined, ConflictModalAction> {

	@HostBinding('class.uf-form-card') cardClass = true;

	runtime = inject<ModalRuntime<ConflictModalAction[] | undefined, ConflictModalAction>>(ModalRuntime);
	data = inject<ConflictModalAction[] | undefined>(ModalData);

	protected readonly sharedTK = SharedTermsTranslationKey;
	protected readonly shellTK = ShellTranslationKey;

	protected apply(action: ConflictModalAction) {
		this.runtime.close(action);
	}

}
