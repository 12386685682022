import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { TokenStorage, TokenStorageInterface } from '@unifii/sdk';

import { UserAccessRootPath } from 'discover/discover-constants';
import { isMfaComponentNavigationState } from 'discover/user-access/mfa.component';

/**
 * Ensure token and state set for Mfa Route
 */
export const mfaGuard: CanActivateFn = () => {

	const router = inject(Router);
	const tokenStorage = inject(TokenStorage) as TokenStorageInterface;
	const state = router.getCurrentNavigation()?.extras.state;

	if ((!tokenStorage.limitedToken && !tokenStorage.token) || !isMfaComponentNavigationState(state)) {
		void router.navigate(['/', UserAccessRootPath]);

		return false;
	}

	return true;
};
