import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { TableContainerManager } from '@unifii/components';
import { Breadcrumb, CommonTranslationKey, FilterEntry, FilterValue, SharedTermsTranslationKey, ToastService, UfControl, UfControlGroup } from '@unifii/library/common';
import { CompaniesClient, Company, FieldWidth, Option, PermissionAction, UserStatus } from '@unifii/sdk';
import { CompanyFormContext, CompanyFormControl, CompanyFormProvider, CompanyKeys } from '@unifii/user-provisioning';
import { Subscription } from 'rxjs';

import { DiscoverTranslationKey } from 'discover/discover.tk';
import { CompanyContent } from 'shell/content/content-types';
import { editedData } from 'shell/decorator/edited-data.decorator';
import { ErrorService } from 'shell/errors/error.service';
import { AppError } from 'shell/errors/errors';
import { ShellTranslationKey } from 'shell/shell.tk';
import { TablePageConfig } from 'shell/table/table-page-config';

@Component({
	selector: 'ud-company-form',
	templateUrl: './company-form.html',
	standalone: false,
})
export class CompanyFormComponent implements OnInit, OnDestroy, CompanyContent {

	@editedData protected edited: boolean;

	company?: Company; // set by content resolver
	title: string;

	protected error: AppError;
	protected form: UfControlGroup;
	protected nameControl: UfControl | undefined;
	protected isNameControlRequired: boolean;
	protected statusControl: UfControl | undefined;
	protected isStatusControlRequired: boolean;
	protected breadcrumbs: Breadcrumb[] = [];
	protected companyStatusOptions: Option[];
	protected companyDisabledIdentifiers: string[] = [];

	protected readonly sharedTK = SharedTermsTranslationKey;
	protected readonly commonTK = CommonTranslationKey;
	protected readonly shellTK = ShellTranslationKey;
	protected readonly discoverTK = DiscoverTranslationKey;
	protected readonly controlKeys = CompanyKeys;
	protected readonly fieldWidth = FieldWidth;

	private router = inject(Router);
	private route = inject(ActivatedRoute);
	private companiesClient = inject(CompaniesClient);
	private translateService = inject(TranslateService);
	private toastService = inject(ToastService);
	private errorService = inject(ErrorService);
	private tableConfig = inject(TablePageConfig);
	private companyFormContext = inject(CompanyFormContext);
	private companyFormPermissionsProvider = inject(CompanyFormProvider);
	private companyFormController = inject(CompanyFormControl);
	private tableManager = inject<TableContainerManager<Company, FilterValue, FilterEntry>>(TableContainerManager, { optional: true });
	private subscriptions = new Subscription();

	ngOnInit() {

		this.companyStatusOptions = [
			{ identifier: UserStatus.Active, name: this.translateService.instant(CommonTranslationKey.UserActiveLabel) },
			{ identifier: UserStatus.Inactive, name: this.translateService.instant(CommonTranslationKey.UserInactiveLabel) },
			{ identifier: UserStatus.Pending, name: this.translateService.instant(CommonTranslationKey.UserPendingLabel) },
		];

		this.companyFormContext.action = this.company ? PermissionAction.Update : PermissionAction.Add;
		this.form = this.companyFormController.buildRoot(this.company);
		this.nameControl = this.form.get(CompanyKeys.Name) as UfControl | undefined;
		this.isNameControlRequired = this.companyFormPermissionsProvider.isEditFieldRequired(CompanyKeys.Name, this.companyFormContext.action);
		this.statusControl = this.form.get(CompanyKeys.Status) as UfControl | undefined;
		this.isStatusControlRequired = this.companyFormPermissionsProvider.isEditFieldRequired(CompanyKeys.Status, this.companyFormContext.action);

		this.companyDisabledIdentifiers = this.getDisableControlKeys(this.form);

		this.subscriptions.add(this.form.valueChanges.subscribe(() => { this.edited = true; }));

		this.breadcrumbs = [
			{ name: this.tableConfig.table.title, urlSegments: ['..'] },
			{ name: this.nameControl?.getRawValue() ?? this.translateService.instant(SharedTermsTranslationKey.NewLabel) },
		];
	}

	ngOnDestroy() {
		this.subscriptions.unsubscribe();
	}

	protected back() {
		void this.router.navigate(['..'], { relativeTo: this.route });
	}

	protected async save() {
		this.form.setSubmitted();

		if (this.form.invalid) {
			return;
		}

		const company = this.companyFormController.toDataModel(this.form);

		try {
			if (this.company) {
				company.id = this.company.id;
				await this.companiesClient.save(company);
				this.toastService.success(this.translateService.instant(this.discoverTK.CompanySavedMessage));
				this.tableManager?.updateItem?.next(company);
			} else {
				await this.companiesClient.save(company);
				this.toastService.success(this.translateService.instant(this.discoverTK.CompanyCreatedMessage));
				this.tableManager?.reload?.next();
			}

			this.edited = false;
			this.back();
		} catch (error) {
			this.toastService.error((error as Error).message || this.errorService.unknownErrorMessage);
		}

	}

	private getDisableControlKeys(group: UfControlGroup): string[] {
		return Object.keys(group.controls)
			.reduce<string[]>((keys, key) => {
				if (group.get(key)?.disabled) {
					keys.push(key);
				}

				return keys;
			}, []);
	}

}
