import { RuntimeField, fieldIterator } from '@unifii/library/common';
import { Dictionary } from '@unifii/sdk';
import { addDays, addMonths, addWeeks, endOfDay, endOfMonth, endOfWeek, endOfYear, startOfDay, startOfMonth, startOfWeek, startOfYear } from 'date-fns';

export const MomentDateFormat = 'YYYY-MM-DD'; // Do not remove, used in forks of discover
export const MomentDatetimeFormat = 'YYYY-MM-DDTHH:mm:ss';

export const getStartOf = (date: Date, unit: string): Date => {
	switch (unit) {
		case 'day':
			return startOfDay(date);
		case 'week':
			return startOfWeek(date);
		case 'month':
			return startOfMonth(date);
		case 'year':
			return startOfYear(date);
		default:
			return date;
	}
};

export const getEndOf = (date: Date, unit: string): Date => {
	switch (unit) {
		case 'day':
			return endOfDay(date);
		case 'week':
			return endOfWeek(date);
		case 'month':
			return endOfMonth(date);
		case 'year':
			return endOfYear(date);
		default:
			return date;
	}
};

export const addOf = (date: Date, unit: string, amount: number): Date => {
	switch (unit) {
		case 'day':
			return addDays(date, amount);
		case 'week':
			return addWeeks(date, amount);
		case 'month':
			return addMonths(date, amount);
		default:
			return date;
	}
};

export const findField = (fields: RuntimeField[], id: string): RuntimeField | undefined => {

	for (const { field } of fieldIterator(fields)) {
		if (field.identifier === id) {
			return field;
		}
	}

	return;
};

export const cleanObj = (obj: Dictionary<any>): Dictionary<any> => {
	const copy = { ...obj };

	for (const key of Object.keys(copy)) {
		const value = copy[key];

		if (value == null || value === '') {
			delete copy[key];
		}
	}

	return copy;
};
