import { Component, EventEmitter, HostBinding, Input, OnDestroy, OnInit, Output, ViewChild, inject } from '@angular/core';
import { TableInputs } from '@unifii/components';
import { AngularRouterLink, ColumnDisplayDescriptor, ContextProvider, FilterValue, SortStatus, TableComponent, TableConfig, TableDataSource, getTableCustomColumnsDisplayDescriptors } from '@unifii/library/common';
import { CompaniesClient, Company, Definition, FormData, PermissionAction, TableDetailTemplate, TableSourceType, UserInfo, UsersClient, ensureUfRequestError, mapUserToUserContext } from '@unifii/sdk';
import { Subscription } from 'rxjs';

import { Config } from 'config';
import { ContentDataResolver } from 'shell/content/content-data-resolver';
import { TableDisplayMode } from 'shell/content/content-node.component';
import { ErrorService } from 'shell/errors/error.service';
import { AppError } from 'shell/errors/errors';
import { UsFormService, UsFormServiceFactoryLogic, formServiceFactory } from 'shell/form/form-service.service';
import { Authentication } from 'shell/services/authentication';
import { PermissionsFunctions } from 'shell/services/permissions-functions';
import { FormDataPath } from 'shell/shell-constants';
import { TableNodeInfo } from 'shell/shell-model';
import { ShellTranslationKey } from 'shell/shell.tk';
import { CompanyTableDataSource } from 'shell/table/companies/company-table-datasource';
import { BucketTableDataSource } from 'shell/table/form-data/bucket-table-datasource';
import { TableData } from 'shell/table/models';
import { TableColumnFactory } from 'shell/table/table-column-factory';
import { checkShowCount } from 'shell/table/table-functions';
import { TableInputManagerFactory } from 'shell/table/table-input-manager-factory';
import { UsersTableDataSource } from 'shell/table/users/users-table-datasource';

@Component({
	selector: 'us-dashboard-table',
	templateUrl: './dashboard-table.html',
	styleUrls: ['./dashboard-table.less'],
	providers: [
		{ provide: UsFormService, useFactory: formServiceFactory, deps: [UsFormServiceFactoryLogic] },
		TableInputManagerFactory,
	],
	standalone: false,
})
export class DashboardTableComponent implements OnInit, OnDestroy {

	@Input({ required: true }) tableInfo: TableNodeInfo;
	@Input() pageMode = TableDetailTemplate.PageView;

	@Output() hideChange = new EventEmitter<boolean>();

	@ViewChild(TableComponent) protected tableComponent: TableComponent<TableData> | null;
	@HostBinding('class.hide') protected _hide: boolean;

	protected readonly shellTK = ShellTranslationKey;

	protected recordCount: number | undefined;
	protected tableConfig: TableConfig<TableData> | null = null;
	protected error: AppError | undefined;
	protected formsDefinition: Map<string, Definition> = new Map<string, Definition>();
	protected datasource: TableDataSource<TableData> | null = null;
	protected customColumns: ColumnDisplayDescriptor[] = [];
	protected tableLink: AngularRouterLink;

	private config = inject(Config);
	private auth = inject(Authentication);
	private companiesClient = inject(CompaniesClient);
	private usersClient = inject(UsersClient);
	private errorService = inject(ErrorService);
	private formService = inject(UsFormService);
	private contextProvider = inject(ContextProvider);
	private dataResolver = inject(ContentDataResolver);
	private inputManagerFactory = inject(TableInputManagerFactory);
	private tableColumnFactory = inject(TableColumnFactory);
	private dataSubscription: Subscription | null;

	get showSeeMoreRow() {
		return this.tableComponent?.status.exhausted === false;
	}

	private get canShowRecordCount() {
		return this.datasource && this.config.unifii.tenantSettings?.features.indexing && this.tableInfo.table.showCount;
	}

	private set hide(v: boolean) {
		if (v === this._hide) {
			return;
		}
		this._hide = v;
		this.hideChange.emit(v);
	}

	async ngOnInit() {
		try {
			if (!this.tableInfo.table.source) {
				throw new Error(`Source not defined for table ${this.tableInfo.table.sourceType}|${this.tableInfo.table.identifier}`);
			}
			this.formService.init(this.tableInfo.table.source);
			const { tablePageConfig } = await this.dataResolver.getTableData(this.tableInfo.table.identifier);
			const { propertyDescriptors } = tablePageConfig;

			this.tableConfig = {
				columns: this.tableColumnFactory.create(
					this.tableInfo.table.sourceType,
					this.tableInfo.table.columns ?? [],
					propertyDescriptors,
					false,
				),
				pageSize: this.tableInfo.pageSize,
				row: {
					link: (item: TableData) => {
						if (this.tableInfo.table.sourceType === TableSourceType.Bucket && this.canRouteToItem(item)) {

							if (this.tableInfo.table.detail) {
								return ['/', this.tableInfo.table.identifier, item.id, { mode: TableDisplayMode.Detail }];
							}

							return ['/', FormDataPath, this.tableInfo.table.source, item.id];
						}

						return [];
					},
				},
				exhaustAhead: checkShowCount(this.config, this.tableInfo.table),
			};

			this.customColumns = getTableCustomColumnsDisplayDescriptors(tablePageConfig.table.columns);

			// Load data
			this.reload();

			if (this.canShowRecordCount) {
				this.recordCount = await this.datasource?.count;
			}

			this.tableLink = ['/', this.tableInfo.table.identifier];

		} catch (e) {
			this.error = ensureUfRequestError(e, this.errorService.unknownErrorMessage);
		}
	}

	ngOnDestroy() {
		this.dataSubscription?.unsubscribe();
	}

	reload() {
		this.dataSubscription?.unsubscribe();
		this.datasource = this.createDataSource();

		/**
         * listen to the datasource first load (no pagination for dashboard-table)
         * decide if shows the table expander container or keep it hidden
        */
		this.dataSubscription = this.datasource.connect().subscribe({
			next: (result) => {
				this.hide = !result.error && !result.data?.length && this.pageMode === TableDetailTemplate.PageViewHideEmptyTables;
			},
			error: () => {
				this.hide = false;
			},
		});
	}

	private createDataSource(): TableDataSource<TableData> {
		const tableInputManager = this.inputManagerFactory.create(this.tableInfo.table);

		let tableInputs: TableInputs<FilterValue> | undefined;
		const sort = SortStatus.fromString(this.tableInfo.table.defaultSort) ?? undefined;

		if (sort) {
			tableInputs = { sort };
		}

		switch (this.tableInfo.table.sourceType) {

			case TableSourceType.Users:
				return new UsersTableDataSource(
					this.usersClient,
					this.tableInfo.table.identifier,
					tableInputManager,
					tableInputs,
				) as TableDataSource<TableData>;

			case TableSourceType.Company:
				return new CompanyTableDataSource(
					this.companiesClient,
					this.tableInfo.table.identifier,
					tableInputManager,
					tableInputs,
				) as TableDataSource<TableData>;

			case TableSourceType.Bucket:
				if (!this.tableInfo.table.source) {
					throw new Error(`Source not defined for table ${this.tableInfo.table.sourceType}|${this.tableInfo.table.identifier}`);
				}

				this.formService.init(this.tableInfo.table.source);

				return new BucketTableDataSource({
					formService: this.formService,
					tableIdentifier: this.tableInfo.table.identifier,
					tableInputManager,
					tableInputs,
					showCount: checkShowCount(this.config, this.tableInfo.table),
				});
		}
	}

	private canRouteToItem(item: TableData): boolean {

		const table = this.tableInfo.table;

		switch (table.sourceType) {
			case TableSourceType.Bucket:
				return !!table.source && !!item.id && this.auth.getGrantedInfo(
					PermissionsFunctions.getBucketDocumentPath(this.config.unifii.projectId, table.source, item.id),
					PermissionAction.Read,
					item as FormData,
					this.contextProvider.get(),
				).granted;
			case TableSourceType.Company:
				return this.auth.getGrantedInfo(
					PermissionsFunctions.getCompanyPath(item.id),
					PermissionAction.Read,
					item as Company,
					this.contextProvider.get(),
				).granted;
			case TableSourceType.Users: {
				const userInfo = item as UserInfo;

				return !!userInfo.id && this.auth.getGrantedInfo(
					PermissionsFunctions.getUserPath(+userInfo.id),
					PermissionAction.Read,
					mapUserToUserContext(item as UserInfo),
					this.contextProvider.get(),
				).granted;
			}
		}
	}

}
