<uf-panel class="container bg-gray">
	@if (definition && formData) {
		<uf-form [definition]="definition" [config]="formConfig" [(formData)]="formData" (submit)="save($event)" (cancelClick)="back()" (edited)="edited = $event">
			<div header class="uf-app-bar transparent">
				<a (click)="back()" class="uf-button tertiary left align-left">
					<uf-icon name="arrowBack" />
					{{ sharedTK.ActionBack | translate }}
				</a>
			</div>
			<footer footer>
				@if (definition.version) {
					<uf-data-display-list class="pad small small-label">
						<dt>{{ commonTK.FormMetadataFieldDefinitionVersionLabel | translate }}</dt>
						<dd>{{ definition.version }}</dd>
					</uf-data-display-list>
				}
			</footer>
		</uf-form>
	}
</uf-panel>
