import { Definition, FormDataOptionsInterface } from '@unifii/sdk';

export enum FileState {
	Pending = 'Pending',
	Uploaded = 'Uploaded',
}

export enum FormDataState {
	Pending = 'Pending',
	InProgress = 'InProgress',
	Uploaded = 'Uploaded',
	Conflicted = 'Conflicted',
}

export interface FileInfo {
	id: string;
	name: string;
	properties: FilePropertyBag;
	size: number;
	storedAt: Date;
	state: FileState;
}

export interface FormInfo extends FormDataOptionsInterface {
	id: string;
	form: Definition;
	result: string;
	state: string;
	storedAt: Date;
	size: number;
	status: FormDataState;
}
