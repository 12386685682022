import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CommonTranslationKey, SharedTermsTranslationKey, UfControl, ValidatorFunctions } from '@unifii/library/common';
import { Subscription } from 'rxjs';

import { DeviceRegistrationService } from 'capacitor/device-registration.service';
import { Config, Environment } from 'config';
import { UserAccessRootPath } from 'discover/discover-constants';
import { AppError } from 'shell/errors/errors';
import { TenantSettingsService } from 'shell/services/tenant-settings.service';
import { UserAccessManager } from 'shell/services/user-access-manager';

@Component({
	selector: 'ud-tenant-selector',
	templateUrl: './tenant-selector.html',
	standalone: false,
})
export class TenantSelectorComponent implements OnDestroy, OnInit {

	protected readonly sharedTermsTK = SharedTermsTranslationKey;
	protected readonly commonTK = CommonTranslationKey;
	protected control: UfControl;

	private config = inject(Config);
	private translate = inject(TranslateService);
	private router = inject(Router);
	private route = inject(ActivatedRoute);
	private accessManager = inject(UserAccessManager);
	private env = inject(Environment);
	private tenantSettings = inject(TenantSettingsService);
	private deviceRegistrationService = inject(DeviceRegistrationService);
	private projectId: string | undefined;
	private subscriptions = new Subscription();

	ngOnInit() {

		// it is unlikely but if someone hacks the url to get to this component reroute to main
		if (this.env.unifii.tenant) {
			void this.router.navigate(['/', UserAccessRootPath]);
		}

		this.control = this.createControl();

		this.subscriptions.add(this.route.params.subscribe((params) => {
			if (params.projectId) {
				this.projectId = params.projectId;
			}

			if (params.tenant) {
				this.control.setValue(params.tenant.toLowerCase());
			}
		}));
	}

	ngOnDestroy() {
		this.subscriptions.unsubscribe();
	}

	async setTenant(): Promise<void> {

		this.accessManager.showError(null);

		/**
         * Extra guard added for Mobile Safari UI/WKWebView
         * tenant is evaluated as undefined guessing it's caused timing issue with validators not firing fast enough
         * sentry error: https://sentry.unifii.dev/organizations/unifii-pty-ltd/issues/258/events/?environment=prod&project=7
         */
		if (this.control.invalid) {
			this.control.setSubmitted();

			return;
		}
		try {

			const tenant = this.control.getRawValue() as string | undefined;
			const params = this.projectId ? { projectId: this.projectId } : {};

			if (!tenant) {
				return;
			}

			await this.tenantSettings.setTenant(tenant.toLowerCase());
			await this.deviceRegistrationService.register();

			void this.router.navigate(['/', UserAccessRootPath, params]);
		} catch (e) {
			this.accessManager.showError(e as AppError);
		}
	}

	private createControl(): UfControl {

		return new UfControl([
			ValidatorFunctions.custom((v) => this.config.unifii.tenant != null || (v != null && v !== ''), this.translate.instant(SharedTermsTranslationKey.ValidatorValueRequired)),
			ValidatorFunctions.pattern(/^[A-Za-z0-9.-]+$/, this.translate.instant(SharedTermsTranslationKey.ValidatorInvalidCharacters)),
			ValidatorFunctions.custom((v) => !/\s/.test(v), this.translate.instant(SharedTermsTranslationKey.ValidatorInvalidSpaces)),
		]);
	}

}
