import { AfterViewInit, Component, ElementRef, HostBinding, OnInit, ViewChild, inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Modal, ModalConfirmData, ModalData, ModalRuntime, SharedTermsTranslationKey } from '@unifii/library/common';

export interface ConfirmExtendedData extends ModalConfirmData {
	content: string;
}

@Component({
	selector: 'us-confirm-extended-modal',
	templateUrl: './confirm-extended-modal.html',
	styleUrls: ['./confirm-extended-modal.less'],
	standalone: false,
})
export class ConfirmExtendedModalComponent implements Modal<ConfirmExtendedData, boolean>, AfterViewInit, OnInit {

	@HostBinding('class.uf-form-card') class = true;
	@ViewChild('content') private content: ElementRef;

	runtime = inject<ModalRuntime<ConfirmExtendedData, boolean>>(ModalRuntime);
	data = inject<ConfirmExtendedData>(ModalData);

	protected readonly sharedTermsTK = SharedTermsTranslationKey;

	private translate = inject(TranslateService);

	ngOnInit() {
		const data = this.data;

		this.data = Object.assign({
			cancelLabel: this.translate.instant(SharedTermsTranslationKey.ActionCancel),
			confirmLabel: this.translate.instant(SharedTermsTranslationKey.ActionConfirm),
		}, data);
	}

	ngAfterViewInit() {
		if (this.content) {
			this.content.nativeElement.innerHTML = this.data.content;
		}
	}

	close(value: boolean) {
		this.runtime.close(value);
	}

}
