<div class="uf-container-sm">
	<div class="auth-providers col">
		<button [disabled]="inProgress" (click)="identitySignIn()" type="button" class="authentication-provider">
			@if (authProvider?.providerLoginIcon) {
				<img [src]="authProvider?.providerLoginIcon" />
			}
			<p [class.grow]="!authProvider?.providerLoginIcon" class="small">{{ authProvider?.providerLoginLabel }}</p>
		</button>
	</div>
</div>
