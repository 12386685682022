@if (image) {
	<img [src]="image" />
}
<h3>{{ heading }}</h3>
<p>{{ message }}</p>
@if (retry) {
	<button (click)="emitRetry()" type="button" class="uf-button primary">
		{{ retry || (sharedTermsTK.ActionRetry | translate) }}
	</button>
}
