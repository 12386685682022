<form (ngSubmit)="resetPassword()" class="uf-grid">
	@if (successMessage) {
		<uf-blockquote [content]="successMessage" class="success col-12 small" icon="successSolid">
			<h3>{{ discoverTK.RequestPasswordChangeFeedbackEmailSentHeading | translate }}</h3>
		</uf-blockquote>
	}

	@if (!successMessage) {
		<uf-text [label]="commonTK.UsernameLabel | translate" [control]="control" class="col-12" name="username" autocomplete="username" />
	}

	<div class="col-12 col center-all gap-lg-top">
		@if (!successMessage) {
			<button class="uf-button primary" type="submit">
				{{ discoverTK.ActionRequestPasswordChange | translate }}
			</button>
		}
	</div>
</form>
