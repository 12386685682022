@if (!tableNodeInfos && !error) {
	<div @fade class="skeleton-container dashboard"></div>
}

@if (error) {
	<div class="uf-container-md">
		<uf-message [content]="error.message" icon="errorSolid" class="error padded gap-top">
			<h3>{{ sharedTk.Error | translate }}</h3>
		</uf-message>
	</div>
}

@if (tableNodeInfos) {
	<uf-panel class="container">
		@if (isMenuView) {
			<uf-tabs [responsive]="true" class="vertical">
				@for (tableInfo of tableNodeInfos; track tableInfo) {
					@if (tableInfo.table.title) {
						<uf-tab [label]="tableInfo.table.title" [path]="tableInfo.table.identifier" />
					}
				}
			</uf-tabs>
		}
	</uf-panel>
}

@if (!isMenuView && tableNodeInfos) {
	<us-dashboard [tableInfos]="tableNodeInfos" [pageMode]="pageMode" [emptyMessage]="emptyMessage" />
}
