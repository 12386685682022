import { Injectable, Type } from '@angular/core';
import { ContentField } from '@unifii/library/smart-forms';
import { UfViewComponentRegistry } from '@unifii/library/smart-forms/display';
import { FieldType } from '@unifii/sdk';

import { LinkListComponent } from './link-list.component';
import { LinkComponent } from './link.component';

@Injectable()
export class UsViewComponentRegistry extends UfViewComponentRegistry {

	override get(type: FieldType): Type<ContentField> | null {
		switch (type) {
			case FieldType.Link:
			case FieldType.DefinitionLink:
				return LinkComponent;

			case FieldType.LinkList:
				return LinkListComponent;

			default:
				return super.get(type);
		}
	}

}
