import { Component, HostBinding, Input, OnInit, inject } from '@angular/core';
import { StructureNode, StructureNodeType } from '@unifii/sdk';

import { NavigationService, StructureNodeAccessInfo } from 'shell/nav/navigation.service';

@Component({
	selector: 'us-nav-item',
	templateUrl: 'nav-item.html',
	styleUrls: ['nav-item.less'],
	standalone: false,
})
export class NavItemComponent implements OnInit {

	@Input() node: StructureNode;
	@Input() level = 0;

	accessInfo: StructureNodeAccessInfo;
	show: boolean;
	expanded: boolean;
	link: string | null;
	href: string | null;

	private nav = inject(NavigationService);

	@HostBinding('class.active-child') get isRootAndActive() {

		const active: StructureNode = this.nav.current as StructureNode;

		if (active === this.node) {
			return true;
		}

		for (const node of this.nav.getAncestors(active)) {
			if (node === this.node) {
				return true;
			}
		}

		return null;
	}

	@HostBinding('class.expanded') get expandedClass() {
		return this.expanded;
	}

	ngOnInit() {
		// console.log(`Node ${this.node.name}`);
		const accessInfo = this.nav.getNodeAccessInfo(this.node);

		if (!accessInfo) {
			this.show = false;

			return;
		}

		this.accessInfo = accessInfo;

		if (!this.accessInfo.matchNodeRules || !this.accessInfo.matchACLs) {
			this.show = false;

			return;
		}

		this.show = true;

		switch (this.node.type) {
			case StructureNodeType.Link:
				// Discern a Structure Link node (External URL Link) from a custom Menu entry from settings.ts
				this.href = this.nav.linkify(this.node.url);
				break;
			default:
				this.link = this.nav.nodeToUrl(this.node);
				break;
		}
	}

	get isActive() {
		return this.nav.current === this.node;
	}

}
