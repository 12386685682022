import { Injectable, inject } from '@angular/core';
import { BucketLoader, ClaimLoader, CollectionItemMetadataIdentifiers, CollectionLoader, DataSourceConverter, DataSourceDisplayTo, DataSourceIdTo, ExpressionParser, FilterLoader, FilterType, FilterValue, ProvisioningLoader, SourceConfigBucket, SourceConfigCollection, SourceConfigUser, SourceConfigUserClaims, sourceConfigMappingsToSourceConfigMappingInfo } from '@unifii/library/common';
import { Client, DATE_DATA_FORMAT, DataSourceType, Dictionary, FieldType, FormDataClient, Option, PermissionAction, PublishedContent, TenantClient, UsersClient } from '@unifii/sdk';
import { ChartType } from 'chart.js';
import { format } from 'date-fns';

import { Config } from 'config';
import { DiscoverContext } from 'discover/discover-context';
import { Authentication } from 'shell/services/authentication';
import { PermissionsFunctions } from 'shell/services/permissions-functions';

export interface ReportConfig {
    identifier: string;
    title: string;
    chartType: ChartType | 'table';
    width: number;
    dateFilters?: ReportDateFilterConfig;
    customFilters?: ReportCustomFilterConfig[];
    xAxis?: ReportAxisConfig;
    yAxis?: ReportAxisConfig;
    legend?: ReportLegendConfig;
    datalabels?: ReportDatalabelsConfig;
}

export interface ReportAxisConfig {
    label?: string;
    stacked?: boolean;
    ticks?: { min?: number; max?: number; precision?: number }; // TODO min, max not inline with chartjs 3.x types
}

export interface ReportLegendConfig {
    position: 'left' | 'right' | 'top' | 'bottom';
    align: 'start' | 'end';
    display: boolean;
}

export interface ReportDatalabelsConfig {
    content?: DataLabelContent;
    display?: boolean | 'auto';
    align?: 'left' | 'right' | 'top' | 'bottom' | 'center' | 'start' | 'end';
    anchor?: 'center' | 'start' | 'end';
    clamp?: boolean;
    offset?: number;
}

export enum DataLabelContent {
    DatasetLabel = 'dataset-label',
    DataLabel = 'data-label',
    Value = 'value',
    X = 'x',
    Y = 'y',
    R = 'r'
}

export interface ReportDateFilterConfig {
    startDate?: boolean;
    endDate?: boolean;
    presetRanges?: boolean;
    intervals?: boolean;
}
export interface ReportCustomFilterConfig { // TODO extend FilterEntry in the future
    identifier: string;
    label: string;
    type: FieldType;
    range?: boolean;
    options?: Option[];
    loader?: ReportDataSourceLoaderConfig; // TODO change to loaderConfig
    customPosition?: boolean;
}

export interface ReportDataSourceLoaderConfig {
    type: DataSourceType;
    id?: string;
    identifierProperty?: string;
    nameProperty?: string;
}

export interface ReportData {
    labels: (string | { value: string })[];
    datasets: ReportDataset[];
}

export interface ReportDataset {
    label?: string;
    labels?: string[];
    tooltips?: string[] | string[][];
    data: any[];
    color?: string | string[];
    tension?: number;
}

@Injectable()
export class ReportService {

    private auth = inject(Authentication);
    private client = inject(Client);
    private config = inject(Config);
    private content = inject(PublishedContent);
    private context = inject(DiscoverContext);
    private dataSourceConverter = inject(DataSourceConverter);
    private parser = inject(ExpressionParser);
    private tenantClient = inject(TenantClient);
    private usersClient = inject(UsersClient);

    getConfig(reportId: string): Promise<ReportConfig> {

        const configUrl = this.url([reportId, 'config']);

        return this.client.get(configUrl) as Promise<ReportConfig>;
    }

    getData(reportId: string, filters: Dictionary<FilterValue>): Promise<ReportData> {
        const dataUrl = this.url([reportId, 'data']);

        return this.client.get(dataUrl, { params: { ...filters, today: format(new Date(), DATE_DATA_FORMAT) } }) as Promise<ReportData>;
    }

    // eslint-disable-next-line complexity
    createFilterLoader(loaderConfig?: ReportDataSourceLoaderConfig): FilterLoader | undefined {
        switch (loaderConfig?.type) {
            case DataSourceType.Collection:
            {
                if (!this.context.project?.id || !loaderConfig.id) {
                    return;
                }

                if (!this.auth.getGrantedInfoWithoutCondition(PermissionsFunctions.getCollectionItemsPath(this.context.project.id, loaderConfig.id), PermissionAction.List).granted) {
                    return;
                }

                const { mappings, mappingsTo, mappingsFrom, mappingsVisibles } = sourceConfigMappingsToSourceConfigMappingInfo([
                    { from: CollectionItemMetadataIdentifiers.Id, to: DataSourceIdTo, type: FieldType.Text, label: loaderConfig.nameProperty ?? 'id' },
					{ from: loaderConfig.nameProperty ?? 'name', to: DataSourceDisplayTo, type: FieldType.Text, label: loaderConfig.nameProperty ?? 'name' },
                ]);

                const config: SourceConfigCollection = {
                    type: loaderConfig.type,
                    mappings,
                    mappingsTo,
                    mappingsFrom,
                    mappingsVisibles,
                    id: loaderConfig.id,
                };

                return new CollectionLoader(
                    config,
                    undefined,
                    this.content,
                    this.dataSourceConverter,
                    this.parser,
                );
            }
            case DataSourceType.Users:
                {
                    if (!this.auth.getGrantedInfoWithoutCondition(PermissionsFunctions.getUsersPath(), PermissionAction.List).granted) {
                        return;
                    }

                    const { mappings, mappingsTo, mappingsFrom, mappingsVisibles } = sourceConfigMappingsToSourceConfigMappingInfo([
                        { from: 'id', to: DataSourceIdTo, type: FieldType.Text, label: loaderConfig.nameProperty ?? 'username' },
                        { from: 'username', to: DataSourceDisplayTo, type: FieldType.Text, label: loaderConfig.nameProperty ?? 'username' },
                    ]);

                    const config: SourceConfigUser = {
                        type: loaderConfig.type,
                        mappings,
                        mappingsTo,
                        mappingsFrom,
                        mappingsVisibles,
                    };

                    return new ProvisioningLoader(
                        config,
                        undefined,
                        this.usersClient,
                        this.dataSourceConverter,
                        this.parser,
                    );
                }
            case DataSourceType.UserClaims:
            {
                if (!loaderConfig.id) {
                    return;
                }

                const { mappings, mappingsTo, mappingsFrom, mappingsVisibles } = sourceConfigMappingsToSourceConfigMappingInfo([
                    { from: 'id', to: DataSourceIdTo, type: FieldType.Text, label: loaderConfig.nameProperty ?? 'display' },
                    { from: 'display', to: DataSourceDisplayTo, type: FieldType.Text, label: loaderConfig.nameProperty ?? 'display' },
                ]);

                const config: SourceConfigUserClaims = {
                    type: loaderConfig.type,
                    mappings,
                    mappingsTo,
                    mappingsFrom,
                    mappingsVisibles,
                    id: loaderConfig.id,
                };

                return new ClaimLoader(
                        config,
                        undefined,
                        this.tenantClient,
                        this.dataSourceConverter,
                    );
            }

            case DataSourceType.Bucket:
            {
                if (!this.context.project?.id || !loaderConfig.id) {
                    return undefined;
                }

                if (!this.auth.getGrantedInfoWithoutCondition(PermissionsFunctions.getBucketDocumentsPath(this.context.project.id, loaderConfig.id), PermissionAction.List).granted) {
                    return;
                }

                const formDataClient = new FormDataClient(this.client, {
                    bucket: loaderConfig.id,
                    preview: this.context.preview,
                    projectId: this.context.project.id,
                });

                const { mappings, mappingsTo, mappingsFrom, mappingsVisibles } = sourceConfigMappingsToSourceConfigMappingInfo([
					{ from: loaderConfig.identifierProperty ?? 'id', to: DataSourceIdTo, type: FieldType.Text, label: loaderConfig.nameProperty ?? 'name' },
					{ from: loaderConfig.nameProperty ?? 'name', to: DataSourceDisplayTo, type: FieldType.Text, label: loaderConfig.nameProperty ?? 'name' },
				]);

                const config: SourceConfigBucket = {
                    type: loaderConfig.type,
                    mappings,
                    mappingsTo,
                    mappingsFrom,
                    mappingsVisibles,
                    id: loaderConfig.id,
                };

                return new BucketLoader(
                    config,
                    undefined,
                    formDataClient,
                    this.dataSourceConverter,
                    this.parser,
                );
            }
            default: return;
        }
    }

    // TODO remove this duplicate once improved filters implementation in Report
    getFilterType(type: FieldType, loader?: FilterLoader): FilterType {
        switch (type) {
            case FieldType.Text:
            case FieldType.MultiText:
            case FieldType.Phone:
            case FieldType.Email:
            case FieldType.Website:
                return FilterType.Text;

            case FieldType.Date:
                return FilterType.DateRange;

            case FieldType.Time:
                return FilterType.TimeRange;

            case FieldType.DateTime:
                return FilterType.DatetimeRange;

            case FieldType.ZonedDateTime:
                return FilterType.ZonedDatetimeRange;

            case FieldType.Hierarchy:
                return FilterType.HierarchyUnit;

            case FieldType.Number:
                return FilterType.NumberRange;

            case FieldType.Cost:
                return FilterType.Cost;

            case FieldType.Bool:
                return FilterType.Bool;

            case FieldType.Choice:
                return loader ? FilterType.DataSeed : FilterType.Choice;

            case FieldType.MultiChoice:
                return loader ? FilterType.DataSeedArray : FilterType.OptionArray;

            case FieldType.Lookup:
                return FilterType.DataSeedArray;

            default:
                throw new Error(`property type ${type} not recognized`);
        }
    }

    private url(parts: string[] = []): string {
        if (!this.config.unifii.reportingApiUrl) {
            throw new Error('No reportingApiUrl provided');
        }

        if (!this.config.unifii.tenant) {
            throw new Error('No tenant provided');
        }

        parts = parts.map((p) => encodeURIComponent(p));
        parts.unshift('charts');
        if (this.config.unifii.preview) {
            parts.unshift('preview');
        }
        parts.unshift(this.config.unifii.projectId);
        parts.unshift(this.config.unifii.tenant);
        parts.unshift(this.config.unifii.reportingApiUrl);

        return parts.join('/');
    }

}
