<uf-panel class="container">
	<div class="header-pane">
		<button (click)="refreshBuckets()" type="button" class="uf-action">
			<uf-icon name="refreshApp" />
		</button>
	</div>

	@if (showNoEmptyMessage && pageMode === templateEnumVales.PageViewHideEmptyTables) {
		<us-empty-module-message [message]="emptyMessage" />
	}

	@for (tableInfo of tableInfos; track tableInfo) {
		<us-dashboard-table [tableInfo]="tableInfo" [pageMode]="pageMode" (hideChange)="updateHiddenTablesSet(tableInfo, $event)" class="content-pane" />
	}
</uf-panel>
