import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TableContainerComponent, TableContainerManager } from '@unifii/components';
import { Breadcrumb, FilterEntry, FilterValue, SharedTermsTranslationKey, TableDataSource } from '@unifii/library/common';

import { DeviceService } from 'capacitor/device.service';
import { Config } from 'config';
import { DiscoverTranslationKey } from 'discover/discover.tk';
import { ShellService } from 'shell/core/shell.service';
import { BreadcrumbsService } from 'shell/services/breadcrumbs.service';
import { NewItemPath } from 'shell/shell-constants';
import { TableDetailPageComponent } from 'shell/table-detail/table-detail-page.component';

import { TableData } from './models';
import { checkShowCount } from './table-functions';
import { TablePageConfig } from './table-page-config';

@Component({
	selector: 'ud-table-page',
	templateUrl: './table-page.html',
	styleUrls: ['./table-page.less'],
	providers: [BreadcrumbsService],
	standalone: false,
})
export class TablePageComponent implements AfterViewInit, OnDestroy, OnInit {

	@ViewChild(TableContainerComponent) private tableContainerComponent?: TableContainerComponent<TableData, FilterValue, FilterEntry>;

	protected readonly sharedTK = SharedTermsTranslationKey;
	protected readonly discoverTK = DiscoverTranslationKey;
	protected breadcrumbs: Breadcrumb[];
	protected recordCount: number | null;
	protected showRecordCount: boolean;

	private router = inject(Router);
	private route = inject(ActivatedRoute);
	private breadcrumbsService = inject(BreadcrumbsService);
	private config = inject(Config);
	private tablePageConfig = inject(TablePageConfig);
	private manager = inject<TableContainerManager<TableData, FilterValue, FilterEntry>>(TableContainerManager);
	private tableDetailPageComponent = inject(TableDetailPageComponent, { optional: true });
	private deviceService = inject(DeviceService);
	private shell = inject(ShellService);

	ngOnInit() {

		this.breadcrumbsService.title = this.tablePageConfig.table.title;
		this.breadcrumbs = this.breadcrumbsService.getBreadcrumbs();

		this.changeBackButtonRoute();

		this.showRecordCount = checkShowCount(this.config, this.tablePageConfig.table);

		// if it has detail page as parent, it needs to keep it's title
		if (this.tableDetailPageComponent) {
			this.shell.setTitle(this.tableDetailPageComponent.title);
		}
	}

	ngOnDestroy() {
		this.tableDetailPageComponent?.updateItemLink();
	}

	ngAfterViewInit() {
		if (this.showRecordCount && this.tableContainerComponent?.dataSource) {
			void this.updateCount(this.tableContainerComponent.dataSource);
		}
	}

	add(identifier?: string) {
		if (this.manager.addActionCallback != null) {
			this.manager.addActionCallback(identifier);
		} else {
			void this.router.navigate([NewItemPath], { relativeTo: this.route });
		}
	}
	async updateCount(dataSource: TableDataSource<TableData> | null) {
		if (this.showRecordCount && dataSource) {
			this.recordCount = await dataSource.count ?? null;
		}
	}

	private changeBackButtonRoute() {
		if (this.tableDetailPageComponent && this.isMobileView()) {
			for (let index = this.breadcrumbs.length - 1; index >= 0; index--) {
				const breadcrumb = this.breadcrumbs[index];

				if (breadcrumb?.urlSegments) {
					breadcrumb.urlSegments = ['../..'];

					return;
				}
			}
		}
	}

	private isMobileView(): boolean {
		return this.deviceService.isIosMobile() || this.deviceService.isAndroid();
	}

}
